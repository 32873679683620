import React, { useState } from 'react';
import CoreFlex from '../../../../core/flex/CoreFlex';
import { Theme, Typography, useTheme } from '@material-ui/core';
import CoreFormInputMoney from '../../../../core/form/input/money/CoreFormInputMoney';
import CoreFormInput from '../../../../core/form/input/CoreFormInput';
import { CoreIconButton } from '../../../../core/icon-button/CoreIconButton';
import { InputType } from '../../../../../interfaces';
import { makeStyles, createStyles } from '@material-ui/styles';
import { grey } from '@material-ui/core/colors';
import CoreChip from '../../../../core/chip/CoreChip';
import CoreSwitch from '../../../../core/switch/CoreSwitch';
import { Country } from '../../../../../interfaces/country';
import { ProductExclusion } from '../../../../../interfaces/product';
import { formatPrice } from '../../../../../services/helpers';
import CoreTranslatedInput from '../../../../core/form/input/translated/CoreTranslatedInput';
import { useStore } from '../../../../../services/store';
import { Language } from '../../../../../config/translation';
import { stores } from '../../../../../stores';
import { CoreFlagElement } from '../../../../core/flag-element/CoreFlagElement';

interface Props {
    exclusion: ProductExclusion;
    index: number;
    country: Country;
    onChangeExclusion: (
        name: keyof ProductExclusion,
        value: string | number | boolean,
        index: number,
        language?: Language,
    ) => void;
    onDeleteExclusion: (index: number) => void;
    onAddExclusion: () => void;
}

export default function ProductExclusionEditorRow({
    exclusion,
    country,
    index,
    onAddExclusion,
    onDeleteExclusion,
    onChangeExclusion,
}: Props) {
    const classes = useStyles();
    const [isEditMode, setIsEditMode] = useState(exclusion && !exclusion.id);
    const theme = useTheme();
    const [language] = useStore<Language>(stores.language);
    const refund = `-${formatPrice(exclusion.refund, country)}`;
    const translation = exclusion.translations?.[language];

    return (
        <CoreFlex
            onClick={() => {
                setIsEditMode(!isEditMode);
            }}
            alignItems="center"
            justify="space-between"
            className={!exclusion.isActive ? classes.rootInactive : !isEditMode ? classes.root : ''}
        >
            <CoreFlex justify="flex-start" className={classes.switch}>
                <CoreSwitch
                    onValueChange={(value) => {
                        onChangeExclusion('isActive', value, index);
                    }}
                    value={exclusion.isActive}
                />
            </CoreFlex>
            <CoreFlex className={classes.formField}>
                {isEditMode && (
                    <CoreTranslatedInput
                        onSubmit={() => {
                            setIsEditMode(false);
                        }}
                        language={language}
                        translationObject={exclusion.translations || {}}
                        keyName="name"
                        label="Exclusion name"
                        startAdornment={<Typography variant="subtitle1">NO</Typography>}
                        required
                        placeholder="pickles"
                        value={translation?.name || ''}
                        type={InputType.text}
                        onValueChange={(value) => onChangeExclusion('name', value, index, language)}
                    />
                )}

                {!isEditMode && (
                    <CoreFlex className={classes.formField} justify="space-between">
                        <Typography variant="subtitle1">NO {translation?.name}</Typography>
                        <CoreFlagElement language={language} className={classes.flag} />
                    </CoreFlex>
                )}
            </CoreFlex>
            <CoreFlex className={classes.smallField}>
                {isEditMode && (
                    <CoreFormInputMoney
                        onSubmit={() => {
                            setIsEditMode(false);
                        }}
                        countryId={country.id}
                        label="Refund"
                        prefix="-"
                        value={(exclusion.refund && exclusion.refund.toString()) || ''}
                        onValueChange={(value) => onChangeExclusion('refund', value, index)}
                    />
                )}
                {!isEditMode && exclusion.refund !== undefined && exclusion.refund > 0 && (
                    <CoreChip label={refund} color={theme.palette.error.main} className={classes.refund} />
                )}
            </CoreFlex>

            <CoreFlex className={classes.buttons} justify="flex-end">
                {isEditMode && (
                    <CoreIconButton
                        iconName="check"
                        onClick={(event) => {
                            event.stopPropagation();
                            setIsEditMode(false);
                        }}
                    />
                )}
                {!isEditMode && (
                    <CoreIconButton
                        iconName="edit"
                        onClick={(event) => {
                            event.stopPropagation();
                            setIsEditMode(true);
                        }}
                    />
                )}
                <CoreIconButton
                    iconName="delete"
                    onClick={(event) => {
                        event.stopPropagation();
                        onDeleteExclusion(index);
                    }}
                />
            </CoreFlex>
        </CoreFlex>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:hover': {
                backgroundColor: grey[100],
            },
        },
        rootInactive: {
            opacity: 0.25,
        },
        switch: {
            width: '100px',
        },
        smallField: {
            padding: '0.5rem 0.5rem',
            width: '250px',
        },
        miniField: {
            padding: '0.5rem 0.5rem',
            minWidth: '150px',
            width: '100%',
        },
        buttons: {
            opacity: 0.7,
            width: '150px',
            marginLeft: '1rem',
        },
        refund: {
            fontSize: '1rem',
        },
        flag: {
            height: '25px',
        },
        formField: {
            display: 'flex',
            flexBasis: 0,
            flexGrow: 1,
            flexShrink: 1,
            padding: '0.5rem 1rem',
        },
    }),
);
