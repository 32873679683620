import { DbBase } from './common';
import { User, UserLocation } from './user';

export enum OrderType {
    DINE_IN = 'DINE_IN',
    TAKE_AWAY = 'TAKE_AWAY',
    ONLINE = 'ONLINE',
}

export const OrderTypeIcons = {
    [OrderType.DINE_IN]: 'local_dining',
    [OrderType.TAKE_AWAY]: 'takeout_dining',
    [OrderType.ONLINE]: 'delivery_dining',
};

export interface OrderTimerThreshold {
    ok: number;
    late: number;
}

export enum OrderStatus {
    RESTAURANT_NO_SHOW = -6,
    COURIER_NO_SHOW = -5,
    CUSTOMER_NO_SHOW = -4,
    REFUNDED = -3,
    CANCELLED = -2,
    ABANDONED = -1,
    CREATED = 0,
    ITEMS_IN_BASKET = 1,
    CHECKOUT = 2,
    COMPLETED = 3,
    RECEIVED = 4,
    COOKING = 5,
    READY = 6,
    PICKED_UP = 7,
    DELIVERED = 8,
    RATED = 9,
    SHARED = 10,
}

export enum PaymentMethod {
    CASH_ON_DELIVERY = 1,
    PAYPAL = 2,
    STRIPE = 3,
    CASH = 4,
    MUNCHIES_COINS = 5,
    GIFT_VOUCHER = 6,
    COMBINED = 7,
}

export interface OrderDb extends DbBase {
    userId: number;
    restaurantId: number;
    type: OrderType;
    status: OrderStatus;
    staffId?: number;
    courierId?: number;
    destinationId?: number;
    paymentId?: number;
    isPaid: boolean;
    totalPrice: number;
    tax: number;
    totalDiscount?: number;
    deliveryDistance?: number;
    deliveryDurationEstimate?: number;
    deliveryCost?: number;
    finalPrice: number;
    paymentMethod: PaymentMethod;
}

export interface OrderEvent extends DbBase {
    orderId: number;
    status: OrderStatus;
    previousStatus?: OrderStatus;
    handledBy?: number;
    comment?: string;
    meta?: string;
}

export interface OrderItemDb extends DbBase {
    orderId: number;
    productId: number;
    restaurantId: number;
    quantity: number;
    name: string;
    specialRequest?: string;
    totalPrice?: number;
    readyAt?: Date;
    isRefunded?: boolean;
    isSoldOut?: boolean;
}

export interface OrderItemChoice {
    id?: number;
    orderItemId: number;
    choiceId: number;
    name: string;
    selection: string;
    selectionName: string;
}

export interface OrderItemVariation {
    id?: number;
    orderItemId: number;
    variationId: number;
    name: string;
    price: number;
}

export interface OrderItemExtra {
    id?: number;
    orderItemId: number;
    extraId: number;
    name: string;
    price: number;
}

export interface OrderItemExclusion {
    id?: number;
    orderItemId: number;
    exclusionId: number;
    name: string;
    refund?: number;
}

export interface OrderItem extends OrderItemDb {
    variation?: OrderItemVariation;
    choices?: OrderItemChoice[];
    extras?: OrderItemExtra[];
    exclusions?: OrderItemExclusion[];
}

export interface Order extends OrderDb {
    items: OrderItem[];
    events: OrderEvent[];
    user?: User;
    destination?: UserLocation;
}

export enum OrderUpdateType {
    INITIAL = 'INITIAL',
    UPDATE = 'UPDATE',
    NEW = 'NEW',
}

export interface OrderUpdate {
    type: OrderUpdateType;
    orders?: Order[];
    order?: Order;
}

export function getOrderStatusName(type: OrderStatus) {
    return (
        Object.keys(OrderStatus)
            .filter((key) => OrderStatus[key as keyof typeof OrderStatus] === type)
            .toString() || 'N/A'
    );
}

export function getPaymentMethodName(type: PaymentMethod) {
    return (
        Object.keys(PaymentMethod)
            .filter((key) => PaymentMethod[key as keyof typeof PaymentMethod] === type)
            .toString() || 'N/A'
    );
}
