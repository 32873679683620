import React, { useEffect, useState, useContext } from 'react';
import { Theme, Card, Typography } from '@material-ui/core';
import { makeStyles, createStyles, useTheme } from '@material-ui/styles';
import { InputType } from '../../interfaces';
import { AuthContext } from '../../context/AuthContext';
import CoreButton from '../../components/core/button/CoreButton';
import { CoreLoader } from '../../components/core/loader/CoreLoader';
import { useRouter, getRoute } from '../../services/router';
import { ROUTES } from '../../routes';
import ProductTable from '../../components/product/table/ProductTable';
import CategoryTable from '../../components/category/table/CategoryTable';
import CoreFormSelect from '../../components/core/form/select/CoreFormSelect';
import { stores } from '../../stores';
import { useStore } from '../../services/store';
import CoreFlex from '../../components/core/flex/CoreFlex';
import { CoreIconButton } from '../../components/core/icon-button/CoreIconButton';
import CoreFormInput from '../../components/core/form/input/CoreFormInput';
import { useDebounce } from '../../hooks/useDebounce';
import { Product } from '../../interfaces/product';
import { Category } from '../../interfaces/category';
import { Restaurant } from '../../interfaces/restaurant';
import { getTranslation, Language } from '../../config/translation';
import { findTextInProduct } from '../../services/product';
import { getRestaurantById } from '../../microservices/restaurant';

export default function ProductPage() {
    const theme = useTheme();
    const classes = useStyles(theme);
    const authContext = useContext(AuthContext);
    const { navigateTo, params } = useRouter();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [products, setProducts] = useStore<Product[]>(stores.products);
    const [categories, setCategories] = useStore<Category[]>(stores.categories);
    const [restaurants] = useStore<Restaurant[]>(stores.restaurants);
    const [selectedRestaurant, setSelectedRestaurant] = useStore<Restaurant | null>(stores.selectedRestaurant);
    const [language] = useStore<Language>(stores.language);
    const [filteredProducts, setFilteredProducts] = useStore<Product[]>(stores.filteredProducts);
    const [filterCategory, setFilterCategory] = useStore<Category | null>(stores.filterCategory);
    const [isCategoriesOpen, setIsCategoriesOpen] = useState(false);
    const [searchText, setSearchText] = useState('');
    const restaurantId = parseInt(params.restaurantId);

    useEffect(() => {
        if (restaurantId) {
            const routeRestaurant = restaurants.find((item) => item.id === restaurantId);
            setSelectedRestaurant(routeRestaurant || null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restaurants]);

    useEffect(() => {
        setFilteredProducts(products);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products]);

    useEffect(() => {
        loadUserRestaurant();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRestaurant]);

    async function loadUserRestaurant() {
        if (!selectedRestaurant?.id && authContext.user?.restaurantId) {
            setSelectedRestaurant(await getRestaurantById(authContext.user?.restaurantId, authContext));
        }
    }

    function handleAddNewProduct() {
        navigateTo(
            getRoute(ROUTES.product.new, {
                restaurantId: selectedRestaurant && selectedRestaurant.id,
            }),
        );
    }
    function handleAddNewCategory() {
        navigateTo(
            getRoute(ROUTES.category.new, {
                restaurantId: selectedRestaurant && selectedRestaurant.id,
            }),
        );
    }

    function handleChangeRestaraunt(restaurantId: number) {
        setSelectedRestaurant(
            restaurants.find((item: Restaurant) => {
                return item.id === restaurantId;
            }) || null,
        );
    }

    function handleSearchChange(value: string) {
        setSearchText(value);
        bufferSearchInput(value, products, filterCategory);
    }

    const bufferSearchInput = useDebounce((value: string, products: Product[], filterCategory?: Category) => {
        if (!value) {
            setFilteredProducts(
                filterCategory ? products.filter((item) => item.categoryIds.includes(filterCategory.id || 0)) : [],
            );
        }
        const newFilteredProducts = products.filter((product: Product) => {
            const isCategoryMatch =
                !filterCategory || (filterCategory.id && product.categoryIds.includes(filterCategory?.id));
            const isTextMatch = findTextInProduct(value, product);
            return isCategoryMatch && isTextMatch;
        });

        setFilteredProducts(newFilteredProducts);
    }, 500);

    function handleChangeFilterCategory(categoryId: number) {
        setFilterCategory(categories.find((item) => item.id === categoryId) || null);
    }

    return (
        <>
            <Typography variant="h5" className={classes.title}>
                Product management
            </Typography>
            {authContext.isAdmin && (
                <Card className={classes.container}>
                    <CoreFlex
                        className={classes.cardHeader}
                        direction="column"
                        justify="flex-start"
                        alignItems="flex-start"
                    >
                        <Typography variant="h6">
                            {selectedRestaurant ? 'Restaurant selected' : 'Please select a restaurant'}
                        </Typography>
                        <CoreFormSelect
                            disabled={!authContext.isOwner}
                            fullWidth
                            className={classes.selectBox}
                            label="Restaurant"
                            value={selectedRestaurant?.id || 0}
                            onValueChange={(value) => handleChangeRestaraunt(value as number)}
                            options={restaurants.map((restaurant: Restaurant) => {
                                return {
                                    value: restaurant.id,
                                    label: `Munchies ${getTranslation(restaurant, language) || ''}`,
                                };
                            })}
                        />
                    </CoreFlex>
                </Card>
            )}
            {selectedRestaurant && products && categories && (
                <>
                    {isLoading && <CoreLoader type="circular" text="Loading products..." />}

                    {!isLoading && (
                        <>
                            <Card className={classes.container}>
                                <CoreFlex justify="space-between" className={classes.cardHeader}>
                                    <CoreFlex justify="flex-start">
                                        <CoreIconButton
                                            iconName={isCategoriesOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                                            onClick={() => {
                                                setIsCategoriesOpen(!isCategoriesOpen);
                                            }}
                                        />
                                        <Typography variant="h6">Categories</Typography>
                                    </CoreFlex>

                                    <CoreButton label="ADD CATEGORY" onClick={handleAddNewCategory} customSize={210} />
                                </CoreFlex>
                                {isCategoriesOpen && (
                                    <>
                                        <CategoryTable
                                            categories={categories}
                                            onUpdateCategories={(categories: Category[]) => setCategories(categories)}
                                        />
                                    </>
                                )}
                            </Card>
                            {categories.length > 0 && (
                                <Card className={classes.container}>
                                    <CoreFlex className={classes.cardHeader} justify="space-between">
                                        <Typography variant="h6">Products</Typography>
                                        <CoreFormInput
                                            className={classes.search}
                                            type={InputType.text}
                                            label="Search"
                                            value={searchText}
                                            onValueChange={handleSearchChange}
                                        />
                                        {categories && (
                                            <CoreFormSelect
                                                className={classes.categoryFilter}
                                                label={'Category'}
                                                value={filterCategory?.id || 0}
                                                onValueChange={(value) => handleChangeFilterCategory(value as number)}
                                                options={categories.map((category: Category) => {
                                                    return {
                                                        value: category.id,
                                                        label: getTranslation(category, language) || '',
                                                    };
                                                })}
                                            />
                                        )}
                                        <CoreButton
                                            label="ADD NEW PRODUCT"
                                            onClick={handleAddNewProduct}
                                            className={classes.button}
                                        />
                                    </CoreFlex>
                                    <ProductTable products={products} onUpdate={setProducts} />
                                </Card>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            flexGrow: 1,
            margin: theme.spacing(1),
        },
        tableWrapper: {
            maxHeight: '85vh',
            overflow: 'auto',
        },
        image: {
            maxWidth: '100px',
        },
        container: {
            padding: '0rem',
            marginBottom: '1rem',
        },
        cardHeader: {
            padding: '1rem',
        },
        selectBox: {
            marginTop: '1rem',
            width: '100%',
        },
        categoryFilter: {
            marginRight: '2rem',
            minWidth: '200px',
        },
        title: {
            marginBottom: '1rem',
        },
        search: {
            margin: '0rem 2rem',
        },
        button: {
            minWidth: '190px',
            maxWidth: '190px',
        },
    }),
);
