import { useState, useEffect } from 'react';
import { DeviceOrientation, DeviceType } from '../constants';
import { ImageRequirements } from '../interfaces';
import { uploadFile } from '../microservices/upload';
import { AuthContextInterface } from '../context/AuthContext';
import { omit } from 'lodash';
import { Language } from '../config/translation';
import moment from 'moment';

export function getUserDeviceOrientation() {
    return window.innerWidth < window.innerHeight ? DeviceOrientation.Portrait : DeviceOrientation.Landscape;
}

export function getUserDevice() {
    const orientation = getUserDeviceOrientation();
    if (
        (window.innerWidth < 500 && DeviceOrientation.Portrait) ||
        (orientation === DeviceOrientation.Landscape && window.innerHeight < 500)
    ) {
        return DeviceType.Mobile;
    } else if (
        (window.innerWidth < 900 && DeviceOrientation.Portrait) ||
        (orientation === DeviceOrientation.Landscape && window.innerHeight < 900)
    ) {
        return DeviceType.Tablet;
    } else {
        return DeviceType.Desktop;
    }
}

export function useDeviceType() {
    const [device, setDevice] = useState<DeviceType>(getUserDevice());

    function handleResize() {
        console.log(getUserDevice(), window.innerWidth, window.innerHeight);
        setDevice(getUserDevice());
    }

    useEffect(() => {
        setDevice(getUserDevice());
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return {
        isMobile: device === DeviceType.Mobile,
        isTable: device === DeviceType.Tablet,
        isDesktop: device === DeviceType.Desktop,
    };
}

export function generateStringArray(length: number, text: string): string[] {
    return Array.from(new Array(length), (val: string, index: number) => {
        return text.replace('{i}', index + '');
    });
}

// A few JavaScript Functions for Images and Files
// Author: Justin Mitchel
// Source: https://kirr.co/ndywes

// Convert a Base64-encoded string to a File object
export function base64StringtoFile(base64String: string, filename: string) {
    const arr = base64String.split(',');
    const mimeMatch = arr[0].match(/:(.*?);/);
    const mime = mimeMatch !== null ? mimeMatch[1] : undefined;
    if (mime) {
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }
    return undefined;
}

// Download a Base64-encoded file

export function downloadBase64File(base64Data: string, filename: string) {
    const element = document.createElement('a');
    element.setAttribute('href', base64Data);
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}

// Extract an Base64 Image's File Extension
export function extractImageFileExtensionFromBase64(base64Data: string) {
    return base64Data.substring('data:image/'.length, base64Data.indexOf(';base64'));
}

// Base64 Image to Canvas with a Crop
export async function processImageCrop(
    originalImage: HTMLImageElement,
    pixelCrop: any,
    filename: string,
    destinationWidth?: number,
    destinationHeight?: number,
    type?: string,
): Promise<string> {
    if (!filename) {
        filename = 'new.jpg';
    }
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (ctx && pixelCrop && originalImage) {
        ctx.fillStyle = '#FFF';
        const scaleX = originalImage.naturalWidth / originalImage.width;
        const scaleY = originalImage.naturalHeight / originalImage.height;
        canvas.width = destinationWidth || pixelCrop.width * scaleX;
        canvas.height = destinationHeight || pixelCrop.height * scaleY;
        ctx.drawImage(
            originalImage,
            pixelCrop.x * scaleX,
            pixelCrop.y * scaleY,
            pixelCrop.width * scaleX,
            pixelCrop.height * scaleY,
            0,
            0,
            canvas.width,
            canvas.height,
        );
    }

    return new Promise((resolve, reject) => {
        canvas.toBlob((blob) => {
            if (!blob) {
                console.error('Canvas is empty');
                return;
            }
            const fileUrl = URL.createObjectURL(blob);
            resolve(fileUrl);
        }, type || 'image/jpeg');
    });
}

export async function startUpload(
    onUploadComplete: (url: string) => void,
    authContext: Partial<AuthContextInterface>,
    file?: File,
    url?: string,
    fileName?: string,
    label?: string,
) {
    if (url && fileName) {
        const data = await fetch(url);
        const blob = await data.blob();
        const croppedFile = new File([blob], fileName + '.' + blob.type.slice(blob.type.lastIndexOf('/') + 1), {
            type: blob.type,
        });
        const fileUrl = await uploadFile(croppedFile, label, authContext);
        onUploadComplete(fileUrl);
    } else if (file) {
        const fileUrl = await uploadFile(file, label, authContext);
        onUploadComplete(fileUrl);
    }
}

export function adjustColor(color: string, amount: number): string {
    return (
        '#' +
        color
            .replace(/^#/, '')
            .replace(/../g, (color) =>
                ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2),
            )
    );
}

export function verifyImageFile(file: File, requirements: ImageRequirements): Promise<boolean> {
    return new Promise((resolve, reject) => {
        if (requirements.destinationWidth && !requirements.minWidth) {
            requirements.minWidth = requirements.destinationWidth;
        }
        if (requirements.destinationHeight && !requirements.minHeight) {
            requirements.minHeight = requirements.destinationHeight;
        }
        if (file) {
            if (requirements.maxSize && file.size > requirements.maxSize) {
                alert(
                    `File of size ${file.size / 1000}KB is too large. Maximum size allowed is ${
                        requirements.maxSize
                    }KB.`,
                );
                resolve(false);
            }
            if (requirements.minSize && file.size < requirements.minSize) {
                alert(
                    `File of size ${file.size / 1000}KB is too small. Minimum size required is ${
                        requirements.minSize
                    }KB.`,
                );
                resolve(false);
            }

            if (requirements.acceptedTypes && !requirements.acceptedTypes.includes(file.type)) {
                alert(`This file is not allowed. Only ${requirements.acceptedTypes.toString()} are allowed.`);
                resolve(false);
            }

            if (requirements.maxHeight || requirements.minHeight || requirements.maxWidth || requirements.minWidth) {
                const image = new Image();
                image.src = URL.createObjectURL(file);
                image.onload = function () {
                    if (
                        (requirements.maxHeight && requirements.maxHeight < image.naturalHeight) ||
                        (requirements.maxWidth && requirements.maxWidth < image.naturalWidth)
                    ) {
                        alert(
                            `Image too large! The uploaded image size is ${image.naturalWidth} x ${image.naturalHeight} pixels, but maximum allowed size is ${requirements.maxWidth} x ${requirements.maxHeight} pixels.`,
                        );
                        resolve(false);
                    }
                    if (
                        (requirements.minHeight && requirements.minHeight > image.naturalHeight) ||
                        (requirements.minWidth && requirements.minWidth > image.naturalWidth)
                    ) {
                        alert(
                            `Image too small! The uploaded image size is ${image.naturalWidth} x ${image.naturalHeight} pixels, but minimum required size is ${requirements.minWidth} x ${requirements.minHeight} pixels.`,
                        );
                        resolve(false);
                    }
                    resolve(true);
                };
            } else {
                resolve(true);
            }
        } else {
            resolve(false);
        }
    });
}

export function objectsEqual(object1: any, object2: any) {
    return JSON.stringify(object1) === JSON.stringify(object2);
}

export function prepareObjectForCloning<T>(
    object: T,
    options?: {
        elements?: (keyof T)[];
        extraElements?: (keyof T)[];
        subExtraElements?: string[];
    },
): T {
    const { elements, extraElements = [], subExtraElements = [] } = options || {};

    const base = ['id', 'createdAt', 'createdBy', 'updatedAt', 'updatedBy'] as (keyof T)[];
    const clonedObject: any = omit(object as Record<string, any>, [...base, ...extraElements] as (keyof T)[]) as T;

    const languages = Object.keys(clonedObject.translations || {}) || [];
    languages.forEach((language) => {
        const translation: any = clonedObject.translations?.[language as Language] || {};
        delete translation.createdAt;
        delete translation.createdBy;
        delete translation.updatedAt;
        delete translation.updatedBy;

        if (translation) {
            translation.name = `${translation.name} (CLONED)`;
        }
    });

    if (!languages.length) {
        clonedObject.name = `${clonedObject.name} (CLONED)`;
    }

    if (elements?.length) {
        const subBase = [...base, ...subExtraElements];
        elements.forEach((element) => {
            const items: any[] = [];
            (clonedObject[element] as any[])?.forEach((item) => {
                items.push(omit(item, subBase) as any);
            });
            if (clonedObject[element]) {
                (clonedObject[element] as any[]) = items;
            }
        });
    }
    return clonedObject;
}

export function formatTimeDifference(from: Date, until = new Date()): string {
    const difference = moment(until).diff(moment(from), 'seconds');
    return formatTimeDifferenceFromMilliseconds(difference);
}

export function formatTimeDifferenceFromMilliseconds(difference: number): string {
    if (!difference) {
        return '0s';
    }
    const timeElements = {
        days: Math.floor(difference / (60 * 60 * 24)),
        hours: Math.floor((difference / (60 * 60)) % 24),
        minutes: Math.floor((difference / 60) % 60),
        seconds: Math.floor(difference % 60),
    };
    return `
        ${timeElements.days ? timeElements.days + 'd' : ''}
        ${timeElements.hours ? timeElements.hours + 'h' : ''}
        ${timeElements.minutes ? timeElements.minutes + 'm' : ''}
        ${timeElements.seconds ? timeElements.seconds + 's' : '0s'}`;
}
