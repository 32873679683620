import React, { useEffect, useState } from 'react';
import { Snackbar, makeStyles, SnackbarContent, Icon, IconButton } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Theme } from '@material-ui/core';
import { green, amber } from '@material-ui/core/colors';
import clsx from 'clsx';

interface Props {
    className?: string;
    message: string;
    onClose?: () => void;
    horizontal?: 'left' | 'center' | 'right';
    vertical?: 'top' | 'bottom';
    variant: keyof typeof variantIcon;
    autoCloseIn?: number;
}

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

export function CoreSnackbar({
    vertical = 'bottom',
    horizontal = 'right',
    className,
    message,
    onClose,
    variant = 'info',
    autoCloseIn,
    ...rest
}: Props) {
    const [isOpen, setIsOpen] = useState(true);
    const classes = useStyles();

    useEffect(() => {
        setIsOpen(true);
    }, []);

    useEffect(() => {
        let timeout: any = null;
        if (autoCloseIn) {
            timeout = setTimeout(() => {
                handleClose();
            }, autoCloseIn);
        }
        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoCloseIn]);

    function handleClose() {
        setIsOpen(false);
        if (onClose) {
            onClose();
        }
    }

    return (
        <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={isOpen}
            className={classes.root}
            onClose={handleClose}
            ContentProps={{
                'aria-describedby': 'message-id',
            }}
        >
            <SnackbarContent
                className={clsx(classes[variant], className)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <Icon className={clsx(classes.icon, classes.iconVariant)} />
                        {message}
                    </span>
                }
                action={[
                    <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
                        <CloseIcon className={classes.icon} />
                    </IconButton>,
                ]}
                {...rest}
            />
        </Snackbar>
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        zIndex: 10000,
    },
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.main,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
}));
