import { Weekday } from './../config/date';
import { Language } from './../config/translation';
import { DbBase, TranslationObjectBase } from './common';

export enum RestaurantType {
    CLOUD_KITCHEN = 'CLOUD_KITCHEN',
    FOOD_TRUCK = 'FOOD_TRUCK',
    KIOSK = 'KIOSK',
    FOOD_COURT = 'FOOD_COURT',
    STAND_ALONE = 'STAND_ALONE',
    SHOPPING_CENTER = 'SHOPPING_CENTER',
    PRIME_STREET = 'PRIME_STREET',
    DRIVE_THRU = 'DRIVE_THRU',
    MUNCHIES_WORLD = 'MUNCHIES_WORLD',
}

export const RestaurantTypeIcons: Record<RestaurantType, string> = {
    [RestaurantType.CLOUD_KITCHEN]: 'cloud_queue',
    [RestaurantType.FOOD_TRUCK]: 'local_shipping',
    [RestaurantType.KIOSK]: 'storefront',
    [RestaurantType.FOOD_COURT]: 'fastfood',
    [RestaurantType.STAND_ALONE]: 'store',
    [RestaurantType.SHOPPING_CENTER]: 'shopping_basket',
    [RestaurantType.PRIME_STREET]: 'flag',
    [RestaurantType.DRIVE_THRU]: 'drive_eta',
    [RestaurantType.MUNCHIES_WORLD]: 'grade',
};

export interface Restaurant extends DbBase {
    countryId: number;
    operatorId: number;
    photos: string[];
    address: string;
    phone: string;
    type: RestaurantType;
    email: string;
    latitude: number;
    longitude: number;
    managerId: number;
    openSince: Date;
    hours?: RestaurantHour[];
    translations?: Partial<Record<Language, RestaurantTranslation>>;
}

export interface RestaurantTranslation extends TranslationObjectBase {
    name: string;
    description?: string;
}

export interface RestaurantHour {
    id?: number;
    restaurantId?: number;
    weekday: number;
    opening: Date;
    closing: Date;
    start?: Date;
    end?: Date;
    datesDisabled: boolean;
    createdAt?: Date;
    updatedAt?: Date;
}

export function getDefaultRestaurant(): Restaurant {
    return {
        name: '',
        photos: [],
        address: '',
        phone: '',
        email: '',
        latitude: 0,
        longitude: 0,
        managerId: 0,
        operatorId: 0,
        type: RestaurantType.CLOUD_KITCHEN,
        openSince: new Date(),
        hours: [],
        countryId: 0,
        createdAt: new Date(),
        updatedAt: new Date(),
        isActive: true,
        isDeleted: false,
        translations: { en: { name: '' } },
    };
}

export function getDefaultOpeningHour(restaurantId?: number): RestaurantHour {
    const openingTime = new Date();
    const closingTime = new Date();
    openingTime.setHours(8, 0, 0, 0);
    closingTime.setHours(21, 0, 0, 0);

    return {
        ...(restaurantId && { restaurantId }),
        weekday: Weekday.EVERY_DAY,
        opening: openingTime,
        closing: closingTime,
        datesDisabled: true,
    };
}
