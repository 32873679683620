import { isString } from 'lodash';
import { ValidationType, ValidationItem } from '../interfaces';

function clearXss(str: string) {
    if (!isString(str)) {
        str = str + '';
    }
    return str.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
}

export function validate(item: ValidationItem): ValidationItem {
    if (item.value) {
        item.value = clearXss(item.value + '');
    }

    item.validated = true;
    if (item.type && item.value !== undefined) {
        switch (item.type) {
            case ValidationType.Name:
                if (!isName(item.value + '')) {
                    item.validated = false;
                    item.message = 'Please enter full name using only latin characters.';
                }
                break;
            case ValidationType.FullName:
                if (!isFullName(item.value + '')) {
                    item.validated = false;
                    item.message = 'Please enter full name using only latin characters.';
                }
                break;
            case ValidationType.Email:
                if (!isEmail(item.value + '')) {
                    item.validated = false;
                    item.message = 'Please enter a valid email address.';
                }
                break;
            case ValidationType.Phone:
                if (!isPhoneNumber(item.value + '')) {
                    item.validated = false;
                    item.message = 'Please enter a full phone number with country and area codes.';
                }
                break;
            case ValidationType.Password:
                if (!isCorrectPassword(item.value + '')) {
                    item.validated = false;
                    item.message =
                        'Password must contain at least one upper (A-Z) and lowercase (a-z) character, number (0-9), special characters (!@#$%^&*~) and be at least 8 characters long.';
                }
                break;
            case ValidationType.Text:
                if (!isText(item.value + '')) {
                    item.validated = false;
                    item.message = `${
                        item.fieldName || 'This field'
                    } can only contain text (A-Z or a-z and symbols .!?-,'()%#).`;
                }
                break;
            case ValidationType.Url:
                if (!isUrl(item.value + '')) {
                    item.validated = false;
                    item.message = `${item.fieldName || 'This field'} needs to contain a valid website address.`;
                }
                break;
            case ValidationType.RelativeUrl:
                if (!isRelativeUrl(item.value + '')) {
                    item.validated = false;
                    item.message = `${item.fieldName || 'This field'} needs to contain a relative URL.`;
                }
                break;
            case ValidationType.Integer:
                if (!isInteger(item.value + '')) {
                    item.validated = false;
                    item.message = `${
                        item.fieldName || 'This field'
                    } can only contain numbers without commas or other punctuation.`;
                }
                break;
            case ValidationType.Number:
                if (!isNumber(item.value + '')) {
                    item.validated = false;
                    item.message = `${item.fieldName || 'This field'} can only contain numbers and fractions.`;
                }
                break;
            default:
        }
    } else if (item.type && !item.value && !item.optional) {
        item.validated = false;
        item.message = `${item.fieldName || 'This field'} is required.`;
    }

    if (item.minLenght && ((item.value && (item.value + '').length < item.minLenght) || !item.value)) {
        item.validated = false;
        item.message = `${item.fieldName || 'This field'} has to be atleast ${item.minLenght} characters long.`;
    }

    if (item.maxLenght && item.value && (item.value + '').length > item.maxLenght) {
        item.validated = false;
        item.message = `${item.fieldName || 'This field'} can only be up to ${item.maxLenght} characters long.`;
    }

    if (item.condition !== undefined && item.condition === false) {
        item.validated = false;
        item.message = `${item.fieldName || 'This field'} does not meet the requirements.`;
    }
    return item;
}

export function isEmail(email: string) {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email,
    );
}

export function isPhoneNumber(phone: string) {
    return /(^\+[0-9]{2,3}|^\+[0-9]{2,3}\(0\)|^\(\+[0-9]{2,3}\)\(0\)|^00[0-9]{2,3}|^0)([0-9]{8,11}$|[0-9\-\s]{8,11}$)/.test(
        phone,
    );
}

export function isFullName(name: string) {
    return /^[A-Z][a-z]*(\s[A-Z][a-z]*)+$/.test(name);
}

export function isCorrectPassword(password: string) {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*., ()_+\-<>/|~`])(?=.{8,})/.test(password);
}

export function isName(text: string) {
    return /^[a-zA-Z0-9\s-']*$/.test(text);
}

export function isText(text: string) {
    return /^[a-zA-Z0-9\s.!?\-,'()%#&;]*$/.test(text);
}

export function isUrl(url: string) {
    return /(https?:\/\/(?:www\[.]|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9][.:][0-9][^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/.test(
        url,
    );
}

export function isRelativeUrl(url: string) {
    return /^[^/]+\/[^/].*$|^\/[^/].*$/gim.test(url);
}

export function isInteger(integer: string) {
    return /^[-+]?[1-9]\d*$/.test(integer);
}

export function isNumber(number: string) {
    return /[+-]?([0-9]*[.])?[0-9]+/.test(number);
}
