import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTheme, createStyles } from '@material-ui/styles';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { AuthContext } from '../../context/AuthContext';
import { CoreLoader } from '../../components/core/loader/CoreLoader';
import { useCoreAnimations } from '../../config/styles/animations';
import { useSocket } from '../../hooks/useSocket';
import { useStore } from '../../services/store';
import { stores } from '../../stores';
import { Order, OrderStatus, OrderUpdate, OrderUpdateType, OrderEvent } from '../../interfaces/order';
import { cloneDeep } from 'lodash';
import { SocketChannelName } from '../../services/socket';
import OrderTable from '../../components/order/table/OrderTable';
import { addOrderEvent } from '../../microservices/order';

export default function OrderPage() {
    const authContext = useContext(AuthContext);
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(false);
    const [orders, setOrders] = useStore<Order[]>(stores.orders);
    const { sendMessage, onClose } = useSocket(SocketChannelName.ORDER, (update) => handleOrderUpdate(update));
    const ordersRef = useRef<Order[]>();

    ordersRef.current = orders;

    const classes = useStyles(theme);

    useEffect(() => {
        setIsLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => {
            onClose();
        };
    }, []);

    useEffect(() => {
        console.log('ORDERS UPDATED', orders);
    }, [orders]);

    async function handleOrderUpdate(update: OrderUpdate) {
        let newOrders = cloneDeep(ordersRef.current) || [];
        switch (update.type) {
            case OrderUpdateType.INITIAL:
                newOrders = update.orders || [];
                newOrders.forEach((item) => {
                    if (item.status === OrderStatus.COMPLETED) {
                        triggerReceivedEvent(item);
                    }
                });
                break;
            case OrderUpdateType.NEW: {
                if (update.order) {
                    newOrders.push(update.order);
                    if (update.order.status === OrderStatus.COMPLETED) {
                        triggerReceivedEvent(update.order);
                    }
                }
                break;
            }
            case OrderUpdateType.UPDATE: {
                const updatedOrderIndex = newOrders.findIndex((item) => item.id === update.order?.id);
                if (updatedOrderIndex > -1 && update.order) {
                    newOrders[updatedOrderIndex] = update.order;
                    if (update.order.status === OrderStatus.COMPLETED) {
                        triggerReceivedEvent(update.order);
                    }
                } else {
                    console.log('COULD NOT FIND THE ORDER TO UPDATE', update.order?.id);
                }
                break;
            }
        }
        setOrders(newOrders);
    }

    async function triggerReceivedEvent(order: Order) {
        if (!order.id) {
            return;
        }
        const receivedEvent: OrderEvent = {
            orderId: order.id || 0,
            status: OrderStatus.RECEIVED,
            handledBy: authContext.user?.id,
            comment: `Order received in backoffice by ${authContext.user?.firstName} ${authContext.user?.lastName}.`,
        };
        await addOrderEvent(order.id, receivedEvent, authContext);
    }

    return (
        <>
            <Typography variant="h5" className={classes.title}>
                Order management
            </Typography>
            {isLoading && <CoreLoader type="circular" text="Loading orders..." />}
            {!isLoading && (
                <OrderTable
                    orders={orders}
                    updateOrders={setOrders}
                    status={OrderStatus.RECEIVED}
                    startExpanded
                    className={classes.table}
                />
            )}
            {!isLoading && (
                <OrderTable
                    orders={orders}
                    updateOrders={setOrders}
                    status={OrderStatus.COOKING}
                    startExpanded
                    className={classes.table}
                />
            )}
            {!isLoading && (
                <OrderTable
                    orders={orders}
                    updateOrders={setOrders}
                    status={OrderStatus.READY}
                    className={classes.table}
                />
            )}
            {!isLoading && (
                <OrderTable
                    orders={orders}
                    updateOrders={setOrders}
                    status={OrderStatus.PICKED_UP}
                    className={classes.table}
                />
            )}
            {!isLoading && (
                <OrderTable
                    orders={orders}
                    updateOrders={setOrders}
                    status={OrderStatus.DELIVERED}
                    className={classes.table}
                />
            )}
        </>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            flexGrow: 1,
            margin: theme.spacing(1),
        },
        table: {
            marginBottom: '2rem',
        },
        title: {
            marginBottom: '1rem',
        },
        ...useCoreAnimations(theme),
    }),
);
