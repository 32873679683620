import React, { MouseEvent } from 'react';
import { IconButton, Icon, Tooltip } from '@material-ui/core';

interface Props {
    onClick: (event: MouseEvent) => void;
    iconName: string;
    disabled?: boolean;
    size?: 'inherit' | 'default' | 'small' | 'large' | undefined;
    className?: string;
    iconClassName?: string;
    toolTip?: string;
    color?: 'inherit' | 'primary' | 'secondary' | 'default' | 'action' | 'disabled' | 'error' | undefined;
}

export function CoreIconButton({
    iconName,
    onClick,
    size = 'default',
    className,
    color,
    iconClassName,
    toolTip,
    disabled,
}: Props) {
    function handleClick(event: MouseEvent) {
        event.stopPropagation();
        if (onClick) {
            onClick(event);
        }
    }

    return (
        <IconButton
            className={className}
            onClick={handleClick}
            color="inherit"
            disableRipple
            size="small"
            edge="start"
            disabled={disabled}
        >
            {toolTip ? (
                <Tooltip title={toolTip} aria-label={toolTip && toolTip.toLowerCase()}>
                    <Icon fontSize={size} color={color} className={iconClassName}>
                        {iconName}
                    </Icon>
                </Tooltip>
            ) : (
                <Icon fontSize={size} color={color} className={iconClassName}>
                    {iconName}
                </Icon>
            )}
        </IconButton>
    );
}
