import CoreCrudToolbar from '../../../components/core/crud-toolbar/CoreCrudToolbar';
import CoreFlex from '../../../components/core/flex/CoreFlex';
import CoreTranslatedInput from '../../../components/core/form/input/translated/CoreTranslatedInput';
import ProductCategorySelect from '../../../components/product/category/select/ProductCategorySelect';
import ProductChoiceEditor from '../../../components/product/choice/editor/ProductChoiceEditor';
import ProductExclusionEditor from '../../../components/product/exclusion/ProductExclusionEditor';
import ProductExtraEditor from '../../../components/product/extra/editor/ProductExtraEditor';
import ProductTagEditor from '../../../components/product/tag/editor/ProductTagEditor';
import ProductVariationEditor from '../../../components/product/variation/editor/ProductVariationEditor';
import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../../../context/AuthContext';
import { cloneDeep } from 'lodash';
import { CoreImageUpload } from '../../../components/core/image-upload/CoreImageUpload';
import { Country } from '../../../interfaces/country';
import { createProduct, getProduct, removeProduct, updateProduct } from '../../../microservices/product';
import { createStyles, makeStyles } from '@material-ui/styles';
import { getDefaultProduct, Product, ProductExtra, ProductTranslation } from '../../../interfaces/product';
import { getRestaurantById } from '../../../microservices/restaurant';
import { getRoute, useRouter } from '../../../services/router';
import { getTranslatedObjectUpdate, Language } from '../../../config/translation';
import { Grid, Paper, Theme, Typography } from '@material-ui/core';
import { InputType, ValidationType } from '../../../interfaces';
import { populateProductTranslations } from '../../../services/product';
import { Restaurant } from '../../../interfaces/restaurant';
import { ROUTES } from '../../../routes';
import { showError } from '../../../services/alert';
import { stores } from '../../../stores';
import { useStore } from '../../../services/store';
import { validate } from '../../../services/validation';
import { prepareObjectForCloning } from '../../../services/utils';

export default function ProductsDetailsPage() {
    const classes = useStyles();
    const authContext = useContext(AuthContext);
    const { navigateTo, params } = useRouter();
    const [products, setProducts] = useStore<Product[]>(stores.products);
    const [restaurant, setRestaurant] = useStore<Restaurant | null>(stores.selectedRestaurant);
    const [country] = useStore<Country | null>(stores.selectedCountry);
    const [restaurants] = useStore<Restaurant[]>(stores.restaurants);
    const [language, setLanguage] = useStore<Language>(stores.language);
    const [product, setProduct] = useStore<Product>(stores.selectedProduct);
    const translation = product.translations?.[language];

    const validators = {
        name: () => {
            return validate({
                type: ValidationType.Text,
                fieldName: 'Product name',
                minLenght: 2,
                maxLenght: 30,
                value: translation?.name || '',
            });
        },
        photo: () => {
            return validate({
                type: ValidationType.RelativeUrl,
                fieldName: 'Image',
                value: product?.photo || '',
            });
        },
        variation: () => {
            return validate({
                fieldName: 'Price',
                condition: Boolean(product?.variations?.length && product.variations[0].price > -1),
            });
        },
        category: () => {
            return validate({
                fieldName: 'Category',
                condition: product && product.categoryIds?.length > 0,
            });
        },
    };

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!restaurant && restaurants && product) {
            setRestaurant(restaurants.find((restaurant: Restaurant) => restaurant.id === product.restaurantId) || null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restaurants, product]);

    // useEffect(() => {
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    //     console.log('Product was updated', product);
    // }, [product]);

    useEffect(() => {
        if (!language) {
            return;
        }
        const productWithTranslationUpdates = populateProductTranslations(language, product);
        if (productWithTranslationUpdates) {
            setProduct(productWithTranslationUpdates);
        }
    }, [language]);

    async function loadData() {
        const productId = parseInt(params.productId);
        const restaurantId = parseInt(params.restaurantId);
        const cloneId = parseInt(params.cloneId);
        const restaurantIdValue: number = product?.restaurantId || 0;

        if (!restaurant) {
            setRestaurant(
                restaurants?.find((item) => item.id === restaurantIdValue) ||
                    (await getRestaurantById(restaurantId, authContext)),
            );
        }

        if (cloneId) {
            const clonedProduct = await getProduct(cloneId, authContext);
            if (clonedProduct) {
                setProduct(
                    prepareObjectForCloning<Product>(clonedProduct, {
                        elements: ['variations', 'extras', 'exclusions', 'choices'],
                        extraElements: ['favorited', 'rating', 'ratings', 'noOfRatings', 'isInStock'],
                    }) || getDefaultProduct(),
                );
                return;
            }
        }

        if (productId) {
            console.log('Come to edit section too');
            const product = await getProduct(productId, authContext);
            setProduct(product || getDefaultProduct());
            return;
        }

        // This is the product/new route
        if (restaurantId) {
            setProduct({
                ...getDefaultProduct(),
                restaurantId,
            });
        }
    }

    function handleValueChange(
        name: keyof Product | keyof ProductTranslation,
        value: string | number | boolean | string[] | number[] | any[],
        language?: Language,
    ) {
        if (language) {
            setProduct(getTranslatedObjectUpdate<any>(product, name, value as string, language));
            return;
        }
        // console.log('Product value change: ', name, '=', value);
        setProduct({
            ...product,
            [name]: value,
        });
    }

    /**
    |--------------------------------------------------
    | PRODUCT
    |--------------------------------------------------
    */

    async function handleSaveProduct() {
        if (
            !validators.name().validated ||
            !validators.photo().validated ||
            !validators.variation().validated ||
            !validators.category().validated
        ) {
            showError(
                `Error: ${
                    validators.name().message ||
                    validators.photo().message ||
                    validators.variation().message ||
                    validators.category().message
                }`,
            );
            return;
        }

        const response = product.id
            ? await updateProduct(product.id, product, authContext)
            : await createProduct(product, authContext);

        if (!response) {
            showError(`Error: Saving product.`);
            return;
        }

        const newProducts = cloneDeep(products);
        if (product.id) {
            newProducts.forEach((item, index) => {
                newProducts[index] = item.id === product.id ? response : item;
            });
        } else {
            newProducts.push(response);
        }
        setProducts(newProducts);
        navigateTo(getRoute(ROUTES.product, { restaurantId: product.restaurantId }));
    }

    async function handleDeleteProduct() {
        if (product.id) {
            await removeProduct(product.id, authContext);
        }
        navigateTo(getRoute(ROUTES.product, { restaurantId: product.restaurantId }));
    }

    function handleCancel() {
        navigateTo(getRoute(ROUTES.product, { restaurantId: product.restaurantId }));
    }

    /**
    |--------------------------------------------------
    | CATEGORY
    |--------------------------------------------------
    */

    function handleAddCategory(categoryId: number | undefined) {
        if (!categoryId) {
            return;
        }
        const newCategories = [...product.categoryIds];
        newCategories.push(categoryId);
        handleValueChange('categoryIds', newCategories);
    }

    function handleDeleteCategory(categoryId: number) {
        if (categoryId) {
            const newCategories = product.categoryIds.filter((item) => {
                return item !== categoryId;
            });
            handleValueChange('categoryIds', newCategories);
        }
    }

    /**
    |--------------------------------------------------
    | TAGS
    |--------------------------------------------------
    */

    function handleAddTag(value: string) {
        setProduct({
            ...product,
            tags: product.tags.concat(value),
        });
    }

    function handleDeleteTag(value: string) {
        setProduct({
            ...product,
            tags: product.tags.filter((tag) => tag !== value.slice(1)),
        });
    }

    return (
        <form autoComplete="off">
            <CoreCrudToolbar
                object={product}
                switchDisabled={!authContext.isAdmin && !authContext.isManager}
                objectName="Product"
                isClone={params.cloneId}
                languages={country?.languages}
                selectedLanguage={language}
                onChangeLanguage={setLanguage}
                translationObject={product.translations}
                translationKeys={['name']}
                allowDelete={authContext.isManager}
                onCancel={handleCancel}
                onSave={handleSaveProduct}
                onDelete={handleDeleteProduct}
                onToggleVisibility={() => {
                    handleValueChange('isActive', !product.isActive);
                }}
            />

            <Paper className={classes.paper}>
                <Grid container direction="column" justify="space-between">
                    <CoreFlex justify="flex-start" className={classes.cardHeader}>
                        <Typography variant="h6">Product details</Typography>
                    </CoreFlex>
                    <CoreFlex>
                        <CoreFlex>
                            <CoreFlex direction="column">
                                <CoreTranslatedInput
                                    className={classes.formField}
                                    language={language}
                                    translationObject={product.translations || {}}
                                    keyName="name"
                                    autoFocus
                                    required
                                    label="Product name"
                                    value={translation?.name || ''}
                                    type={InputType.text}
                                    onValueChange={(value) => handleValueChange('name', value, language)}
                                    placeholder="Eg. Crispy mushroom sandwitch"
                                />

                                <CoreTranslatedInput
                                    className={classes.formField}
                                    label="Description"
                                    placeholder="Eg. Pan fried brown oyster mushrooms on a crispy wholegrain toast, served with tomateos and sriracha mayo."
                                    rows={5}
                                    multiline
                                    language={language}
                                    translationObject={product.translations || {}}
                                    keyName="description"
                                    value={translation?.description || ''}
                                    type={InputType.text}
                                    onValueChange={(value) => handleValueChange('description', value, language)}
                                />
                            </CoreFlex>
                        </CoreFlex>
                        <CoreImageUpload
                            className={classes.imageUpload}
                            label="Product thumbnail"
                            aspect={1 / 1}
                            basePath="product"
                            width={220}
                            height={220}
                            imageUrl={product.photo}
                            onUploadComplete={(value: string) => {
                                handleValueChange('photo', value);
                            }}
                            requirements={{
                                destinationWidth: 220,
                                destinationHeight: 220,
                            }}
                        />
                        <CoreImageUpload
                            label="High quality product image"
                            aspect={3 / 2}
                            width={220 * 1.5}
                            height={220}
                            basePath="product-hq"
                            imageUrl={product.fullPhoto}
                            onUploadComplete={(value: string) => {
                                handleValueChange('fullPhoto', value);
                            }}
                            requirements={{
                                destinationWidth: 1500,
                                destinationHeight: 1000,
                            }}
                        />
                    </CoreFlex>
                    <Grid item className={classes.formField}>
                        <CoreFlex justify="flex-start" className={classes.cardHeader}>
                            <Typography variant="h6">Categories</Typography>
                        </CoreFlex>
                        <ProductCategorySelect
                            categoryIds={product.categoryIds || []}
                            restaurantId={restaurant?.id || undefined}
                            onAddCategory={handleAddCategory}
                            onDeleteCategory={handleDeleteCategory}
                        />
                    </Grid>
                    {restaurant && (
                        <Grid item>
                            <ProductVariationEditor
                                product={product}
                                onUpdate={(value) => handleValueChange('variations', value)}
                                restaurant={restaurant}
                            />
                        </Grid>
                    )}
                </Grid>
            </Paper>

            <Paper className={classes.paper}>
                <ProductTagEditor
                    tags={(product && product.tags) || []}
                    onAddTag={handleAddTag}
                    onDeleteTag={handleDeleteTag}
                />
            </Paper>
            {product && products && restaurant && setProduct && (
                <>
                    <Paper className={classes.paper}>
                        <ProductExtraEditor
                            products={products}
                            product={product}
                            onUpdate={(value: ProductExtra[]) => handleValueChange('extras', value)}
                            restaurant={restaurant}
                        />
                    </Paper>
                    <Paper className={classes.paper}>
                        <ProductExclusionEditor
                            product={product}
                            exclusions={product.exclusions || []}
                            onUpdate={(value) => handleValueChange('exclusions', value)}
                            restaurant={restaurant}
                            products={products}
                        />
                    </Paper>
                    <Paper className={classes.paper}>
                        <ProductChoiceEditor
                            product={product}
                            onUpdate={(value) => handleValueChange('choices', value)}
                            restaurant={restaurant}
                            products={products}
                        />
                    </Paper>
                </>
            )}
        </form>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formField: {
            padding: '0.5rem 0.5rem',
            minWidth: '300px',
            width: '100%',
        },
        smallField: {
            padding: '0.5rem 0.5rem',
            minWidth: '200px',
            width: '100%',
        },
        miniField: {
            padding: '0.5rem 0.5rem',
            minWidth: '150px',
            width: '100%',
        },
        imageUpload: {
            marginRight: '0.5rem',
        },
        paper: {
            margin: '2rem',
            padding: '2rem',
        },
        cardHeader: {
            padding: '1rem 0.5rem',
        },
    }),
);
