import React, { useContext } from 'react';
import { Button, Grid, AppBar, Toolbar, IconButton, Typography, Theme } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/styles';
import clsx from 'clsx';
import MenuIcon from '@material-ui/icons/Menu';
import { AuthContext } from '../../../context/AuthContext';
import { useStore } from '../../../services/store';
import { Restaurant } from '../../../interfaces/restaurant';
import { stores } from '../../../stores';
import { getTranslation, Language } from '../../../config/translation';
import CoreFlex from '../flex/CoreFlex';
import { Country } from '../../../interfaces/country';
import { CoreFlagElement } from '../flag-element/CoreFlagElement';

interface Props {
    menuWidth: number;
    slideWithMenu?: boolean;
    onClickMenu: () => void;
    menuOpen?: boolean;
}

interface StyleProps {
    menuWidth: number;
    slideWithMenu?: boolean;
    menuOpen: boolean;
}

export default function CoreAppHeader({ menuWidth, slideWithMenu = false, onClickMenu, menuOpen = false }: Props) {
    const authContext = useContext(AuthContext);
    const classes = useStyles({ menuWidth, slideWithMenu, menuOpen });
    const shrinkHeader: boolean = menuOpen && slideWithMenu;
    const [restaurant] = useStore<Restaurant | null>(stores.selectedRestaurant);
    const [language] = useStore<Language>(stores.language);
    const [country] = useStore<Country>(stores.selectedCountry);

    return (
        <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
                [classes.appBarShift]: shrinkHeader,
            })}
        >
            <Toolbar>
                <Grid container direction="row" justify="space-between" alignItems="center">
                    <Grid item>
                        <Grid container direction="row" alignItems="center">
                            <Grid item>
                                <IconButton
                                    color="inherit"
                                    aria-label="open menu"
                                    onClick={onClickMenu}
                                    edge="start"
                                    className={clsx(classes.menuButton, {
                                        [classes.hide]: shrinkHeader,
                                    })}
                                >
                                    <MenuIcon />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <Typography variant="h6" noWrap>
                                    {`Welcome ${authContext.user?.firstName}`}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <CoreFlex>
                            <Typography variant="h6">
                                {restaurant ? `Munchies ${getTranslation(restaurant, language)}` : ''}
                            </Typography>
                            {country && <CoreFlagElement countryId={country.id} className={classes.flag} />}
                        </CoreFlex>
                    </Grid>
                    <Grid item>
                        <Link to="/auth/logout" className={classes.logoutButton}>
                            <Button variant="contained">Logout</Button>
                        </Link>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            // backgroundColor: theme.palette.secondary.dark,
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.easeIn,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: (props: StyleProps) => ({
            marginLeft: props.menuWidth,
            width: `calc(100% - ${props.menuWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.easeIn,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
        menuButton: {
            marginRight: 20,
        },
        hide: {
            display: 'none',
        },
        logoutButton: {
            textDecoration: 'none!important',
        },
        flag: {
            marginLeft: '1rem',
            width: '30px',
        },
    }),
);
