import React, { useState, useEffect } from 'react';
import { Theme, Typography, useTheme } from '@material-ui/core';
import CoreFormInputMoney from '../../../../core/form/input/money/CoreFormInputMoney';
import { CoreIconButton } from '../../../../core/icon-button/CoreIconButton';
import { InputType } from '../../../../../interfaces';
import { makeStyles, createStyles } from '@material-ui/styles';
import { grey } from '@material-ui/core/colors';
import CoreChip from '../../../../core/chip/CoreChip';
import CoreSwitch from '../../../../core/switch/CoreSwitch';
import { Country } from '../../../../../interfaces/country';
import { ProductExtra } from '../../../../../interfaces/product';
import CoreFlex from '../../../../core/flex/CoreFlex';
import { formatPrice } from '../../../../../services/helpers';
import { showError } from '../../../../../services/alert';
import { Language } from '../../../../../config/translation';
import { stores, useStore } from '../../../../../stores';
import CoreTranslatedInput from '../../../../core/form/input/translated/CoreTranslatedInput';
import { CoreImageUpload } from '../../../../core/image-upload/CoreImageUpload';
import { CoreFlagElement } from '../../../../core/flag-element/CoreFlagElement';

interface Props {
    extra: ProductExtra;
    index: number;
    country: Country;
    onValueChange: (
        name: keyof ProductExtra,
        value: string | number | boolean,
        index: number,
        language?: Language,
    ) => void;
    onDelete: (index: number) => void;
    onAddNew: () => void;
}

export default function ProductExtraEditorRow({ extra, country, index, onAddNew, onDelete, onValueChange }: Props) {
    const theme = useTheme();
    const classes = useStyles();
    const [isEditMode, setIsEditMode] = useState(extra && !extra.id);
    const [language] = useStore<Language>(stores.language);
    const price = `+${formatPrice(extra.price, country)}`;
    const discount = extra.discount ? `+${formatPrice(extra.discount, country)}` : '';
    const translation = extra.translations?.[language];

    useEffect(() => {
        if (!isEditMode) {
            if (!extra.price || extra.price === 0 || !translation?.name) {
                showError('ERROR: Please fill in the price and name of an extra.');
                setIsEditMode(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditMode]);

    return (
        <CoreFlex
            onClick={() => {
                setIsEditMode(!isEditMode);
            }}
            alignItems="center"
            justify="space-between"
            className={!extra.isActive ? classes.rootInactive : !isEditMode ? classes.root : ''}
        >
            <CoreFlex justify="flex-start" className={classes.switch}>
                <CoreSwitch onValueChange={(value) => onValueChange('isActive', value, index)} value={extra.isActive} />
            </CoreFlex>
            <CoreFlex className={classes.imageUpload}>
                <CoreImageUpload
                    onUploadComplete={(value: string) => onValueChange('photo', value, index)}
                    width={100}
                    label="Optional photo"
                    imageUrl={extra.photo}
                    height={100}
                    basePath="product-extra"
                    requirements={{
                        destinationHeight: 220,
                        destinationWidth: 220,
                    }}
                />
            </CoreFlex>
            <CoreFlex className={classes.formField}>
                {isEditMode && (
                    <CoreTranslatedInput
                        onSubmit={() => {
                            setIsEditMode(false);
                        }}
                        label="Extra name"
                        language={language}
                        translationObject={extra.translations || {}}
                        keyName="name"
                        required
                        placeholder="Eg. Double cheese"
                        value={translation?.name ? translation?.name.toString() : ''}
                        type={InputType.text}
                        onValueChange={(value) => onValueChange('name', value, index, language)}
                    />
                )}
                {!isEditMode && (
                    <CoreFlex className={classes.formField} justify="space-between">
                        <Typography variant="subtitle1">{translation?.name}</Typography>
                        <CoreFlagElement language={language} className={classes.flag} />
                    </CoreFlex>
                )}
            </CoreFlex>
            <CoreFlex className={classes.smallField}>
                {isEditMode && (
                    <CoreFormInputMoney
                        onSubmit={() => {
                            setIsEditMode(false);
                        }}
                        countryId={country.id}
                        label="Price"
                        required
                        prefix="+"
                        value={extra.price + ''}
                        type={InputType.number}
                        onValueChange={(value) => onValueChange('price', value, index)}
                    />
                )}
                {!isEditMode && (
                    <CoreChip
                        label={price}
                        color={
                            extra.isDiscountActive && extra.discount && extra.discount > 0
                                ? theme.palette.grey[500]
                                : theme.palette.primary.main
                        }
                        className={
                            extra.isDiscountActive && extra.discount && extra.discount > 0
                                ? classes.discountActive
                                : classes.priceTag
                        }
                    />
                )}
            </CoreFlex>
            <CoreFlex className={classes.smallField}>
                {isEditMode && (
                    <>
                        <CoreSwitch
                            onValueChange={(value) => onValueChange('isDiscountActive', value, index)}
                            value={extra.isDiscountActive}
                        />
                        <CoreFormInputMoney
                            onSubmit={() => {
                                setIsEditMode(false);
                            }}
                            countryId={country.id}
                            disabled={!extra.isDiscountActive}
                            label={`Discount price ${extra.isDiscountActive ? 'active' : 'disabled'}`}
                            value={extra.discount ? extra.discount.toString() : ''}
                            type={InputType.number}
                            onValueChange={(value) => onValueChange('discount', value, index)}
                        />
                    </>
                )}
                {!isEditMode && extra.isDiscountActive && extra.discount !== undefined && extra.discount > 0 && (
                    <CoreChip
                        label={discount}
                        color={theme.palette.secondary.main}
                        onDelete={() => {
                            onValueChange('isDiscountActive', false, index);
                        }}
                        className={classes.priceTag}
                    />
                )}
            </CoreFlex>
            <CoreFlex className={classes.buttons} justify="flex-end">
                {isEditMode && (
                    <CoreIconButton
                        iconName="check"
                        onClick={(event) => {
                            event.stopPropagation();
                            setIsEditMode(false);
                        }}
                    />
                )}
                {!isEditMode && (
                    <CoreIconButton
                        iconName="edit"
                        onClick={(event) => {
                            event.stopPropagation();
                            setIsEditMode(true);
                        }}
                    />
                )}
                <CoreIconButton
                    iconName="delete"
                    onClick={(event) => {
                        event.stopPropagation();
                        onDelete(index);
                    }}
                />
            </CoreFlex>
        </CoreFlex>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:hover': {
                backgroundColor: grey[100],
            },
        },
        rootInactive: {
            opacity: 0.25,
        },
        formField: {
            display: 'flex',
            flexBasis: 0,
            flexGrow: 1,
            flexShrink: 1,
            padding: '0.5rem 1rem',
        },
        smallField: {
            padding: '0.5rem 0.5rem',
            width: '250px',
        },
        miniField: {
            padding: '0.5rem 0.5rem',
            minWidth: '150px',
            width: '100%',
        },
        discountActive: {
            fontSize: '1rem',
            textDecoration: 'line-through',
        },
        priceTag: {
            fontSize: '1rem',
        },
        flag: {
            height: '25px',
        },
        buttons: {
            opacity: 0.7,
            width: '150px',
            marginLeft: '1rem',
        },
        switch: {
            width: '100px',
        },
        imageUpload: {
            width: '150px',
            marginBottom: '0.5rem',
        },
    }),
);
