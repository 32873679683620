import React, { useEffect, useState, useContext } from 'react';
import { createStyles, makeStyles, withStyles } from '@material-ui/styles';
import { AuthContext } from './context/AuthContext';
import { stores, useStore } from './stores';
import { IpLocation } from './interfaces/location';
import { getCountries, getCountry } from './microservices/country';
import { getOperatorRestaurants, getRestaurantById, getRestaurants } from './microservices/restaurant';
import { LinearProgress, Theme } from '@material-ui/core';
import CoreFlex from './components/core/flex/CoreFlex';
import { Country } from './interfaces/country';
import { Restaurant } from './interfaces/restaurant';
import { COLOR } from './services/createTheme';
import { getUserLocationFromIp } from './microservices/ipLocation';
import { showSuccess } from './services/alert';
import { User } from './interfaces/user';
import { Category } from './interfaces/category';
import { Product } from './interfaces/product';
import { getProducts } from './microservices/product';
import { getCategories } from './microservices/category';
import { getTranslation, Language } from './config/translation';
import { getUserList } from './microservices/user';

export function Loader(props: any) {
    const classes = useStyles();
    const authContext = useContext(AuthContext);
    const [ipLocation, setIpLocation] = useStore<IpLocation | null>(stores.ipLocation);
    const [selectedCountry, setSelectedCountry] = useStore<Country | null>(stores.selectedCountry);
    const [, setRestaurants] = useStore<Restaurant[]>(stores.restaurants);
    const [countries, setCountries] = useStore<Country[]>(stores.countries);
    const [isLoading, setIsLoading] = useState(true);
    const [progress, setProgress] = useState(0);
    const [restaurant, setRestaurant] = useStore<Restaurant | null>(stores.selectedRestaurant);
    const [categories, setCategories] = useStore<Category[]>(stores.categories);
    const [products, setProducts] = useStore<Product[]>(stores.products);
    const [language] = useStore<Language>(stores.language);
    const [team, setTeam] = useStore<Partial<User>[]>(stores.team);

    useEffect(() => {
        if (!ipLocation) {
            getIpLocation();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (ipLocation) {
            loadData(ipLocation);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ipLocation]);

    useEffect(() => {
        if (restaurant?.id) {
            loadRestarauntData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restaurant]);

    useEffect(() => {
        if (progress === 100) {
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [progress]);

    async function loadRestarauntData() {
        const [categories, products, country, users] = await Promise.all([
            getCategories(restaurant?.id || 0, authContext),
            getProducts(restaurant?.id || 0, {}, authContext),
            getCountry(restaurant?.countryId || 0, authContext),
            getUserList(authContext),
        ]);
        setProducts(products);
        setCategories(categories);
        setTeam(users);
        if (country?.id !== selectedCountry?.id) {
            setSelectedCountry(country);
        }
    }

    async function getIpLocation() {
        const ipLocation = await getUserLocationFromIp();
        setIpLocation(ipLocation);
    }

    async function loadData(location: IpLocation) {
        let loadedCountries: Country[] = [];
        if (!countries?.length) {
            loadedCountries = await getCountries(authContext);
            setCountries(loadedCountries);
        }

        if (!loadedCountries.length) {
            loadedCountries = countries;
        }
        setProgress(100);

        let country;

        if (authContext.isAdmin) {
            let countryIndex = 0;
            const activeCountries = loadedCountries.filter((item) => item.isActive);

            if (authContext.user?.restaurantId) {
                const userRestaurant = await getRestaurantById(authContext.user.restaurantId, authContext);
                country = loadedCountries.find((item) => item.id === userRestaurant?.countryId);
                setRestaurant(userRestaurant);
                showSuccess(`Country selected based on home restaurant: ${getTranslation(country, language)}.`);
                return;
            }

            country =
                activeCountries.find((country) => country.translations?.en?.name === location.country) ||
                activeCountries[countryIndex];

            if (!country || !country.id) {
                return;
            }

            let wasFound = false;
            while (!wasFound) {
                const countryRestaurants = await getRestaurants({ countryId: country.id }, authContext);
                if (countryRestaurants.length) {
                    wasFound = true;
                    setSelectedCountry(country);
                    setRestaurants(countryRestaurants);
                    showSuccess(
                        `Country selected based on location automatically: ${getTranslation(country, language)}.`,
                    );
                } else {
                    countryIndex++;
                    country = activeCountries[countryIndex];
                }
            }
            return;
        }

        if (authContext.isOwner && authContext.user?.operatorId) {
            const userRestaurants = await getOperatorRestaurants(authContext.user?.operatorId || 0, {}, authContext);
            if (userRestaurants.length) {
                setSelectedCountry(loadedCountries.find((item) => item.id === userRestaurants[0]?.countryId) || null);
                setRestaurants(userRestaurants);
            }
            return;
        }

        if (authContext.user?.restaurantId) {
            const userRestaurant = await getRestaurantById(authContext.user?.restaurantId, authContext);
            if (userRestaurant) {
                setRestaurant(userRestaurant);
                setRestaurants([userRestaurant]);
            }
            return;
        }
    }

    return (
        <>
            {isLoading && (
                <CoreFlex className={classes.root} direction="column" alignItems="center" justify="center">
                    <img src="/assets/munchies-logo.png" alt="Munchies logo" className={classes.center} />
                    <CoreFlex className={classes.progresWrapper} alignItems="center">
                        <StyledLinearProgress
                            variant="determinate"
                            value={progress}
                            valueBuffer={progress}
                            className={classes.progress}
                        />
                    </CoreFlex>
                </CoreFlex>
            )}
            {!isLoading && <>{props.children}</>}
        </>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'absolute',
            margin: 0,
            height: '100%',
            zIndex: 10000,
            alignItems: 'center',
            display: 'block',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            pointerEvents: 'none',
            backgroundColor: theme.palette.background.default,
        },
        center: {
            display: 'block',
            [theme.breakpoints.down('sm')]: {
                width: '177px',
            },
            [theme.breakpoints.up('sm')]: {},
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translateY(-50%) translateX(-50%)',
        },
        progress: {
            position: 'relative',
            top: '2px',
            height: '16px',
            borderRadius: '8px',
            color: theme.palette.secondary.main,
        },
        progresWrapper: {
            display: 'block',
            width: '70%',
            left: '50%',
            height: '20px',
            borderRadius: '16px',
            bottom: '10%',
            position: 'absolute',
            padding: '0.1rem 0.2rem',
            transform: 'translateY(-50%) translateX(-50%)',
            backgroundColor: 'rgba(255,255,255, 0.50)',
        },
    }),
);

const StyledLinearProgress = withStyles({
    colorPrimary: {
        backgroundColor: 'rgba(0,0,0,0)',
    },
    barColorPrimary: {
        backgroundColor: COLOR.MunchiesGreen,
    },
})(LinearProgress);
