import React from 'react';
import { Button, Icon, makeStyles, Theme } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import CoreFlex from '../flex/CoreFlex';
import { adjustColor } from '../../../services/utils';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
    main: (props: Props) => ({
        backgroundColor: props.customColor || theme.palette.secondary.main,
        width: props.customSize,
        minWidth: '100px',
        display: 'inline-block',
        '&:hover': {
            backgroundColor: adjustColor(props.customColor || theme.palette.secondary.main, -30),
        },
        color: theme.palette.getContrastText(adjustColor(props.customColor || theme.palette.secondary.main, -20)),
    }),
}));

interface Props extends ButtonProps {
    className?: string;
    label?: string;
    icon?: string;
    image?: string;
    customColor?: string;
    customSize?: number;
}

export default function CoreButton({ label, className, customColor, customSize, icon, image, ...rest }: Props) {
    const classes = useStyles({
        label,
        customColor,
        customSize,
        icon,
        image,
    });
    return (
        <Button variant="contained" {...rest} className={clsx(classes.main, className)}>
            <CoreFlex alignItems="center" justify="center" wrap="nowrap">
                {image && <img src={image} alt={label} />}
                {icon && <Icon>icon</Icon>}
                {label && label.toUpperCase()}
            </CoreFlex>
        </Button>
    );
}
