import React from 'react';
import { Chip, Icon, Avatar, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { isFunction } from 'lodash';
import { grey } from '@material-ui/core/colors';

interface Props {
    iconName?: string;
    overrideClasses?: Record<string, any>;
    color?: string;
    size?: 'small' | 'medium';
    avatarSize?: number;
    label: string;
    onClick?: () => void;
    className?: string;
    onDelete?: (value: string) => void;
    deleteIconName?: string;
    avatar?: string;
}

interface StyleProps {
    color?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: (props: StyleProps) => ({
        backgroundColor: props.color,
        margin: '0.2rem 0.1rem',
    }),
}));

export default function CoreChip({
    iconName,
    overrideClasses,
    size = 'medium',
    avatarSize,
    color,
    label,
    onClick,
    className,
    onDelete,
    deleteIconName,
    avatar,
}: Props) {
    const classes = useStyles({ color });
    const extraProps: Record<string, any> = {};
    if (iconName) {
        extraProps['icon'] = <Icon>{iconName}</Icon>;
    }
    if (deleteIconName) {
        extraProps['deleteIcon'] = <Icon>{deleteIconName}</Icon>;
    }
    if (avatar) {
        extraProps['avatar'] = (
            <Avatar
                sizes="50"
                src={avatar}
                style={{
                    width: avatarSize || 70 + 'px',
                    height: avatarSize || 70 + 'px',
                    backgroundColor: grey[700],
                    padding: '0.5rem',
                }}
            />
        );
    }

    if (onDelete) {
        extraProps['onDelete'] = handleDelete;
    }

    if (overrideClasses) {
        extraProps['classes'] = overrideClasses;
    }

    if (onClick) {
        extraProps['onClick'] = handleClick;
    }

    function handleDelete(event: MouseEvent) {
        if (onDelete && isFunction(onDelete)) {
            onDelete(label);
        }
    }

    function handleClick(event: any) {
        if (onClick && isFunction(onClick)) {
            event.stopPropagation();
            event.preventDefault();
            onClick();
        }
    }

    return (
        <>
            <Chip
                size={size}
                clickable
                onClick={handleClick}
                color="primary"
                label={label.toUpperCase()}
                className={clsx(className, classes.root)}
                {...extraProps}
            />
        </>
    );
}
