import React, { useState, useContext, useEffect } from 'react';
import { Grid, Paper, Theme, Typography, Switch } from '@material-ui/core';
import CoreFormInput from '../../../components/core/form/input/CoreFormInput';
import { InputType, ValidationType } from '../../../interfaces';
import { makeStyles, createStyles } from '@material-ui/styles';
import { useStore } from '../../../services/store';
import { stores } from '../../../stores';
import { AuthContext } from '../../../context/AuthContext';
import CoreFlex from '../../../components/core/flex/CoreFlex';
import { validate, isUrl } from '../../../services/validation';
import { updateCountry, createCountry, removeCountry } from '../../../microservices/country';
import CoreCrudToolbar from '../../../components/core/crud-toolbar/CoreCrudToolbar';
import CoreFormSelect from '../../../components/core/form/select/CoreFormSelect';
import { timezones } from '../../../services/timezones';
import { CoreImageUpload } from '../../../components/core/image-upload/CoreImageUpload';
import { useRouter, getRoute } from '../../../services/router';
import { ROUTES } from '../../../routes';
import { Country, getDefaultCountry, CountryTranslation } from '../../../interfaces/country';
import { getLanguageName, Language, TranslationFlag } from '../../../config/translation';
import { CoreFlagElement } from '../../../components/core/flag-element/CoreFlagElement';
import CoreTranslatedInput from '../../../components/core/form/input/translated/CoreTranslatedInput';
import { showError } from '../../../services/alert';
import CoreFormInputMoney from '../../../components/core/form/input/money/CoreFormInputMoney';
import { cloneDeep } from 'lodash';
import { prepareObjectForCloning } from '../../../services/utils';

export default function CountryDetailPage() {
    const { navigateTo, params } = useRouter();
    const authContext = useContext(AuthContext);
    const classes = useStyles();
    const [country, setCountry] = useState<Country>(getDefaultCountry());
    const [countries] = useStore(stores.countries);
    const [language, setLanguage] = useStore<Language>(stores.language);
    const translation = country.translations?.[language];

    const validators = {
        name: () => {
            return validate({
                type: ValidationType.Name,
                value: translation?.name,
            });
        },
        nameShort: () => {
            return validate({
                type: ValidationType.Text,
                value: country.shortName,
            });
        },
        currencySymbol: () => {
            return {
                validated: country.currencySymbol.length > 0,
                message: 'Please enter currency symbol',
            };
        },
        currencyName: () => {
            return validate({
                type: ValidationType.Text,
                value: country.currencyName,
            });
        },
        phoneNumberPrefix: () => {
            return {
                validated: Boolean(country.phonePrefix) && country.phonePrefix.length > 1,
                message: 'Please enter countries phone perfix.',
            };
        },
        timeZone: () => {
            return validate({
                type: ValidationType.Number,
                value: country.timeZone,
            });
        },
        deliveryBasePrice: () => {
            return validate({
                type: ValidationType.Number,
                value: country.deliveryBasePrice,
            });
        },
        deliveryPerKmPrice: () => {
            return validate({
                type: ValidationType.Number,
                value: country.deliveryPerKmPrice,
            });
        },
        taxRate: () => {
            return validate({
                type: ValidationType.Number,
                value: country.taxRate,
            });
        },
        flag: () => {
            return {
                validated: country.flag && isUrl(country.flag),
                message: 'Please upload a flag.',
            };
        },
    };

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function loadData() {
        const countryId = parseInt(params.countryId);
        const cloneId = parseInt(params.cloneId);

        if (countryId) {
            const countryFound: Country | null = countries.find((country: Country) => country.id === countryId);
            if (countryFound) {
                countryFound.translations = countryFound.translations || {};
                setCountry(countryFound);
            }
            return;
        }
        if (cloneId) {
            const clonedCountry: Country | null = cloneDeep(
                countries.find((country: Country) => country.id === cloneId),
            );
            if (clonedCountry) {
                setCountry(prepareObjectForCloning<Country>(clonedCountry));
            }
        }
    }

    function handleAddLanguage(language: Language) {
        if (country.languages.includes(language)) {
            return;
        }
        handleValueChange('languages', country.languages.concat([language]));
    }

    function handleRemoveLanguage(language: Language) {
        if (!country.languages.includes(language)) {
            return;
        }
        handleValueChange(
            'languages',
            country.languages.filter((item) => item !== language),
        );
    }

    function handleValueChange(
        name: keyof Country | keyof CountryTranslation,
        value: string | number | boolean | any[],
        language?: Language,
    ) {
        if (language) {
            setCountry({
                ...country,
                translations: {
                    ...country.translations,
                    [language]: {
                        ...(country.translations ? country.translations[language] : {}),
                        [name]: value,
                    },
                },
            });
        } else {
            setCountry({
                ...country,
                [name]: value,
            });
        }
    }

    function handleImageUploaded(imageUrl: string) {
        handleValueChange('flag', imageUrl);
    }

    async function handleSaveCountry() {
        if (
            !validators.currencyName().validated ||
            !validators.currencySymbol().validated ||
            !validators.deliveryBasePrice().validated ||
            !validators.deliveryPerKmPrice().validated ||
            !validators.nameShort().validated ||
            !validators.phoneNumberPrefix().validated ||
            !validators.timeZone().validated
        ) {
            showError('Problem with country validation');
            return;
        }

        const response = country.id
            ? await updateCountry(country.id, country, authContext)
            : await createCountry(country, authContext);

        if (response && response.success && response.result && response.result.id) {
            setCountry(response.result);
            navigateTo(getRoute(ROUTES.country));
        } else {
            showError('Something went wrong: ' + response.message);
        }
    }

    async function handleDeleteCountry() {
        if (country.id) {
            await removeCountry(country.id, authContext);
        }
        navigateTo(getRoute(ROUTES.country));
    }

    function handleCancel() {
        navigateTo(getRoute(ROUTES.country));
    }

    return (
        <form autoComplete="off">
            <CoreCrudToolbar
                allowDelete={authContext.isAdmin}
                object={country}
                objectName="Country"
                isClone={params.cloneId}
                onCancel={handleCancel}
                onSave={handleSaveCountry}
                onDelete={handleDeleteCountry}
                onToggleVisibility={() => {
                    handleValueChange('isActive', !country.isActive);
                }}
                languages={Object.values(Language)}
                onChangeLanguage={setLanguage}
                selectedLanguage={language}
                translationObject={country.translations}
                translationKeys={['name']}
            />

            <Paper className={classes.paper}>
                <Grid container direction="column" justify="space-between">
                    <Grid item className={classes.formField}>
                        <Typography variant="h6">Country details</Typography>
                    </Grid>
                    <CoreFlex alignItems="flex-start">
                        <CoreFlex direction="column">
                            <CoreFlex>
                                <CoreTranslatedInput
                                    className={classes.formField}
                                    autoFocus
                                    required
                                    validation={validators.name}
                                    label="Country name (translated)"
                                    translationObject={country.translations || {}}
                                    keyName="name"
                                    language={language}
                                    value={translation?.name || ''}
                                    type={InputType.text}
                                    onValueChange={(value) => handleValueChange('name', value, language)}
                                    placeholder="Please enter a country name"
                                />
                                <CoreFormInput
                                    className={classes.smallField}
                                    validation={validators.nameShort}
                                    label="Short name"
                                    placeholder="Enter short name like US, TH, AU, FR etc"
                                    required
                                    value={country.shortName || ''}
                                    type={InputType.text}
                                    onValueChange={(event) => handleValueChange('shortName', event)}
                                />
                            </CoreFlex>

                            <CoreFlex alignItems="flex-start">
                                <CoreFormInput
                                    className={classes.smallField}
                                    label="Phone prefix"
                                    required
                                    validation={validators.phoneNumberPrefix}
                                    value={country.phonePrefix}
                                    type={InputType.text}
                                    onValueChange={(event) => handleValueChange('phonePrefix', event)}
                                    placeholder="Enter phone number"
                                />
                                <CoreFormSelect
                                    fullWidth
                                    validation={validators.timeZone}
                                    className={classes.formField}
                                    value={country.timeZone || ''}
                                    onValueChange={(value) => handleValueChange('timeZone', value as string)}
                                    label="Timezone"
                                    options={timezones.map((zone) => {
                                        return {
                                            value: zone.offset,
                                            label: zone.text,
                                        };
                                    })}
                                />
                            </CoreFlex>
                        </CoreFlex>
                        <CoreImageUpload
                            className={classes.imageUpload}
                            label="Country flag"
                            aspect={3 / 2}
                            basePath="flag"
                            width={190}
                            height={190 / (3 / 2)}
                            imageUrl={country.flag}
                            onUploadComplete={handleImageUploaded}
                            requirements={{
                                destinationWidth: 200,
                                destinationHeight: 133,
                            }}
                        />
                    </CoreFlex>
                    <Grid item className={classes.formField}>
                        <Typography variant="h6">Country languages</Typography>
                    </Grid>
                    <CoreFlex direction="column" className={classes.languageBlock}>
                        <CoreFlex justify="flex-start">
                            {country.languages.map((language) => (
                                <CoreFlagElement
                                    key={language}
                                    language={language}
                                    onClick={(value) => handleRemoveLanguage(value as Language)}
                                    className={classes.flag}
                                />
                            ))}
                        </CoreFlex>
                        <CoreFormSelect
                            label="Add language"
                            value={''}
                            onValueChange={(value) => handleAddLanguage(value as Language)}
                            options={Object.values(Language)
                                .filter((item) => !country.languages.includes(item))
                                .map((language) => {
                                    return {
                                        label: getLanguageName(language),
                                        image: TranslationFlag[language],
                                        value: language,
                                    };
                                })}
                        />
                    </CoreFlex>
                    <Grid item className={classes.formField}>
                        <Typography variant="h6">Country prices</Typography>
                    </Grid>
                    <CoreFlex className={classes.formField} justify="center">
                        <CoreFlex className={classes.priceSample}>
                            <Typography variant="h4">
                                {country.isSymbolAtFront && country.currencySymbol}
                                159
                                {country.isShowingDecimals && (country.isUsingComa ? ',24' : '.24')}
                                {!country.isSymbolAtFront && country.currencySymbol} {country.currencyName}
                            </Typography>
                        </CoreFlex>
                    </CoreFlex>
                    <CoreFlex>
                        <CoreFormInput
                            className={classes.smallField}
                            validation={validators.currencyName}
                            label="Currency name"
                            value={country.currencyName || ''}
                            onValueChange={(event) => handleValueChange('currencyName', event)}
                        />
                        <CoreFormInput
                            className={classes.smallField}
                            validation={validators.currencySymbol}
                            label="Currency symbol"
                            value={country.currencySymbol || ''}
                            onValueChange={(event) => handleValueChange('currencySymbol', event)}
                        />
                        <CoreFlex direction="column" className={classes.switch}>
                            <Typography variant="caption">Symbol at front</Typography>
                            <Switch
                                checked={country.isSymbolAtFront}
                                onChange={() => {
                                    handleValueChange('isSymbolAtFront', !country.isSymbolAtFront);
                                }}
                                value={country.isSymbolAtFront}
                                color="secondary"
                            />
                        </CoreFlex>
                        <CoreFlex direction="column" className={classes.switch}>
                            <Typography variant="caption">Show decimals</Typography>
                            <Switch
                                checked={country.isShowingDecimals}
                                onChange={() => {
                                    handleValueChange('isShowingDecimals', !country.isShowingDecimals);
                                }}
                                value={country.isShowingDecimals}
                                color="secondary"
                            />
                        </CoreFlex>
                        <CoreFlex direction="column" className={classes.switch}>
                            <Typography variant="caption">Use comma</Typography>
                            <Switch
                                checked={country.isUsingComa}
                                onChange={() => {
                                    handleValueChange('isUsingComa', !country.isUsingComa);
                                }}
                                value={country.isUsingComa}
                                color="secondary"
                            />
                        </CoreFlex>
                    </CoreFlex>

                    <CoreFlex justify="space-between">
                        <CoreFormInputMoney
                            className={classes.smallField}
                            countryId={country.id}
                            label={`Delivery base price`}
                            value={country.deliveryBasePrice + '' || ''}
                            type={InputType.number}
                            onValueChange={(value) => handleValueChange('deliveryBasePrice', value)}
                        />
                        <CoreFormInputMoney
                            className={classes.smallField}
                            countryId={country.id}
                            label="Delivery price per km"
                            value={country.deliveryPerKmPrice + '' || ''}
                            type={InputType.number}
                            onValueChange={(event) => handleValueChange('deliveryPerKmPrice', event)}
                        />

                        <CoreFormInput
                            className={classes.smallField}
                            validation={validators.taxRate}
                            label="Tax rate (%)"
                            value={country.taxRate + '' || ''}
                            onValueChange={(event) => handleValueChange('taxRate', event)}
                        />
                    </CoreFlex>
                </Grid>
            </Paper>
        </form>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formField: {
            padding: '0.5rem 0.5rem',
            minWidth: '300px',
            width: '100%',
        },
        select: {
            width: '100%',
        },

        smallField: {
            padding: '0.5rem 0.5rem',
            minWidth: '200px',
            width: '100%',
        },
        priceSample: {
            margin: '1rem 1rem',
            padding: '1rem 1rem',
            maxWidth: '300px',
            minWidth: '300px',
            borderRadius: '8px',
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
        },
        switch: {
            minWidth: '100px',
            padding: '0.5rem 1rem',
            textAlign: 'center',
        },
        imageUpload: {
            margin: '1rem 1rem',
        },
        paper: {
            margin: '2rem',
            padding: '2rem',
        },
        flag: {
            height: '25px',
            margin: '0.5rem',
            marginBottom: '1rem',
        },
        languageBlock: {
            backgroundColor: 'rgba(0,0,0,0.05)',
            padding: '1rem',
            borderRadius: '1rem',
            margin: '0.5rem',
        },
    }),
);
