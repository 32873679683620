import React, { useState, useEffect, useContext } from 'react';
import { useTheme, createStyles } from '@material-ui/styles';
import { TableColumnType } from '../../interfaces';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import {
    getRestaurants,
    getRestaurantById,
    updateRestaurant,
    getOperatorRestaurants,
    removeRestaurant,
} from '../../microservices/restaurant';
import CoreTableSimple from '../../components/core/table/simple/CoreTableSimple';
import { useRouter, getRoute } from '../../services/router';
import { ROUTES } from '../../routes';
import { useStore } from '../../services/store';
import { stores } from '../../stores';
import { AuthContext } from '../../context/AuthContext';
import { objectsEqual } from '../../services/utils';
import { getDefaultRestaurant, Restaurant, RestaurantTranslation, RestaurantType } from '../../interfaces/restaurant';
import { isArray } from 'lodash';
import CoreFlex from '../../components/core/flex/CoreFlex';
import { CoreFlagElement } from '../../components/core/flag-element/CoreFlagElement';
import { getTranslation, Language } from '../../config/translation';
import RestaurantTypeBox from '../../components/restaurant/type-box/RestaurantTypeBox';
import { getOperators } from '../../microservices/operator';
import { Operator } from '../../interfaces/operator';
import clsx from 'clsx';
import { useCoreAnimations } from '../../config/styles/animations';
import { useUserTypeStyles } from '../../config/styles/userType';
import UserCard from '../../components/user/card/UserCard';

export default function RestaurantPage() {
    const authContext = useContext(AuthContext);
    const theme = useTheme();
    const { navigateTo } = useRouter();
    const classes = useStyles(theme);
    const [restaurants, setRestaurants] = useStore<Restaurant[]>(stores.restaurants);
    const [, setSelectedRestaurant] = useStore<Restaurant>(stores.selectedRestaurant);
    const [language] = useStore<Language>(stores.language);
    const [operators, setOperators] = useState<Operator[]>([]);

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function loadData() {
        const restaurantId = authContext.user?.restaurantId;
        let userRestaurant: Restaurant | null = null;
        if (restaurantId) {
            userRestaurant = await getRestaurantById(restaurantId, authContext);
        }
        const operatorId = userRestaurant?.operatorId || authContext.user?.operatorId;

        const result = authContext.isAdmin
            ? await getRestaurants({}, authContext)
            : await getOperatorRestaurants(operatorId || 0, {}, authContext);

        if (isArray(result) && !objectsEqual(restaurants, result)) {
            setRestaurants(result);
        }
        const operators = await getOperators(authContext);
        setOperators(operators);
    }

    function handleAddNewRestaurant() {
        setSelectedRestaurant(getDefaultRestaurant());
        navigateTo(getRoute(ROUTES.restaurant.new));
    }

    function handleEditRestaurant(id: number) {
        navigateTo(getRoute(ROUTES.restaurant.edit, { restaurantId: id }));
    }

    async function handleToggleVisibility(value: string, restaurant: Restaurant) {
        const updatedRestaurants: Restaurant[] = [];
        if (!restaurant.id) {
            return;
        }
        restaurants.forEach((item: Restaurant) => {
            if (item.id === restaurant.id) {
                item.isActive = !restaurant.isActive;
            }
            updatedRestaurants.push(item);
        });
        await updateRestaurant(restaurant.id, { isActive: restaurant.isActive }, authContext);
        setRestaurants(updatedRestaurants);
    }

    function formatFlag(countryId: number): JSX.Element {
        return (
            <CoreFlex>
                <CoreFlagElement countryId={countryId} className={classes.flag} />
            </CoreFlex>
        );
    }

    function formatRestaurantType(type: RestaurantType): JSX.Element {
        return <RestaurantTypeBox type={type} compact />;
    }

    function formatPhoto(photos: string[]): JSX.Element {
        if (!photos.length) {
            return <></>;
        }
        return (
            <CoreFlex justify="flex-start">
                <img src={photos[0]} className={classes.image} />
            </CoreFlex>
        );
    }

    function formatOperator(operatorId: number): JSX.Element {
        const operator = operators.find((item) => item.id === operatorId);
        if (!operator) {
            return <></>;
        }
        return (
            <CoreFlex className={classes.operator}>
                <Typography variant="subtitle1">{operator.name}</Typography>
            </CoreFlex>
        );
    }

    function formatManager(userId: number): JSX.Element {
        return <UserCard userId={userId} />;
    }

    function formatRestaurantName(
        translations: Partial<Record<Language, RestaurantTranslation>>,
        restaurant: Restaurant,
    ): JSX.Element {
        return <Typography>{`Munchies ${getTranslation(restaurant, language)}`}</Typography>;
    }

    async function handleRemoveRestaurant(id: number) {
        await removeRestaurant(id, authContext);
        setRestaurants(restaurants.filter((item) => item.id !== id));
    }

    function handleCloneRestaurant(id: number) {
        navigateTo(getRoute(ROUTES.restaurant.clone, { cloneId: id }));
    }

    return (
        <>
            <Typography variant="h5" className={classes.title}>
                Restaurant management
            </Typography>
            <CoreTableSimple
                data={restaurants}
                onClickRow={() => {}}
                hover
                onAddNew={handleAddNewRestaurant}
                onClone={handleCloneRestaurant}
                onEdit={handleEditRestaurant}
                objectName="restaurant"
                onRemove={handleRemoveRestaurant}
                rowHeight={135}
                columns={[
                    {
                        label: 'Visible',
                        column: 'isActive' as keyof Restaurant,
                        type: TableColumnType.Switch,
                        className: classes.switch,
                        action: handleToggleVisibility,
                    },
                    {
                        label: 'Photo',
                        column: 'photos' as keyof Restaurant,
                        formatFunction: formatPhoto,
                        className: clsx(classes.image, classes.animationScale5x),
                    },
                    {
                        label: 'Country',
                        column: 'countryId' as keyof Restaurant,
                        formatFunction: formatFlag,
                        className: clsx(classes.flag, classes.animationScale2x),
                    },
                    {
                        label: 'Name',
                        column: 'name' as keyof Restaurant,
                        formatFunction: formatRestaurantName,
                        className: classes.nameColumn,
                    },
                    {
                        label: 'Type',
                        column: 'type' as keyof Restaurant,
                        formatFunction: formatRestaurantType,
                        className: classes.animationScale2x,
                    },
                    {
                        label: 'Operator',
                        column: 'operatorId' as keyof Restaurant,
                        formatFunction: formatOperator,
                    },
                    {
                        label: 'Manager',
                        column: 'managerId' as keyof Restaurant,
                        formatFunction: formatManager,
                        className: classes.animationScale1_2x,
                    },
                    {
                        label: 'Address',
                        column: 'address' as keyof Restaurant,
                        type: TableColumnType.Text,
                        className: classes.address,
                    },
                    {
                        label: 'Updated',
                        column: 'updatedAt' as keyof Restaurant,
                        type: TableColumnType.Date,
                    },
                ]}
            />
        </>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        flag: {
            maxWidth: '2rem',
        },
        image: {
            width: '100px',
            zIndex: 1000,
            position: 'relative',
            '&:hover': {},
        },
        nameColumn: {
            // minWidth: '200px',
            flexWrap: 'nowrap',
        },
        address: {
            flexWrap: 'wrap',
            maxWidth: '250px',
            flexShrink: 1,
            flexBasis: 0,
        },
        switch: {},
        title: {
            marginBottom: '1rem',
        },
        operator: {
            // borderColor: theme.palette.common.black,
            borderWidth: '1px',
            borderRadius: '1rem',
            // borderStyle: 'solid',
            paddding: '0.5rem',
            marginRight: '3rem',
        },

        ...useCoreAnimations(theme),
        ...useUserTypeStyles(theme),
    }),
);
