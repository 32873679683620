import { OrderStatus, OrderTimerThreshold } from '../interfaces/order';
import { minutesToMilliseconds } from './tools';

export const OrderTimerThresholds: Partial<Record<OrderStatus, OrderTimerThreshold>> = {
    [OrderStatus.RECEIVED]: {
        ok: minutesToMilliseconds(3),
        late: minutesToMilliseconds(5),
    },
    [OrderStatus.COOKING]: {
        ok: minutesToMilliseconds(10),
        late: minutesToMilliseconds(20),
    },
    [OrderStatus.READY]: {
        ok: minutesToMilliseconds(12),
        late: minutesToMilliseconds(20),
    },
    [OrderStatus.PICKED_UP]: {
        ok: minutesToMilliseconds(20),
        late: minutesToMilliseconds(30),
    },
    [OrderStatus.DELIVERED]: {
        ok: minutesToMilliseconds(30),
        late: minutesToMilliseconds(45),
    },
};
