import React from 'react';
import { Switch } from '@material-ui/core';
import { SwitchProps } from '@material-ui/core/Switch';

interface Props extends SwitchProps {
    onValueChange: (value: boolean) => void;
}

export default function CoreSwitch({ onValueChange, ...rest }: Props) {
    function handleChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
        onValueChange(checked);
    }

    return (
        <Switch
            onClick={(event) => event.stopPropagation()}
            checked={rest.value !== undefined && (rest.value as boolean)}
            onChange={handleChange}
            value={rest.value}
            color="secondary"
        />
    );
}
