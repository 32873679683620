import React from 'react';
import CoreChip from '../CoreChip';
import { Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { amber, indigo, lightBlue, purple, teal } from '@material-ui/core/colors';
import clsx from 'clsx';
import { adjustColor } from '../../../../services/utils';
import { UserType } from '../../../../interfaces/user';
import CoreFlex from '../../flex/CoreFlex';
import { getUserTypeData, useUserTypeStyles } from '../../../../config/styles/userType';
import { useCoreAnimations } from '../../../../config/styles/animations';

interface Props {
    userType: UserType;
    className?: string;
    onClick?: () => void;
}

export default function CoreChipUserType({ userType, onClick, className }: Props) {
    const classes = useStyles();

    const userTypeData = getUserTypeData(userType);

    return (
        <CoreFlex justify="center" alignItems="center" className={classes.container}>
            <CoreChip
                color="primary"
                label={userTypeData.label}
                iconName={userTypeData.icon}
                onClick={onClick}
                className={clsx(
                    classes.userTypeChip,
                    className,
                    classes[userTypeData.className as keyof typeof classes],
                    classes.animationScale1_5x,
                )}
            />
        </CoreFlex>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '170px',
        },
        userTypeChip: {
            minWidth: '130px',
            maxWidth: '130px',
            minHeight: '50px',
        },
        ...useUserTypeStyles(theme),
        ...useCoreAnimations(theme),
    }),
);
