import React, { useContext } from 'react';
import { TableHead, TableRow, TableCell, Theme, Table, TableBody, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Order, OrderItem, OrderStatus } from '../../../../../../interfaces/order';
import { COLOR } from '../../../../../../services/createTheme';
import OrderTableRowDetailsItemsRow from './row/OrderTableRowDetailsItemsRow';
import { grey } from '@material-ui/core/colors';
import CoreFlex from '../../../../../core/flex/CoreFlex';
import { markOrderItemAsReady, updateOrder } from '../../../../../../microservices/order';
import { cloneDeep } from 'lodash';
import { showError } from '../../../../../../services/alert';
import { AuthContext } from '../../../../../../context/AuthContext';

interface Props {
    order: Order;
    headerColor?: string;
    onMoreDetails?: () => void;
    onUpdateOrder: (order: Order) => void;
}

interface StyleProps {
    headerColor: string;
}

export default function OrderTableRowDetailsItems({
    order,
    onMoreDetails,
    onUpdateOrder,
    headerColor = COLOR.MunchiesGreen,
}: Props) {
    const classes = useStyles({ headerColor });
    const authContext = useContext(AuthContext);

    async function handleToggleReady(orderItem: OrderItem) {
        if (order.status !== OrderStatus.COOKING) {
            showError('Ready status can only be set during the cooking status.');
            return;
        }
        const newOrder = cloneDeep(order);
        const orderItemIndex = order.items.findIndex((item) => item.id === orderItem.id);
        newOrder.items[orderItemIndex].readyAt = orderItem.readyAt ? undefined : new Date();
        onUpdateOrder(newOrder);
        markOrderItemAsReady(orderItem.id || 0, authContext);
    }

    return (
        <Table>
            <TableHead className={classes.root}>
                <TableRow className={classes.tableHeader}>
                    <TableCell id="ready" className={classes.cell}>
                        READY
                    </TableCell>
                    <TableCell id="waiting" className={classes.cell}>
                        QUANTITY
                    </TableCell>
                    <TableCell id="name" className={classes.cell}>
                        ORDER ITEM
                    </TableCell>
                    <TableCell id="unitPrice" className={classes.cell}>
                        UNIT PRICE
                    </TableCell>
                    <TableCell id="totalPrice" className={classes.cell}>
                        TOTAL PRICE
                    </TableCell>
                    <TableCell id="total" className={classes.cell}>
                        CUSTOMISATION
                    </TableCell>
                    <TableCell id="total" className={classes.fullCell}>
                        <CoreFlex justify="center" className={classes.moreDetails} onClick={onMoreDetails}>
                            <Typography variant="button">MORE DETAILS</Typography>
                        </CoreFlex>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {order.items.map((orderItem: OrderItem) => {
                    return (
                        <OrderTableRowDetailsItemsRow
                            orderItem={orderItem}
                            order={order}
                            key={orderItem.id}
                            onToggleReady={() => handleToggleReady(orderItem)}
                        />
                    );
                })}
            </TableBody>
        </Table>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        moreDetails: {
            backgroundColor: grey[300],
            color: grey[800],
            width: '250px',
            cursor: 'pointer',
            height: '40px',
            // margin: '-0.5rem',
            fontWeight: 800,
            '&:hover': {
                backgroundColor: grey[400],
            },
        },
        root: {
            borderRadius: '1rem 1rem 0rem 0rem',
        },
        cell: {
            color: theme.palette.common.white,
            paddingBottom: '0.5rem',
            paddingTop: '0.5rem',
            borderWidth: '0px',
        },
        fullCell: {
            margin: 0,
            padding: 0,
            borderWidth: '0px',
        },
        tableHeader: (props: StyleProps) => ({
            backgroundColor: props.headerColor,
        }),
    }),
);
