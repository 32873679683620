import { Theme } from '@material-ui/core';
import { amber, indigo, lightBlue, purple, teal } from '@material-ui/core/colors';
import { UserType } from '../../interfaces/user';
import { adjustColor } from '../../services/utils';

export function getUserTypeStyleName(userType: UserType): string {
    const map = {
        [UserType.CUSTOMER]: 'userTypeCustomer',
        [UserType.COURIER]: 'userTypeCourier',
        [UserType.STAFF]: 'userTypeStaff',
        [UserType.MANAGER]: 'userTypeManager',
        [UserType.OWNER]: 'userTypeOwner',
        [UserType.ADMIN]: 'userTypeAdmin',
        [UserType.SUPER_ADMIN]: 'userTypeSuperAdmin',
    };
    return map[userType];
}

export function useUserTypeStyles(theme: Theme) {
    return {
        userTypeManager: {
            backgroundColor: purple[700],
            '&:hover': {
                backgroundColor: adjustColor(purple[700], -20),
            },
            '&:focus': {
                backgroundColor: adjustColor(purple[700], -20),
            },
        },
        userTypeOwner: {
            backgroundColor: teal[700],
            '&:hover': {
                backgroundColor: adjustColor(teal[700], -20),
            },
            '&:focus': {
                backgroundColor: adjustColor(teal[700], -20),
            },
        },
        userTypeAdmin: {
            backgroundColor: lightBlue[500],
            '&:hover': {
                backgroundColor: adjustColor(lightBlue[500], -20),
            },
            '&:focus': {
                backgroundColor: adjustColor(lightBlue[500], -20),
            },
        },
        userTypeSuperAdmin: {
            backgroundColor: indigo[700],
            '&:hover': {
                backgroundColor: adjustColor(indigo[700], -20),
            },
            '&:focus': {
                backgroundColor: adjustColor(indigo[700], -20),
            },
        },
        userTypeStaff: {
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: adjustColor(theme.palette.primary.main, -20),
            },
            '&:focus': {
                backgroundColor: adjustColor(theme.palette.primary.main, -20),
            },
        },
        userTypeCourier: {
            backgroundColor: amber[700],
            '&:hover': {
                backgroundColor: adjustColor(amber[700], -20),
            },
            '&:focus': {
                backgroundColor: adjustColor(amber[700], -20),
            },
        },
        userTypeCustomer: {
            backgroundColor: theme.palette.secondary.main,
            '&:hover': {
                backgroundColor: adjustColor(theme.palette.secondary.main, -20),
            },
            '&:focus': {
                backgroundColor: adjustColor(theme.palette.secondary.main, -20),
            },
        },
    };
}

export function getUserTypeData(userType: UserType) {
    let className = '';
    let icon = '';
    let label = '';

    switch (userType) {
        case UserType.SUPER_ADMIN:
            icon = 'fingerprint';
            className = 'userTypeSuperAdmin';
            label = 'SUPERADMIN';
            break;
        case UserType.ADMIN:
            icon = 'stars';
            className = 'userTypeAdmin';
            label = 'ADMIN';
            break;
        case UserType.OWNER:
            icon = 'assistant_photo';
            className = 'userTypeOwner';
            label = 'OWNER';
            break;
        case UserType.MANAGER:
            icon = 'supervisor_account';
            className = 'userTypeManager';
            label = 'MANAGER';
            break;
        case UserType.STAFF:
            icon = 'face';
            className = 'userTypeStaff';
            label = 'STAFF';
            break;
        case UserType.COURIER:
            icon = 'drive_eta';
            className = 'userTypeCourier';
            label = 'COURIER';
            break;
        case UserType.CUSTOMER:
        default:
            className = 'userTypeCustomer';
            icon = 'favorite';
            label = 'CUSTOMER';
    }
    return { icon, className, label };
}
