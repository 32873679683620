import React from 'react';
import { TableHead, TableRow, TableCell, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { OrderStatus, Order } from '../../../../interfaces/order';

interface Props {
    isExpanded: boolean;
    bgColor: string;
}

interface StyleProps {
    bgColor: string;
}

export default function OrderTableHeader({ isExpanded, bgColor }: Props) {
    const classes = useStyles({ bgColor });

    return (
        <TableHead className={classes.root}>
            {isExpanded && (
                <TableRow className={classes.bottomBar}>
                    <TableCell id="number" className={classes.cell}>
                        NUMBER
                    </TableCell>
                    <TableCell id="waiting" className={classes.cell}>
                        WAITING
                    </TableCell>
                    <TableCell id="type" className={classes.cell}>
                        TYPE
                    </TableCell>
                    <TableCell id="customer" className={classes.cell}>
                        CUSTOMER
                    </TableCell>
                    <TableCell id="items" className={classes.cell}>
                        ITEMS
                    </TableCell>
                    <TableCell id="total" className={classes.cell}>
                        TOTAL
                    </TableCell>
                    <TableCell id="actions" className={classes.cell}>
                        ACTIONS
                    </TableCell>
                    <TableCell id="expand" className={classes.cell} align="right">
                        EXPAND
                    </TableCell>
                </TableRow>
            )}
        </TableHead>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderRadius: '1rem 1rem 0rem 0rem',
        },
        bottomBar: (props: StyleProps) => ({
            backgroundColor: props.bgColor,
            color: theme.palette.common.white,
            textAlign: 'left',
            margin: 0,
        }),
        cell: {
            color: theme.palette.common.white,
            paddingBottom: '0.5rem',
            paddingTop: '0.5rem',
            borderWidth: '0px',
        },
        topRowCell: {},
    }),
);
