import React, { useContext, useState, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { AuthContext } from './context/AuthContext';
import { refreshTokens, setStateLoggedIn } from './microservices/auth';
import { ThemeProvider } from '@material-ui/styles';
import createTheme from './services/createTheme';
import Layout from './pages/Layout';
import { RegisterPage } from './pages/auth/register/RegisterPage';
import { useStore } from './services/store';
import { stores } from './stores';
import LoginPage from './pages/auth/login/LoginPage';
import { useRouter } from './services/router';
import { Loader } from './Loader';
import { AlertMessage } from './interfaces';
import { CoreSnackbar } from './components/core/snackbar/CoreSnackbar';
import { initSocket } from './services/socket';
import AuthForgotPasswordPage from './pages/auth/ForgotPassword/ForgotPasswordPage';
import { AuthResetPasswordPage } from './pages/auth/ResetPassword/ResetPasswordPage';

const App: React.FC = () => {
    const authContext = useContext(AuthContext);
    const [originalRoute, setOriginalRoute] = useState<string>();
    const [showMessage, setShowMessage] = useStore<AlertMessage | null>(stores.showMessage);
    const { location, navigateTo } = useRouter();

    if (!originalRoute) {
        setOriginalRoute(location.pathname);
    }

    useEffect(() => {
        initSocket();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (originalRoute) {
            console.log('Original route set', location.pathname);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [originalRoute]);

    createBrowserHistory();

    // history.listen((location, action) => {
    //     console.log("location changed", location);
    //     history.push(location);
    //     setRouterState({ location, action });
    // });

    useEffect(() => {
        if (!authContext.loggedIn) {
            updateTokens();
        } else {
            navigateTo(originalRoute);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authContext.loggedIn]);

    async function updateTokens() {
        const response = await refreshTokens(authContext);
        if (response && response.success) {
            setStateLoggedIn(response, authContext);
            setTimeout(updateTokens, 10 * 60 * 1000);
        }
    }

    return (
        <ThemeProvider theme={createTheme()}>
            {authContext.loggedIn ? (
                <Loader>
                    <Layout />
                </Loader>
            ) : (
                <Switch>
                    <Route exact path="/auth/register" component={RegisterPage} />
                    <Route exact path="/auth/forgot-password" component={AuthForgotPasswordPage} />
                    <Route exact path="/auth/reset-password" component={AuthResetPasswordPage} />
                    <Route exact path="/auth/login" component={LoginPage} />
                    {!authContext.loggedIn && <Redirect to="/auth/login" />}
                </Switch>
            )}
            {showMessage && (
                <CoreSnackbar
                    autoCloseIn={showMessage.autoCloseIn}
                    variant={showMessage.type}
                    message={showMessage.message}
                    onClose={() => setShowMessage(null)}
                />
            )}
        </ThemeProvider>
    );
};

export default App;
