import { cloneDeep, omit } from 'lodash';
import { Language, getTranslation } from '../config/translation';
import { Product, ProductChoice, ProductChoiceType, ProductExtra, ProductExclusion } from '../interfaces/product';

export function findTextInProduct(text: string, product: Product): boolean {
    if (text) {
        text = (text + '').toLowerCase();
    }
    let match = false;
    const languages = Object.keys(product.translations || {});
    product.tags.forEach((tag) => {
        if (tag.toLowerCase().includes(text)) {
            match = true;
        }
    });

    languages.forEach((language) => {
        const translation = product.translations?.[language as Language];
        if (translation?.name.toLowerCase().includes(text) || translation?.description?.toLowerCase().includes(text)) {
            match = true;
        }
        product.variations?.forEach((item) => {
            if (item.translations?.[language as Language]?.name.toLowerCase().includes(text)) {
                match = true;
            }
        });
        product.extras?.forEach((item) => {
            if (item.translations?.[language as Language]?.name.toLowerCase().includes(text)) {
                match = true;
            }
        });
        product.exclusions?.forEach((item) => {
            if (item.translations?.[language as Language]?.name.toLowerCase().includes(text)) {
                match = true;
            }
        });
        product.choices?.forEach((item) => {
            if (item.translations?.[language as Language]?.name.toLowerCase().includes(text)) {
                match = true;
            }
        });
    });
    return match;
}

export function populateProductTranslations(language: Language, product: Product): Product | null {
    if (!language || !product) {
        return null;
    }

    const newProduct = cloneDeep(product);

    const languages = Object.keys(newProduct.translations || {});
    const translation = newProduct.translations?.[language as Language];
    const hasTranslationData = Boolean(translation?.name);

    if (!hasTranslationData && languages.length) {
        const translationLanguageWithValues = languages.find(
            (item) => newProduct.translations?.[item as Language]?.name,
        );
        if (translationLanguageWithValues) {
            if (!newProduct.translations) {
                newProduct.translations = {};
            }
            if (!newProduct.translations?.[language as Language]) {
                newProduct.translations[language as Language] = { name: '', description: '' };
            }
            const translation: any = newProduct.translations?.[language as Language];
            if (translation) {
                translation.name =
                    getTranslation(product, translationLanguageWithValues as Language) +
                    ` (${language?.toUpperCase()})`;
                translation.description =
                    getTranslation(product, translationLanguageWithValues as Language, 'description') +
                    ` (${language?.toUpperCase()})`;
            }
        }
    }

    const productItems: (keyof Product)[] = ['extras', 'exclusions', 'choices', 'variations'];

    productItems.forEach((item) => {
        const itemArray: any[] = (newProduct[item] as any[]) || [];
        itemArray?.forEach((itemObject: any) => {
            const languages = Object.keys(itemObject.translations || {});
            const translation = itemObject.translations?.[language as Language];
            const hasTranslationData = Boolean(translation?.name);

            if (!hasTranslationData && languages.length) {
                const translationLanguageWithValues = languages.find((item) => {
                    return itemObject.translations?.[item as Language]?.[item !== 'choices' ? 'name' : 'options'];
                });
                if (!translationLanguageWithValues) {
                    return;
                }
                if (!itemObject.translations) {
                    itemObject.translations = {};
                }
                if (!itemObject.translations?.[language as Language]) {
                    itemObject.translations[language as Language] =
                        item === 'choices' ? { name: '', options: '' } : { name: '' };
                }
                const translation: any = itemObject.translations?.[language as Language];
                if (translation) {
                    if (item === 'choices') {
                        translation.options =
                            itemObject.type === ProductChoiceType.TEXT
                                ? itemObject.translations[translationLanguageWithValues as Language]?.options.map(
                                      (option: string) => `${option} (${language?.toUpperCase()})`,
                                  )
                                : itemObject.translations[translationLanguageWithValues as Language]?.options || [];
                    }
                    translation.name =
                        getTranslation(itemObject, translationLanguageWithValues as Language) +
                        ` (${language?.toUpperCase()})`;
                }
            }
        });
    });

    return newProduct;
}
