export const ROUTES = {
    dashboard: {},
    auth: {
        login: {},
        register: {},
        logout: {},
    },
    restaurant: {
        new: {},
        edit: {
            '@params': {
                productId: 'restaurantId',
            },
        },
        clone: {
            '@params': {
                cloneId: 'cloneId',
            },
        },
    },
    operator: {
        new: {},
        edit: {
            '@params': {
                productId: 'operatorId',
            },
        },
        clone: {
            '@params': {
                cloneId: 'cloneId',
            },
        },
    },
    country: {
        new: {},
        edit: {
            '@params': {
                countryId: 'countryId',
            },
        },
        clone: {
            '@params': {
                cloneId: 'cloneId',
            },
        },
    },
    category: {
        new: {
            '@params': {
                restaurantId: 'restaurantId',
            },
        },
        edit: {
            '@params': {
                categoryId: 'categoryId',
            },
        },
        clone: {
            '@params': {
                cloneId: 'cloneId',
            },
        },
    },
    product: {
        '@params': {
            restaurantId: 'restaurantId',
        },
        new: {
            '@params': {
                restaurantId: 'restaurantId',
            },
        },
        edit: {
            '@params': {
                productId: 'productId',
            },
        },
        clone: {
            '@params': {
                cloneId: 'cloneId',
            },
        },
    },
    customer: {},
    user: {
        edit: {
            '@params': {
                userId: 'userId',
            },
        },
    },
    order: {
        '@params': {
            orderId: 'orderid',
        },
        fullfill: {},
        cancel: {},
    },
    review: {},
};
