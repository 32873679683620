import React, { useState, useEffect } from 'react';
import CoreFlex from '../../core/flex/CoreFlex';
import { Theme, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { useStore } from '../../../services/store';
import { stores } from '../../../stores';
import CoreButton from '../../core/button/CoreButton';
import ProductsFormExclusionsRow from './editor/row/ProductExclusionEditorRow';
import CoreFormSelect from '../../core/form/select/CoreFormSelect';
import { Country } from '../../../interfaces/country';
import { Restaurant } from '../../../interfaces/restaurant';
import { Product, ProductExclusion } from '../../../interfaces/product';
import { Language, getTranslatedObjectUpdate, getTranslation } from '../../../config/translation';
import { isString } from 'lodash';

interface Props {
    product: Product;
    restaurant: Restaurant;
    products: Product[];
    exclusions: ProductExclusion[];
    onUpdate: (exclusions: ProductExclusion[]) => void;
}

const initialExclusion: ProductExclusion = {
    productId: 0,
    refund: 0,
    name: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    isActive: true,
    isDeleted: false,
};

export default function ProductExclusionEditor({ product, restaurant, onUpdate, products }: Props) {
    const classes = useStyles();
    const [country, setCountry] = useState<Country>();
    const [countries] = useStore<Country[]>(stores.countries);
    const [language] = useStore<Language>(stores.language);

    useEffect(() => {
        if (countries && restaurant.countryId) {
            setCountry(countries.find((country: Country) => country.id === restaurant.countryId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restaurant.countryId]);

    async function handleChangeExclusion(
        name: keyof ProductExclusion,
        value: string | boolean | number,
        index: number,
        language?: Language,
    ) {
        if (!product.exclusions?.length || product.exclusions.length <= index) {
            return;
        }
        const newExclusions = [...product.exclusions];

        let numberValue = 0;
        const exclusion: any = newExclusions[index];

        if (name === 'name' && language && exclusion && isString(value)) {
            newExclusions[index] = getTranslatedObjectUpdate<ProductExclusion>(exclusion, name, value, language);
            onUpdate(newExclusions);
            return;
        }

        if (name === 'refund' && isString(value)) {
            numberValue = parseFloat(value);
            if (numberValue < 0) {
                return;
            }
        }

        exclusion[name] = numberValue || value;
        onUpdate(newExclusions);
    }

    function handleAddExclusion() {
        onUpdate((product.exclusions || []).concat({ ...initialExclusion }));
    }

    function handleDeleteExclusion(index: number) {
        onUpdate((product.exclusions || []).filter((e, i) => index !== i));
    }

    function handleCloneExclusions(productId: number) {
        const clonedProduct = products.find((product: Product) => product.id === productId);
        if (clonedProduct) {
            const clonedExclusions = clonedProduct.exclusions;
            if (clonedExclusions) {
                onUpdate((product.exclusions || []).concat(clonedExclusions));
            }
        }
    }

    return (
        <CoreFlex direction="column" alignItems="flex-start">
            <CoreFlex justify="space-between" className={classes.cardHeader}>
                <Typography variant="h6">Optional exclusions</Typography>
                <CoreFormSelect
                    className={classes.cloneSelect}
                    value={''}
                    onValueChange={(value) => handleCloneExclusions(value as number)}
                    label="Clone from"
                    options={products
                        .filter((item: Product) => item?.exclusions?.length)
                        .map((item: Product) => {
                            return {
                                value: item.id || 0,
                                label: getTranslation(item, language) || '',
                            };
                        })}
                />
                <CoreButton label="ADD EXCLUSION" onClick={handleAddExclusion} />
            </CoreFlex>
            {product &&
                country &&
                product.variations &&
                (product.exclusions || []).map((exclusion: ProductExclusion, index: number) => {
                    return (
                        <ProductsFormExclusionsRow
                            key={index}
                            index={index}
                            exclusion={exclusion}
                            onAddExclusion={handleAddExclusion}
                            onDeleteExclusion={handleDeleteExclusion}
                            onChangeExclusion={handleChangeExclusion}
                            country={country}
                        />
                    );
                })}
        </CoreFlex>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardHeader: {
            paddingBottom: '2rem',
        },
        cloneSelect: {
            maxWidth: '300px',
            margin: '0rem 1rem',
        },
    }),
);
