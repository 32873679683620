import { Language } from './../config/translation';
import { DbBase, TranslationObjectBase } from './common';

export enum ProductChoiceType {
    PRODUCT = 'PRODUCT',
    IMAGE = 'IMAGE',
    ICON = 'ICON',
    TEXT = 'TEXT',
}

export interface Product extends DbBase {
    restaurantId: number;
    categoryIds: number[];
    relatedProductIds: number[];
    tags: string[];
    photo: string;
    fullPhoto?: string;
    isInStock: boolean;
    translations?: Partial<Record<Language, ProductTranslation>>;
    choices?: ProductChoice[];
    extras?: ProductExtra[];
    exclusions?: ProductExclusion[];
    variations?: ProductVariation[];
    ratings?: ProductRating[];
    noOfRatings?: number;
    rating?: number;
    favorited?: number;
}

/**
 * Variation
 */

export interface ProductTranslation extends TranslationObjectBase {
    name: string;
    description?: string;
}

export interface ProductVariation extends DbBase {
    productId: number;
    quantity?: number;
    price: number;
    photo?: string;
    discount?: number;
    isDiscountActive: boolean;
    translations?: Partial<Record<Language, ProductVariationTranslation>>;
}

export interface ProductVariationTranslation extends TranslationObjectBase {
    name: string;
}

/**
 * Extra
 */

export interface ProductExtra extends DbBase {
    productId: number;
    price: number;
    photo?: string;
    discount?: number;
    isDiscountActive: boolean;
    translations?: Partial<Record<Language, ProductExtraTranslation>>;
}

export interface ProductExtraTranslation extends TranslationObjectBase {
    name: string;
}

/**
 * Exclusion
 */

export interface ProductExclusion extends DbBase {
    productId: number;
    photo?: string;
    refund: number;
    translations?: Partial<Record<Language, ProductExclusionTranslation>>;
}

export interface ProductExclusionTranslation extends TranslationObjectBase {
    name: string;
}

/**
 * Choice
 */

export interface ProductChoice extends DbBase {
    productId: number;
    type: ProductChoiceType;
    isRequired: boolean;
    photo?: string;
    isMultipleChoice: boolean;
    translations?: Partial<Record<Language, ProductChoiceTranslation>>;
}

export interface ProductChoiceTranslation extends TranslationObjectBase {
    name: string;
    options: string[];
}

/**
 * Rating
 */

export interface ProductRating extends DbBase {
    userId?: number;
    productId?: number;
    stars: number;
    title: string;
    text?: string;
}

export function getDefaultProduct(): Product {
    return {
        name: '',
        restaurantId: 0,
        photo: '',
        fullPhoto: '',
        categoryIds: [],
        variations: [],
        tags: [],
        exclusions: [],
        relatedProductIds: [],
        extras: [],
        ratings: [],
        choices: [],
        isActive: true,
        isDeleted: false,
        isInStock: true,
    };
}

export function getDefaultProductVariation(productId?: number): ProductVariation {
    return {
        productId: productId || 0,
        price: 0,
        quantity: 0,
        translations: {
            en: {
                name: 'Regular price',
            },
        },
        isDiscountActive: false,
        discount: 0,
        isActive: true,
        isDeleted: false,
    };
}

export function getDefaultProductExtra(): ProductExtra {
    return {
        name: '',
        productId: 0,
        price: 0,
        isDiscountActive: false,
        discount: 0,
        isActive: true,
        isDeleted: false,
    };
}

export function getDefaultProductExclusion(): ProductExclusion {
    return {
        name: '',
        productId: 0,
        refund: 0,
        isActive: true,
        isDeleted: false,
    };
}

export function getDefaultProductChoice(): ProductChoice {
    return {
        name: '',
        productId: 0,
        isRequired: false,
        type: ProductChoiceType.TEXT,
        isMultipleChoice: false,
        translations: {
            en: {
                name: '',
                options: [],
            },
        },
        isActive: true,
        isDeleted: false,
    };
}
