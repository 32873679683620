import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import { InputAdornment, IconButton, Icon, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import moment from 'moment';

interface Props {
    value: Date | undefined;
    onValueChange: (value: Date | undefined) => void;
    label?: string;
    disablePast?: boolean;
    disableFuture?: boolean;
    disabled?: boolean;
    autoOk?: boolean;
    className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            width: '100%',
            // minWidth: "165px",
            // maxWidth: "165px"
        },
    }),
);

export default function CoreFormDatePicker({
    label = 'Please pick a date',
    value,
    onValueChange,
    disablePast = false,
    disableFuture = false,
    disabled = false,
    autoOk = true,
    className,
}: Props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    function handleDateChange(date: Date | null) {
        const correctedDate = date === null ? undefined : date;
        onValueChange(correctedDate);
    }

    function OutlinedTextField() {
        const inputProps: Record<string, any> = {};
        inputProps['endAdornment'] = (
            <InputAdornment position="end">
                <IconButton onClick={() => setOpen(true)} onMouseDown={() => setOpen(true)} disabled={disabled}>
                    <Icon>date_range</Icon>
                </IconButton>
            </InputAdornment>
        );
        return (
            <TextField
                disabled={disabled}
                label={label}
                className={classes.formControl}
                onFocus={() => setOpen(true)}
                variant="outlined"
                InputProps={inputProps}
                value={value && moment(value).format('DD-MM-YYYY')}
            />
        );
    }

    return (
        <div className={className}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    disablePast={disablePast}
                    disableFuture={disableFuture}
                    disabled={disabled}
                    autoOk={autoOk}
                    margin="normal"
                    id="date-picker-dialog"
                    open={open}
                    onClose={() => setOpen(false)}
                    TextFieldComponent={OutlinedTextField}
                    label={label}
                    format="dd/MM/yyyy"
                    value={value}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </MuiPickersUtilsProvider>
        </div>
    );
}
