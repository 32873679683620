import React, { useState } from 'react';
import { TableHead, TableRow, TableCell, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import CoreFlex from '../../../../core/flex/CoreFlex';
import OrderTableRowDetailsItems from './items/OrderTableRowDetailsItems';
import { Order } from '../../../../../interfaces/order';
import OrderTableRowDetailsMore from './more/OrderTableRowDetailsMore';

interface Props {
    order: Order;
    headerColor: string;
    onUpdateOrder: (order: Order) => void;
}

enum OrderDetailsMode {
    ITEMS,
    MORE,
}

export default function OrderTableRowDetails({ order, headerColor, onUpdateOrder: updateOrder }: Props) {
    const classes = useStyles();
    const [mode, setMode] = useState<OrderDetailsMode>(OrderDetailsMode.ITEMS);

    return (
        <CoreFlex className={classes.root}>
            {mode === OrderDetailsMode.ITEMS && (
                <OrderTableRowDetailsItems
                    order={order}
                    headerColor={headerColor}
                    onUpdateOrder={updateOrder}
                    onMoreDetails={() => setMode(OrderDetailsMode.MORE)}
                />
            )}
            {mode === OrderDetailsMode.MORE && (
                <OrderTableRowDetailsMore order={order} onOrderItems={() => setMode(OrderDetailsMode.ITEMS)} />
            )}
        </CoreFlex>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderRadius: '1rem 1rem 0rem 0rem',
        },
    }),
);
