import { Category } from './interfaces/category';
import { Country } from './interfaces/country';
import { Restaurant, getDefaultRestaurant } from './interfaces/restaurant';
import { getDefaultProduct, Product } from './interfaces/product';
import { User } from './interfaces/user';
import { IpLocation } from './interfaces/location';
import { createStores, useStore, getStoreValue } from './services/store';
import { AlertMessage } from './interfaces';
import { Language } from './config/translation';
import { RouteProps } from 'react-router';
import { getRootRoutes } from './pages';
import { Order } from './interfaces/order';

export { useStore, getStoreValue };

// interface Store {
//     user?: User;
//     authToken: string;
//     countries: Country[];
//     restaurants: Restaurant[];
//     products: Product[];
//     users: User[];
//     customers: User[];
//     orders: any[];
// }

export const stores = createStores({
    authToken: null as string | null,
    team: [] as Partial<User>[],
    language: Language.ENGLISH as Language,
    selectedRestaurant: getDefaultRestaurant() as Restaurant | null,
    selectedOrder: null as Order | null,
    selectedCountry: null as Country | null,
    selectedProduct: getDefaultProduct() as Product | null,
    filterCategory: null as Category | null,
    ipLocation: null as IpLocation | null,
    countries: [] as Country[],
    restaurants: [] as Restaurant[],
    products: [] as Product[],
    filteredProducts: [] as Product[],
    categories: [] as Category[],
    customers: [] as User[],
    orders: [] as Order[],
    routes: getRootRoutes() as RouteProps[],
    showMessage: null as AlertMessage | null,
});

declare global {
    interface Window {
        stores: any;
    }
}

window.stores = stores;
