import React, { useState } from 'react';
import { Theme, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Order } from '../../../../../../../interfaces/order';
import CoreFlex from '../../../../../../core/flex/CoreFlex';
import { useStore } from '../../../../../../../services/store';
import { stores } from '../../../../../../../stores';
import { grey } from '@material-ui/core/colors';
import { User } from '../../../../../../../interfaces/user';
import { CoreMap } from '../../../../../../core/map/CoreMap';
import { Marker } from 'react-map-gl';
import { CoreMapPin } from '../../../../../../core/map/pin/CoreMapPin';
import { formatTimeDifferenceFromMilliseconds } from '../../../../../../../services/utils';

interface Props {
    order: Order;
}

export default function OrderTableRowDetailsMoreDelivery({ order }: Props) {
    const classes = useStyles();
    const [team] = useStore<User[]>(stores.team);
    const courier = team.find((item) => item.id === order.courierId);
    const [viewport, setViewport] = useState({
        latitude: order.destination?.latitude || 0,
        longitude: order.destination?.longitude || 0,
        zoom: 14,
        bearing: 0,
        pitch: 0,
    });

    return (
        <CoreFlex className={classes.root} direction="column">
            <CoreFlex className={classes.header} justify="space-between">
                <Typography>DELIVERY DETAILS</Typography>
            </CoreFlex>
            <CoreFlex className={classes.body} justify="flex-start">
                <CoreFlex direction="column" className={classes.details}>
                    <CoreFlex justify="space-between" alignItems="center">
                        <Typography>Delivery location:</Typography>
                        <Typography>{order.destination?.name}</Typography>
                    </CoreFlex>
                    <CoreFlex justify="space-between" alignItems="center">
                        <Typography>Delivery distance:</Typography>
                        <Typography>{`${((order.deliveryDistance || 0) / 1000).toFixed(1)}km`}</Typography>
                    </CoreFlex>
                    <CoreFlex justify="space-between" alignItems="center">
                        <Typography>Delivery duration:</Typography>
                        <Typography>
                            EST: {formatTimeDifferenceFromMilliseconds(order.deliveryDurationEstimate || 0)}
                        </Typography>
                    </CoreFlex>
                    <CoreFlex justify="space-between" alignItems="center">
                        <Typography>Customer phone number:</Typography>
                        <Typography>{order.user?.phone}</Typography>
                    </CoreFlex>
                    <CoreFlex justify="space-between" alignItems="center">
                        <Typography>Courier assigned:</Typography>
                        <Typography>{courier?.name}</Typography>
                    </CoreFlex>
                    <CoreFlex justify="space-between" alignItems="center">
                        <Typography>Courier phone number:</Typography>
                        <Typography>{courier?.phone}</Typography>
                    </CoreFlex>
                </CoreFlex>
                <CoreFlex className={classes.mapBox}>
                    <div className={classes.mapContainer}>
                        <CoreMap viewport={viewport} onViewportChange={setViewport} className={classes.map}>
                            <Marker
                                longitude={order.destination?.longitude || 0}
                                latitude={order.destination?.latitude || 0}
                                offsetTop={-20}
                                offsetLeft={-10}
                            >
                                <CoreMapPin size={30} />
                            </Marker>
                        </CoreMap>
                    </div>
                </CoreFlex>
            </CoreFlex>
        </CoreFlex>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderRadius: '1rem 1rem 0rem 0rem',
        },
        mapBox: {
            width: '180px',
        },
        header: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            paddingLeft: '1rem',
            height: '40px',
        },
        body: {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.common.white,
        },
        details: {
            padding: '1rem',
            paddingRight: '1.5rem',
        },
        priceBreakdown: {
            backgroundColor: theme.palette.common.white,
            padding: '0.5rem',
            paddingTop: '1rem',
            width: '250px',
            color: grey[700],
        },
        indent: {
            padding: '0rem 1rem',
            marginBottom: '0.25rem',
        },
        mapContainer: {
            zIndex: 500,
            width: '180px',
            height: '180px',
        },
        map: {
            width: '180px',
            height: '180px',
        },
    }),
);
