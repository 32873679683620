import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../../../context/AuthContext';
import { Redirect } from 'react-router';
import { logoutUser } from '../../../microservices/auth';

export function LogoutPage() {
    const authContext = useContext(AuthContext);

    useEffect(() => {
        doLogout();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function doLogout() {
        localStorage.clear();
        await logoutUser(authContext);

        authContext.setLoggedIn && authContext.setLoggedIn(false);
    }

    if (!authContext.loggedIn) {
        return <Redirect to="/" />;
    }

    return <></>;
}
