import React, { Suspense, useContext } from 'react';
import { CssBaseline, Theme } from '@material-ui/core';
import { makeStyles, createStyles, useTheme } from '@material-ui/styles';
import CoreNavigationDrawer from '../components/core/navigation/drawer/CoreNavigationDrawer';
import { navigationConfig } from '../config/navigationConfig';
import CoreNavigationHandler from '../components/core/navigation/handler/CoreNavigationHandler';
import CoreAppHeader from '../components/core/app-header/CoreAppHeader';
import { CoreLoader } from '../components/core/loader/CoreLoader';
import { AuthContext } from '../context/AuthContext';

const menuWidth = 240;

export default function Layout() {
    const theme = useTheme();
    const classes = useStyles(theme);
    const [menuOpen, setMenuOpen] = React.useState(true);
    const authContext = useContext(AuthContext);

    function toggleMenu() {
        setMenuOpen(!menuOpen);
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <CoreAppHeader menuOpen={menuOpen} onClickMenu={toggleMenu} menuWidth={menuWidth} slideWithMenu />
            <CoreNavigationDrawer
                open={menuOpen}
                toggleDrawer={toggleMenu}
                width={menuWidth}
                menuContent={navigationConfig.filter((item) => item.access <= (authContext?.user?.type || 0))}
            />
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Suspense fallback={<CoreLoader type="circular" text="Loading..." />}>
                    <CoreNavigationHandler />
                </Suspense>
            </main>
        </div>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(2),
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
    }),
);
