import React, { useContext, useState, useEffect } from 'react';
import { AlertMessage, TableColumnType } from '../../../interfaces';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Theme, Typography } from '@material-ui/core';
import { useRouter, getRoute } from '../../../services/router';
import { AuthContext } from '../../../context/AuthContext';
import { ROUTES } from '../../../routes';
import { removeCategory, updateCategory } from '../../../microservices/category';
import CoreTableSimple from '../../core/table/simple/CoreTableSimple';
import orderBy from 'lodash/orderBy';
import CoreFlex from '../../core/flex/CoreFlex';
import { CoreIconButton } from '../../core/icon-button/CoreIconButton';
import { grey } from '@material-ui/core/colors';
import { Category } from '../../../interfaces/category';
import { ApiResult } from '../../../interfaces/common';
import { getTranslation, Language } from '../../../config/translation';
import { useStore } from '../../../services/store';
import { stores } from '../../../stores';

interface Props {
    categories: Category[];
    onUpdateCategories: (category: Category[]) => void;
}

export default function CategoryTable({ categories, onUpdateCategories }: Props) {
    const { navigateTo } = useRouter();
    const classes = useStyles();
    const authContext = useContext(AuthContext);
    const [language] = useStore<Language>(stores.language);
    const [showMessage, setShowMessage] = useState<AlertMessage | undefined>();
    const [sortedCategories, setSortedCategories] = useState<Category[]>([]);

    useEffect(() => {
        if (categories) {
            setSortedCategories(orderBy(categories, 'order', 'asc'));
        }
    }, [categories]);

    async function handleToggleVisibility(value: string, category: Category) {
        if (!category.id) {
            return;
        }
        const response: ApiResult = await updateCategory(category.id, { isActive: !category.isActive }, authContext);
        if (response.success) {
            const updatedCategories: Category[] = [];
            categories.forEach((item: Category) => {
                if (item.id === category.id) {
                    item.isActive = !category.isActive;
                }
                updatedCategories.push(item);
            });
            onUpdateCategories(updatedCategories);
        } else {
            setShowMessage({
                type: 'error',
                message: `ERROR: While updating categories, ${response.message}`,
            });
        }
    }

    function handleEditCategory(id: number) {
        navigateTo(getRoute(ROUTES.category.edit, { categoryId: id }));
    }

    async function changeOrder(event: React.MouseEvent<Element>, direction: 'up' | 'down', category: Category) {
        event.stopPropagation();
        const updatedCategories: Category[] = [];
        const newCategories: Category[] = [];
        categories.forEach((_category: Category, index: number) => {
            if (_category.order === undefined) {
                _category.order = categories.length;
                updatedCategories.push(_category);
            }
            if (category.id && _category.id && _category.id === category.id) {
                _category.order =
                    direction === 'down' && category.order > 1
                        ? category.order - 1
                        : direction === 'up' && category.order < categories.length
                        ? category.order + 1
                        : category.order;
                updatedCategories.push(_category);
            }
            newCategories.push(_category);
        });

        setSortedCategories(orderBy(newCategories, 'order', 'asc'));

        try {
            for (let i = 0; i < updatedCategories.length; i++) {
                const cat = updatedCategories[i];
                if (cat.id) {
                    await updateCategory(cat.id, { order: cat.order }, authContext);
                }
            }
        } catch (err) {
            setShowMessage({
                type: 'error',
                message: `ERROR: Updating categories. ${err}`,
            });
        }
    }

    function orderFormatter(order: number, category: Category): JSX.Element {
        return (
            <>
                <CoreFlex direction="row" justify="center" alignItems="center">
                    <CoreFlex direction="column" justify="flex-end">
                        <Typography variant="h3">{order}</Typography>
                    </CoreFlex>
                    <CoreFlex direction="column" justify="flex-end" alignItems="flex-start">
                        <CoreIconButton
                            iconName="arrow_drop_up"
                            onClick={(event) => {
                                changeOrder(event, 'up', category);
                            }}
                        />
                        <CoreIconButton
                            iconName="arrow_drop_down"
                            onClick={(event) => {
                                changeOrder(event, 'down', category);
                            }}
                        />
                    </CoreFlex>
                </CoreFlex>
            </>
        );
    }

    async function handleRemoveCategory(id: number) {
        await removeCategory(id, authContext);
        onUpdateCategories((categories || []).filter((item) => item.id !== id));
    }

    function handleCloneCategory(id: number) {
        navigateTo(getRoute(ROUTES.category.clone, { cloneId: id }));
    }

    console.log('Categories to display', sortedCategories);

    return (
        <CoreTableSimple
            onClickRow={() => {}}
            data={sortedCategories}
            onClone={handleCloneCategory}
            onEdit={handleEditCategory}
            onRemove={handleRemoveCategory}
            objectName="category"
            columns={[
                {
                    label: 'Visible',
                    column: 'isActive' as keyof Category,
                    type: TableColumnType.Switch,
                    action: handleToggleVisibility,
                    invisible: !authContext.isManager,
                },
                {
                    label: 'Order',
                    column: 'order' as keyof Category,
                    type: TableColumnType.Text,
                    formatFunction: orderFormatter,
                },
                {
                    label: 'Photo',
                    column: 'photo' as keyof Category,
                    type: TableColumnType.Image,
                    className: classes.image,
                },
                {
                    label: 'Name',
                    column: 'name' as keyof Category,
                    type: TableColumnType.Translation,
                    className: classes.name,
                    formatFunction: (value, object) => <Typography>{getTranslation(object, language)}</Typography>,
                },
                {
                    label: 'Description',
                    type: TableColumnType.Translation,
                    column: 'description' as keyof Category,
                    formatFunction: (value, object) => (
                        <Typography>{getTranslation(object, language, 'description')}</Typography>
                    ),
                },
            ]}
        />
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        image: {
            maxWidth: '100px',
        },
        name: {
            paddingLeft: '2rem',
            width: '250px',
        },
        order: {
            backgroundColor: grey[300],
        },
    }),
);
