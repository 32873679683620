/**
|--------------------------------------------------
| Token interfaces
|--------------------------------------------------
*/

import { UserType } from './interfaces/user';

export enum InputType {
    email = 'email',
    password = 'password',
    text = 'text',
    phone = 'tel',
    url = 'url',
    number = 'number',
}

/**
|--------------------------------------------------
| User interfaces
|--------------------------------------------------
*/

export interface AlertMessage {
    type: 'info' | 'success' | 'warning' | 'error';
    message: string;
    autoCloseIn?: number;
}

/**
|--------------------------------------------------
| Validation interfaces
|--------------------------------------------------
*/

export enum ValidationType {
    Text = 1,
    Email = 2,
    Number = 3,
    Integer = 4,
    Password = 5,
    Name = 6,
    Phone = 7,
    Url = 8,
    FullName = 9,
    RelativeUrl = 10,
}

export interface ValidationItem {
    fieldName?: string;
    type?: ValidationType;
    value?: string | number;
    minLenght?: number;
    maxLenght?: number;
    condition?: boolean;
    validated?: boolean;
    message?: string;
    optional?: boolean;
}

/**
|--------------------------------------------------
| Form interfaces
|--------------------------------------------------
*/

export interface ImageRequirements {
    minHeight?: number;
    maxHeight?: number;
    minWidth?: number;
    maxWidth?: number;
    minSize?: number;
    maxSize?: number;
    destinationWidth?: number;
    destinationHeight?: number;
    acceptedTypes?: string[];
}

/**
|--------------------------------------------------
| Table interfaces
|--------------------------------------------------
*/

export enum TableColumnType {
    Text,
    Image,
    Element,
    Switch,
    Date,
    Translation,
}

export interface TableColumn {
    label: string;
    column: string;
    invisible?: boolean;
    disabled?: boolean;
    sort?: boolean;
    sortKey?: string;
    type?: TableColumnType;
    action?: (value: string, object: any) => void;
    className?: string;
    formatFunction?: (content: any, object?: any) => JSX.Element;
}

/**
|--------------------------------------------------
| Navigation interfaces
|--------------------------------------------------
*/

export enum NavigationItemType {
    Item,
    Group,
    Colapse,
}

export interface NavigationItem {
    id: string;
    title: string;
    type: NavigationItemType;
    icon: string;
    path: string;
    access: UserType;
}

/**
|--------------------------------------------------
| Database interfaces
|--------------------------------------------------
*/

/**
|--------------------------------------------------
| Restaurant interfaces
|--------------------------------------------------
*/

/**
|--------------------------------------------------
| Product interfaces
|--------------------------------------------------
*/

/**
|--------------------------------------------------
| PopUp menu interfaces
|--------------------------------------------------
*/

export interface PopUpMenuItem {
    title: string;
    icon?: string;
    image?: string;
    value: string;
}
