import { DbBase } from './common';

export enum UserType {
    CUSTOMER = 1,
    COURIER = 2,
    STAFF = 3,
    MANAGER = 4,
    OWNER = 5,
    ADMIN = 6,
    SUPER_ADMIN = 7,
}

export enum FavoriteType {
    PRODUCT = 'PRODUCT',
    RESTAURANT = 'RESTAURANT',
    CATEGORY = 'CATEGORY',
}

export enum PaymentOptionType {
    PAYPAL = 'PAYPAL',
    CARD = 'CARD',
    COIN = 'COIN',
    CASH = 'CASH',
}

export interface User extends DbBase {
    firstName?: string;
    lastName?: string;
    alias?: string;
    email: string;
    photo?: string;
    phone?: string;
    password?: string;
    facebookToken?: string;
    googleToken?: string;
    type: UserType;
    operatorId?: number;
    restaurantId?: number;
    role?: string;
    tokenVersion?: number;
    isVerified?: boolean;
    lastLogin?: Date;
    locations?: UserLocation[];
    favorites?: UserFavorite[];
    paymentOptions?: UserPaymentOption[];
    totalLogins?: number;
    totalOrders?: number;
    lastOrder?: Date;
}

export interface UserLocation extends DbBase {
    userId: number;
    city: string;
    suburb: string;
    street: string;
    houseNumber: string;
    houseName?: string;
    postCode: string;
    latitude: number;
    longitude: number;
    lastUsed?: Date;
}

export interface UserFavorite {
    id?: number;
    userId: number;
    type: FavoriteType;
    favoriteId: number;
    createdAt?: Date;
    updatedAt?: Date;
}

export interface UserLogin {
    id?: number;
    userId: number;
    ip: string;
    city: string;
    country: string;
    createdAt?: Date;
    updatedAt?: Date;
}

export interface UserPaymentOption {
    id?: number;
    userId: number;
    type: PaymentOptionType;
    token: string;
    billingAddress: string;
    paymentEmail: string;
    currency: string;
    createdAt?: Date;
    updatedAt?: Date;
}

export interface UserProfile {
    id: number;
    firstName: string;
    lastName: string;
    photo: string;
    alias: string;
    operatorId: number;
    restaurantId: number;
    type: UserType;
    role: string;
}

export function getUserTypeName(type: UserType) {
    return (
        Object.keys(UserType)
            .filter((key) => UserType[key as keyof typeof UserType] === type)
            .toString() || 'N/A'
    );
}
