import { ApiResult } from './../interfaces/common';
import { AuthContextInterface } from '../context/AuthContext';
import { httpGet, httpPost, httpPut, httpDelete } from '../services/api';
import { Product } from '../interfaces/product';
import { showError, showSuccess } from '../services/alert';
import { getTranslation, Language } from '../config/translation';

export async function getProduct(id: number, authContext?: Partial<AuthContextInterface>): Promise<Product | null> {
    try {
        const response: ApiResult = await httpGet(`product/get/${id}`, {}, authContext);
        if (response.success && response.result) {
            return response.result;
        } else {
            showError("Something's wrong" + response.message);
            return null;
        }
    } catch (err) {
        showError('Error retriveing products: ' + err);
        return null;
    }
}

export async function getProducts(
    restaurantId: number,
    query?: Record<string, any>,
    authContext?: Partial<AuthContextInterface>,
): Promise<Product[]> {
    try {
        const response: ApiResult = await httpGet(`product/find/${restaurantId}`, query, authContext);
        if (response.success && response.result) {
            return response.result;
        } else {
            showError("Something's wrong: " + response.message);
            return [];
        }
    } catch (err) {
        showError('Error retriveing products: ' + err);
        return [];
    }
}

export async function createProduct(
    data: Partial<Product>,
    authContext?: Partial<AuthContextInterface>,
): Promise<Product | null> {
    try {
        const response = await httpPost('product/new', data, authContext);
        if (response.success && response.result) {
            showSuccess(
                `Product ${response.result?.id} ${getTranslation(
                    response.result,
                    Language.ENGLISH,
                )} created successfully`,
            );
            return response.result;
        } else {
            showError("Something's wrong: " + response.message);
            return null;
        }
    } catch (err) {
        showError('Error when creating a product: ' + err);
        return null;
    }
}

export async function updateProduct(
    id: number,
    data: Partial<Product>,
    authContext?: Partial<AuthContextInterface>,
): Promise<Product | null> {
    try {
        const response = await httpPut(`product/update/${id}`, data, authContext);
        if (response.success && response.result) {
            showSuccess(
                `Product ${id} ${data.translations ? getTranslation(data, Language.ENGLISH) : ''} updated successfully`,
            );
            return response.result;
        } else {
            showError("Something's wrong: " + response.message);
            return null;
        }
    } catch (err) {
        showError('Error when updating a product: ' + err);
        return null;
    }
}

export async function removeProduct(id: number, authContext?: Partial<AuthContextInterface>): Promise<any> {
    try {
        const response = await httpDelete(`product/remove/${id}`, {}, authContext);
        if (response.success && response.result) {
            showSuccess(`Product ${id} deleted successfully`);
            return response.result;
        } else {
            showError("Something's wrong: " + response.message);
            return null;
        }
    } catch (err) {
        showError('Error when deleting a product: ' + err);
        return null;
    }
}
