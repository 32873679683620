import React, { useState, Fragment, useContext } from 'react';
import { Grid, Paper, makeStyles, CircularProgress } from '@material-ui/core';
import { ValidationType } from '../../../interfaces';
import { yellow } from '@material-ui/core/colors';
import logo from '../../../assets/logo/munchies_logo.png';
import { validate } from '../../../services/validation';
import { resetPassword, setStateLoggedIn } from '../../../microservices/auth';
import { useDeviceType } from '../../../services/utils';
import { AuthContext } from '../../../context/AuthContext';
import { ResetPasswordType } from '../../../interfaces/auth';
import { showError, showSuccess } from '../../../services/alert';
import { useRouter } from '../../../services/router';
import { LoginResetPassword } from '../../../components/login/ResetPassword/ResetPassword';

export function AuthResetPasswordPage() {
    const {
        queryParams: { token },
        navigateTo,
    } = useRouter();

    const authContext = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [resetPasswordData, setResetPasswordData] = useState<ResetPasswordType>({
        password: '',
        passwordRepeat: '',
    });

    const validators = {
        password: () => {
            return validate({
                type: ValidationType.Password,
                value: resetPasswordData.password,
            });
        },
        passwordRepeat: () => {
            return {
                validated: resetPasswordData.password === resetPasswordData.passwordRepeat,
                message: 'Passwords do not match, please check and try again.',
            };
        },
    };

    const { isMobile } = useDeviceType();
    const classes = useStyles();

    function handleValueChange(name: keyof ResetPasswordType, value: string | number | boolean | any[] | undefined) {
        setResetPasswordData({ ...resetPasswordData, [name]: value });
    }

    async function handleResetPassword() {
        if (validators.password().validated && validators.passwordRepeat().validated) {
            setIsLoading(true);
            const response = await resetPassword({
                password: resetPasswordData.password,
                token: token as string,
            });
            setIsLoading(false);
            if (!response) {
                showError('Network problem!');

                return;
            }
            if (response && !response.success) {
                showSuccess(response.message + '\n' + response.result);
            }
            if (response && response.success) {
                setStateLoggedIn(response, authContext);
                showSuccess('Password has been changed!');

                navigateTo('/auth/login');
            }
        }
    }

    return (
        <>
            <Grid
                container
                alignItems="center"
                justify="center"
                alignContent="center"
                direction="column"
                className={classes.gridContainer}
            >
                <Grid item className={classes.padding}>
                    {!isMobile && <img src={logo} className={classes.logo} alt="Munchies logo" />}
                </Grid>
                <Grid item>
                    <Paper className={isMobile ? classes.paperMobile : classes.paper} elevation={3}>
                        {isLoading ? (
                            <Grid item>
                                <CircularProgress />
                            </Grid>
                        ) : (
                            <LoginResetPassword
                                classes={classes}
                                onValueChange={handleValueChange}
                                handler={handleResetPassword}
                                values={resetPasswordData}
                                validation={validators}
                            />
                        )}
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}

const useStyles = makeStyles({
    gridContainer: {
        backgroundColor: yellow[600],
        top: '0',
        left: '0',
        bottom: '0',
        right: '0',
        width: '100%',
        height: '100vh',
    },

    paperMobile: {
        position: 'absolute',
        top: '0',
        left: '0',
        bottom: '0',
        right: '0',
        width: '100vw',
        height: '100vh',
        overflowY: 'scroll',
    },

    paper: {
        minWidth: '400px',
        maxWidth: '400px',
        textAlign: 'center',
        transitionDuration: '2s',
        WebkitTransition: '2s',
        transition: 'height 4s',
    },

    logo: {
        margin: '30px',
        width: '200px',
    },

    logoMobile: {
        margin: '10px',
        width: '120px',
    },
    formContainerMobile: {
        padding: '1rem',
        height: '100%',
        display: 'flex',
        width: '100%',
    },

    formContainer: {
        padding: '2.5rem',
        height: '100%',
        display: 'flex',
        width: '100%',
    },
    gridItem: {
        width: '100%',
    },
    padding: {
        padding: '1rem',
    },
});
