import React, { useState, useEffect, useContext } from 'react';
import { Typography, Theme } from '@material-ui/core';
import CoreTableSimple from '../../components/core/table/simple/CoreTableSimple';
import { TableColumnType, AlertMessage } from '../../interfaces';
import { updateUser, getUsers } from '../../microservices/user';
import { AuthContext } from '../../context/AuthContext';
import { useRouter, getRoute } from '../../services/router';
import { ROUTES } from '../../routes';
import { makeStyles, createStyles } from '@material-ui/styles';
import moment from 'moment';
import CoreChipUserType from '../../components/core/chip/user-type/CoreChipUserType';
import { useStore } from '../../services/store';
import { stores } from '../../stores';
import { CoreSnackbar } from '../../components/core/snackbar/CoreSnackbar';
import { isArray } from 'lodash';
import { User } from '../../interfaces/user';
import { Restaurant } from '../../interfaces/restaurant';
import { getOperators } from '../../microservices/operator';
import { Operator } from '../../interfaces/operator';
import { getTranslation } from '../../config/translation';

export default function UsersPage() {
    const [users, setUsers] = useState<User[]>([]);
    const [showMessage, setShowMessage] = useState<AlertMessage | undefined>();
    const classes = useStyles();
    const authContext = useContext(AuthContext);
    const [restaurants] = useStore(stores.restaurants);
    const [language] = useStore(stores.language);
    const [operators, setOperators] = useState<Operator[]>([]);
    const { navigateTo } = useRouter();

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function loadData() {
        const result: User[] | User = await getUsers({}, authContext);
        if (!result) {
            return;
        }
        if (!isArray(result) && result.id) {
            handleEditUser(result.id);
            return;
        } else if (isArray(result) && result.length) {
            setUsers(result);
        }
        if (authContext.isAdmin) {
            setOperators(await getOperators(authContext));
        }
    }

    function handleEditUser(id: number) {
        navigateTo(getRoute(ROUTES.user.edit, { userId: id }));
    }

    async function handleToggle(name: keyof User, user: User) {
        if (!user.id) {
            return;
        }
        const response = await updateUser(user.id, { [name]: !user[name] }, authContext);
        if (response.success) {
            const updatedUsers: User[] = [];
            users.forEach((item: User) => {
                if (item.id === user.id) {
                    item[name as 'isVerified' | 'isActive'] = !user[name];
                }
                updatedUsers.push(item);
            });
            setUsers(updatedUsers);
        } else {
            setShowMessage({
                type: 'error',
                message: `ERROR: ${response.message}`,
            });
        }
    }

    function dateFormatter(value: any): JSX.Element {
        const date = value ? moment(value).format('DD.MMM.YYYY hh:mm') : '';
        return <>{date}</>;
    }

    function userTypeFormatter(value: any): JSX.Element {
        return (
            <>
                <CoreChipUserType userType={value} />
            </>
        );
    }
    function formatOperator(operatorId: number): JSX.Element {
        const operator = operators.find((item) => item.id === operatorId);
        if (!operator) {
            return <></>;
        }
        return <div className={classes.operator}>{operator.name}</div>;
    }

    function formatRestaurant(id: number): JSX.Element {
        console.log('Restaurants', restaurants, id);
        const restaurant = restaurants.find((item: Restaurant) => {
            return item.id === id;
        });
        const name = getTranslation(restaurant, language);
        return <>{name ? `Munchies ${name}` : '-'}</>;
    }

    return (
        <>
            <Typography variant="h5" className={classes.title}>
                User management
            </Typography>
            <CoreTableSimple
                data={users}
                onClickRow={handleEditUser}
                onEdit={handleEditUser}
                objectName="user"
                onRemove={() => {}}
                columns={[
                    {
                        label: 'Active',
                        column: 'isActive',
                        type: TableColumnType.Switch,
                        className: classes.switch,
                        action: (value: string, user: User) => {
                            handleToggle('isActive', user);
                        },
                    },
                    {
                        label: 'Photo',
                        column: 'photo',
                        type: TableColumnType.Image,
                        className: classes.image,
                    },
                    {
                        label: 'First name',
                        column: 'firstName',
                        type: TableColumnType.Text,
                        className: classes.name,
                    },
                    {
                        label: 'Last name',
                        column: 'lastName',
                        type: TableColumnType.Text,
                        className: classes.name,
                    },
                    {
                        label: 'Email',
                        column: 'email',
                    },
                    {
                        label: 'Type',
                        column: 'type',
                        formatFunction: userTypeFormatter,
                    },
                    {
                        label: 'Restaurant',
                        column: 'restaurantId',
                        formatFunction: formatRestaurant,
                    },
                    {
                        label: 'Operator',
                        column: 'operatorId',
                        invisible: !authContext.isAdmin,
                        formatFunction: formatOperator,
                    },
                    {
                        label: 'Verified',
                        column: 'isVerified',
                        type: TableColumnType.Switch,
                        className: classes.switch,
                        action: (value: string, user: User) => {
                            handleToggle('isVerified', user);
                        },
                    },
                    {
                        label: 'Last login',
                        column: 'lastLogin',
                        formatFunction: dateFormatter,
                    },
                ]}
            />
            {showMessage && (
                <CoreSnackbar
                    variant={showMessage.type}
                    message={showMessage.message}
                    onClose={() => setShowMessage(undefined)}
                />
            )}
        </>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        image: {
            width: '100px',
            margin: '0rem 1rem',
        },
        name: {
            minWidth: '100px',
        },
        address: {
            minWidth: '300px',
        },
        switch: {
            width: '50px',
        },
        title: {
            marginBottom: '1rem',
        },
        operator: {
            // borderColor: theme.palette.common.black,
            borderWidth: '1px',
            borderRadius: '1rem',
            // borderStyle: 'solid',
            paddding: '0.5rem',
            marginRight: '3rem',
        },
    }),
);
