import React, { useState, useEffect } from 'react';
import CoreFlex from '../../../core/flex/CoreFlex';
import { Typography, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { useStore } from '../../../../services/store';
import { stores } from '../../../../stores';
import CoreButton from '../../../core/button/CoreButton';
import ProductsFormChoicesRow from './row/ProductChoiceEditorRow';
import CoreFormSelect from '../../../core/form/select/CoreFormSelect';
import { Country } from '../../../../interfaces/country';
import { Restaurant } from '../../../../interfaces/restaurant';
import {
    getDefaultProductChoice,
    Product,
    ProductChoice,
    ProductChoiceTranslation,
} from '../../../../interfaces/product';
import { getTranslation, getTranslatedObjectUpdate, Language } from '../../../../config/translation';
import { ProductChoiceType } from '../../../../interfaces/product';
import { cloneDeep } from 'lodash';

interface Props {
    restaurant: Restaurant;
    product: Product;
    products: Product[];
    onUpdate: (choices: ProductChoice[]) => void;
}

export default function ProductChoiceEditor({ product, restaurant, onUpdate, products }: Props) {
    const classes = useStyles();
    const [countries] = useStore(stores.countries);
    const [country, setCountry] = useState<Country>();
    const [language] = useStore(stores.language);

    useEffect(() => {
        if (restaurant.countryId) {
            setCountry(countries.find((country: Country) => country.id === restaurant.countryId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restaurant.countryId]);

    async function handleChangeChoice(
        name: keyof ProductChoice | keyof ProductChoiceTranslation,
        value: string | boolean | number | string[],
        index: number,
    ) {
        if (!product.choices?.length || product.choices.length <= index) {
            return;
        }
        const newChoices = cloneDeep(product.choices);

        const choice: any = newChoices[index];
        if (['name', 'options'].includes(name) && language && choice) {
            newChoices[index] = getTranslatedObjectUpdate<any>(choice, name, value as any, language);
            onUpdate(newChoices);
            return;
        }

        choice[name] = value;
        onUpdate(newChoices);
    }

    function handleAddChoice() {
        onUpdate((product.choices || []).concat([getDefaultProductChoice()]));
    }

    function handleDeleteChoice(index: number) {
        onUpdate((product.choices || []).filter((v, i) => index !== i));
    }

    function handleCloneExtras(productId: number) {
        const clonedProduct = products.find((product: Product) => product.id === productId);
        if (!clonedProduct) {
            return;
        }

        const clonedChoices = clonedProduct.choices;
        if (!clonedChoices || !clonedChoices.length) {
            return;
        }
        onUpdate((product.choices || []).concat(clonedChoices));
    }

    return (
        <>
            <CoreFlex justify="space-between" className={classes.cardHeader}>
                <Typography variant="h6">Optional choices</Typography>
                <CoreFormSelect
                    className={classes.cloneSelect}
                    value={''}
                    onValueChange={(value) => handleCloneExtras(value as number)}
                    label="Clone from"
                    options={products
                        .filter((item: Product) => item?.choices?.length)
                        .map((item: Product) => {
                            return {
                                value: item.id,
                                label: getTranslation(item, language) || '',
                            };
                        })}
                />
                <CoreButton label="ADD CHOICE" onClick={handleAddChoice} />
            </CoreFlex>
            <CoreFlex direction="column">
                {product &&
                    country &&
                    product.choices &&
                    product.choices.map((choice: ProductChoice, index: number) => {
                        return (
                            <ProductsFormChoicesRow
                                key={index}
                                index={index}
                                choice={choice}
                                products={products}
                                onDeleteChoice={handleDeleteChoice}
                                onChangeChoice={handleChangeChoice}
                            />
                        );
                    })}
            </CoreFlex>
        </>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardHeader: {
            padding: '1rem 0.5rem',
        },
        cloneSelect: {
            maxWidth: '300px',
            margin: '0rem 1rem',
        },
    }),
);
