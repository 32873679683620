import { stores } from '../stores';
import { AlertMessage } from './../interfaces';

export function showAlertMessage(alert: AlertMessage | null): void {
    stores.showMessage.set(alert);
}

export function showError(message: string) {
    showAlertMessage({ type: 'error', message, autoCloseIn: 5000 });
}

export function showWarning(message: string) {
    showAlertMessage({ type: 'warning', message, autoCloseIn: 5000 });
}

export function showSuccess(message: string) {
    showAlertMessage({ type: 'success', message, autoCloseIn: 5000 });
}
