import { createMuiTheme, Modal } from '@material-ui/core';
import { red } from '@material-ui/core/colors';

export enum COLOR {
    MunchiesOrange = '#E77815',
    MunchiesGreen = '#39B54A',
    MunchiesYellow = 'rgba(254, 226, 16, 1)',
    MunchiesBlue = '#00B2FF',
    LightOrange = '#FFCC00',
    DarkOrange = '#C35C00',
    ShadingSuprLight = '#FFF0BC',
    ShadingMedium = '#FFB700',
    LightYellow = '#FEF6DC',
    MediumYellow = '#FFF0BC',
    Purple = '#7140B7',
}

export default function createTheme() {
    return createMuiTheme({
        palette: {
            primary: {
                main: '#F47427',
                contrastText: '#FFFFFF',
            },
            secondary: {
                main: '#39B54A',
                contrastText: '#FFFFFF',
            },
            error: red,
            contrastThreshold: 3,
            tonalOffset: 0.2,
            background: {
                paper: '#FFFFFF',
                default: 'rgba(254, 226, 16, 1)',
            },
        },
        zIndex: {
            modal: 10000000,
        },
    });
}
