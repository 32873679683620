import React from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { grey, green, red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';
import { LoginResetPasswordProps } from './ResetPassword.type';
import { InputType } from '../../../interfaces';
import { useDeviceType } from '../../../services/utils';
import CoreFormInput from '../../core/form/input/CoreFormInput';

export function LoginResetPassword(props: LoginResetPasswordProps) {
    const { values, classes, handler, onValueChange, validation } = props;

    const { isMobile } = useDeviceType();
    const internalnewClasses = useStyles();

    const newClasses: any = { ...classes, ...internalnewClasses };

    return (
        <form>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                alignContent="center"
                spacing={2}
                className={isMobile ? newClasses.formContainerMobile : newClasses.formContainer}
            >
                <Grid item className={newClasses.gridItem}>
                    <Typography variant="h5" align="center">
                        Reset Password
                    </Typography>
                </Grid>

                <Grid item className={newClasses.gridItem}>
                    <CoreFormInput
                        value={values.password}
                        type={InputType.password}
                        onValueChange={(value) => onValueChange('password', value)}
                        label="Password"
                        placeholder="Enter your password"
                        name="password"
                        id="password"
                        validation={validation.password}
                        helperText="Password must include uppercase and lowercase letters numbers and symbols and be at least 8 characters long."
                    />
                </Grid>
                <Grid item className={newClasses.gridItem}>
                    <CoreFormInput
                        value={values.passwordRepeat || ''}
                        type={InputType.password}
                        onValueChange={(value) => onValueChange('passwordRepeat', value)}
                        placeholder="Please repeat the password"
                        validation={validation.passwordRepeat}
                        label="Repeat password"
                        name="repeatPassword"
                        id="repeatPassword"
                    />
                </Grid>
                <Grid item className={newClasses.gridItem}>
                    <Button variant="contained" size="large" color="primary" fullWidth onClick={handler}>
                        Reset password
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}

const useStyles = makeStyles({
    select: {
        '&:before': {
            borderColor: grey[500],
        },
        '&:after': {
            borderColor: grey[500],
        },
    },
    selectSuccess: {
        '&:before': {
            borderColor: green[500],
        },
        '&:after': {
            borderColor: green[500],
        },
    },
    main: {
        borderColor: grey[500],
    },
    error: {
        borderWidth: '2px',
        borderColor: red[500],
    },
    validated: {
        borderWidth: '2px',
        borderColor: green[500],
    },
    helperText: {
        textAlign: 'center',
    },
    capitalize: {
        textTransform: 'capitalize',
    },
});
