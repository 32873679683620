import { useState, useContext } from 'react';
import { RouteProps } from 'react-router';
import kebabCase from 'lodash/kebabCase';
import { ROUTES } from '../routes';
import { parse } from 'query-string';
import * as reactRouterDom from 'react-router-dom';

export function useRouter() {
    const router = useContext<any>((reactRouterDom as any).__RouterContext);

    return {
        location: router.location,
        history: router.history,
        navigateTo: router.history.push,
        params: (router.match && router.match.params) || {},
        queryParams: parse(router.history.location.search),
        goBack: router.history.goBack,
        pathname: router.location.pathname,
    };
}

export function getRoute(route: Record<string, any> | string, params: any = {}): string {
    return Object.keys(params).reduce(
        (route, key) => route.replace(`:${key}`, params[key]),
        (route as { '@route': string })['@route'] || (route as string),
    );
}

export function useRoutes(allRoutes: RouteProps[]) {
    const [routes] = useState(allRoutes.filter(Boolean));
    return routes;
}

function generateRoutes(routes: any, routeBase: string, key: string) {
    routeBase = `${routeBase}/${kebabCase(key)}`;
    Object.keys(routes).forEach((key) => {
        if (!key.startsWith('@')) {
            generateRoutes(routes[key], routeBase, key);
        }
    });

    routes['@route'] = routes['@params']
        ? Object.keys(routes['@params']).reduce(
              (route, paramKey) => route.concat(`:${routes['@params'][paramKey]}`),
              routeBase.concat('/'),
          )
        : routeBase;
}

export function initRoutes() {
    Object.keys(ROUTES).forEach((key) => {
        generateRoutes(ROUTES[key as keyof typeof ROUTES], '', key);
    });
}

initRoutes();
