import React, { MouseEvent } from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles({
    main: (props: Props) => ({
        display: 'flex',
        alignItems: props.alignItems,
        flexWrap: props.wrap,
        alignContent: props.alignLines,
        flexFlow: props.direction,
        justifyContent: props.justify,
        width: props.fullWidth ? '100%' : 'initial',
    }),
});

interface Props {
    onClick?: (event: MouseEvent<HTMLDivElement>) => void;
    children: any;
    direction?: 'row' | 'column' | 'initial' | 'inherit';
    alignItems?: 'stretch' | 'center' | 'flex-start' | 'flex-end' | 'baseline' | 'initial' | 'inherit';
    wrap?: 'nowrap' | 'wrap' | 'wrap-reverse' | 'initial' | 'inherit';
    justify?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'initial' | 'inherit';
    alignLines?:
        | 'stretch'
        | 'center'
        | 'flex-start'
        | 'flex-end'
        | 'space-between'
        | 'space-around'
        | 'initial'
        | 'inherit';
    fullWidth?: boolean;
    className?: string;
}

export default function CoreFlex({
    children,
    onClick,
    direction,
    alignItems = 'center',
    wrap,
    justify = 'center',
    alignLines = 'center',
    fullWidth = true,
    className,
    ...rest
}: Props) {
    const classes = useStyles({
        children,
        direction,
        alignItems,
        wrap,
        justify,
        alignLines,
        fullWidth,
        className,
        ...rest,
    });
    return (
        <div onClick={onClick} className={clsx(classes.main, className)}>
            {children}
        </div>
    );
}
