import jwtDecode from 'jwt-decode';
import { TokenPayload } from '../interfaces/auth';

const AUTH_TOKEN_KEY = 'auth-token';

export function getAuthToken() {
    return localStorage.getItem(AUTH_TOKEN_KEY);
}

export function setAuthToken(token: string) {
    localStorage.setItem(AUTH_TOKEN_KEY, token);
}

export function decodeToken(token: string): TokenPayload {
    const payload: TokenPayload = jwtDecode(token);
    return payload;
}

export function isTokenExpired(token: string): boolean {
    const exp = decodeToken(token).exp;
    return Date.now() / 1000 > exp;
}
