import React, { useState } from 'react';
import { Paper, Typography, Theme } from '@material-ui/core';
import CoreFlex from '../flex/CoreFlex';
import CoreButton from '../button/CoreButton';
import { grey, red } from '@material-ui/core/colors';
import { makeStyles, createStyles } from '@material-ui/styles';
import moment from 'moment';
import { CoreFlagElement } from '../flag-element/CoreFlagElement';
import { Language, getTranslation } from '../../../config/translation';
import clsx from 'clsx';
import { CheckCircle, Error } from '@material-ui/icons';
import { COLOR } from '../../../services/createTheme';
import CoreSwitch from '../switch/CoreSwitch';
import { CoreConfirmation } from '../confirmation/CoreConfirmation';
import { useStore } from '../../../services/store';
import { stores } from '../../../stores';

interface Props {
    allowDelete?: boolean;
    switchKey?: string;
    switchDisabled?: boolean;
    objectName: string;
    object: any;
    onCancel: () => void;
    onSave: () => void;
    onDelete: () => void;
    onToggleVisibility: () => void;
    languages?: Language[];
    translationObject?: Partial<Record<Language, any>>;
    translationKeys?: string[];
    selectedLanguage?: Language;
    isClone?: boolean;
    onChangeLanguage?: (language: Language) => void;
}

export default function CoreCrudToolbar({
    allowDelete = false,
    switchKey = 'isActive',
    objectName,
    switchDisabled = false,
    object,
    onCancel,
    onSave,
    onDelete,
    onToggleVisibility,
    languages,
    selectedLanguage,
    onChangeLanguage,
    translationObject,
    translationKeys,
    isClone,
}: Props) {
    const classes = useStyles();
    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
    const translatedName = selectedLanguage && object?.translations ? getTranslation(object, selectedLanguage) : '';
    const [language] = useStore<Language>(stores.language);

    function handleLanguageChange(language: Language) {
        if (onChangeLanguage) {
            onChangeLanguage(language);
        }
    }

    return (
        <>
            {onDelete && isDeleteConfirmationOpen && (
                <CoreConfirmation
                    variant="error"
                    onClose={() => setIsDeleteConfirmationOpen(false)}
                    onCancel={() => setIsDeleteConfirmationOpen(false)}
                    onConfirm={() => {
                        onDelete();
                        setIsDeleteConfirmationOpen(false);
                    }}
                    title={`WARNING: You are about to delete a ${objectName}`}
                    open={isDeleteConfirmationOpen}
                    message={`Are you sure you want to delete the ${
                        object?.translations ? getTranslation(object, language) : object?.name
                    }? This action is permanent and unreversable.`}
                />
            )}
            <Paper className={classes.toolbar}>
                <CoreFlex justify="flex-start" direction="column">
                    <CoreFlex justify="flex-start" fullWidth className={classes.formField}>
                        <CoreFlex justify="flex-start">
                            <Typography variant="h6">
                                {object.id
                                    ? `Edit ${objectName} ${translatedName}`
                                    : isClone
                                    ? `Clone ${objectName} ${translatedName}`
                                    : `Add new ${objectName} ${translatedName}`}
                            </Typography>
                        </CoreFlex>
                        <CoreFlex justify="flex-end">
                            <Typography>
                                {(switchKey && object[switchKey]) || object.isActive
                                    ? `${objectName} is visible`
                                    : `${objectName} is not visible`}
                            </Typography>
                            {!switchDisabled && (
                                <CoreSwitch
                                    value={switchKey && object[switchKey] ? object[switchKey] : object.isActive}
                                    onValueChange={onToggleVisibility}
                                    color="secondary"
                                />
                            )}
                        </CoreFlex>
                    </CoreFlex>
                    <CoreFlex justify="flex-start">
                        {languages?.length && (
                            <CoreFlex justify="flex-start" alignItems="flex-end" className={classes.formField}>
                                {languages.map((language, index) => {
                                    const isTranslated = Boolean(translationObject?.[language]);
                                    const isCorrectlyTranslated = !translationKeys?.filter(
                                        (item) => !translationObject?.[language]?.[item],
                                    ).length;
                                    return (
                                        <CoreFlex
                                            direction="column"
                                            key={index}
                                            alignItems="flex-end"
                                            justify="flex-start"
                                            className={clsx(
                                                classes.flagContainer,
                                                language === selectedLanguage && classes.flagContainerSelected,
                                            )}
                                        >
                                            {isTranslated && isCorrectlyTranslated && (
                                                <CheckCircle className={classes.check} />
                                            )}
                                            {isTranslated && !isCorrectlyTranslated && (
                                                <Error className={classes.error} />
                                            )}
                                            {!isTranslated && <CoreFlex className={classes.check}>{``}</CoreFlex>}
                                            <CoreFlagElement
                                                language={language}
                                                onClick={(value) => handleLanguageChange(value as Language)}
                                                className={clsx(
                                                    classes.flag,
                                                    isTranslated ? classes.flagSelected : classes.flagUnselected,
                                                )}
                                            />
                                        </CoreFlex>
                                    );
                                })}
                            </CoreFlex>
                        )}
                        <CoreFlex direction="column" alignItems="flex-end">
                            <CoreFlex
                                justify="flex-end"
                                alignItems="flex-end"
                                className={classes.formField}
                                direction="column"
                            >
                                <Typography variant="caption">
                                    Created at: {moment(object.createdAt).format('DD MMM YYYY HH:mm')}
                                </Typography>
                                <Typography variant="caption">
                                    Last updated at: {moment(object.updatedAt).format('DD MMM YYYY HH:mm')}
                                </Typography>
                            </CoreFlex>
                            <CoreFlex justify="flex-end" className={classes.formField}>
                                <CoreButton
                                    label="Back"
                                    onClick={onCancel}
                                    customColor={grey[600]}
                                    className={classes.button}
                                />
                                {allowDelete && (
                                    <CoreButton
                                        label="Delete"
                                        onClick={() => setIsDeleteConfirmationOpen(true)}
                                        customColor={red[500]}
                                        className={classes.button}
                                    />
                                )}
                                <CoreButton
                                    label={object.id ? 'Update' : 'Save'}
                                    onClick={onSave}
                                    className={classes.button}
                                />
                            </CoreFlex>
                        </CoreFlex>
                    </CoreFlex>
                </CoreFlex>
            </Paper>
        </>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolbar: {
            backgroundColor: grey[300],
            margin: '2rem',
            padding: '2rem',
        },
        formField: {
            padding: '0.5rem 1rem',
            minWidth: '300px',
            width: '100%',
        },
        flagSelected: {
            opacity: 1,
        },
        flagUnselected: {
            opacity: 0.5,
        },
        flag: {
            height: '25px',
            margin: '0.5rem',
        },
        check: {
            height: '20px',
            color: COLOR.MunchiesGreen,
        },
        error: {
            height: '20px',
            color: red[700],
        },
        flagContainer: {
            borderRadius: '1rem',
            borderWidth: '2px',
            padding: '0.25rem',
            width: '60px',
        },
        flagContainerSelected: {
            borderColor: grey[700],
            backgroundColor: grey[200],
        },
        button: {
            marginLeft: '1rem',
        },
    }),
);
