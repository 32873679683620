import React, { useState, ChangeEvent, KeyboardEvent, useEffect } from 'react';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { red, green, grey } from '@material-ui/core/colors';
import { TextField, InputAdornment, IconButton, makeStyles } from '@material-ui/core';
import { InputType, ValidationItem, ValidationType } from '../../../../interfaces';
import clsx from 'clsx';
import CoreFlex from '../../flex/CoreFlex';
import { toTitleCase } from '../../../../services/tools';

export interface CoreFormInputProps {
    onSubmit?: () => void;
    autoFocus?: boolean;
    value: string;
    onValueChange: (value: string, event?: any) => void;
    label?: string;
    placeholder?: string;
    name?: string;
    id?: string;
    helperText?: string;
    type?: InputType;
    money?: boolean;
    endAdornment?: JSX.Element;
    startAdornment?: JSX.Element;
    required?: boolean;
    validation?: (value: string) => ValidationItem;
    autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters';
    onBlur?: () => void;
    multiline?: boolean;
    rows?: number;
    className?: string;
    disabled?: boolean;
    autoComplete?: string;
}

export default function CoreFormInput({
    onSubmit,
    autoFocus = false,
    disabled,
    className,
    value,
    onValueChange,
    label,
    placeholder,
    name,
    id,
    helperText,
    validation,
    required = false,
    type = InputType.text,
    autoCapitalize,
    multiline,
    rows,
    onBlur,
    endAdornment,
    autoComplete,
    startAdornment,
    ...rest
}: CoreFormInputProps) {
    const classes = useStyles();

    const [showPassword, setshowPassword] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [validationResult, setValidationResult] = useState<ValidationItem | null>(null);
    const isPassword = type === InputType.password;

    useEffect(() => {
        doValidation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    function toggleShowPassword() {
        setshowPassword(!showPassword);
    }

    const handleMouseDownPassword = (event: any) => {
        event.preventDefault();
    };

    function handleChangeEvent(event: React.ChangeEvent<any>) {
        if (autoCapitalize) {
            event.target.value = toTitleCase(event.target.value);
        }
        onValueChange(event.target.value, event);
    }

    function showHelperText() {
        setIsFocused(true);
    }

    function hideHelperText() {
        setIsFocused(false);
    }

    let textFieldClass;

    if (validationResult && validationResult.validated) {
        textFieldClass = classes.validated;
    } else if (validationResult && !validationResult.validated) {
        textFieldClass = classes.error;
    } else {
        textFieldClass = classes.main;
    }

    const inputProps: Record<string, any> = {};
    inputProps['spellCheck'] = false;
    if (isPassword) {
        inputProps['endAdornment'] = (
            <InputAdornment position="end">
                <IconButton
                    edge="end"
                    aria-label="toggle password visibility"
                    onClick={toggleShowPassword}
                    onMouseDown={handleMouseDownPassword}
                >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
            </InputAdornment>
        );
    }

    if (endAdornment) {
        inputProps['endAdornment'] = <InputAdornment position="end">{endAdornment}</InputAdornment>;
    }

    if (startAdornment) {
        inputProps['startAdornment'] = <InputAdornment position="start">{startAdornment}</InputAdornment>;
    }

    if (validationResult && validationResult?.validated) {
        inputProps['classes'] = {
            notchedOutline: classes.validated,
            focused: classes.main,
        };
    } else if (validationResult && !validationResult?.validated) {
        inputProps['classes'] = {
            notchedOutline: classes.error,
        };
    } else {
        inputProps['classes'] = {
            root: classes.main,
            focused: classes.main,
        };
    }

    function doValidation() {
        if (validation && value !== '') {
            const result = validation(value);
            if (validation(value).type === ValidationType.Email) {
                console.log('Validation result', value, result);
            }
            setValidationResult(result);
        } else {
            setValidationResult(null);
            textFieldClass = classes.main;
        }
    }

    function handleFinishedEntry() {
        hideHelperText();
        doValidation();
        if (!validationResult?.validated) {
            showHelperText();
        }
    }

    function handleStartEntry() {
        showHelperText();
    }

    function handleKeyDown(event: KeyboardEvent<HTMLDivElement>) {
        if (event.keyCode === 13 && onSubmit) {
            onSubmit();
        }
    }

    return (
        <CoreFlex className={clsx(className, validation && classes.validationRoot)} alignItems="flex-start">
            <TextField
                autoComplete={autoComplete}
                onKeyDown={handleKeyDown}
                onClick={(event) => event.stopPropagation()}
                disabled={disabled}
                multiline={multiline}
                rows={rows}
                className={textFieldClass}
                autoFocus={autoFocus}
                fullWidth
                id={id}
                value={value}
                label={label}
                name={name}
                error={Boolean(validation && validationResult && !validationResult?.validated)}
                helperText={
                    validationResult && !validationResult?.validated
                        ? validationResult?.message
                        : isFocused && helperText
                }
                onFocus={handleStartEntry}
                onBlur={handleFinishedEntry}
                placeholder={placeholder}
                required={required}
                onChange={handleChangeEvent}
                type={!isPassword ? type : showPassword ? InputType.text : InputType.password}
                variant="outlined"
                FormHelperTextProps={{
                    error: Boolean(validationResult && !validationResult?.validated),
                    classes: { root: classes.helperText },
                }}
                InputProps={inputProps}
                {...rest}
            />
        </CoreFlex>
    );
}

const useStyles = makeStyles({
    validationRoot: {
        height: '94px',
    },
    main: {
        borderWidth: '2px',
        borderColor: grey[500],
        '&:focus': {
            borderColor: 'inherit',
            backgroundColor: grey[600],
        },
    },
    error: {
        borderWidth: '2px',
        borderColor: red[500],
        '&:focus': {
            borderColor: 'inherit',
            backgroundColor: grey[600],
        },
    },
    validated: {
        borderWidth: '2px',
        borderColor: green[500],
        '&:focus': {
            borderColor: 'inherit',
            backgroundColor: grey[600],
        },
    },
    helperText: {
        textAlign: 'center',
    },
    capitalize: {
        textTransform: 'capitalize',
    },
});
