import { NavigationItem, NavigationItemType } from './../interfaces';
import { getRoute } from '../services/router';
import { ROUTES } from '../routes';
import { UserType } from '../interfaces/user';

export const navigationConfig: NavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: NavigationItemType.Item,
        path: getRoute(ROUTES.dashboard),
        icon: 'bar_chart',
        access: UserType.COURIER,
    },
    {
        id: 'users',
        title: 'Users',
        type: NavigationItemType.Item,
        path: getRoute(ROUTES.user),
        icon: 'account_box',
        access: UserType.MANAGER,
    },
    {
        id: 'profile',
        title: 'Profile',
        type: NavigationItemType.Item,
        path: getRoute(ROUTES.user),
        icon: 'account_box',
        access: UserType.COURIER,
    },
    {
        id: 'countries',
        title: 'Countries',
        type: NavigationItemType.Item,
        path: getRoute(ROUTES.country),
        icon: 'explore',
        access: UserType.ADMIN,
    },
    {
        id: 'operators',
        title: 'Operators',
        type: NavigationItemType.Item,
        path: getRoute(ROUTES.operator),
        icon: 'location_city',
        access: UserType.ADMIN,
    },
    {
        id: 'restaurants',
        title: 'Restaurants',
        type: NavigationItemType.Item,
        path: getRoute(ROUTES.restaurant),
        icon: 'storefront',
        access: UserType.OWNER,
    },
    {
        id: 'products',
        title: 'Products',
        type: NavigationItemType.Item,
        path: getRoute(ROUTES.product),
        icon: 'fastfood',
        access: UserType.COURIER,
    },
    {
        id: 'orders',
        title: 'Orders',
        type: NavigationItemType.Item,
        path: getRoute(ROUTES.order),
        icon: 'monetization_on',
        access: UserType.COURIER,
    },
    {
        id: 'customers',
        title: 'Customers',
        type: NavigationItemType.Item,
        path: getRoute(ROUTES.customer),
        icon: 'people',
        access: UserType.MANAGER,
    },
    {
        id: 'reviews',
        title: 'Reviews',
        type: NavigationItemType.Item,
        path: getRoute(ROUTES.review),
        icon: 'grade',
        access: UserType.COURIER,
    },
];
