import React from 'react';
import { Tooltip } from '@material-ui/core';
import { MouseEvent, useEffect, useState } from 'react';
import { getLanguageName, Language, TranslationFlag } from '../../../config/translation';
import { Country } from '../../../interfaces/country';
import { stores, useStore } from '../../../stores';
import { toTitleCase } from '../../../services/tools';

interface Props {
    className?: string;
    language?: Language;
    countryId?: number;
    onClick?: (value: Language | number) => void;
}

export function CoreFlagElement({ className, language, onClick, countryId }: Props) {
    const [flagUrl, setFlagUrl] = useState<string | null>(null);
    const [countries] = useStore<Country[]>(stores.countries);
    const [selectedLanguage] = useStore<Language | null>(stores.language);
    const [title, setTitle] = useState<string>('');

    useEffect(() => {
        if (language) {
            setFlagUrl(TranslationFlag[language]);
            setTitle(toTitleCase(getLanguageName(language)));
        }
    }, [language]);

    useEffect(() => {
        if (countryId) {
            const country = countries.find((country) => country.id === countryId);
            if (country && country.flag && selectedLanguage) {
                setFlagUrl(country?.flag);
                setTitle(country.translations?.[selectedLanguage]?.name || '');
            }
        }
    }, [countryId]);

    if ((!language && !countryId) || !flagUrl) {
        return null;
    }

    function flagClicked(event: MouseEvent<Element>) {
        event.stopPropagation();
        if (onClick) {
            onClick(language || countryId || 0);
        }
    }

    return (
        <Tooltip title={title} aria-label={title.toLowerCase()}>
            <img className={className} src={flagUrl} onClick={flagClicked} alt={`${title.toLowerCase()} flag`} />
        </Tooltip>
    );
}
