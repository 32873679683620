import React, { useCallback, useEffect } from 'react';
import { Theme, Checkbox, Tooltip } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import CoreFormSelect from '../../../core/form/select/CoreFormSelect';
import CoreFormDatePicker from '../../../core/form/date-picker/CoreFormDatePicker';
import CoreFlex from '../../../core/flex/CoreFlex';
import { grey } from '@material-ui/core/colors';
import clsx from 'clsx';
import { CoreRowNumber } from '../../../core/row-number/CoreRowNumber';
import { getDefaultOpeningHour, RestaurantHour } from '../../../../interfaces/restaurant';
import moment from 'moment';
import { toTitleCase } from '../../../../services/tools';

import { Weekday } from '../../../../config/date';
import { CoreIconButton } from '../../../core/icon-button/CoreIconButton';
import { RestaurantFormOpeningHoursProps } from './RestaurantFormOpeningHours.type';

export default function RestaurantFormOpeningHours({
    openingHours,
    onUpdateHours,
    restaurantId,
}: RestaurantFormOpeningHoursProps) {
    const classes = useStyles();

    const HOURS = generateHours();

    useEffect(() => {
        if (!openingHours.length) {
            onUpdateHours([getDefaultOpeningHour(restaurantId)]);
        }
    }, [restaurantId, openingHours, onUpdateHours]);

    function generateHours(): Date[] {
        const minutes = [0, 15, 30, 45];
        const baseDate = new Date();
        const hours: Date[] = [];
        for (let i = 0; i < 24; i++) {
            minutes.forEach((minute) => {
                const newDate = new Date(baseDate.getFullYear(), baseDate.getMonth(), baseDate.getDate(), i, minute);
                hours.push(newDate);
            });
        }
        return hours;
    }

    function toggleDatesDisabled(row: number) {
        const hours: RestaurantHour[] = [...openingHours];
        const adjustedHour = hours[row];
        adjustedHour.datesDisabled = !adjustedHour.datesDisabled;
        if (adjustedHour.datesDisabled) {
            adjustedHour.start = undefined;
            adjustedHour.end = undefined;
        }
        onUpdateHours(hours);
    }

    function handleOpeningHoursChange(name: keyof RestaurantHour, value: number | boolean | undefined, row: number) {
        const hours: RestaurantHour[] = [...openingHours];
        if (hours.length <= row || !hours[row] || !hours[row][name]) {
            return;
        }
        const adjustableHour: any = hours[row];
        if (['opening', 'closing'].includes(name)) {
            adjustableHour[name] = new Date(value as number);
        } else {
            adjustableHour[name] = value;
        }
        onUpdateHours(hours);
    }

    function handleOpeningHoursDateChange(name: 'start' | 'end', date: Date | undefined, row: number) {
        const hours: RestaurantHour[] = [...openingHours];
        hours[row][name] = date;
        onUpdateHours(hours);
    }

    function handleDeleteRow(index: number) {
        if (openingHours.length > 1) {
            const hours = [...openingHours];
            hours.splice(index, 1);
            onUpdateHours(hours);
        }
    }

    const handleAddRow = useCallback(() => {
        const hours = [...openingHours];
        const newHour = getDefaultOpeningHour(restaurantId);
        newHour.weekday = hours.length % 7;
        hours.push(newHour);
        onUpdateHours(hours);
    }, [restaurantId, openingHours, onUpdateHours]);

    return (
        <>
            {openingHours.map((hour: RestaurantHour, index: number) => {
                return (
                    <CoreFlex
                        key={index}
                        wrap="nowrap"
                        justify="flex-start"
                        className={clsx(classes.openingHoursRow, index % 2 === 0 ? classes.evenRow : classes.oddRow)}
                    >
                        <CoreRowNumber value={index + 1} className={classes.rowNumber} />
                        <CoreFlex alignItems="center" wrap="wrap" justify="flex-start">
                            <CoreFormSelect
                                className={classes.formField}
                                value={hour.weekday}
                                label="Weekday"
                                onValueChange={(value) => handleOpeningHoursChange('weekday', value as number, index)}
                                options={Object.keys(Weekday)
                                    .filter((value) => value.length > 1)
                                    .map((day: string, index: number) => {
                                        return {
                                            value: index,
                                            label: toTitleCase(day.replaceAll('_', ' ')),
                                        };
                                    })}
                            />
                            <CoreFormSelect
                                className={classes.formField}
                                value={new Date(hour.opening).getTime()}
                                label="Opening"
                                onValueChange={(value) => handleOpeningHoursChange('opening', value as number, index)}
                                options={HOURS.map((opening: Date, index: number) => {
                                    return {
                                        value: new Date(opening).getTime(),
                                        label: moment(opening).format('HH:mm'),
                                    };
                                })}
                            />
                            <CoreFormSelect
                                className={classes.formField}
                                value={new Date(hour.closing).getTime()}
                                label="Closing"
                                onValueChange={(value) => handleOpeningHoursChange('closing', value as number, index)}
                                options={HOURS.map((closing: Date, index: number) => {
                                    return {
                                        value: new Date(closing).getTime(),
                                        label: moment(closing).format('HH:mm'),
                                    };
                                })}
                            />
                            {!hour.datesDisabled && (
                                <>
                                    <CoreFormDatePicker
                                        className={classes.formField}
                                        disablePast
                                        value={hour.start || undefined}
                                        label="Start date"
                                        disabled={hour.datesDisabled}
                                        onValueChange={(date) => handleOpeningHoursDateChange('start', date, index)}
                                    />
                                    <CoreFormDatePicker
                                        className={classes.formField}
                                        disablePast
                                        value={hour.end || undefined}
                                        label="End date"
                                        disabled={hour.datesDisabled}
                                        onValueChange={(date) => handleOpeningHoursDateChange('end', date, index)}
                                    />
                                </>
                            )}
                        </CoreFlex>
                        <CoreFlex className={classes.buttons} justify="flex-end">
                            <Tooltip title="Limit with date range">
                                <Checkbox
                                    checked={!hour.datesDisabled}
                                    color="primary"
                                    onChange={() => toggleDatesDisabled(index)}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                />
                            </Tooltip>
                            {openingHours.length > 1 && (
                                <CoreIconButton
                                    iconName="delete"
                                    toolTip="Delete hours"
                                    onClick={() => handleDeleteRow(index)}
                                />
                            )}
                            <CoreIconButton iconName="add" toolTip="Add new hours" onClick={handleAddRow} />
                        </CoreFlex>
                    </CoreFlex>
                );
            })}
        </>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flex: '1 1 auto',
            width: '100%',
        },
        formField: {
            // padding: '0rem 0.5rem 0.5rem 0.5rem',
            margin: 0,
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: 0,
            marginRight: '0.5rem',
            marginTop: '0.5rem',
            marginBottom: '0.5rem',
        },
        rowNumber: {
            paddingRight: '1rem',
        },
        openingHoursRow: {
            width: '100%',
            padding: '1.5rem',
        },
        evenRow: {
            backgroundColor: grey[100],
        },
        oddRow: {
            backgroundColor: grey[200],
        },
        buttons: {
            width: '150px',
        },
    }),
);
