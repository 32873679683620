import React from 'react';
import { TableHead, TableRow, TableCell, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { TableColumn } from '../../../../interfaces';
import { grey } from '@material-ui/core/colors';

interface Props {
    columns: TableColumn[];
    actions?: boolean;
    className?: string;
}

export default function CoreTableHeader({ columns, actions, className }: Props) {
    const classes = useStyles();
    return (
        <TableHead className={className}>
            <TableRow>
                {columns.map((column, key) => {
                    if (column.invisible) {
                        return null;
                    }
                    return (
                        <TableCell id={column.column} className={classes.tableHeader} key={key}>
                            {column.label.toUpperCase()}
                        </TableCell>
                    );
                })}
                {actions && (
                    <TableCell id="actions" className={classes.tableHeader}>
                        ACTIONS
                    </TableCell>
                )}
            </TableRow>
        </TableHead>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tableHeader: {
            backgroundColor: grey[700], // theme.palette.primary.main,
            color: theme.palette.common.white,
            textAlign: 'left',
        },
    }),
);
