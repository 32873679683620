import { Theme } from '@material-ui/core';

export function useCoreAnimations(theme: Theme) {
    return {
        animationScale1_2x: {
            transform: 'scale3d(1, 1, 1)',
            transition: theme.transitions.create(['transform', 'backgroundc-color'], {
                easing: theme.transitions.easing.easeInOut,
                duration: '0.5s',
            }),
            '&:hover': {
                transform: 'scale3d(1.2, 1.2, 1.2)',
                transition: theme.transitions.create(['transform', 'background-color'], {
                    easing: theme.transitions.easing.easeInOut,
                    duration: '0.5s',
                }),
            },
        },
        animationScale1_5x: {
            transform: 'scale3d(1, 1, 1)',
            transition: theme.transitions.create(['transform', 'backgroundc-color'], {
                easing: theme.transitions.easing.easeInOut,
                duration: '0.5s',
            }),
            '&:hover': {
                transform: 'scale3d(1.5, 1.5, 1.5)',
                transition: theme.transitions.create(['transform', 'background-color'], {
                    easing: theme.transitions.easing.easeInOut,
                    duration: '0.5s',
                }),
            },
        },
        animationScale2x: {
            transform: 'scale3d(1, 1, 1)',
            transition: theme.transitions.create(['transform', 'backgroundc-color'], {
                easing: theme.transitions.easing.easeInOut,
                duration: '0.5s',
            }),
            '&:hover': {
                transform: 'scale3d(2, 2, 2)',
                transition: theme.transitions.create(['transform', 'background-color'], {
                    easing: theme.transitions.easing.easeInOut,
                    duration: '0.5s',
                }),
            },
        },
        animationScale5x: {
            transform: 'scale3d(1, 1, 1)',
            transition: theme.transitions.create(['transform', 'backgroundc-color'], {
                easing: theme.transitions.easing.easeInOut,
                duration: '0.5s',
            }),
            '&:hover': {
                transform: 'scale3d(2, 2, 2)',
                transition: theme.transitions.create(['transform', 'background-color'], {
                    easing: theme.transitions.easing.easeInOut,
                    duration: '0.5s',
                }),
            },
        },
    };
}
