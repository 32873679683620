import React, { useState } from 'react';
import { Theme, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Order, getPaymentMethodName } from '../../../../../../../interfaces/order';
import CoreFlex from '../../../../../../core/flex/CoreFlex';
import { formatPrice } from '../../../../../../../services/helpers';
import { Country } from '../../../../../../../interfaces/country';
import { useStore } from '../../../../../../../services/store';
import { stores } from '../../../../../../../stores';
import { COLOR } from '../../../../../../../services/createTheme';
import { grey } from '@material-ui/core/colors';
import moment from 'moment';

interface Props {
    order: Order;
    onOrderItems: () => void;
}

enum OrderDetailsMode {
    ITEMS,
    MORE,
}

export default function OrderTableRowDetailsMoreTransaction({ order, onOrderItems }: Props) {
    const classes = useStyles();
    const [mode, setMode] = useState<OrderDetailsMode>(OrderDetailsMode.ITEMS);
    const [country] = useStore<Country>(stores.selectedCountry);

    return (
        <CoreFlex className={classes.root} direction="column">
            <CoreFlex className={classes.header} justify="space-between">
                <Typography>TRANSACTION DETAILS</Typography>
                <CoreFlex className={classes.orderItemsButton} onClick={onOrderItems}>
                    <Typography>ORDER ITEMS</Typography>
                </CoreFlex>
            </CoreFlex>
            <CoreFlex className={classes.body} justify="flex-start">
                <CoreFlex direction="column" className={classes.priceBreakdown} alignItems="flex-start">
                    <Typography align="left">
                        <strong>Price breakdown</strong>
                    </Typography>
                    <CoreFlex justify="space-between" className={classes.indent}>
                        <Typography>Items total</Typography>
                        <Typography>{formatPrice(order.totalPrice, country, true)}</Typography>
                    </CoreFlex>
                    <CoreFlex justify="space-between" className={classes.indent}>
                        <Typography>Discount</Typography>
                        <Typography>{formatPrice(order.totalDiscount || 0, country, true)}</Typography>
                    </CoreFlex>
                    <CoreFlex justify="space-between" className={classes.indent}>
                        <Typography>Delivery</Typography>
                        <Typography>{formatPrice(order.deliveryCost || 0, country, true)}</Typography>
                    </CoreFlex>
                    <CoreFlex justify="space-between" className={classes.indent}>
                        <Typography>Tax</Typography>
                        <Typography>{formatPrice(order.tax, country, true)}</Typography>
                    </CoreFlex>
                    <CoreFlex justify="space-between" className={classes.indent}>
                        <Typography>
                            <strong>Final price</strong>
                        </Typography>
                        <Typography>
                            <strong>{formatPrice(order.finalPrice, country, true)}</strong>
                        </Typography>
                    </CoreFlex>
                </CoreFlex>
                <CoreFlex
                    direction="column"
                    className={classes.details}
                    alignItems="flex-start"
                    alignLines="flex-start"
                >
                    <CoreFlex justify="space-between" alignItems="flex-start">
                        <Typography>
                            <strong>Payment method</strong>
                        </Typography>
                        <Typography>
                            <strong>{getPaymentMethodName(order.paymentMethod).replaceAll('_', ' ')}</strong>
                        </Typography>
                    </CoreFlex>
                    <CoreFlex justify="space-between">
                        <Typography noWrap className={classes.detailLabel}>
                            <strong>Has been paid</strong>
                        </Typography>
                        {order.isPaid && (
                            <CoreFlex justify="flex-end">
                                <Typography>{moment().format('DD-MM-YYYY HH:mm')}</Typography>
                                <CoreFlex className={classes.paymentTag}>
                                    <Typography variant="subtitle1">YES</Typography>{' '}
                                </CoreFlex>
                            </CoreFlex>
                        )}
                        {!order.isPaid && (
                            <CoreFlex className={classes.noPaymentTag}>
                                <Typography variant="subtitle1">NO</Typography>
                            </CoreFlex>
                        )}
                    </CoreFlex>
                    <CoreFlex justify="space-between" direction="column">
                        <CoreFlex justify="flex-start">
                            <Typography noWrap className={classes.detailLabel}>
                                <strong>Payment breakdown:</strong>
                            </Typography>
                        </CoreFlex>
                        <CoreFlex justify="space-between" className={classes.indent}>
                            <Typography>- card</Typography>
                            <Typography>{formatPrice(0, country)}</Typography>
                        </CoreFlex>
                        <CoreFlex justify="space-between" className={classes.indent}>
                            <Typography>- PayPal</Typography>
                            <Typography>{formatPrice(0, country)}</Typography>
                        </CoreFlex>
                        <CoreFlex justify="space-between" className={classes.indent}>
                            <Typography>- Munchies coins</Typography>
                            <Typography>{formatPrice(0, country)}</Typography>
                        </CoreFlex>
                        <CoreFlex justify="space-between" className={classes.indent}>
                            <Typography>- Gift Voucher</Typography>
                            <Typography>{formatPrice(0, country)}</Typography>
                        </CoreFlex>
                    </CoreFlex>
                </CoreFlex>
            </CoreFlex>
        </CoreFlex>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderRadius: '1rem 1rem 0rem 0rem',
        },
        header: {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.common.white,
            paddingLeft: '1rem',
            height: '40px',
        },
        details: {
            color: theme.palette.common.white,
            padding: '0.5rem 0rem 0.5rem 1rem',
        },
        detailLabel: {
            width: '200px',
        },
        paymentTag: {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.common.white,
            borderRadius: '1rem',
            marginLeft: '1rem',
            width: '50px',
        },
        noPaymentTag: {
            backgroundColor: theme.palette.error.dark,
            color: theme.palette.common.white,
            borderRadius: '1rem',
            width: '50px',
        },
        orderItemsButton: {
            backgroundColor: COLOR.MunchiesBlue,
            color: theme.palette.common.white,
            height: '40px',
            cursor: 'pointer',
            width: '250px',
        },
        body: {
            backgroundColor: theme.palette.secondary.dark,
            paddingLeft: '1rem',
            paddingRight: '1.5rem',
        },
        priceBreakdown: {
            backgroundColor: theme.palette.common.white,
            padding: '0.5rem',
            paddingTop: '1rem',
            width: '350px',
            height: '220px',
            color: grey[700],
        },
        indent: {
            padding: '0rem 1rem',
            marginBottom: '0.25rem',
        },
    }),
);
