import React from 'react';
import { makeStyles, Typography, DialogActions, Icon, Dialog } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Theme } from '@material-ui/core';
import { green, amber, grey } from '@material-ui/core/colors';
import clsx from 'clsx';
import CoreFlex from '../flex/CoreFlex';
import CoreButton from '../button/CoreButton';

interface Props {
    variant?: keyof typeof variantIcon;
    title?: string;
    message: string;
    open: boolean;
    onClose: () => void;
    onCancel?: () => void;
    onConfirm?: () => void;
    confirmLabel?: string;
    cancelLabel?: string;
    secondaryMessage?: string;
}

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

export function CoreConfirmation({
    variant = 'info',
    title = variant,
    open,
    onClose,
    confirmLabel = 'Confirm',
    cancelLabel = 'Cancel',
    onConfirm = () => {},
    onCancel = () => {},
    message,
    secondaryMessage,
}: Props) {
    const classes = useStyles();
    const icon = variant === 'success' ? 'check' : variant;

    function handleClose() {
        onCancel();
        onClose();
    }
    function handleConfirm() {
        onConfirm();
        onClose();
    }

    return (
        <Dialog open={open}>
            <CoreFlex direction="column">
                <CoreFlex className={clsx(classes[variant], classes.title)} alignItems="center">
                    <CoreFlex className={classes.titleIcon}>
                        <Icon fontSize="large">{icon}</Icon>
                    </CoreFlex>
                    <Typography variant="h6">{title.toUpperCase()}</Typography>
                </CoreFlex>
                <CoreFlex direction="column" className={classes.root}>
                    <CoreFlex className={classes.message}>
                        <Typography variant="subtitle1">{message} </Typography>
                    </CoreFlex>
                    {secondaryMessage && (
                        <CoreFlex className={classes.secondaryMessage}>
                            <Typography variant="subtitle1">{secondaryMessage} </Typography>
                        </CoreFlex>
                    )}
                </CoreFlex>
            </CoreFlex>
            <DialogActions>
                <CoreButton onClick={handleClose} label={cancelLabel} customColor={grey[200]} />
                <CoreButton variant="contained" color="default" onClick={handleConfirm} label={confirmLabel} />
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: '2rem',
    },
    success: {
        backgroundColor: green[600],
        color: theme.palette.getContrastText(green[600]),
    },
    error: {
        backgroundColor: theme.palette.error.dark,
        color: theme.palette.getContrastText(theme.palette.error.dark),
    },
    info: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    warning: {
        backgroundColor: amber[700],
        color: theme.palette.common.white,
    },
    title: {
        padding: '1rem 2rem',
    },
    titleIcon: {
        width: '50px',
        paddingRight: '0.5rem',
    },
    message: {
        alignItems: 'center',
        textAlign: 'center',
        padding: '2rem',
    },
    secondaryMessage: {
        color: grey[500],
        padding: '0 2rem',
    },
    buttons: {
        padding: '2rem',
        color: theme.palette.getContrastText(theme.palette.background.paper),
    },
}));
