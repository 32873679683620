import { getOrderStatusName, Order, OrderEvent } from '../interfaces/order';
import { AuthContextInterface } from '../context/AuthContext';
import { httpGet, httpPost, httpPut } from '../services/api';
import { showSuccess, showError } from '../services/alert';

export async function updateOrder(
    id: number,
    data: Partial<Order>,
    authContext?: Partial<AuthContextInterface>,
): Promise<Order | null> {
    try {
        const response = await httpPut(`order/update/${id}`, data, authContext);
        if (response.success && response.result) {
            showSuccess(`Order ${id} updated successfully`);
            return response.result;
        } else {
            showError("Something's wrong: " + response.message);
            return null;
        }
    } catch (err) {
        showError('Error when updating a order: ' + err);
        return null;
    }
}

export async function addOrderEvent(
    id: number,
    orderEvent: OrderEvent,
    authContext?: Partial<AuthContextInterface>,
): Promise<Order | null> {
    try {
        const response = await httpPost(`order/event/${id}`, orderEvent, authContext);
        if (response.success && response.result) {
            showSuccess(
                `Order event ${getOrderStatusName(orderEvent.status).replaceAll(
                    '_',
                    ' ',
                )} successfully added to oder ${id}`,
            );
            return response.result;
        } else {
            showError("Something's wrong: " + response.message);
            return null;
        }
    } catch (err) {
        showError('Error when adding order event: ' + err);
        return null;
    }
}

export async function markOrderItemAsReady(id: number, authContext?: Partial<AuthContextInterface>): Promise<boolean> {
    try {
        const response = await httpGet(`order/item/ready/${id}`, {}, authContext);
        if (response.success && response.result) {
            showSuccess(`Order item ${id} successfully marked as READY`);
            return response.success;
        } else {
            showError("Something's wrong: " + response.message);
            return false;
        }
    } catch (err) {
        showError('Error when trying to mark order item as ready: ' + err);
        return false;
    }
}

export async function markOrderItemAsSoldOut(
    id: number,
    authContext?: Partial<AuthContextInterface>,
): Promise<boolean> {
    try {
        const response = await httpGet(`order/item/sold-out/${id}`, {}, authContext);
        if (response.success && response.result) {
            showSuccess(`Order item ${id} successfully marked as SOLD OUT`);
            return true;
        } else {
            showError("Something's wrong: " + response.message);
            return false;
        }
    } catch (err) {
        showError('Error when trying to mark order item as sold out: ' + err);
        return false;
    }
}
