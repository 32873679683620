export function toTitleCase(str: string) {
    return str
        .split(/\s+/)
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1).toLowerCase())
        .join(' ');
}

export function minutesToMilliseconds(minutes: number) {
    return minutes * 60 * 1000;
}
