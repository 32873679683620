import React, { useState, useEffect, ChangeEvent, useContext } from 'react';
import CoreFlex from '../../../core/flex/CoreFlex';
import CoreFormSelect from '../../../core/form/select/CoreFormSelect';
import { getCategories } from '../../../../microservices/category';
import CoreChip from '../../../core/chip/CoreChip';
import { AuthContext } from '../../../../context/AuthContext';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { Category } from '../../../../interfaces/category';
import { useStore } from '../../../../services/store';
import { stores } from '../../../../stores';
import { getTranslation, Language } from '../../../../config/translation';

interface Props {
    categoryIds?: number[];
    restaurantId?: number;
    onAddCategory: (categoryId: number | undefined) => void;
    onDeleteCategory: (categoryId: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        categoryBox: {
            paddingBottom: '1rem',
        },
    }),
);

export default function ProductCategorySelect({ categoryIds, restaurantId, onDeleteCategory, onAddCategory }: Props) {
    const classes = useStyles();
    const authContext = useContext(AuthContext);
    const [categories, setCategories] = useStore<Category[]>(stores.categories);
    const [language] = useStore<Language>(stores.language);

    useEffect(() => {
        if (!categories.length && restaurantId) {
            loadCategories();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restaurantId]);

    function handleDeleteCategory(categoryName: string) {
        const category = categories?.find((category) => getTranslation(category, language) === categoryName);
        if (!category || !category.id) {
            return;
        }
        onDeleteCategory(category.id);
    }

    function getOptions() {
        if (!categories) {
            return [];
        }

        return categories
            .filter((category: Category) => {
                return category.id && categoryIds && !categoryIds.includes(category.id);
            })
            .map((category: Category) => {
                return {
                    value: category.id || 0,
                    label: getTranslation(category, language) || '',
                };
            });
    }

    async function loadCategories() {
        if (restaurantId) {
            setCategories(await getCategories(restaurantId, authContext));
        }
    }

    return (
        <CoreFlex>
            <CoreFlex direction="column">
                {categoryIds && categories && (
                    <CoreFlex justify="flex-start" className={classes.categoryBox} wrap="wrap">
                        {categoryIds.map((categoryId) => {
                            const categoryFound = categories.find((category) => {
                                return category.id === categoryId;
                            });
                            if (!categoryFound) {
                                return null;
                            }
                            const categoryName = getTranslation(categoryFound, language);
                            return (
                                <CoreChip
                                    key={categoryFound.id}
                                    label={categoryName || ''}
                                    onDelete={handleDeleteCategory}
                                />
                            );
                        })}
                    </CoreFlex>
                )}
                <CoreFormSelect
                    disabled={!getOptions().length}
                    fullWidth
                    value={''}
                    label="Add category"
                    onValueChange={(value) => onAddCategory(value as number)}
                    options={getOptions()}
                />
            </CoreFlex>
        </CoreFlex>
    );
}
