import { Language } from '../config/translation';
import { Country } from '../interfaces/country';

export function formatPrice(price: number, country: Country, compact?: boolean) {
    return `${country?.isSymbolAtFront && country.currencySymbol}
    ${price / 100} ${country && !compact ? country.currencyName : ''}`;
}

export function getMinMaxFromArray(array: any[], key: keyof any): { min: number; max: number } {
    let min = Number.POSITIVE_INFINITY;
    let max = Number.NEGATIVE_INFINITY;
    array?.forEach((item) => {
        max = item.price > max ? item.price : max;
        min = item.price < min ? item.price : min;
    });
    return { min, max };
}
