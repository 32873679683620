import React from 'react';
import { TableHead, TableRow, TableCell, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Order } from '../../../../../../interfaces/order';
import CoreFlex from '../../../../../core/flex/CoreFlex';
import OrderTableRowDetailsMoreEvents from './events/OrderTableRowDetailsMoreEvents';
import OrderTableRowDetailsMoreTransaction from './transaction/OrderTableRowDetailsMoreTransaction';
import OrderTableRowDetailsMoreDelivery from './delivery/OrderTableRowDetailsMoreDelivery';

interface Props {
    order: Order;
    onOrderItems: () => void;
}

export default function OrderTableRowDetailsMore({ order, onOrderItems }: Props) {
    const classes = useStyles();

    return (
        <CoreFlex justify="flex-start" alignItems="flex-start">
            <CoreFlex className={classes.events}>
                <OrderTableRowDetailsMoreEvents order={order} />
            </CoreFlex>
            <CoreFlex direction="column">
                <CoreFlex className={classes.transactionDetails}>
                    <OrderTableRowDetailsMoreTransaction order={order} onOrderItems={onOrderItems} />
                </CoreFlex>
                <CoreFlex className={classes.deliveryDetails}>
                    <OrderTableRowDetailsMoreDelivery order={order} />
                </CoreFlex>
            </CoreFlex>
        </CoreFlex>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderRadius: '1rem 1rem 0rem 0rem',
        },
        cell: {
            color: theme.palette.common.white,
            paddingBottom: '0.5rem',
            paddingTop: '0.5rem',
            borderWidth: '0px',
        },
        events: {
            // width: '50%',
        },
        transactionDetails: {
            // width: '700px',
        },
        deliveryDetails: {
            // width: '50%',
        },
    }),
);
