import React from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import MapGL from 'react-map-gl';

// const GOOGLE_MAP_TOKEN = "AIzaSyCkS47aH2HbMgeu3crBrMgTwJ7gMzJMbnM";
const MAP_TOKEN = 'pk.eyJ1IjoibXVuY2hpZXMiLCJhIjoiY2syNTN6YnNuMTM5ODNsbXpicGZraTEyayJ9.BgBdMJWsOXjdI-EZ9LvTKg';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            minWidth: '300px',
            minHeight: '300px',
        },
    }),
);

interface Props {
    className?: string;
    viewport: any;
    onViewportChange: any;
    children?: JSX.Element | JSX.Element[];
}

export function CoreMap({ viewport, onViewportChange, children, className }: Props) {
    const classes = useStyles();
    return (
        <MapGL
            {...viewport}
            className={className ? className : classes.container}
            width="100%"
            height="100%"
            attributionControl={false}
            mapStyle="mapbox://styles/mapbox/outdoors-v11"
            onViewportChange={onViewportChange}
            mapboxApiAccessToken={MAP_TOKEN}
        >
            {children}
        </MapGL>
    );
}
