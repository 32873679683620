import { httpGet, httpPost, httpPut, httpDelete } from '../services/api';
import { AuthContextInterface } from '../context/AuthContext';
import { Country } from '../interfaces/country';
import { showError, showSuccess } from '../services/alert';
import { populateCountries, populateCountry } from '../services/country';

export async function getCountry(id: number, authContext?: Partial<AuthContextInterface>): Promise<Country | null> {
    try {
        const response = await httpGet(`country/get/${id}`, {}, authContext);
        if (response.success && response.result) {
            const country: Country = response.result;
            return populateCountry(country);
        } else {
            showError("Something's wrong: " + response.message);
            return null;
        }
    } catch (err) {
        showError('Error retriveing countries: ' + err);
        return null;
    }
}

export async function getCountries(authContext?: Partial<AuthContextInterface>): Promise<Country[]> {
    try {
        const response = await httpGet('country/find', {}, authContext);
        if (response.success && response.result) {
            const countries: Country[] = response.result;
            return populateCountries(countries);
        } else {
            showError("Something's wrong: " + response.message);
            return [];
        }
    } catch (err) {
        showError('Error retriveing countries: ' + err);
        return [];
    }
}

export async function createCountry(
    countryData: Record<string, any>,
    authContext?: Partial<AuthContextInterface>,
): Promise<Country | null> {
    try {
        const response = await httpPost('country/new', countryData, authContext);
        if (response.success && response.result) {
            showSuccess(`Country added successfully: ` + response.message);
            return response.result;
        } else {
            showError("Something's wrong: " + response.message);
            return null;
        }
    } catch (err) {
        showError('Error when creating a country:' + err);
        return null;
    }
}

export async function updateCountry(
    id: number,
    updatedData: Record<string, any>,
    authContext?: Partial<AuthContextInterface>,
) {
    try {
        const response = await httpPut(`country/update/${id}`, updatedData, authContext);
        if (response.success && response.result) {
            showSuccess(`Country ${id} updated successfully`);
            return response;
        } else {
            showError("Something's wrong: " + response.message);
            return response;
        }
    } catch (err) {
        showError('Error when updating a country: ' + err);
        return err;
    }
}

export async function removeCountry(id: number, authContext?: Partial<AuthContextInterface>) {
    try {
        const response = await httpDelete(`country/remove/${id}`, {}, authContext);
        if (response.success && response.result) {
            showSuccess(`Country ${id} deleted successfully`);
            return response;
        } else {
            showError("Something's wrong: " + response.message);
            return response;
        }
    } catch (err) {
        showError('Error when deleting a country: ' + err);
        return err;
    }
}
