import React, { useState, ChangeEvent, useContext } from 'react';
import logo from '../../../assets/logo/munchies_logo.png';
import { Grid, Dialog, DialogTitle, Grow, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { Redirect } from 'react-router';
import { AuthContext } from '../../../context/AuthContext';
import { AlertMessage } from '../../../interfaces';
import { useDeviceType } from '../../../services/utils';
import { loginUser, setStateLoggedIn } from '../../../microservices/auth';
import { LoginForm } from '../../../components/login/form/LoginForm';
import { CoreSnackbar } from '../../../components/core/snackbar/CoreSnackbar';
import { LoginData } from '../../../interfaces/auth';

/**
|--------------------------------------------------
| Login box component
|--------------------------------------------------
*/

const Transition: React.ComponentType<any> = React.forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} />;
});

export default function LoginPage(): JSX.Element {
    const [showMessage, setShowMessage] = useState<AlertMessage | undefined>();
    const authContext = useContext(AuthContext);
    const [rememberMe, setRememberMe] = useState(false);
    const [loginData, setLoginData] = useState<LoginData>({ email: '', password: '', rememberMe: true });
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const classes = useStyles();
    const { isMobile } = useDeviceType();

    function handleValueChange(name: keyof LoginData, value: string | boolean) {
        setLoginData({ ...loginData, [name]: value });
    }

    if (authContext.loggedIn) {
        return <Redirect to="/" />;
    }

    async function handleLogin(event: React.FormEvent) {
        event.preventDefault();
        setIsLoading(true);
        const response = await loginUser(loginData, authContext);
        setIsLoading(false);
        if (!response) {
            setShowMessage({
                type: 'error',
                message: 'Error: Unable to connect to the server.',
            });
            return;
        }

        if (!response.success) {
            setShowMessage({
                type: 'error',
                message: `Login failed. ${response.message}`,
            });
            return;
        }
        setStateLoggedIn(response, authContext);
    }

    return (
        <>
            <Dialog
                PaperProps={{
                    className: isMobile ? classes.dialogPaperMobile : classes.dialogPaperDesktop,
                }}
                open
                fullWidth
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="xs"
                TransitionComponent={Transition}
                transitionDuration={2500}
            >
                <Grid
                    container
                    justify="space-around"
                    alignContent="space-around"
                    alignItems="center"
                    direction="column"
                    className={classes.formContainer}
                >
                    <Grid item className={classes.padding}>
                        <img src={logo} width={isMobile ? 150 : 150} alt="Munchies logo" />
                    </Grid>
                    {!isMobile && (
                        <Grid item>
                            <DialogTitle className={classes.dilogTitle}>Welcome to Munchies backoffice</DialogTitle>
                        </Grid>
                    )}
                    {!isLoading ? (
                        <LoginForm loginData={loginData} onValueChange={handleValueChange} handleLogin={handleLogin} />
                    ) : (
                        <Grid item>
                            <CircularProgress />
                        </Grid>
                    )}
                </Grid>
            </Dialog>
            {showMessage && (
                <CoreSnackbar
                    variant={showMessage.type}
                    message={showMessage.message}
                    onClose={() => setShowMessage(undefined)}
                />
            )}
        </>
    );
}

const useStyles = makeStyles({
    dialogPaperDesktop: {
        minHeight: '70vh',
        maxHeight: '70vh',
    },
    dialogPaperMobile: {
        minHeight: '100vh',
        maxHeight: '100vh',
        maxWidth: '100vw',
        minWidth: '100vw',
        overflowY: 'hidden',
    },
    padding: {
        padding: '1rem',
    },

    dilogTitle: {
        // backgroundColor: 'yellow'
    },

    logo: {
        textAlign: 'center',
    },

    formContainer: {
        position: 'absolute',
        height: '100%',
        display: 'flex',
        top: '0px',
        bottom: '0px',
        width: '100%',
    },
    gridItem: {
        height: '100%',
    },
});
