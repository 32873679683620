import { Theme, createStyles, makeStyles, Tooltip, Icon, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';
import { getUserTypeData, getUserTypeStyleName, useUserTypeStyles } from '../../../config/styles/userType';
import { AuthContext } from '../../../context/AuthContext';
import { RestaurantType, RestaurantTypeIcons } from '../../../interfaces/restaurant';
import { User, UserType } from '../../../interfaces/user';
import { getUserById } from '../../../microservices/user';
import CoreFlex from '../../core/flex/CoreFlex';

interface Props {
    userId: number;
    onClick?: (userId: number) => void;
    className?: string;
}

export default function UserCard({ userId, onClick, className }: Props) {
    const classes = useStyles();
    const [user, setUser] = useState<User | null>(null);
    const authContext = useContext(AuthContext);

    useEffect(() => {
        loadData();
    }, []);

    async function loadData() {
        if (user || !userId) {
            return;
        }
        setUser((await getUserById(userId, authContext)) || null);
    }

    function handleClick() {
        if (onClick) {
            onClick(userId);
        }
    }

    if (!user) {
        return <></>;
    }

    const styleName = getUserTypeStyleName(user?.type || UserType.CUSTOMER) as keyof typeof classes;
    const data = getUserTypeData(user?.type);
    return (
        <CoreFlex className={clsx(classes.manager, classes && classes[styleName])}>
            <img src={user.photo} className={classes.profileImage} />
            <CoreFlex direction="column" alignItems="flex-start">
                <Typography variant="subtitle1">{`${user.firstName} ${user.lastName}`}</Typography>
                <Typography variant="caption">{`${user.role}`}</Typography>
            </CoreFlex>
            <Tooltip title={data.label}>
                <Icon className={classes.userType}>{data.icon}</Icon>
            </Tooltip>
        </CoreFlex>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        manager: {
            width: '250px',
            borderRadius: '1rem',
            // backgroundColor: grey['A100'],
            color: theme.palette.common.white,

            // backgroundColor: theme.palette.primary.main,

            // padding: '0.5rem',
            marginRight: '2rem',
            '&:hover': {
                // backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white,
            },
        },
        userType: {
            marginRight: '0.5rem',
            fontSize: '50px',
        },
        profileImage: {
            width: '70px',
            height: '70px',
            marginRight: '1rem',
            borderRadius: '1rem 0rem 0rem 1rem',
        },
        ...useUserTypeStyles(theme),
    }),
);
