import { Language } from './../config/translation';
import { DbBase, TranslationObjectBase } from './common';

export interface Category extends DbBase {
    restaurantId: number;
    order: number;
    photo: string;
    translations?: Partial<Record<Language, CategoryTranslation>>;
}

export interface CategoryTranslation extends TranslationObjectBase {
    name: string;
    description?: string;
}

export function getDefaultCategory() {
    return {
        name: '',
        order: 1,
        photo: '',
        restaurantId: 0,
        createdAt: new Date(),
        updatedAt: new Date(),
        isActive: true,
        isDeleted: false,
        translations: {
            [Language.ENGLISH]: {
                language: Language.ENGLISH,
                parentId: 0,
                name: '',
                description: '',
            },
        },
    };
}
