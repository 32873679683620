import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import CoreFlex from '../../../core/flex/CoreFlex';
import { Theme, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { useStore } from '../../../../services/store';
import { stores } from '../../../../stores';
import CoreFormSelect from '../../../core/form/select/CoreFormSelect';
import ProductsFormExtraRow from './row/ProductExtraEditorRow';
import CoreButton from '../../../core/button/CoreButton';
import { Country } from '../../../../interfaces/country';
import { Restaurant } from '../../../../interfaces/restaurant';
import { isString } from 'lodash';
import { getDefaultProductExtra, Product, ProductExtra, ProductExtraTranslation } from '../../../../interfaces/product';
import { getTranslatedObjectUpdate, Language, getTranslation } from '../../../../config/translation';

interface Props {
    restaurant: Restaurant;
    product: Product;
    products: Product[];
    onUpdate: (extras: ProductExtra[]) => void;
}

export default function ProductExtraEditor({ product, onUpdate, products, restaurant }: Props) {
    const classes = useStyles();
    const [countries] = useStore(stores.countries);
    const [language] = useStore(stores.language);
    const [country, setCountry] = useState<Country>();

    useEffect(() => {
        if (restaurant && restaurant.countryId) {
            setCountry(countries.find((country: Country) => country.id === restaurant.countryId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restaurant]);

    async function handleChangeExtra(
        name: keyof ProductExtra | keyof ProductExtraTranslation,
        value: string | number | boolean,
        index: number,
        language?: Language,
    ) {
        if (!product.extras?.length || product.extras.length <= index) {
            return;
        }
        const newExtras = [...product.extras];

        let numberValue = 0;
        const extra: any = newExtras[index];

        if (name === 'name' && language && extra && isString(value)) {
            newExtras[index] = getTranslatedObjectUpdate<ProductExtra>(extra, name, value, language);
            onUpdate(newExtras);
            return;
        }

        if (['price', 'discount'].includes(name) && isString(value)) {
            numberValue = parseFloat(value);
            if (numberValue < 0) {
                return;
            }
        }

        if (name === 'price') {
            const discount = extra.discount;
            if (discount && discount > numberValue) {
                return;
            }
        } else if (name === 'discount') {
            const price = extra.price;
            if (price && price < numberValue) {
                return;
            }
        }

        extra[name] = numberValue || value;
        onUpdate(newExtras);
    }

    function handleAddExtra() {
        onUpdate((product.extras || []).concat({ ...getDefaultProductExtra() }));
    }

    function handleDeleteExtra(index: number) {
        if (!product.extras) {
            return;
        }
        onUpdate(product.extras.filter((e, i) => index !== i));
    }

    function handleCloneExtras(productId: number) {
        const clonedProduct = products.find((product: Product) => product.id === productId);
        if (clonedProduct) {
            const clonedExtras = clonedProduct.extras;
            if (clonedExtras) {
                onUpdate((product.extras || []).concat(clonedExtras));
            }
        }
    }

    return (
        <CoreFlex direction="column" alignItems="flex-start">
            <CoreFlex justify="space-between" className={classes.cardHeader}>
                <Typography variant="h6">Optional extras</Typography>
                <CoreFormSelect
                    className={classes.cloneSelect}
                    value={''}
                    onValueChange={(value) => handleCloneExtras(value as number)}
                    label="Clone from"
                    options={products
                        .filter((item: Product) => item.extras && item.extras.length)
                        .map((item: Product) => {
                            return {
                                value: item.id || 0,
                                label: getTranslation(item, language) || '',
                            };
                        })}
                />
                <CoreButton label="ADD EXTRA" onClick={handleAddExtra} />
            </CoreFlex>
            {product &&
                country &&
                product.variations &&
                (product.extras || []).map((extra: ProductExtra, index: number) => {
                    return (
                        <ProductsFormExtraRow
                            key={index}
                            index={index}
                            extra={extra}
                            onAddNew={handleAddExtra}
                            onDelete={handleDeleteExtra}
                            onValueChange={handleChangeExtra}
                            country={country}
                        />
                    );
                })}
        </CoreFlex>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardHeader: {
            paddingBottom: '2rem',
        },
        cloneSelect: {
            maxWidth: '300px',
            margin: '0rem 1rem',
        },
    }),
);
