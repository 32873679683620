import React, { useState, createContext, Dispatch, SetStateAction } from 'react';
import { User, UserType } from '../interfaces/user';

export interface AuthContextInterface {
    loggedIn: boolean;
    user: User | undefined;
    isSuper: boolean;
    isOwner: boolean;
    isAdmin: boolean;
    isManager: boolean;
    isStaff: boolean;
    isCustomer: boolean;
    authToken: string | undefined;
    setLoggedIn: Dispatch<SetStateAction<boolean>>;
    setUser: Dispatch<React.SetStateAction<User | undefined>>;
    setAuthToken: Dispatch<React.SetStateAction<string | undefined>>;
}

interface Props {
    props: any[];
}

export const AuthContext = createContext<Partial<AuthContextInterface>>({});

export function AuthProvider(props: any) {
    const [loggedIn, setLoggedIn] = useState(false);
    const [user, setUser] = useState<User>();
    const [authToken, setAuthToken] = useState<string>();
    const isSuper = user ? user.type === UserType.SUPER_ADMIN : false;
    const isAdmin = user ? user.type >= UserType.ADMIN : false;
    const isOwner = user ? user.type >= UserType.OWNER : false;
    const isManager = user ? user.type >= UserType.MANAGER : false;
    const isStaff = user ? user.type >= UserType.STAFF : false;
    const isCustomer = user ? user.type === UserType.CUSTOMER : false;

    const contextValue: AuthContextInterface = {
        isOwner,
        isSuper,
        isAdmin,
        isManager,
        isStaff,
        isCustomer,
        loggedIn,
        user,
        setLoggedIn,
        setUser,
        authToken,
        setAuthToken,
    };

    return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>;
}
