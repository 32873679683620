import React from 'react';
import { Grid, Button, DialogContent, DialogActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { InputType } from '../../../interfaces';
import CoreFormInput from '../../core/form/input/CoreFormInput';

import { LoginForgotPasswordProps } from './ForgotPassword.type';

export function LoginForgotPassword(props: LoginForgotPasswordProps) {
    const { email, handler, setEmail } = props;

    const classes = useStyles();

    return (
        <form>
            <Grid item>
                <DialogContent dividers>
                    <Grid
                        container
                        alignItems="stretch"
                        alignContent="stretch"
                        justify="space-around"
                        direction="column"
                        spacing={3}
                    >
                        <Grid item className={classes.gridItem}>
                            <CoreFormInput
                                type={InputType.email}
                                value={email}
                                label="Email"
                                placeholder="Please enter your login email"
                                onValueChange={(value) => setEmail(value)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Grid>
            <Grid item>
                <DialogActions>
                    <Grid container justify="space-evenly" alignItems="center" className={classes.actions}>
                        <Grid container justify="space-evenly" alignItems="center" className={classes.actions}>
                            <Grid item>
                                <Button onClick={handler}>Send</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Grid>
        </form>
    );
}

const useStyles = makeStyles({
    actions: {
        margin: '1rem',
    },
    gridItem: {
        width: '100%',
    },
    button: {
        textDecoration: 'none',
    },
});
