import React from 'react';
import { IconButton, Drawer, Divider, List, Theme, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import clsx from 'clsx';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { NavigationItem } from '../../../../interfaces';
import CoreNavigationDrawerItem from './item/CoreNavigationDrawerItem';
import CoreFlex from '../../flex/CoreFlex';
import { COLOR } from '../../../../services/createTheme';

interface Props {
    open: boolean;
    toggleDrawer: () => void;
    menuContent: NavigationItem[];
    width?: number;
}

interface StyleProps {
    width: number;
}

export default function CoreNavigationDrawer({ open, toggleDrawer, menuContent, width = 240 }: Props) {
    const classes = useStyles({ width });
    return (
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            })}
            classes={{
                paper: clsx(classes.drawerDesign, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                }),
            }}
            open={open}
        >
            <div className={classes.toolbar}>
                {open && (
                    <CoreFlex justify="space-between" alignItems="center" className={classes.logoBg}>
                        <CoreFlex direction="column">
                            <img src="/assets/munchies-logo.svg" className={classes.logo} />
                            <Typography variant="h5" className={classes.title}>
                                BACKOFFICE
                            </Typography>
                        </CoreFlex>
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon className={classes.menuItem} />
                        </IconButton>
                    </CoreFlex>
                )}
            </div>
            <Divider />
            <List>
                {menuContent.map((menuItem: NavigationItem, index: number) => (
                    <CoreNavigationDrawerItem item={menuItem} key={index} />
                ))}
            </List>
        </Drawer>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        drawer: (props: StyleProps) => ({
            flexShrink: 0,
            whiteSpace: 'nowrap',
        }),
        drawerDesign: {
            background: theme.palette.grey[800],
            // background: `linear-gradient(rgba(0, 0, 0, 0.77) 0%, rgba(0, 0, 0, 0.77) 100%)`
        },
        logo: {
            marginLeft: '2rem',
            marginTop: '2rem',
            marginBottom: '0.5rem',
            width: '150px',
        },
        title: {
            marginLeft: '2rem',
            marginBottom: '2rem',
            color: theme.palette.common.white,
        },
        logoBg: {
            backgroundColor: COLOR.MunchiesBlue, // theme.palette.common.white,
        },
        drawerOpen: (props: StyleProps) => ({
            width: props.width,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.easeIn,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
        drawerClose: {
            width: theme.spacing(7) + 1,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
        },
        menuItem: {
            textDecoration: 'none!important',
            // color: theme.palette.common.white,
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            // padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
    }),
);
