import { useState, useContext, useEffect } from 'react';
import { SocketChannel } from '../interfaces/socket';
import { addNewChannel, socket, SocketChannelName } from '../services/socket';
import { AuthContext } from '../context/AuthContext';
import { Order } from '../interfaces/order';

interface Props {
    callback: () => void;
    delay: number;
    options?: Record<string, any>;
}

export function useSocket(
    channelName: string,
    messageCallback: (payload: any) => void,
): { sendMessage: (payload: Record<string, any>) => void; onClose: () => void } {
    const authContext = useContext(AuthContext);
    const [channel, setChannel] = useState<SocketChannel>();

    useEffect(() => {
        setChannel(addNewChannel(channelName, messageCallback, authContext));
    }, []);

    useEffect(() => {
        if (authContext) {
            socket.emit(SocketChannelName.AUTH, authContext);
        }
    }, [authContext]);

    if (channel) {
        return { sendMessage: channel?.sendMessage, onClose: channel?.onClose };
    } else {
        return { sendMessage: () => {}, onClose: () => {} };
    }
}
