import React, { useState, useEffect } from 'react';
import CoreFlex from '../../../../core/flex/CoreFlex';
import { Typography, Theme, useTheme } from '@material-ui/core';
import CoreFormInputMoney from '../../../../core/form/input/money/CoreFormInputMoney';
import CoreFormInput from '../../../../core/form/input/CoreFormInput';
import { CoreIconButton } from '../../../../core/icon-button/CoreIconButton';
import { InputType } from '../../../../../interfaces';
import { makeStyles, createStyles } from '@material-ui/styles';
import CoreSwitch from '../../../../core/switch/CoreSwitch';
import CoreChip from '../../../../core/chip/CoreChip';
import { grey } from '@material-ui/core/colors';
import { Country } from '../../../../../interfaces/country';
import { ProductVariation } from '../../../../../interfaces/product';
import { formatPrice } from '../../../../../services/helpers';
import { showError } from '../../../../../services/alert';
import CoreTranslatedInput from '../../../../core/form/input/translated/CoreTranslatedInput';
import { stores, useStore } from '../../../../../stores';
import { Language } from '../../../../../config/translation';
import { CoreFlagElement } from '../../../../core/flag-element/CoreFlagElement';
import { CoreImageUpload } from '../../../../core/image-upload/CoreImageUpload';

interface Props {
    variation: ProductVariation;
    index: number;
    country: Country;
    onValueChange: (
        name: keyof ProductVariation,
        value: string | number | boolean,
        index: number,
        language?: Language,
    ) => void;
    onDelete: (index: number) => void;
}

export default function ProductVariationEditorRow({ variation, country, index, onDelete, onValueChange }: Props) {
    const classes = useStyles();
    const [isEditMode, setIsEditMode] = useState(variation && !variation.id);
    const theme = useTheme();
    const price = formatPrice(variation.price, country);
    const discount = formatPrice(variation.discount || 0, country);
    const [language] = useStore<Language>(stores.language);
    const translation = variation.translations?.[language];

    useEffect(() => {
        if (!isEditMode) {
            if (!variation.price || variation.price === 0 || (!translation?.name && !variation.quantity)) {
                showError('ERROR: Please fill in the price and either name or quantity.');
                setIsEditMode(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditMode]);

    return (
        <CoreFlex
            onClick={() => {
                setIsEditMode(!isEditMode);
            }}
            alignItems="center"
            justify="space-between"
            className={!variation.isActive ? classes.rootInactive : !isEditMode ? classes.root : ''}
        >
            <CoreSwitch
                onValueChange={(value) => {
                    onValueChange('isActive', value, index);
                }}
                value={variation.isActive}
            />
            <CoreFlex className={classes.imageUpload}>
                <CoreImageUpload
                    label="Optional photo"
                    aspect={3 / 2}
                    basePath="product-variation"
                    width={100 * 1.5}
                    height={100}
                    imageUrl={variation.photo}
                    onUploadComplete={(value: string) => {
                        onValueChange('photo', value, index);
                    }}
                    requirements={{
                        destinationWidth: 1500,
                        destinationHeight: 1000,
                    }}
                />
            </CoreFlex>
            <CoreFlex className={classes.formField}>
                {isEditMode && (
                    <CoreTranslatedInput
                        onSubmit={() => {
                            setIsEditMode(false);
                        }}
                        language={language}
                        translationObject={variation.translations || {}}
                        keyName="name"
                        label="Variation name"
                        placeholder="Eg. Gluten free"
                        value={translation?.name || ''}
                        type={InputType.text}
                        onValueChange={(value) => onValueChange('name', value, index, language)}
                    />
                )}

                {!isEditMode && (
                    <CoreFlex className={classes.formField} justify="space-between">
                        <Typography variant="subtitle1">{translation?.name}</Typography>
                        <CoreFlagElement language={language} className={classes.flag} />
                    </CoreFlex>
                )}
            </CoreFlex>
            <CoreFlex className={classes.smallField}>
                {isEditMode && (
                    <CoreFormInputMoney
                        onSubmit={() => {
                            setIsEditMode(false);
                        }}
                        countryId={country.id}
                        autoFocus
                        label="Price"
                        required
                        value={variation.price.toString()}
                        type={InputType.number}
                        onValueChange={(value) => onValueChange('price', value, index)}
                    />
                )}
                {!isEditMode && variation.price && variation.price > 0 && (
                    <CoreChip
                        label={price}
                        color={
                            variation.isDiscountActive && variation.discount && variation.discount > 0
                                ? theme.palette.grey[500]
                                : theme.palette.primary.main
                        }
                        className={
                            variation.isDiscountActive && variation.discount && variation.discount > 0
                                ? classes.discountActive
                                : classes.priceTag
                        }
                    />
                )}
            </CoreFlex>
            <CoreFlex className={classes.smallField}>
                {isEditMode && (
                    <>
                        <CoreSwitch
                            onValueChange={(value) => {
                                onValueChange('isDiscountActive', value, index);
                            }}
                            value={variation.isDiscountActive}
                        />
                        <CoreFormInputMoney
                            onSubmit={() => {
                                setIsEditMode(false);
                            }}
                            countryId={country.id}
                            disabled={!variation.isDiscountActive}
                            label={`Discount price ${variation.isDiscountActive ? 'active' : 'disabled'}`}
                            value={variation.discount ? variation.discount.toString() : ''}
                            type={InputType.number}
                            onValueChange={(value) => onValueChange('discount', value, index)}
                        />
                    </>
                )}
                {!isEditMode &&
                    variation.isDiscountActive &&
                    variation.discount !== undefined &&
                    variation.discount > 0 && (
                        <CoreChip
                            label={discount}
                            color={theme.palette.secondary.main}
                            onDelete={(event) => {
                                onValueChange('isDiscountActive', false, index);
                            }}
                            className={classes.priceTag}
                        />
                    )}
            </CoreFlex>

            <CoreFlex className={classes.miniField}>
                {isEditMode && (
                    <CoreFormInput
                        onSubmit={() => {
                            setIsEditMode(false);
                        }}
                        label="Quantity"
                        value={variation.quantity ? variation.quantity.toString() : ''}
                        type={InputType.number}
                        endAdornment={<Typography variant="subtitle1">pc</Typography>}
                        onValueChange={(value) => onValueChange('quantity', value, index)}
                    />
                )}

                {!isEditMode && variation.quantity !== undefined && variation.quantity > 0 && !variation.name && (
                    <Typography variant="subtitle1">{variation.quantity}pc</Typography>
                )}
            </CoreFlex>
            <CoreFlex className={classes.buttons} justify="flex-end">
                {isEditMode && (
                    <CoreIconButton
                        iconName="check"
                        onClick={(event) => {
                            event.stopPropagation();
                            setIsEditMode(false);
                        }}
                    />
                )}
                {!isEditMode && (
                    <CoreIconButton
                        iconName="edit"
                        onClick={(event) => {
                            event.stopPropagation();
                            setIsEditMode(true);
                        }}
                    />
                )}
                <CoreIconButton
                    iconName="delete"
                    onClick={(event) => {
                        event.stopPropagation();
                        onDelete(index);
                    }}
                />
            </CoreFlex>
        </CoreFlex>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:hover': {
                backgroundColor: grey[100],
            },
        },
        rootInactive: {
            opacity: 0.25,
        },
        smallField: {
            padding: '0.5rem 0.5rem',
            width: '250px',
        },
        miniField: {
            padding: '0.5rem 0.5rem',
            width: '150px',
        },
        formField: {
            display: 'flex',
            flexBasis: 0,
            flexGrow: 1,
            flexShrink: 1,
            padding: '0.5rem 1rem',
        },
        discountActive: {
            fontSize: '1rem',
            textDecoration: 'line-through',
        },
        priceTag: {
            fontSize: '1rem',
        },
        buttons: {
            opacity: 0.7,
            width: '150px',
            marginLeft: '1rem',
        },
        flag: {
            height: '25px',
        },
        imageUpload: {
            width: '150px',
            marginLeft: '1rem',
            marginBottom: '0.5rem',
        },
    }),
);
