import { RestaurantType } from './restaurant';
import { DbBase } from './common';

export interface Operator extends DbBase {
    ownerId?: number;
    name: string;
    legalName: string;
    registrationNumber: string;
    taxNumber: string;
    phone: string;
    address: string;
    email: string;
    ceoName: string;
    ceoPhone: string;
    ceoEmail: string;
    allowedRestaurantTypes: RestaurantType[];
    countries: number[];
    franchiseFee: number;
    priceClass: number;
    isFranchise: boolean;
    signedAt: Date;
}

export function getDefaultOperator(): Operator {
    return {
        name: '',
        legalName: '',
        registrationNumber: '',
        taxNumber: '',
        phone: '',
        address: '',
        email: '',
        ceoName: '',
        ceoPhone: '',
        ceoEmail: '',
        allowedRestaurantTypes: [],
        countries: [],
        franchiseFee: 0,
        priceClass: 0,
        isFranchise: true,
        signedAt: new Date(),
        isActive: true,
        isDeleted: false,
    };
}
