import React from 'react';
import { TableHead, TableRow, TableCell, Theme, Table, TableBody } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Order } from '../../../../../../../interfaces/order';
import OrderTableRowDetailsMoreEventsRow from './row/OrderTableRowDetailsMoreEventsRow';
import { grey } from '@material-ui/core/colors';

interface Props {
    order: Order;
}

interface StyleProps {
    bgColor: string;
}

export default function OrderTableRowDetailsMoreEvents({ order }: Props) {
    const classes = useStyles();

    return (
        <Table className={classes.root}>
            <TableHead>
                <TableRow className={classes.header}>
                    <TableCell id="index" className={classes.cell}>
                        #
                    </TableCell>
                    <TableCell id="status" className={classes.cell}>
                        EVENT
                    </TableCell>
                    <TableCell id="handledBy" className={classes.cell}>
                        HANDLED BY
                    </TableCell>
                    <TableCell id="createdAt" className={classes.cell}>
                        TIME & DURATION
                    </TableCell>
                    <TableCell id="comment" className={classes.cell}>
                        COMMENT
                    </TableCell>
                    <TableCell id="meta" className={classes.cell}>
                        META
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {order.events
                    ?.sort((a, b) =>
                        a.createdAt && b.createdAt
                            ? new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
                            : 0,
                    )
                    .map((item, index) => {
                        return (
                            <OrderTableRowDetailsMoreEventsRow
                                key={index}
                                index={index}
                                event={item}
                                nextEvent={order.events[index + 1]}
                            />
                        );
                    })}
            </TableBody>
        </Table>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderRadius: '1rem 1rem 0rem 0rem',
            marginBottom: '1.5rem',
        },
        header: {
            backgroundColor: theme.palette.background.default,
        },
        cell: {
            color: grey[700],
            paddingBottom: '0.5rem',
            paddingTop: '0.5rem',
            borderWidth: '0px',
        },
        topRowCell: {},
    }),
);
