export enum Weekday {
    MONDAY = 0,
    TUESDAY = 1,
    WEDNESDAY = 2,
    THURSDAY = 3,
    FRIDAY = 4,
    SATURDAY = 5,
    SUNDAY = 6,
    EVERY_DAY = 7,
    WEEKDAYS = 8,
    WEEKEND = 9,
}
