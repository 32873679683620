import React, { useState, useEffect, Dispatch, SetStateAction, useContext } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles, Theme } from '@material-ui/core';
import ReactCrop, { Crop } from 'react-image-crop';
import { ImageRequirements } from '../../../../interfaces';
import { processImageCrop, startUpload } from '../../../../services/utils';
import { AuthContext } from '../../../../context/AuthContext';
import CoreFlex from '../../flex/CoreFlex';
import CoreButton from '../../button/CoreButton';
import { grey } from '@material-ui/core/colors';

interface Props {
    requirements: ImageRequirements;
    croppedImageSrc?: string;
    setCroppedImageSrc: Dispatch<SetStateAction<string | undefined>>;
    file?: File;
    aspect: number;
    open: boolean;
    label: string;
    onUploadComplete: (url: string) => void;
    onClose: () => void;
}

export function CoreImageUploadCropDialog({
    requirements,
    aspect,
    file,
    label,
    open,
    onClose,
    onUploadComplete,
    croppedImageSrc,
    setCroppedImageSrc,
}: Props) {
    const classes = useStyles();
    const authContext = useContext(AuthContext);
    const [imageElement, setImageElement] = useState<HTMLImageElement | undefined>();
    const [initialCropSet, setInitialCropSet] = useState(false);
    const [crop, setCrop] = useState<Crop | undefined>();
    const [fileType, setFileType] = useState<string | undefined>();
    const [imageSrc, setImageSrc] = useState<string | undefined>();
    const [preCropSrc, setPreCropSrc] = useState<string | undefined>();
    const [fileName, setFileName] = useState<string | undefined>();

    useEffect(() => {
        if (file) {
            setCroppedImageSrc(undefined);
            setImageElement(undefined);
            setInitialCropSet(false);
            setFileName(file.name.slice(0, file.name.indexOf('.')));
            setFileType(file.type);
            setImageSrc(URL.createObjectURL(file));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [file]);

    useEffect(() => {
        if (imageSrc) {
            setPreCropSrc(croppedImageSrc);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageSrc]);

    useEffect(() => {
        if (imageElement) {
            setCrop({
                ...crop,
                aspect: aspect,
                width:
                    imageElement.height >= imageElement.width / aspect
                        ? imageElement.width
                        : imageElement.height * aspect,
                height:
                    imageElement.width >= imageElement.height * aspect
                        ? imageElement.height
                        : imageElement.width / aspect,
                x: 0,
                y: 0,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageElement]);

    useEffect(() => {
        if (crop && imageElement && !initialCropSet && (crop.width || crop.height)) {
            handleCropComplete(crop);
            setInitialCropSet(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [crop]);

    async function handleCropComplete(crop: Crop) {
        if (!imageElement) {
            return;
        }
        crop.unit = 'px';
        const imageUrl = await processImageCrop(
            imageElement,
            crop,
            fileName || '',
            requirements && requirements.destinationWidth,
            requirements && requirements.destinationHeight,
            fileType,
        );
        setCroppedImageSrc(imageUrl);
    }

    function handleCropImageLoaded(image: HTMLImageElement) {
        setImageElement(image);
    }

    function handleChangeCrop(crop: Crop) {
        if (imageElement && initialCropSet) {
            const scaleX = imageElement.naturalWidth / imageElement.width;
            const scaleY = imageElement.naturalHeight / imageElement.height;
            if (
                !requirements ||
                (!requirements.destinationHeight && !requirements.destinationWidth) ||
                (requirements.destinationHeight &&
                    crop.height &&
                    requirements.destinationHeight < crop.height * scaleY &&
                    requirements.destinationWidth &&
                    crop.width &&
                    requirements.destinationWidth < crop.width * scaleX)
            ) {
                setCrop(crop);
            }
        }
    }

    function handleCancelCrop() {
        setCroppedImageSrc(preCropSrc);
        onClose();
    }

    function handleConfirmCrop() {
        onClose();
        startUpload(onUploadComplete, authContext, undefined, croppedImageSrc, fileName, label);
    }

    return (
        <>
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>Please crop your image</DialogTitle>
                <DialogContent className={classes.root}>
                    <ReactCrop
                        src={imageSrc || ''}
                        crop={crop}
                        onImageLoaded={handleCropImageLoaded}
                        onComplete={handleCropComplete}
                        onChange={handleChangeCrop}
                    />
                </DialogContent>
                <DialogActions>
                    <CoreFlex justify="flex-end" fullWidth alignItems="center" className={classes.buttonBox}>
                        <CoreButton
                            onClick={handleCancelCrop}
                            customColor={grey[400]}
                            className={classes.button}
                            label="Cancel"
                        />
                        <CoreButton
                            onClick={handleConfirmCrop}
                            variant="contained"
                            className={classes.button}
                            label="Upload"
                        />
                    </CoreFlex>
                </DialogActions>
            </Dialog>
        </>
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    buttonBox: {
        margin: '0rem 2.5rem 1rem 2.5rem',
    },
    button: {
        marginRight: '1rem',
    },
    uploadButton: {
        backgroundColor: theme.palette.secondary.main,
    },
    cancelButton: {
        backgroundColor: theme.palette.grey[400],
    },
    root: {
        height: '500px',
    },
}));
