import { ApiResult } from './../interfaces/common';
import { stringify } from 'query-string';
import { AuthContextInterface } from '../context/AuthContext';

const host = process.env.REACT_APP_ENV === 'dev' ? '/api/' : process.env.REACT_APP_SERVER_HOST + '/api/';

export async function httpGet(
    url: string,
    query?: Record<string, any>,
    authContext?: Partial<AuthContextInterface>,
): Promise<ApiResult> {
    url = query ? `${url}?${stringify(query)}` : url;
    try {
        const response = await fetch(`${host}` + url, {
            method: 'GET',
            headers: createHeaders(authContext),
        });
        return handleResponse(response, authContext);
    } catch (err) {
        return {
            success: false,
            result: err,
        };
    }
}

export async function httpPost(
    url: string,
    data?: Record<string, any>,
    authContext?: Partial<AuthContextInterface>,
): Promise<ApiResult> {
    try {
        const response = await fetch(`${host}` + url, {
            method: 'POST',
            headers: createHeaders(authContext),
            body: JSON.stringify(data),
        });
        return handleResponse(response, authContext);
    } catch (err) {
        return {
            success: false,
            result: err,
        };
    }
}

export async function httpPostFile(url: string, data: FormData, authContext?: Partial<AuthContextInterface>) {
    try {
        const response = await fetch(`${host}` + url, {
            method: 'POST',
            headers: createHeaders(authContext, { withoutContentType: true }),
            body: data,
        });
        return handleResponse(response, authContext);
    } catch (error) {
        return {
            success: false,
            result: error,
        };
    }
}

export async function httpPut(
    url: string,
    data: Record<string, any>,
    authContext?: Partial<AuthContextInterface>,
): Promise<ApiResult> {
    try {
        const response = await fetch(`${host}` + url, {
            method: 'PUT',
            headers: createHeaders(authContext),
            body: JSON.stringify(data),
        });
        return handleResponse(response, authContext);
    } catch (err) {
        return {
            success: false,
            result: err,
        };
    }
}

export async function httpDelete(
    url: string,
    data: Record<string, any>,
    authContext?: Partial<AuthContextInterface>,
): Promise<ApiResult> {
    try {
        const response = await fetch(`${host}` + url, {
            method: 'DELETE',
            headers: createHeaders(authContext),
            body: JSON.stringify(data),
        });
        return handleResponse(response, authContext);
    } catch (err) {
        return {
            success: false,
            result: err,
        };
    }
}

export async function handleResponse(
    response: Response,
    authContext?: Partial<AuthContextInterface>,
): Promise<ApiResult> {
    let data: ApiResult = { success: false, headers: {}, result: undefined };

    try {
        if (response.body) {
            data = await response.json();
        }
    } catch (err) {
        data.result = err;
    }

    // Parse headers
    response.headers.forEach(function (value, name) {
        if (data.headers) {
            data.headers[name] = value;
        }
        // console.log('HEADERS', name, value);
        if (name === 'auth-token' && value.startsWith('Bearer ') && authContext && authContext.setAuthToken) {
            const trimmedToken = value.split(' ')[1].trim();
            authContext.setAuthToken(trimmedToken);
        }
    });
    return data;
}

export function createHeaders(authContext?: Partial<AuthContextInterface>, options?: Record<string, any>) {
    const headers: Record<string, string> = {};
    const token = authContext && authContext.authToken;
    if (token) {
        headers['auth-token'] = 'Bearer ' + token;
    }
    if (!options || (options && !options.withoutContentType)) {
        headers['Content-type'] = 'application/json; charset=utf-8';
    }
    headers['Accept'] = 'application/json';
    return headers;
}
