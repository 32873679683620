import { ApiResult } from './../interfaces/common';
import { User, UserProfile } from './../interfaces/user';
import { httpGet, httpPut, httpDelete } from '../services/api';
import { AuthContextInterface } from '../context/AuthContext';
import { showError, showSuccess } from '../services/alert';

export async function getUserById(id: number, authContext?: Partial<AuthContextInterface>): Promise<User | undefined> {
    try {
        const response = await httpGet(`user/get/${id}`, {}, authContext);
        if (response.success && response.result) {
            const user: User = response.result;
            return user;
        } else {
            showError("Something's wrong: " + response.message);
            return undefined;
        }
    } catch (err) {
        showError('Error retriveing users: ' + err);
        return undefined;
    }
}

export async function getUserList(authContext?: Partial<AuthContextInterface>): Promise<UserProfile[]> {
    try {
        const response = await httpGet('user/list', {}, authContext);
        if (response.success && response.result) {
            const users: UserProfile[] = response.result;
            return users;
        } else {
            showError("Something's wrong: " + response.message);
            return [];
        }
    } catch (err) {
        showError('Error retriveing users: ' + err);
        return [];
    }
}

export async function getUsers(
    query?: Record<string, any>,
    authContext?: Partial<AuthContextInterface>,
): Promise<User[] | User> {
    try {
        const response = await httpGet('user/find', query, authContext);
        if (response.success && response.result) {
            const users: User[] = response.result;
            return users;
        } else {
            showError("Something's wrong: " + response.message);
            return [];
        }
    } catch (err) {
        showError('Error retriveing users: ' + err);
        return [];
    }
}

export async function updateUser(
    id: number,
    data: Record<string, any>,
    authContext?: Partial<AuthContextInterface>,
): Promise<ApiResult> {
    try {
        const response = await httpPut(`user/update/${id}`, data, authContext);
        if (response.success && response.result) {
            showSuccess(`User ${id} updated successfully`);
            return response;
        } else {
            showError("Something's wrong: " + response.message);
            return response;
        }
    } catch (err) {
        showError('Error updating user: ' + err);
        throw err;
    }
}

export async function removeUser(id: number, authContext?: Partial<AuthContextInterface>) {
    try {
        const response = await httpDelete(`user/remove/${id}`, {}, authContext);
        if (response.success && response.result) {
            showSuccess(`User ${id} deleted successfully`);
            return response;
        } else {
            showError("Something's wrong: " + response.message);
            return response;
        }
    } catch (err) {
        showError('Error deleting user: ' + err);
        return err;
    }
}
