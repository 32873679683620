import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Paper, Theme, Typography } from '@material-ui/core';
import CoreFormInput from '../../../components/core/form/input/CoreFormInput';
import { InputType, ValidationType, AlertMessage } from '../../../interfaces';
import { makeStyles, createStyles } from '@material-ui/styles';
import CoreFormSelect from '../../../components/core/form/select/CoreFormSelect';
import { useStore } from '../../../services/store';
import { stores } from '../../../stores';
import RestaurantFormOpeningHours from '../../../components/restaurant/form/opening-hours/RestaurantFormOpeningHours';
import { AuthContext } from '../../../context/AuthContext';
import CoreFlex from '../../../components/core/flex/CoreFlex';
import { CoreMap } from '../../../components/core/map/CoreMap';
import { ViewportProps, Marker, DragEvent } from 'react-map-gl';
import { CoreMapPin } from '../../../components/core/map/pin/CoreMapPin';
import CoreFormInputAddress, { AddressResult } from '../../../components/core/form/input/address/CoreFormInputAddress';
import { grey } from '@material-ui/core/colors';
import {
    updateRestaurant,
    createRestaurant,
    removeRestaurant,
    getRestaurantById,
} from '../../../microservices/restaurant';
import { validate } from '../../../services/validation';
import CoreCrudToolbar from '../../../components/core/crud-toolbar/CoreCrudToolbar';
import { CoreImageUpload } from '../../../components/core/image-upload/CoreImageUpload';
import { getRoute, useRouter } from '../../../services/router';
import { ROUTES } from '../../../routes';
import { CoreSnackbar } from '../../../components/core/snackbar/CoreSnackbar';
import { Country } from '../../../interfaces/country';
import {
    getDefaultRestaurant,
    Restaurant,
    RestaurantHour,
    RestaurantTranslation,
} from '../../../interfaces/restaurant';
import { Language } from '../../../config/translation';
import CoreTranslatedInput from '../../../components/core/form/input/translated/CoreTranslatedInput';
import { Operator } from '../../../interfaces/operator';
import { getOperators } from '../../../microservices/operator';
import { UserProfile } from '../../../interfaces/user';
import { getUserList } from '../../../microservices/user';
import { prepareObjectForCloning } from '../../../services/utils';

export default function RestaurantDetailPage() {
    const [showMessage, setShowMessage] = useState<AlertMessage | undefined>();
    const authContext = useContext(AuthContext);
    const { navigateTo, params } = useRouter();
    const classes = useStyles();
    const [operators, setOperators] = useState<Operator[]>([]);
    const [users, setUsers] = useState<UserProfile[]>([]);
    const [restaurant, setRestaurant] = useStore<Restaurant>(stores.selectedRestaurant);
    const [countries] = useStore(stores.countries);
    const [selectedCountry, setSelectedCountry] = useStore<Country | null>(stores.selectedCountry);
    const [restaurants] = useStore<Restaurant[]>(stores.restaurants);
    const [language, setLanguage] = useStore<Language>(stores.language);
    const translation: RestaurantTranslation | null = language ? restaurant?.translations?.[language] || null : null;
    const [viewport, setViewport] = useState({
        latitude: (restaurant && restaurant.latitude) || 0,
        longitude: (restaurant && restaurant.longitude) || 0,
        zoom: 14,
        bearing: 0,
        pitch: 0,
    });

    const validators = {
        name: () => {
            return validate({
                type: ValidationType.Name,
                value: translation?.name,
            });
        },
        email: () => {
            return validate({
                type: ValidationType.Email,
                value: restaurant.email,
            });
        },
        phone: () => {
            return validate({
                type: ValidationType.Phone,
                value: restaurant.phone,
            });
        },
        photo: () => {
            return {
                validated: restaurant.photos && restaurant.photos.length,
                message: 'Please upload a restaraunt image.',
            };
        },
        country: () => {
            return {
                validated: Boolean(restaurant.countryId),
                message: 'Please choose a restaurant country.',
            };
        },
        operator: () => {
            return {
                validated: Boolean(restaurant.operatorId),
                message: 'Please select a restaraunt operator.',
            };
        },
        manager: () => {
            return {
                validated: Boolean(restaurant.operatorId),
                message: 'Please select a restaraunt manager.',
            };
        },
    };

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setViewport({
            ...viewport,
            latitude: restaurant.latitude,
            longitude: restaurant.longitude,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restaurant.latitude, restaurant.longitude]);

    async function loadData() {
        const restaurantId = parseInt(params.restaurantId);
        const cloneId = parseInt(params.cloneId);
        if (restaurantId) {
            const selectedRestaurant =
                restaurants.find((item: Restaurant) => {
                    return item.id === restaurantId;
                }) || (await getRestaurantById(params.restaurantId, authContext));

            setRestaurant(selectedRestaurant || getDefaultRestaurant());
        }

        if (cloneId) {
            const clonedRestaurant = await getRestaurantById(cloneId, authContext);

            if (clonedRestaurant) {
                const cleanedUpRestaurant = prepareObjectForCloning<Restaurant>(clonedRestaurant, {
                    elements: ['hours'],
                    subExtraElements: ['restaurantId'],
                });
                console.log('Cleaned up', cleanedUpRestaurant);
                setRestaurant(cleanedUpRestaurant || getDefaultRestaurant());
            }
        }

        const [users, operators] = await Promise.all([getUserList(authContext), getOperators(authContext)]);

        setOperators(operators || []);
        setUsers(users || []);
    }

    const handleValueChange = useCallback(
        (
            name: keyof Restaurant | keyof RestaurantTranslation,
            value: string | number | boolean | string[] | undefined,
            language?: Language,
        ) => {
            if (language) {
                setRestaurant({
                    ...restaurant,
                    translations: {
                        ...restaurant.translations,
                        [language]: {
                            ...restaurant.translations?.[language],
                            [name]: value,
                        },
                    },
                });
                return;
            }

            setRestaurant({
                ...restaurant,
                [name]: value,
            });
        },
        [restaurant],
    );

    const handleImageUploaded = useCallback(
        (imageUrl: string) => {
            const newPhotos = [...restaurant.photos];
            newPhotos.push(imageUrl);
            handleValueChange('photos', newPhotos);
        },
        [restaurant],
    );

    const handleUpdateOpeningHours = useCallback(
        (hours: RestaurantHour[]) => {
            setRestaurant({
                ...restaurant,
                hours: hours,
            });
        },
        [restaurant],
    );

    function handleUpdateViewport(viewPort: ViewportProps) {
        setViewport(viewPort);
    }

    const handleUpdateLocation = useCallback(
        (location: AddressResult) => {
            const country = countries.find((country: Country) => country.name === location.country);
            setRestaurant({
                ...restaurant,
                latitude: location.lat,
                longitude: location.long,
                address: location.title,
                countryId: country?.id || restaurant.countryId,
            });
        },
        [restaurant],
    );

    const handleUpdateLatLong = useCallback(
        (latitude: number, longitude: number) => {
            setRestaurant({
                ...restaurant,
                latitude,
                longitude,
            });
        },
        [restaurant],
    );

    function handleMarkerDragEnd(event: DragEvent) {
        handleUpdateLatLong(event.lngLat[1], event.lngLat[0]);
    }

    const handleSaveRestaurant = useCallback(async () => {
        if (
            !validators.name().validated ||
            !validators.email().validated ||
            !validators.phone().validated ||
            !validators.photo().validated ||
            !validators.country().validated ||
            !validators.operator().validated
        ) {
            setShowMessage({
                type: 'error',
                message: 'Validation problem. Please make sure all the fields are filled in properly.',
            });
            return;
        }

        const response = restaurant.id
            ? await updateRestaurant(restaurant.id, restaurant, authContext)
            : await createRestaurant(restaurant, authContext);

        if (response && response.success && response.result) {
            navigateTo(getRoute(ROUTES.restaurant));
        } else {
            setShowMessage({
                type: 'error',
                message: `${response.message}`,
            });
        }
    }, [restaurant, validators]);

    const handleDeleteRestaurant = useCallback(async () => {
        const response = restaurant.id ? await removeRestaurant(restaurant.id, authContext) : { success: true };

        if (response.success) {
            navigateTo(getRoute(ROUTES.restaurant));
        } else {
            setShowMessage({
                type: 'error',
                message: `Error: ${response ? response.message : 'Unable to connect with server'}`,
            });
        }
    }, [restaurant]);

    const handleCountryChange = useCallback(
        (countryId: number) => {
            const country = countries.find((country: Country) => country.id === countryId);
            handleValueChange('countryId', countryId);
            setSelectedCountry(country || null);
        },
        [countries],
    );

    function handleCancel() {
        navigateTo(getRoute(ROUTES.restaurant));
    }

    return (
        <form autoComplete="off">
            <CoreCrudToolbar
                isClone={params.cloneId}
                object={restaurant}
                objectName="Restaurant"
                onCancel={handleCancel}
                onSave={handleSaveRestaurant}
                onDelete={handleDeleteRestaurant}
                languages={selectedCountry?.languages || []}
                onChangeLanguage={setLanguage}
                allowDelete={true}
                translationObject={restaurant.translations}
                selectedLanguage={language}
                translationKeys={['name'] as (keyof RestaurantTranslation)[]}
                onToggleVisibility={() => {
                    handleValueChange('isActive', !restaurant.isActive);
                }}
            />

            <Paper className={classes.paper}>
                <CoreFlex direction="column" justify="space-between">
                    <Typography variant="h6" className={classes.formField}>
                        Restaurant details
                    </Typography>
                    <CoreFlex alignItems="flex-start">
                        <CoreFlex direction="column">
                            <CoreFlex className={classes.formField}>
                                <CoreFormSelect
                                    value={restaurant.countryId || ''}
                                    label="Country"
                                    disabled={!authContext.isAdmin}
                                    validation={validators.country}
                                    onValueChange={(value) => handleCountryChange(value as number)}
                                    options={
                                        countries
                                            ? countries.map((country: Country) => {
                                                  return {
                                                      value: country.id,
                                                      label: country.name,
                                                  };
                                              })
                                            : []
                                    }
                                />
                                <CoreFlex className={classes.spacing}>{``}</CoreFlex>
                                <CoreFormSelect
                                    fullWidth
                                    disabled={!authContext.isAdmin}
                                    validation={validators.operator}
                                    value={restaurant.operatorId || ''}
                                    onValueChange={(value) => handleValueChange('operatorId', value as number)}
                                    label="Operator"
                                    options={operators.map((operator: Operator) => {
                                        return {
                                            value: operator.id,
                                            label: `${operator.id}: ${operator.name}`,
                                        };
                                    })}
                                />
                                <CoreFlex className={classes.spacing}>{``}</CoreFlex>
                                <CoreFormSelect
                                    fullWidth
                                    disabled={!authContext.isOwner}
                                    validation={validators.manager}
                                    value={restaurant.managerId || ''}
                                    onValueChange={(value) => handleValueChange('managerId', value as number)}
                                    label="Manager"
                                    options={users.map((user: UserProfile) => {
                                        return {
                                            value: user.id,
                                            image: user.photo,
                                            label: `${user.id}: ${user.firstName} ${user.lastName}`,
                                        };
                                    })}
                                />
                            </CoreFlex>
                            <CoreFlex className={classes.formField}>
                                <CoreTranslatedInput
                                    translationObject={restaurant.translations || {}}
                                    keyName="name"
                                    language={language}
                                    validation={validators.name}
                                    autoFocus
                                    required
                                    label="Restaurant name"
                                    value={translation?.name || ''}
                                    type={InputType.text}
                                    onValueChange={(value) => handleValueChange('name', value, language)}
                                    placeholder="Please enter a restaurant name"
                                />
                                <CoreFlex className={classes.spacing}>{``}</CoreFlex>

                                <CoreFormInput
                                    validation={validators.email}
                                    label="Email"
                                    required
                                    value={restaurant.email}
                                    type={InputType.email}
                                    onValueChange={(value) => handleValueChange('email', value)}
                                />
                                <CoreFlex className={classes.spacing}>{``}</CoreFlex>

                                <CoreFormInput
                                    validation={validators.phone}
                                    label="Phone number"
                                    required
                                    value={restaurant.phone}
                                    type={InputType.phone}
                                    onValueChange={(value) => handleValueChange('phone', value)}
                                    placeholder="Enter phone number"
                                />
                            </CoreFlex>
                            <CoreFlex className={classes.formField}>
                                <CoreTranslatedInput
                                    translationObject={restaurant.translations || {}}
                                    keyName={'description' as keyof RestaurantTranslation}
                                    language={language}
                                    className={classes.descriptionBox}
                                    multiline
                                    rows={5}
                                    label="Description"
                                    value={translation?.description || ''}
                                    type={InputType.text}
                                    onValueChange={(value) => handleValueChange('description', value, language)}
                                />
                            </CoreFlex>
                        </CoreFlex>
                        <CoreImageUpload
                            className={classes.imageUpload}
                            label="Restaurant image"
                            aspect={1 / 1}
                            width={300}
                            basePath="restaurant"
                            height={300}
                            imageUrl={restaurant.photos.length ? restaurant.photos[0] : ''}
                            onUploadComplete={handleImageUploaded}
                        />
                    </CoreFlex>
                    <CoreFlex justify="space-between" className={classes.formField}>
                        <CoreFormInputAddress
                            onChangeCoordinates={handleUpdateLocation}
                            value={restaurant.address}
                            onValueChange={(value: string) => handleValueChange('address', value)}
                        />
                    </CoreFlex>
                    <div className={classes.mapContainer}>
                        <CoreMap viewport={viewport} onViewportChange={handleUpdateViewport}>
                            <Marker
                                longitude={restaurant.longitude}
                                latitude={restaurant.latitude}
                                offsetTop={-20}
                                offsetLeft={-10}
                                draggable
                                onDragEnd={handleMarkerDragEnd}
                            >
                                <CoreMapPin size={30} />
                            </Marker>
                        </CoreMap>
                    </div>
                </CoreFlex>
            </Paper>

            <Paper className={classes.paper}>
                <CoreFlex className={classes.formField} justify="flex-start">
                    <Typography variant="h6">Opening hours</Typography>
                </CoreFlex>
                <CoreFlex className={classes.formField} direction="column">
                    <RestaurantFormOpeningHours
                        openingHours={restaurant.hours || []}
                        onUpdateHours={handleUpdateOpeningHours}
                        restaurantId={restaurant.id}
                    />
                </CoreFlex>
            </Paper>

            {showMessage && (
                <CoreSnackbar
                    variant={showMessage.type}
                    message={showMessage.message}
                    onClose={() => setShowMessage(undefined)}
                />
            )}
        </form>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolbar: {
            backgroundColor: grey[300],
            margin: '2rem',
            padding: '2rem',
        },
        formField: {
            padding: '0.5rem 1rem',
            minWidth: '300px',
            width: '100%',
        },
        imageUpload: {
            margin: '0.5rem 1rem',
        },
        countrySelect: {
            padding: '0.5rem 1rem',
            minWidth: '250px',
        },
        paper: {
            margin: '2rem',
            padding: '2rem',
        },
        descriptionBox: {
            width: '100%',
            height: '200px',
        },
        mapContainer: {
            padding: '1rem 1rem',
            zIndex: 500,
            width: '100%',
            height: '400px',
        },
        spacing: {
            width: '2rem',
        },
    }),
);
