import { io, Socket } from 'socket.io-client/build/index';
import { SocketChannel } from '../interfaces/socket';
import { AuthContextInterface } from '../context/AuthContext';
import { cloneDeep } from 'lodash';
import { Order } from '../interfaces/order';

export enum SocketChannelName {
    AUTH = 'auth',
    ORDER = 'order',
}

export const socket: Socket = io({
    transports: ['websocket'],
    path: '/socket',
});

export const channels: Record<string, SocketChannel> = {};

export function initSocket() {
    socket.on('connect', handleConnect);
    socket.on('disconnect', handleDisconnect);
    socket.on('connect_error', () => {
        setTimeout(() => {
            socket.connect();
        }, 1000);
    });
}

export function handleConnect() {
    console.log('Websocket connection established');
}

export function handleDisconnect() {
    setTimeout(() => {
        socket.connect();
    }, 1000);
    console.log('Connection closed');
}

export function addNewChannel(
    name: string,
    onMessage: (payload: Record<string, any>) => void,
    authContext: Partial<AuthContextInterface>,
): SocketChannel {
    if (channels[name]) {
        return channels[name];
    }
    const newChannel: SocketChannel = {
        channelName: name,
        sendMessage: (payload: Record<string, any>) => {
            sendSocketMessage(name, payload);
        },
        onClose: () => removeChannel(name),
    };
    socket.on(name, (value: Record<string, any>) => {
        // console.log('Message came in', name, value);
        onMessage(value);
    });
    channels[name] = newChannel;
    console.log('New channel added');
    return newChannel;
}

export function removeChannel(name: string): SocketChannel | null {
    const removedChannel = cloneDeep(channels[name]) || null;
    socket.off(name);
    delete channels[name];
    return removedChannel;
}

export function sendSocketMessage(channel: string, message: Record<string, any>) {
    socket.emit(channel, message);
}
