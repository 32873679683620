import React, { useState, useEffect, useContext } from 'react';
import { useTheme, createStyles } from '@material-ui/styles';
import { TableColumnType } from '../../interfaces';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { getCountries, removeCountry, updateCountry } from '../../microservices/country';
import CoreTableSimple from '../../components/core/table/simple/CoreTableSimple';
import { AuthContext } from '../../context/AuthContext';
import { useRouter, getRoute } from '../../services/router';
import { ROUTES } from '../../routes';
import { useStore } from '../../services/store';
import { stores } from '../../stores';
import { CoreLoader } from '../../components/core/loader/CoreLoader';
import { objectsEqual } from '../../services/utils';
import { Country } from '../../interfaces/country';
import { isArray } from 'lodash';
import CoreFlex from '../../components/core/flex/CoreFlex';
import { CoreFlagElement } from '../../components/core/flag-element/CoreFlagElement';
import { Language } from '../../config/translation';
import { formatPrice } from '../../services/helpers';

export default function CountryPage() {
    const { navigateTo } = useRouter();
    const authContext = useContext(AuthContext);
    const theme = useTheme();
    const [countries, setCountries] = useStore<Country[]>(stores.countries);
    const [isLoading, setIsLoading] = useState(false);

    const classes = useStyles(theme);

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function loadData() {
        setIsLoading(true);
        const result = await getCountries(authContext);
        if (isArray(result) && !objectsEqual(countries, result)) {
            setCountries(result);
        }
        setIsLoading(false);
    }

    function handleAddNew() {
        navigateTo(getRoute(ROUTES.country.new));
    }

    function handleEditCountry(id: number) {
        navigateTo(getRoute(ROUTES.country.edit, { countryId: id }));
    }

    async function handleToggleVisibility(value: string, country: Country) {
        if (!country.id) {
            return;
        }
        const updatedCountries: Country[] = [];
        countries.forEach((item) => {
            if (item.id === country.id) {
                item.isActive = !country.isActive;
            }
            updatedCountries.push(item);
        });
        await updateCountry(country.id, { isActive: country.isActive }, authContext);
        setCountries(updatedCountries);
    }

    function formatLanguages(languages: Language[]): JSX.Element {
        return (
            <CoreFlex justify="flex-start">
                {languages.map((language) => {
                    return <CoreFlagElement key={language} language={language} className={classes.languageFlag} />;
                })}
            </CoreFlex>
        );
    }

    async function handleRemoveCountry(id: number) {
        await removeCountry(id, authContext);
        setCountries(countries.filter((item) => item.id !== id));
    }

    function handleCloneCountry(id: number) {
        navigateTo(getRoute(ROUTES.country.clone, { cloneId: id }));
    }

    return (
        <>
            <Typography variant="h5" className={classes.title}>
                Country management
            </Typography>
            {isLoading && <CoreLoader type="circular" text="Loading countries..." />}
            {!isLoading && (
                <CoreTableSimple
                    rowHeight={135}
                    onAddNew={handleAddNew}
                    hover
                    onClickRow={() => {}}
                    onClone={handleCloneCountry}
                    onRemove={handleRemoveCountry}
                    onEdit={handleEditCountry}
                    objectName="country"
                    data={countries}
                    columns={[
                        {
                            label: 'Visible',
                            column: 'isActive',
                            type: TableColumnType.Switch,
                            className: classes.switch,
                            action: handleToggleVisibility,
                        },
                        {
                            label: 'Flag',
                            column: 'flag',
                            type: TableColumnType.Image,
                            className: classes.flag,
                        },
                        { label: 'Name', column: 'name' },
                        { label: 'Short', column: 'shortName' },
                        { label: 'Currency', column: 'currencyName' },
                        { label: 'Symbol', column: 'currencySymbol' as keyof Country },
                        {
                            label: 'Delivery base',
                            column: 'deliveryBasePrice' as keyof Country,
                            formatFunction: (value, country) => {
                                return <>{formatPrice(value, country)}</>;
                            },
                        },
                        {
                            label: 'Delivery km',
                            column: 'deliveryPerKmPrice' as keyof Country,
                            formatFunction: (value, country) => {
                                return <>{formatPrice(value, country)}</>;
                            },
                        },
                        { label: 'Tax rate', column: 'taxRate' as keyof Country },
                        { label: 'Timezone', column: 'timeZone' },
                        { label: 'Phone prefix', column: 'phonePrefix' as keyof Country },
                        { label: 'Languages', column: 'languages' as keyof Country, formatFunction: formatLanguages },
                    ]}
                />
            )}
        </>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            flexGrow: 1,
            margin: theme.spacing(1),
        },
        tableWrapper: {
            maxHeight: '85vh',
            overflow: 'auto',
        },
        flag: {
            maxWidth: '3rem',
        },
        switch: {},
        tableHeader: {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.common.white,
        },
        title: {
            marginBottom: '1rem',
        },
        languageFlag: {
            maxWidth: '1.5rem',
            marginRight: '0.5rem',
        },
    }),
);
