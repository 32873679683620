import { showError } from '../services/alert';
import { stores } from '../stores';
import { IpLocation } from './../interfaces/location';
export async function getUserLocationFromIp(): Promise<IpLocation | null> {
    try {
        const response = await fetch('https://extreme-ip-lookup.com/json/');
        const ipLocation = await response.json();
        stores.ipLocation.set(ipLocation);
        return ipLocation;
    } catch (error) {
        showError('Error getting country from user IP: ' + error);
    }
    return null;
}
