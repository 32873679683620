import React, { useState, useContext } from 'react';
import logo from '../../../assets/logo/munchies_logo.png';
import { Grid, Dialog, DialogTitle, Grow, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { Redirect } from 'react-router';
import { AuthContext } from '../../../context/AuthContext';
import { AlertMessage, ValidationType } from '../../../interfaces';
import { useDeviceType } from '../../../services/utils';
import { forgotPassword } from '../../../microservices/auth';
import { CoreSnackbar } from '../../../components/core/snackbar/CoreSnackbar';
import { LoginForgotPassword } from '../../../components/login/ForgotPassword/ForgotPassword';
import { validate } from '../../../services/validation';
import { useRouter } from '../../../services/router';

/**
|--------------------------------------------------
| Forgot password box component
|--------------------------------------------------
*/

const Transition: React.ComponentType<any> = React.forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} />;
});

export default function AuthForgotPasswordPage(): JSX.Element {
    const { navigateTo } = useRouter();

    const [showMessage, setShowMessage] = useState<AlertMessage | undefined>();
    const authContext = useContext(AuthContext);
    const [email, setEmail] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);
    const classes = useStyles();
    const { isMobile } = useDeviceType();

    if (authContext.loggedIn) {
        return <Redirect to="/" />;
    }

    const forgotPasswordHandler = async () => {
        const { validated } = validate({
            type: ValidationType.Email,
            value: email,
        });

        if (!validated) {
            return;
        }

        setIsLoading(true);

        const response = await forgotPassword({ email });

        setIsLoading(false);

        if (!response) {
            setShowMessage({
                type: 'error',
                message: 'Error: Unable to connect to the server.',
            });
            return;
        }

        if (!response.success) {
            setShowMessage({
                type: 'error',
                message: `Recover password failed. ${response.message}`,
            });
            return;
        }

        navigateTo('/');
    };

    return (
        <>
            <Dialog
                PaperProps={{
                    className: isMobile ? classes.dialogPaperMobile : classes.dialogPaperDesktop,
                }}
                open
                fullWidth
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="xs"
                TransitionComponent={Transition}
                transitionDuration={2500}
            >
                <Grid
                    container
                    justify="space-around"
                    alignContent="space-around"
                    alignItems="center"
                    direction="column"
                    className={classes.formContainer}
                >
                    <Grid item className={classes.padding}>
                        <img src={logo} width={isMobile ? 150 : 150} alt="Munchies logo" />
                    </Grid>
                    {!isMobile && (
                        <Grid item>
                            <DialogTitle className={classes.dilogTitle}>
                                Enter your email address to recover your Munchies back office password
                            </DialogTitle>
                        </Grid>
                    )}
                    {!isLoading ? (
                        <LoginForgotPassword email={email} setEmail={setEmail} handler={forgotPasswordHandler} />
                    ) : (
                        <Grid item>
                            <CircularProgress />
                        </Grid>
                    )}
                </Grid>
            </Dialog>
            {showMessage && (
                <CoreSnackbar
                    variant={showMessage.type}
                    message={showMessage.message}
                    onClose={() => setShowMessage(undefined)}
                />
            )}
        </>
    );
}

const useStyles = makeStyles({
    dialogPaperDesktop: {
        minHeight: '70vh',
        maxHeight: '70vh',
    },
    dialogPaperMobile: {
        minHeight: '100vh',
        maxHeight: '100vh',
        maxWidth: '100vw',
        minWidth: '100vw',
        overflowY: 'hidden',
    },
    padding: {
        padding: '1rem',
    },

    dilogTitle: {
        // backgroundColor: 'yellow'
    },

    logo: {
        textAlign: 'center',
    },

    formContainer: {
        position: 'absolute',
        height: '100%',
        display: 'flex',
        top: '0px',
        bottom: '0px',
        width: '100%',
    },
    gridItem: {
        height: '100%',
    },
});
