import React from 'react';
import { NavLink } from 'react-router-dom';
import { NavigationItem } from '../../../../../interfaces';
import { ListItem, ListItemIcon, Icon, ListItemText, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';

interface Props {
    item: NavigationItem;
}

export default function CoreNavigationDrawerItem({ item }: Props) {
    const classes = useStyles();

    return (
        <NavLink to={item.path} className={classes.menuItem}>
            <ListItem button>
                <ListItemIcon>
                    <Icon className={classes.menuItem}>{item.icon}</Icon>
                </ListItemIcon>
                <ListItemText primary={item.title} />
            </ListItem>
        </NavLink>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menuItem: {
            textDecoration: 'none!important',
            color: theme.palette.common.white,
        },
    }),
);
