import React, { createElement, MouseEvent, ChangeEvent, useState } from 'react';
import {
    Paper,
    Table,
    TableBody,
    createStyles,
    makeStyles,
    Theme,
    TableRow,
    TableCell,
    Switch,
    Typography,
} from '@material-ui/core';
import CoreTableHeader from '../header/CoreTableHeader';
import { TableColumn, TableColumnType } from '../../../../interfaces';
import clsx from 'clsx';
import CoreButton from '../../button/CoreButton';
import moment from 'moment';
import { stores, useStore } from '../../../../stores';
import { getTranslation, Language } from '../../../../config/translation';
import { CoreIconButton } from '../../icon-button/CoreIconButton';
import { grey } from '@material-ui/core/colors';
import { useCoreAnimations } from '../../../../config/styles/animations';
import { CoreConfirmation } from '../../confirmation/CoreConfirmation';

interface Props {
    data: any[];
    columns: TableColumn[];
    onClickRow?: (id: number) => void;
    objectName?: string;
    onAddNew?: () => void;
    rowHeight?: number;
    hover?: boolean;
    onClone?: (id: number) => void;
    onEdit?: (id: number) => void;
    onRemove?: (id: number) => void;
}

interface StyleProps {
    rowHeight?: number;
}

export default function CoreTableSimple({
    data,
    columns,
    onClickRow,
    onAddNew,
    onEdit,
    onRemove,
    onClone,
    hover,
    rowHeight,
    objectName = 'object',
}: Props) {
    const classes = useStyles({ rowHeight });
    const [language] = useStore<Language>(stores.language);
    const [deletedObjectId, setDeletedObjectId] = useState<number | null>(null);
    const deletedObject = deletedObjectId ? data.find((object) => object.id === deletedObjectId) : null;

    function handleClick(event: MouseEvent<HTMLTableRowElement>) {
        onClickRow && onClickRow(parseInt(event.currentTarget.id));
    }

    function handleSwitchChange(event: ChangeEvent<HTMLInputElement>, column: TableColumn, object: any) {
        column.action && column.action(event.target.value, object);
    }

    function handleClickSwitch(event: MouseEvent<HTMLButtonElement>) {
        event.stopPropagation();
    }

    function getCellContent(object: any, column: TableColumn): any {
        switch (column.type) {
            case undefined:
            case TableColumnType.Text:
                return object[column.column];
            case TableColumnType.Image:
                if (!object[column.column]) {
                    return;
                }
                return <img className={column.className} alt={column.label} src={object[column.column]} />;
            case TableColumnType.Element:
                return createElement(object[column.column]);
            case TableColumnType.Switch:
                return (
                    <Switch
                        className={classes.switch}
                        checked={object[column.column]}
                        value={object[column.column]}
                        onClick={handleClickSwitch}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            handleSwitchChange(event, column, object);
                        }}
                    />
                );
            case TableColumnType.Date:
                return <Typography> {moment(object[column.column]).format('DD.MM.YYYY HH:mm')}</Typography>;
            case TableColumnType.Translation:
                return <Typography> {object.translations?.[language]?.[column.column]}</Typography>;
            default:
                return;
        }
    }

    return (
        <>
            {onAddNew && <CoreButton label="Add new" onClick={onAddNew} className={classes.button} />}
            {deletedObjectId && onRemove && (
                <CoreConfirmation
                    variant="error"
                    onClose={() => setDeletedObjectId(null)}
                    onCancel={() => setDeletedObjectId(null)}
                    onConfirm={() => {
                        onRemove(deletedObjectId);
                        setDeletedObjectId(null);
                    }}
                    title={`WARNING: You are about to delete a ${objectName}`}
                    open={Boolean(deletedObjectId)}
                    message={`Are you sure you want to delete the ${
                        deletedObject?.translations ? getTranslation(deletedObject, language) : deletedObject?.name
                    }? This action is permanent and unreversable.`}
                />
            )}
            <Paper className={classes.content}>
                <div className={classes.tableWrapper}>
                    <Table>
                        <CoreTableHeader columns={columns} actions={Boolean(onEdit || onRemove || onClone)} />
                        {data && data.length > 0 && (
                            <TableBody>
                                {data.map((object: any, index: number) => {
                                    return (
                                        <TableRow
                                            id={object.id}
                                            key={index}
                                            // hover
                                            onClick={handleClick}
                                            className={clsx(
                                                classes.row,
                                                !object.isActive && classes.disabled,
                                                hover && classes.hover,
                                            )}
                                        >
                                            {columns.map((column: TableColumn, index) => {
                                                const cellContent = getCellContent(object, column);
                                                if (column.invisible) {
                                                    return null;
                                                }
                                                return (
                                                    <TableCell
                                                        padding={
                                                            column.type === TableColumnType.Switch
                                                                ? 'checkbox'
                                                                : 'default'
                                                        }
                                                        className={clsx(column.className, classes.cell)}
                                                        key={index}
                                                    >
                                                        {column.formatFunction
                                                            ? column.formatFunction(cellContent, object)
                                                            : cellContent}
                                                    </TableCell>
                                                );
                                            })}
                                            {(onRemove || onEdit || onClone) && (
                                                <TableCell align="center" className={classes.actions}>
                                                    {onClone && (
                                                        <CoreIconButton
                                                            toolTip={`Clone ${objectName}`}
                                                            iconName="content_copy"
                                                            className={clsx(
                                                                classes.iconButton,
                                                                classes.animationScale1_2x,
                                                            )}
                                                            onClick={() => onClone(object.id)}
                                                        />
                                                    )}
                                                    {onEdit && (
                                                        <CoreIconButton
                                                            toolTip={`Edit ${objectName}`}
                                                            iconName="create"
                                                            className={clsx(
                                                                classes.iconButton,
                                                                classes.animationScale1_2x,
                                                            )}
                                                            onClick={() => onEdit(object.id)}
                                                        />
                                                    )}
                                                    {onRemove && (
                                                        <CoreIconButton
                                                            toolTip={`Delete ${objectName}`}
                                                            iconName="delete"
                                                            className={clsx(
                                                                classes.iconButton,
                                                                classes.animationScale1_2x,
                                                            )}
                                                            onClick={() => setDeletedObjectId(object.id)}
                                                        />
                                                    )}
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        )}
                    </Table>
                </div>
            </Paper>
        </>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        row: ({ rowHeight }: StyleProps) => ({
            height: rowHeight || 'unset',
        }),
        content: {
            flexGrow: 1,
            margin: theme.spacing(0),
        },
        tableWrapper: {
            overflow: 'auto',
        },
        image: {
            // marginRight: "2rem"
        },
        cell: {
            // margin: "0.5rem 0rem",
            padding: '0.5rem',
        },
        switch: {
            margin: '0rem',
        },
        button: {
            marginBottom: '1rem',
        },
        actions: ({ rowHeight }: StyleProps) => ({
            width: '200px',
            padding: '0.5rem',
            color: theme.palette.grey[700],
            height: 'inherit',
            position: 'relative',
            backgroundColor: grey[200],
        }),
        iconButton: {
            padding: '0.5rem',
            margin: '0.5rem',
        },
        clearMargin: {
            margin: 0,
            padding: 0,
            height: '100%',
        },
        disabled: {
            opacity: 0.5,
        },
        hover: {
            '&:hover': {
                backgroundColor: `rgba(0,0,0,0.02)`,
            },
        },
        ...useCoreAnimations(theme),
    }),
);
