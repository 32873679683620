import { httpPostFile } from '../services/api';
import { AuthContextInterface } from '../context/AuthContext';
import { showError, showSuccess } from '../services/alert';

export async function uploadFile(
    file: File,
    basePath?: string,
    authContext?: Partial<AuthContextInterface>,
): Promise<string> {
    try {
        const formData = new FormData();
        formData.append('file', file);
        if (basePath) {
            formData.append('basePath', basePath);
        }

        const response = await httpPostFile('upload', formData, authContext);
        if (response.success) {
            showSuccess('File upload successful: ' + response.message);
            return response.result.filePath || 'no url';
        }
        return 'no url';
    } catch (err) {
        showError('Error while uploading file:' + err);
        return err;
    }
}
