import { Operator } from './../interfaces/operator';
import { httpGet, httpPost, httpPut, httpDelete } from '../services/api';
import { AuthContextInterface } from '../context/AuthContext';
import { showError, showSuccess } from '../services/alert';
import { ApiResult } from '../interfaces/common';

export async function getOperatorById(
    id: number,
    authContext?: Partial<AuthContextInterface>,
): Promise<Operator | null> {
    try {
        const response = await httpGet(`operator/get/${id}`, {}, authContext);
        if (response.success && response.result) {
            const operator: Operator = response.result;
            return operator;
        } else {
            showError("Something's wrong: " + response.message);
            return null;
        }
    } catch (err) {
        showError('Error retriveing operators: ' + err);
        return null;
    }
}

export async function getOperators(authContext?: Partial<AuthContextInterface>): Promise<Operator[]> {
    try {
        const response: any = await httpGet('operator/find', {}, authContext);
        if (response.success && response.result) {
            const operators: Operator[] = response.result;
            return operators;
        } else {
            showError('Error retriveing operators: ' + (response.message || response.name));
            return [];
        }
    } catch (err) {
        showError('Error retriveing operators: ' + err);
        return [];
    }
}

export async function createOperator(
    operatorData: Partial<Operator>,
    authContext?: Partial<AuthContextInterface>,
): Promise<ApiResult | null> {
    try {
        const response = await httpPost('operator/new', operatorData, authContext);
        if (response.success && response.result) {
            showSuccess(`Operator added successfully: ` + response.message);
            return response;
        } else {
            showError("Something's wrong: " + response.message);
            return response;
        }
    } catch (err) {
        showError('Error when creating a operator:' + err);
        return null;
    }
}

export async function updateOperator(
    id: number,
    updatedData: Partial<Operator>,
    authContext?: Partial<AuthContextInterface>,
) {
    try {
        const response = await httpPut(`operator/update/${id}`, updatedData, authContext);
        if (response.success && response.result) {
            showSuccess(`Operator ${id} ${response.result.name} updated successfully`);
            return response;
        } else {
            showError("Something's wrong: " + response.message);
            return response;
        }
    } catch (err) {
        showError('Error when updating a operator: ' + err);
        return err;
    }
}

export async function removeOperator(id: number, authContext?: Partial<AuthContextInterface>) {
    try {
        const response = await httpDelete(`operator/remove/${id}`, {}, authContext);
        if (response.success && response.result) {
            showSuccess(`Operator ${id} deleted successfully`);
            return response;
        } else {
            showError("Something's wrong: " + response.message);
            return response;
        }
    } catch (err) {
        showError('Error when deleting a operator: ' + err);
        return err;
    }
}
