import React, { useState, useContext, useEffect } from 'react';
import { Grid, Paper, Theme, Typography } from '@material-ui/core';
import CoreFormInput from '../../../components/core/form/input/CoreFormInput';
import { InputType, ValidationType, AlertMessage } from '../../../interfaces';
import { makeStyles, createStyles } from '@material-ui/styles';
import { useStore } from '../../../services/store';
import { stores } from '../../../stores';
import { AuthContext } from '../../../context/AuthContext';
import CoreFlex from '../../../components/core/flex/CoreFlex';
import { validate } from '../../../services/validation';
import CoreCrudToolbar from '../../../components/core/crud-toolbar/CoreCrudToolbar';
import CoreFormSelect from '../../../components/core/form/select/CoreFormSelect';
import { CoreImageUpload } from '../../../components/core/image-upload/CoreImageUpload';
import { useRouter, getRoute } from '../../../services/router';
import { ROUTES } from '../../../routes';
import { updateUser, removeUser, getUserById } from '../../../microservices/user';
import { CoreSnackbar } from '../../../components/core/snackbar/CoreSnackbar';
import CoreChipUserType from '../../../components/core/chip/user-type/CoreChipUserType';
import { Restaurant } from '../../../interfaces/restaurant';
import { User, UserType } from '../../../interfaces/user';
import { Operator } from '../../../interfaces/operator';
import { getOperators } from '../../../microservices/operator';
import { findAvailableLanguage } from '../../../config/translation';
import { isWorker } from 'cluster';

export default function UsersDetailsPage() {
    const { navigateTo, params } = useRouter();
    const authContext = useContext(AuthContext);
    const [showMessage, setShowMessage] = useState<AlertMessage | undefined>();
    const classes = useStyles();
    const [user, setUser] = useState<User>();
    const [operators, setOperators] = useState<Operator[]>([]);
    const [restaurants] = useStore(stores.restaurants);

    const validators = {
        firstName: () => {
            return validate({
                type: ValidationType.Name,
                value: user?.firstName,
            });
        },
        lastName: () => {
            return validate({
                type: ValidationType.Name,
                value: user?.lastName,
            });
        },
        alias: () => {
            return validate({
                type: ValidationType.Text,
                value: user?.alias,
            });
        },
        email: () => {
            return validate({
                type: ValidationType.Email,
                value: user?.email,
            });
        },
        phone: () => {
            return validate({
                type: ValidationType.Phone,
                value: user?.phone,
            });
        },
        restaurantId: () => {
            return {
                validated: Boolean(user?.restaurantId) || Boolean(user?.operatorId),
                message: 'Please choose either Operator or Restaurant to connect with the user',
                optional: Boolean(user?.operatorId),
            };
        },
        operatorId: () => {
            return {
                validated: Boolean(user?.operatorId) || Boolean(user?.restaurantId),
                message: 'Please choose either Operator or Restaurant to connect with the user',
                optional: Boolean(user?.restaurantId),
            };
        },
        role: () => {
            return validate({
                type: ValidationType.Text,
                value: user?.role,
                optional: true,
            });
        },
    };

    useEffect(() => {
        if (params.userId) {
            loadData(params.userId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function loadData(id: number) {
        const user = await getUserById(id, authContext);
        if (user) {
            setUser(user);
        }
        if (authContext.isAdmin) {
            setOperators(await getOperators(authContext));
        }
    }

    function handleValueChange(name: keyof User, value: string | number | boolean) {
        if (user) {
            setUser({
                ...user,
                [name]: value,
            });
        }
    }

    function handleImageUploaded(imageUrl: string) {
        handleValueChange('photo', imageUrl);
    }

    async function handleSaveUser() {
        if (!user || !user.id) {
            return;
        }

        if (
            !validators.firstName().validated ||
            !validators.lastName().validated ||
            !validators.alias().validated ||
            !validators.email().validated ||
            !validators.phone().validated ||
            !validators.restaurantId().validated ||
            !validators.role().validated ||
            !validators.operatorId().validated
        ) {
            setShowMessage({
                type: 'error',
                message: 'Please fill all the form fields with correct data.',
            });
            return;
        }

        const response = await updateUser(user.id, user, authContext);

        if (response && response.success) {
            navigateTo(getRoute(ROUTES.user));
        } else {
            setShowMessage({
                type: 'error',
                message: `ERROR: ${response.message}`,
            });
        }
    }

    async function handleDeleteUser() {
        if (!user) {
            return;
        }
        if (user.id && authContext.isAdmin) {
            await removeUser(user.id, authContext);
        }
        navigateTo(getRoute(ROUTES.user));
    }

    function handleCancel() {
        navigateTo(getRoute(ROUTES.user));
    }

    function promoteUser(type: UserType) {
        handleValueChange('type', type);
    }

    return (
        <form autoComplete="off">
            {user && (
                <>
                    <Typography variant="h5" className={classes.title}>
                        User profile
                    </Typography>
                    <CoreCrudToolbar
                        allowDelete={authContext.isAdmin}
                        switchKey="isActive"
                        switchDisabled={!authContext.isManager}
                        object={user}
                        objectName="User"
                        onCancel={handleCancel}
                        onSave={handleSaveUser}
                        onDelete={handleDeleteUser}
                        onToggleVisibility={() => {
                            handleValueChange('isActive', !user.isActive);
                        }}
                    />

                    <Paper className={classes.paper}>
                        <Grid container direction="column" justify="space-between">
                            <Grid item className={classes.formField}>
                                <Typography variant="h6">User details</Typography>
                            </Grid>
                            <CoreFlex alignItems="flex-start">
                                <CoreFlex direction="column">
                                    <CoreFlex>
                                        <CoreFormInput
                                            className={classes.formField}
                                            validation={validators.firstName}
                                            autoFocus
                                            required
                                            label="First name"
                                            value={user.firstName || ''}
                                            type={InputType.text}
                                            onValueChange={(value) => handleValueChange('firstName', value)}
                                            placeholder="Please enter a users first name"
                                        />
                                        <CoreFormInput
                                            className={classes.formField}
                                            validation={validators.lastName}
                                            required
                                            label="Last name"
                                            value={user.lastName || ''}
                                            type={InputType.text}
                                            onValueChange={(value) => handleValueChange('lastName', value)}
                                            placeholder="Please enter a users last name"
                                        />
                                        <CoreFormInput
                                            className={classes.formField}
                                            validation={validators.alias}
                                            label="Alias"
                                            value={user.alias || ''}
                                            type={InputType.text}
                                            onValueChange={(value) => handleValueChange('alias', value)}
                                            placeholder="Please enter an alias"
                                        />
                                    </CoreFlex>
                                    <CoreFlex>
                                        <CoreFormInput
                                            className={classes.formField}
                                            validation={validators.email}
                                            label="Email"
                                            placeholder="Enter users email"
                                            required
                                            value={user.email}
                                            type={InputType.email}
                                            onValueChange={(value) => handleValueChange('email', value)}
                                        />
                                        <CoreFormInput
                                            className={classes.formField}
                                            validation={validators.phone}
                                            label="Phone"
                                            required
                                            value={user.phone || ''}
                                            type={InputType.text}
                                            onValueChange={(value) => handleValueChange('phone', value)}
                                            placeholder="Enter phone number"
                                        />
                                    </CoreFlex>
                                    <CoreFlex justify="space-between">
                                        <CoreFlex className={classes.smallField}>
                                            <CoreChipUserType userType={user.type} className={classes.userType} />
                                        </CoreFlex>
                                        <CoreFormInput
                                            className={classes.formField}
                                            validation={validators.role}
                                            label="Role"
                                            value={user.role || ''}
                                            type={InputType.text}
                                            onValueChange={(value) => handleValueChange('role', value)}
                                            placeholder="Please enter users role"
                                        />
                                    </CoreFlex>
                                    <CoreFlex>
                                        {authContext.isOwner && (
                                            <CoreFormSelect
                                                fullWidth
                                                className={classes.formField}
                                                value={user.restaurantId || ''}
                                                validation={validators.restaurantId}
                                                onValueChange={(value) =>
                                                    handleValueChange('restaurantId', value as number)
                                                }
                                                label="Restaurant"
                                                options={restaurants.map((restaurant: Restaurant) => {
                                                    return {
                                                        value: restaurant.id,
                                                        label: `Munchies ${
                                                            restaurant.translations?.[
                                                                findAvailableLanguage(restaurant.translations || {}) ||
                                                                    'en'
                                                            ]?.name
                                                        }`,
                                                    };
                                                })}
                                            />
                                        )}
                                        {authContext.isAdmin && (
                                            <>
                                                <Typography variant="subtitle1" className={classes.or}>
                                                    OR
                                                </Typography>
                                                <CoreFormSelect
                                                    fullWidth
                                                    className={classes.formField}
                                                    validation={validators.operatorId}
                                                    value={user.operatorId || ''}
                                                    onValueChange={(value) =>
                                                        handleValueChange('operatorId', value as number)
                                                    }
                                                    label="Operator"
                                                    options={operators.map((operator: Operator) => {
                                                        return {
                                                            value: operator.id,
                                                            label: `${operator.id}: ${operator.name}`,
                                                        };
                                                    })}
                                                />
                                            </>
                                        )}
                                    </CoreFlex>
                                </CoreFlex>
                                <CoreImageUpload
                                    className={classes.imageUpload}
                                    label="Profile picture"
                                    aspect={1 / 1}
                                    basePath="profile"
                                    width={200}
                                    height={200}
                                    imageUrl={user.photo}
                                    onUploadComplete={handleImageUploaded}
                                    requirements={{
                                        destinationWidth: 200,
                                        destinationHeight: 200,
                                    }}
                                />
                            </CoreFlex>
                            {authContext.isManager && (
                                <CoreFlex direction="column" className={classes.promoteBox}>
                                    <Typography variant="h6" className={classes.promoteTitle}>
                                        Promote / Demote user
                                    </Typography>
                                    <CoreFlex justify="center">
                                        {authContext.isManager && user.type !== UserType.CUSTOMER && (
                                            <CoreChipUserType
                                                className={classes.chip}
                                                userType={UserType.CUSTOMER}
                                                onClick={() => promoteUser(UserType.CUSTOMER)}
                                            ></CoreChipUserType>
                                        )}
                                        {authContext.isManager && user.type !== UserType.COURIER && (
                                            <CoreChipUserType
                                                className={classes.chip}
                                                userType={UserType.COURIER}
                                                onClick={() => promoteUser(UserType.COURIER)}
                                            ></CoreChipUserType>
                                        )}
                                        {authContext.isManager && user.type !== UserType.STAFF && (
                                            <CoreChipUserType
                                                className={classes.chip}
                                                userType={UserType.STAFF}
                                                onClick={() => promoteUser(UserType.STAFF)}
                                            />
                                        )}
                                        {authContext.isOwner && user.type !== UserType.MANAGER && (
                                            <CoreChipUserType
                                                className={classes.chip}
                                                userType={UserType.MANAGER}
                                                onClick={() => promoteUser(UserType.MANAGER)}
                                            />
                                        )}
                                        {authContext.isAdmin && user.type !== UserType.OWNER && (
                                            <CoreChipUserType
                                                className={classes.chip}
                                                userType={UserType.OWNER}
                                                onClick={() => promoteUser(UserType.OWNER)}
                                            />
                                        )}
                                        {authContext.isSuper && user.type !== UserType.ADMIN && (
                                            <CoreChipUserType
                                                className={classes.chip}
                                                userType={UserType.ADMIN}
                                                onClick={() => promoteUser(UserType.ADMIN)}
                                            />
                                        )}
                                        {authContext.isSuper && user.type !== UserType.SUPER_ADMIN && (
                                            <CoreChipUserType
                                                className={classes.chip}
                                                userType={UserType.SUPER_ADMIN}
                                                onClick={() => promoteUser(UserType.SUPER_ADMIN)}
                                            />
                                        )}
                                    </CoreFlex>
                                </CoreFlex>
                            )}
                        </Grid>
                    </Paper>
                    {showMessage && (
                        <CoreSnackbar
                            variant={showMessage.type}
                            message={showMessage.message}
                            onClose={() => setShowMessage(undefined)}
                        />
                    )}
                </>
            )}
        </form>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formField: {
            padding: '0.5rem 0.5rem',
            minWidth: '300px',
            width: '100%',
        },
        select: {
            width: '100%',
        },
        userType: {
            width: '170px',
        },
        smallField: {
            padding: '0.5rem 0.5rem',
            width: '300px',
        },
        priceSample: {
            margin: '1rem 1rem',
            padding: '1rem 1rem',
            maxWidth: '300px',
            minWidth: '300px',
            borderRadius: '8px',
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
        },
        switch: {
            minWidth: '100px',
            padding: '0.5rem 1rem',
            textAlign: 'center',
        },
        imageUpload: {
            margin: '1rem 1rem',
        },
        paper: {
            margin: '2rem',
            padding: '2rem',
        },
        promoteBox: {
            padding: '2rem',
        },
        promoteTitle: {
            padding: '2rem',
        },
        chip: {
            margin: '0rem 1rem',
        },
        title: {
            marginBottom: '1rem',
        },
        or: {
            margin: '0rem 1rem',
        },
    }),
);
