import React, { useContext, useEffect, useState } from 'react';
import CoreTableSimple from '../../core/table/simple/CoreTableSimple';
import { useRouter, getRoute } from '../../../services/router';
import { ROUTES } from '../../../routes';
import { TableColumnType } from '../../../interfaces';
import { removeProduct, updateProduct } from '../../../microservices/product';
import { AuthContext } from '../../../context/AuthContext';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Theme, Typography } from '@material-ui/core';
import CoreChip from '../../core/chip/CoreChip';
import { grey } from '@material-ui/core/colors';
import moment from 'moment';
import CoreFlex from '../../core/flex/CoreFlex';
import { Category } from '../../../interfaces/category';
import { Product } from '../../../interfaces/product';
import { showError } from '../../../services/alert';
import { getTranslation, Language } from '../../../config/translation';
import { stores, useStore } from '../../../stores';
import { useCoreAnimations } from '../../../config/styles/animations';
import { formatPrice, getMinMaxFromArray } from '../../../services/helpers';
import { Country } from '../../../interfaces/country';

interface Props {
    products: Product[];
    onUpdate: (products: Product[]) => void;
}

export default function ProductTable({ products, onUpdate }: Props) {
    const { navigateTo } = useRouter();
    const classes = useStyles();
    const authContext = useContext(AuthContext);
    const [language] = useStore<Language>(stores.language);
    const [categories] = useStore<Category[]>(stores.categories);
    const [filterCategory, setFilterCategory] = useStore<Category>(stores.filterCategory);
    const [country] = useStore<Country>(stores.selectedCountry);
    const [filteredProducts, setFilteredProducts] = useStore<Product[]>(stores.filteredProducts);

    useEffect(() => {
        handleFilterByCategory(filterCategory?.id || null);
    }, [filterCategory]);

    async function handleToggleVisibility(value: string, product: Product) {
        if (!product.id) {
            return;
        }
        const response = await updateProduct(product.id, { isActive: !product.isActive }, authContext);
        if (response) {
            const updatedProducts: Product[] = [];
            products.forEach((item: Product) => {
                if (item.id === product.id) {
                    item.isActive = !product.isActive;
                }
                updatedProducts.push(item);
            });
            onUpdate(updatedProducts);
        } else {
            showError(`ERROR: ${response}`);
        }
    }

    function formatCategory(categoryIds: number[]): JSX.Element {
        const displayCategories: { object: Category; name: string }[] = [];
        categories.forEach((item: Category) => {
            if (item.id && categoryIds.includes(item.id)) {
                displayCategories.push({ name: getTranslation(item, language) || '', object: item });
            }
        });
        return (
            <CoreFlex direction="column" alignItems="flex-start">
                {displayCategories.map((item, index: number) => {
                    return (
                        <CoreChip
                            onClick={() => setFilterCategory(item.object)}
                            label={item.name}
                            key={index}
                            color={grey[500]}
                            className={classes.animationScale1_5x}
                        />
                    );
                })}
            </CoreFlex>
        );
    }

    function handleEditProduct(id: number) {
        navigateTo(getRoute(ROUTES.product.edit, { productId: id }));
    }

    function formatDate(value: any): JSX.Element {
        const date = value ? moment(value).format('DD.MMM.YYYY hh:mm') : '';
        return <>{date}</>;
    }

    function formatName(value: string, product: Product): JSX.Element {
        return <Typography>{`${getTranslation(product, language)}`}</Typography>;
    }

    function formatDescription(value: string, product: Product): JSX.Element {
        return (
            <Typography variant="caption" className={classes.description}>{`${getTranslation(
                product,
                language,
                'description',
            )}`}</Typography>
        );
    }

    function formatPriceRange(value: string, product: Product): JSX.Element {
        const variations = product.variations?.filter((item) => item.isActive);
        const { min, max } = getMinMaxFromArray(variations || [], 'price');
        return (
            <CoreFlex>
                <Typography>{`${formatPrice(min, country)} ${max !== min ? ' - ' : ''} ${
                    max !== min ? formatPrice(max, country) : ''
                }`}</Typography>
            </CoreFlex>
        );
    }

    function handleFilterByCategory(categoryId: number | null) {
        if (!categoryId) {
            setFilteredProducts(products);
            return;
        }
        setFilteredProducts(products.filter((item) => item.categoryIds.includes(categoryId)));
    }

    async function handleRemoveProduct(id: number) {
        await removeProduct(id, authContext);
        onUpdate(products.filter((item) => item.id !== id));
    }

    function handleCloneProduct(id: number) {
        console.log('ID coming in', id);
        navigateTo(getRoute(ROUTES.product.clone, { cloneId: id }));
    }

    return (
        <CoreTableSimple
            hover
            onClickRow={() => {}}
            onEdit={handleEditProduct}
            onRemove={handleRemoveProduct}
            objectName="product"
            onClone={handleCloneProduct}
            data={filteredProducts}
            columns={[
                {
                    label: 'Visible',
                    column: 'isActive' as keyof Product,
                    type: TableColumnType.Switch,
                    action: handleToggleVisibility,
                    invisible: !authContext.isAdmin && !authContext.isManager,
                },
                {
                    label: 'Image',
                    column: 'photo' as keyof Product,
                    type: TableColumnType.Image,
                },
                { label: 'Name', column: 'name' as keyof Product, formatFunction: formatName },
                {
                    label: 'Description',
                    column: 'description' as keyof Product,
                    formatFunction: formatDescription,
                    className: classes.description,
                },
                {
                    label: 'Category',
                    column: 'categoryIds' as keyof Product,
                    formatFunction: formatCategory,
                },
                {
                    label: 'Price range',
                    column: 'name' as keyof Product,
                    formatFunction: formatPriceRange,
                    className: classes.date,
                },
                {
                    label: 'Modified',
                    column: 'updatedAt' as keyof Product,
                    formatFunction: formatDate,
                    className: classes.date,
                },
            ]}
        />
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        image: {
            maxWidth: '150px',
            paddingRight: '2rem',
        },
        date: {
            textAlign: 'center',
        },
        disabled: {
            opacity: 0.77,
        },
        description: {
            maxWidth: '250px',
        },
        ...useCoreAnimations(theme),
    }),
);
