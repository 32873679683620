import { cloneDeep, min } from 'lodash';

export enum Language {
    ENGLISH = 'en',
    ESTONIAN = 'et',
    RUSSIAN = 'ru',
    FINNISH = 'fi',
    ARABIC = 'ar',
    SWEDISH = 'sw',
    DEUTCH = 'de',
    FRENCH = 'fr',
    SPANISH = 'sp',
    ITALIAN = 'it',
    JAPANESE = 'jp',
    THAI = 'th',
    CHINESE = 'ch',
    INDIAN = 'in',
    VIETNAMESE = 'vt',
    KOREAN = 'ko',
    NORWAY = 'no',
}

export const TranslationFlag: Record<Language, string> = {
    [Language.ENGLISH]: '/assets/flags/united-kingdom-uk.svg',
    [Language.ESTONIAN]: '/assets/flags/estonia-ee.svg',
    [Language.RUSSIAN]: '/assets/flags/russia-ru.svg',
    [Language.FINNISH]: '/assets/flags/finland-fi.svg',
    [Language.FRENCH]: '/assets/flags/france-fr.svg',
    [Language.INDIAN]: '/assets/flags/india-in.svg',
    [Language.ITALIAN]: '/assets/flags/italy-it.svg',
    [Language.JAPANESE]: '/assets/flags/japan-jp.svg',
    [Language.KOREAN]: '/assets/flags/south-korea-kr.svg',
    [Language.SPANISH]: '/assets/flags/spain-es.svg',
    [Language.SWEDISH]: '/assets/flags/sweden-se.svg',
    [Language.THAI]: '/assets/flags/thailand-th.svg',
    [Language.VIETNAMESE]: '/assets/flags/vietnam-vn.svg',
    [Language.NORWAY]: '/assets/flags/norway-no.svg',
    [Language.ARABIC]: '/assets/flags/uae-ae.svg',
    [Language.DEUTCH]: '/assets/flags/germany-de.svg',
    [Language.CHINESE]: '/assets/flags/china-cn.svg',
};

export function getLanguageName(language: Language) {
    return (
        Object.keys(Language)
            .filter((key) => Language[key as keyof typeof Language] === language)
            .toString()
            .toLowerCase() || 'N/A'
    );
}

export function findAvailableLanguage(translations: Partial<Record<Language, any>>): Language | null {
    let response = null;
    const languages = Object.keys(translations) as Language[];
    if (translations.en) {
        response = Language.ENGLISH;
    } else if (languages.length) {
        response = languages[0];
    }
    return response;
}

export function getTranslation(object: any, language: Language, column = 'name'): string {
    if (!object) {
        return '';
    }
    const allLanguages = Object.keys(object.translations);
    const fallBackLanguage = object.translations.en ? Language.ENGLISH : object.translations[allLanguages[0]];
    return object.translations[language]?.[column] || object.translations[fallBackLanguage]?.[column];
}

export function getTranslatedObjectUpdate<T>(object: T, name: keyof T, value: string, language: Language) {
    const newObject: any = cloneDeep(object);
    return {
        ...newObject,
        translations: {
            ...newObject.translations,
            [language]: {
                ...(newObject.translations?.[language] || {}),
                [name]: value,
            },
        },
    };
}
