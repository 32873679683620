import { Language } from '../config/translation';
import { DbBase, TranslationObjectBase } from './common';

export interface Country extends DbBase {
    flag?: string;
    languages: Language[];
    shortName?: string;
    currencySymbol: string;
    currencyName: string;
    phonePrefix: string;
    timeZone: string;
    deliveryBasePrice: number;
    deliveryPerKmPrice: number;
    taxRate: number;
    isSymbolAtFront: boolean;
    isShowingDecimals: boolean;
    isUsingComa: boolean;
    translations?: Partial<Record<Language, CountryTranslation>>;
}

export interface CountryTranslation extends TranslationObjectBase {
    name: string;
}

export function getDefaultCountry(): Country {
    return {
        name: '',
        flag: undefined,
        languages: [Language.ENGLISH],
        shortName: '',
        phonePrefix: '',
        currencySymbol: '',
        currencyName: '',
        isSymbolAtFront: false,
        isShowingDecimals: true,
        isUsingComa: false,

        timeZone: '',
        deliveryBasePrice: 0,
        deliveryPerKmPrice: 0,
        taxRate: 0,

        isActive: true,
        isDeleted: false,
        createdAt: new Date(),
        updatedAt: new Date(),
        translations: { en: { name: '' } },
    };
}
