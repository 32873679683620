import React, { useState, useEffect } from 'react';
import CoreFlex from '../../../core/flex/CoreFlex';
import { Typography, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { useStore } from '../../../../services/store';
import { stores } from '../../../../stores';
import CoreButton from '../../../core/button/CoreButton';
import ProductVariationEditorRow from './row/ProductVariationEditorRow';
import { Country } from '../../../../interfaces/country';
import { Restaurant } from '../../../../interfaces/restaurant';
import { getDefaultProductVariation, Product, ProductVariation } from '../../../../interfaces/product';
import { getTranslatedObjectUpdate, Language } from '../../../../config/translation';
import { cloneDeep } from 'lodash';

interface Props {
    restaurant: Restaurant;
    product: Product;
    onUpdate: (variations: ProductVariation[]) => void;
}

export default function ProductVariationEditor({ product, restaurant, onUpdate }: Props) {
    const classes = useStyles();
    const [country] = useStore<Country>(stores.selectedCountry);

    useEffect(() => {
        if (product && (!product.variations || !product.variations.length)) {
            onUpdate([getDefaultProductVariation(product.id)]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function handleValueChange(
        name: keyof ProductVariation,
        value: string | boolean | number,
        index: number,
        language?: Language,
    ) {
        if (language && product.variations?.length) {
            const newVariations = cloneDeep(product.variations) || [];
            newVariations[index] = getTranslatedObjectUpdate<any>(newVariations?.[index], name, `${value}`, language);
            onUpdate(newVariations || []);
            return;
        }

        if (['price', 'discount'].includes(name) && value < 0) {
            return;
        }

        if (product && product.variations && product.variations.length > index) {
            const newVariations: ProductVariation[] = product.variations;
            const variation: any = newVariations[index];
            variation[name] = ['price', 'discount'].includes(name) ? parseInt(value as string) : value;
            onUpdate(newVariations);
        }
    }

    function handleAddVariation() {
        onUpdate((product.variations || []).concat([getDefaultProductVariation(product.id)]));
    }

    function handleDeleteVariation(index: number) {
        if ((product.variations || []).length < 2) {
            return;
        }
        onUpdate((product.variations || []).filter((v, i) => index !== i));
    }

    return (
        <>
            <CoreFlex justify="space-between" className={classes.cardHeader}>
                <Typography variant="h6">Prices and Variations</Typography>
                <CoreButton label="ADD VARIATION" onClick={handleAddVariation} />
            </CoreFlex>
            <CoreFlex direction="column">
                {product &&
                    country &&
                    product.variations?.length &&
                    product.variations.map((variation: ProductVariation, index: number) => {
                        return (
                            <ProductVariationEditorRow
                                key={index}
                                index={index}
                                variation={variation}
                                onDelete={handleDeleteVariation}
                                onValueChange={handleValueChange}
                                country={country}
                            />
                        );
                    })}
            </CoreFlex>
        </>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardHeader: {
            padding: '1rem 0.5rem',
        },
    }),
);
