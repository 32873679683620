import React, { useState } from 'react';
import { TableHead, TableRow, TableCell, Theme, Typography, Tooltip, Modal } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { getOrderStatusName, OrderEvent, OrderStatus } from '../../../../../../../../interfaces/order';
import { grey } from '@material-ui/core/colors';
import { COLOR } from '../../../../../../../../services/createTheme';
import clsx from 'clsx';
import { useStore } from '../../../../../../../../services/store';
import { stores } from '../../../../../../../../stores';
import { User } from '../../../../../../../../interfaces/user';
import CoreFlex from '../../../../../../../core/flex/CoreFlex';
import moment from 'moment';
import { formatTimeDifference } from '../../../../../../../../services/utils';
import { CoreIconButton } from '../../../../../../../core/icon-button/CoreIconButton';
import ReactModal from 'react-modal-resizable-draggable';
import { DuxPanel } from 'duxpanel';
import Draggable, { DraggableCore } from 'react-draggable';

interface Props {
    event: OrderEvent;
    nextEvent?: OrderEvent;
    index: number;
}

interface StyleProps {
    bgColor: string;
}

export default function OrderTableRowDetailsMoreEventsRow({ event, index, nextEvent }: Props) {
    const classes = useStyles();
    const [team] = useStore<Partial<User>[]>(stores.team);
    const [isShowingMeta, setIsShowingMeta] = useState<boolean>(false);
    const handler = team.find((item) => item.id === event.handledBy);
    const duration = event.createdAt ? formatTimeDifference(event.createdAt, nextEvent?.createdAt) : null;
    const metaObject = event.meta ? JSON.parse(event.meta) : null;

    let colorClass;
    switch (event.status) {
        case OrderStatus.CREATED:
        case OrderStatus.ITEMS_IN_BASKET:
            colorClass = classes.lightGrey;
            break;
        case OrderStatus.COMPLETED:
        case OrderStatus.CHECKOUT:
            colorClass = classes.darkGrey;
            break;
        case OrderStatus.RECEIVED:
            colorClass = classes.blue;
            break;
        case OrderStatus.COOKING:
            colorClass = classes.orange;
            break;
        case OrderStatus.READY:
            colorClass = classes.green;
            break;
        case OrderStatus.PICKED_UP:
            colorClass = classes.purple;
            break;
        case OrderStatus.DELIVERED:
            colorClass = classes.darkGreen;
            break;
        case OrderStatus.REFUNDED:
        case OrderStatus.COURIER_NO_SHOW:
        case OrderStatus.RESTAURANT_NO_SHOW:
        case OrderStatus.CUSTOMER_NO_SHOW:
        case OrderStatus.ABANDONED:
        case OrderStatus.CANCELLED:
            colorClass = classes.red;
            break;
    }

    function handleShowMeta() {
        setIsShowingMeta(!isShowingMeta);
    }

    return (
        <TableRow className={classes.row}>
            <TableCell id="number" className={classes.cell}>
                <Typography>{index}</Typography>
            </TableCell>
            <TableCell id="waiting" className={classes.cell}>
                <div className={clsx(classes.status, colorClass)}>
                    <Typography>{getOrderStatusName(event.status).replaceAll('_', ' ')}</Typography>
                </div>
            </TableCell>
            <TableCell id="handledBy" className={clsx(classes.cell, classes.handledBy)}>
                <Typography>{`${handler?.firstName} ${handler?.lastName}`}</Typography>
            </TableCell>
            <TableCell id="customer" className={clsx(classes.cell, classes.time)}>
                {moment(event.createdAt).format('HH:mm')}{' '}
                {duration && event.status !== OrderStatus.DELIVERED ? `(${duration})` : ''}
            </TableCell>
            <TableCell id="items" className={classes.cell}>
                {event.comment || '-'}
            </TableCell>
            <TableCell id="total" className={classes.cell}>
                {Boolean(event.meta) && (
                    <CoreIconButton
                        toolTip="Meta information"
                        iconName="info"
                        onClick={handleShowMeta}
                        size="small"
                        className={classes.meta}
                    />
                )}
                {isShowingMeta && metaObject && (
                    <Draggable>
                        <Modal
                            className={classes.metaModal}
                            open={isShowingMeta}
                            onClose={() => setIsShowingMeta(false)}
                            title="Meta information"
                            disablePortal
                        >
                            <div className={classes.metaContainer}>
                                <CoreFlex direction="column">
                                    {Object.keys(metaObject).map((item, index) => {
                                        return (
                                            <CoreFlex
                                                justify="space-between"
                                                key={index}
                                                className={clsx(
                                                    classes.metaRow,
                                                    index % 2 ? classes.metaHighlight : '',
                                                )}
                                            >
                                                <CoreFlex className={classes.metaKey} justify="flex-start">
                                                    <Typography>
                                                        <strong>{item}</strong>
                                                    </Typography>
                                                </CoreFlex>
                                                <CoreFlex justify="flex-end">
                                                    <Typography>{JSON.stringify(metaObject[item]) || ''}</Typography>
                                                </CoreFlex>
                                            </CoreFlex>
                                        );
                                    })}
                                </CoreFlex>
                            </div>
                        </Modal>
                    </Draggable>
                )}
            </TableCell>
        </TableRow>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        row: {
            '&:hover': {
                backgroundColor: 'rgba(0,0,0, 0.02)',
            },
        },
        cell: {
            paddingBottom: '0.5rem',
            paddingTop: '0.5rem',
            borderWidth: '0px',
        },
        lightGrey: {
            backgroundColor: grey[300],
            color: grey[700],
        },
        darkGrey: {
            backgroundColor: grey[500],
        },
        blue: {
            backgroundColor: COLOR.MunchiesBlue,
        },
        orange: {
            backgroundColor: COLOR.MunchiesOrange,
        },
        purple: {
            backgroundColor: COLOR.Purple,
        },
        green: {
            backgroundColor: theme.palette.secondary.main,
        },
        darkGreen: {
            backgroundColor: theme.palette.secondary.dark,
        },
        red: {
            backgroundColor: theme.palette.error.main,
        },
        time: {
            width: '170px',
        },
        handledBy: {
            width: '150px',
        },
        status: {
            borderRadius: '0.5rem',
            width: '170px',
            padding: '0rem 1rem',
            color: theme.palette.common.white,
        },
        meta: {
            color: grey[700],
        },
        metaModal: {
            padding: '1rem',
            position: 'fixed',
            width: '700px',
            height: '400px',
            backgroundColor: 'rgba(215,255,255,1)',
            borderRadius: '1rem',
            zIndex: 10000000,
            border: '1px black solid',
        },
        metaContainer: {
            height: '370px',
            overflow: 'scroll',
            backgroundColor: theme.palette.background.default,
        },
        metaKey: {
            width: '150px',
        },
        metaRow: {
            padding: '0.25rem 1rem',
        },
        metaHighlight: {
            backgroundColor: 'rgba(255,255,255, 0.2)',
        },
    }),
);
