import React, { useEffect, useState } from 'react';
import { Paper, Table, TableBody, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { useStore } from '../../../services/store';
import { Language } from '../../../config/translation';
import { stores } from '../../../stores';
import OrderTableRow from './row/OrderTableRow';
import { Order, OrderStatus } from '../../../interfaces/order';
import { useCoreAnimations } from '../../../config/styles/animations';
import OrderTableHeader from './header/OrderTableHeader';
import clsx from 'clsx';
import CoreFlex from '../../core/flex/CoreFlex';
import { CoreIconButton } from '../../core/icon-button/CoreIconButton';
import { COLOR } from '../../../services/createTheme';
import { cloneDeep } from 'lodash';

interface Props {
    orders: Order[];
    status: OrderStatus;
    startExpanded?: boolean;
    className?: string;
    updateOrders: (orders: Order[]) => void;
}

interface StyleProps {
    rowHeight?: number;
    topBarBackgroundColor: string;
}

export default function OrderTable({ orders, status, startExpanded, className, updateOrders }: Props) {
    let topBarBackgroundColor: string;
    let headerBackgroundColor: string;
    let tableTitle: string;

    switch (status) {
        case OrderStatus.RECEIVED:
            topBarBackgroundColor = COLOR.MunchiesBlue;
            headerBackgroundColor = '#0086C0';
            tableTitle = 'NEW';
            break;
        case OrderStatus.COOKING:
            topBarBackgroundColor = COLOR.MunchiesOrange;
            headerBackgroundColor = '#C16100';
            tableTitle = 'COOKING';
            break;
        case OrderStatus.READY:
            topBarBackgroundColor = COLOR.MunchiesGreen;
            headerBackgroundColor = '#129B00';
            tableTitle = 'READY';
            break;
        case OrderStatus.PICKED_UP:
            topBarBackgroundColor = '#7140B7';
            headerBackgroundColor = '#582A9A';
            tableTitle = 'PICKED UP';
            break;
        case OrderStatus.DELIVERED:
        default:
            topBarBackgroundColor = '#108500';
            headerBackgroundColor = '#0C6200';
            tableTitle = 'DELIVERED';
            break;
    }

    const classes = useStyles({ rowHeight: 50, topBarBackgroundColor });
    const [language] = useStore<Language>(stores.language);
    const [isExpanded, setIsExpanded] = useState(Boolean(startExpanded));
    const [filteredOrders, setFilteredOrders] = useState<Order[]>(orders);

    useEffect(() => {
        setFilteredOrders(
            orders
                .filter((item) => item.status === status)
                .sort((a, b) => {
                    return (
                        new Date(b.createdAt || new Date()).getTime() - new Date(a.createdAt || new Date()).getTime()
                    );
                }),
        );
    }, [orders]);

    function handleUpdateOrder(order: Order) {
        const newOrders = cloneDeep(orders);
        const orderIndex = orders.findIndex((item) => item.id === order.id);
        newOrders[orderIndex] = order;
        updateOrders(newOrders);
    }

    return (
        <>
            <CoreFlex justify="space-between" className={classes.topBar}>
                <Typography variant="h6">{`${tableTitle} ${filteredOrders.length ? '(' : ''}${
                    filteredOrders.length ? filteredOrders.length : ''
                }${filteredOrders.length ? ')' : ''}`}</Typography>
                <CoreIconButton
                    iconName={isExpanded ? 'expand_less' : 'expand_more'}
                    toolTip={isExpanded ? 'Collapse' : 'Expand'}
                    onClick={() => setIsExpanded(!isExpanded)}
                />
            </CoreFlex>
            <Paper className={clsx(classes.content, className)}>
                <div className={classes.tableWrapper}>
                    <Table>
                        <OrderTableHeader isExpanded={isExpanded} bgColor={headerBackgroundColor} />
                        {isExpanded && orders.length > 0 && (
                            <TableBody className={isExpanded ? classes.tableBodyExpanded : classes.tableBodyCollapsed}>
                                {filteredOrders.map((order: Order) => {
                                    return (
                                        <OrderTableRow
                                            order={order}
                                            status={status}
                                            key={order.id}
                                            onUpdateOrder={handleUpdateOrder}
                                            detailsHeaderColor={topBarBackgroundColor}
                                        />
                                    );
                                })}
                            </TableBody>
                        )}
                    </Table>
                </div>
            </Paper>
        </>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        row: ({ rowHeight }: StyleProps) => ({
            height: rowHeight || 'unset',
        }),
        content: {
            flexGrow: 1,
            margin: theme.spacing(0),
        },
        topBar: (props: StyleProps) => ({
            backgroundColor: props.topBarBackgroundColor,
            color: theme.palette.common.white,
            textAlign: 'left',
            padding: '0.5rem 1rem',
            borderRadius: '1rem 1rem 0rem 0rem',
        }),
        tableWrapper: {
            overflow: 'auto',
        },
        disabled: {
            opacity: 0.5,
        },
        hover: {
            '&:hover': {
                backgroundColor: `rgba(0,0,0,0.02)`,
            },
        },
        tableBodyCollapsed: {
            height: '0px',
            overflow: 'hidden',
            transition: theme.transitions.create(['height', 'max-height'], {
                easing: theme.transitions.easing.easeInOut,
                duration: '0.5s',
            }),
        },
        tableBodyExpanded: {
            height: '1000px',
            overflow: 'hidden',
            transition: theme.transitions.create(['height'], {
                easing: theme.transitions.easing.easeInOut,
                duration: '0.5s',
            }),
        },
        ...useCoreAnimations(theme),
    }),
);
