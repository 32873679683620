import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import CoreFlex from '../flex/CoreFlex';

const useStyles = makeStyles({
    circularWrapper: {
        padding: '2rem',
    },
    linearWrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: 4,
        zIndex: 1,
    },
    loaderText: {
        padding: '1rem',
    },
});

interface Props {
    type?: 'linear' | 'circular';
    text?: string;
}

export function CoreLoader({ type = 'circular', text }: Props) {
    const classes = useStyles();

    return type === 'linear' ? (
        <LinearProgress variant="indeterminate" className={clsx('animated fadeIn', classes.linearWrapper)} />
    ) : (
        <CoreFlex direction="column" className={classes.circularWrapper}>
            {text && <Typography className={classes.loaderText}>{text}</Typography>}
            <CircularProgress thickness={2} className="animated fadeIn" />
        </CoreFlex>
    );
}
