import { Theme, createStyles, makeStyles, Tooltip, Icon, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useCoreAnimations } from '../../../config/styles/animations';
import { RestaurantType, RestaurantTypeIcons } from '../../../interfaces/restaurant';
import CoreFlex from '../../core/flex/CoreFlex';

interface Props {
    type: RestaurantType;
    compact?: boolean;
    selected?: boolean;
    onClick?: (type: RestaurantType) => void;
    className?: string;
}

export default function RestaurantTypeBox({ type, compact = false, selected = false, onClick, className }: Props) {
    const classes = useStyles();

    function handleClick() {
        if (onClick) {
            onClick(type);
        }
    }

    if (compact) {
        return (
            <CoreFlex className={clsx(classes.restaurantTypeCompact, className)} key={type}>
                <Tooltip title={type.replaceAll('_', ' ')}>
                    <Icon>{RestaurantTypeIcons[type as RestaurantType]}</Icon>
                </Tooltip>
            </CoreFlex>
        );
    } else {
        return (
            <CoreFlex
                key={type}
                onClick={handleClick}
                className={clsx(
                    classes.restaurantType,
                    classes.animationScale1_2x,
                    selected && classes.restaurantTypeSelected,
                )}
                direction="column"
                wrap="wrap"
            >
                <Icon>{RestaurantTypeIcons[type as RestaurantType]}</Icon>
                <Typography variant="subtitle1">{type.replaceAll('_', ' ')}</Typography>
            </CoreFlex>
        );
    }
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        restaurantTypeCompact: {
            margin: '0.5rem',
            padding: '0.5rem',
            width: '40px',
            height: '40px',
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
        },
        restaurantType: {
            margin: '0.5rem',
            padding: '0.5rem',
            backgroundColor: 'rgba(0,0,0,0.05)',
            borderRadius: '1rem',
            textAlign: 'center',
            width: '100px',
            height: '120px',
            cursor: 'pointer',
        },
        restaurantTypeSelected: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
        },
        ...useCoreAnimations(theme),
    }),
);
