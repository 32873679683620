import { ROUTES } from './routes';
import DashboardPage from './pages/dashboard/DashboardPage';
import { RouteProps } from 'react-router';
import { getRoute } from './services/router';
import { LogoutPage } from './pages/auth/logout/LogoutPage';
import ProductPage from './pages/product/ProductPage';
import RestaurantPage from './pages/restaurants/RestaurantPage';
import CountryPage from './pages/country/CountyPage';
import CustomersPage from './pages/customers/CustomersPage';
import UsersPage from './pages/profile/UsersPage';
import RestaurantDetailPage from './pages/restaurants/details/RestaurantDetailPage';
import UsersDetailsPage from './pages/profile/details/UsersDetailsPage';
import CountryDetailPage from './pages/country/details/CountryDetailPage';
import ProductsDetailsPage from './pages/product/details/ProductDetailsPage';
import CategoryDetailPage from './pages/category/details/CategoryDetailPage';
import OperatorPage from './pages/operator/OperatorPage';
import OperatorDetailPage from './pages/operator/details/OperatorDetailPage';
import OrderPage from './pages/order/OrderPage';

export function getRootRoutes(): RouteProps[] {
    return [
        {
            path: getRoute(ROUTES.dashboard),
            component: DashboardPage,
            exact: true,
        },
        {
            path: getRoute(ROUTES.product),
            component: ProductPage,
            exact: true,
        },
        {
            path: getRoute(ROUTES.product.new),
            component: ProductsDetailsPage,
        },
        {
            path: getRoute(ROUTES.product.edit),
            component: ProductsDetailsPage,
        },
        {
            path: getRoute(ROUTES.product.clone),
            component: ProductsDetailsPage,
        },
        {
            path: getRoute(ROUTES.category.edit),
            component: CategoryDetailPage,
        },
        {
            path: getRoute(ROUTES.category.clone),
            component: CategoryDetailPage,
        },
        {
            path: getRoute(ROUTES.category.new),
            component: CategoryDetailPage,
        },
        {
            path: getRoute(ROUTES.restaurant),
            component: RestaurantPage,
            exact: true,
        },
        {
            path: getRoute(ROUTES.country),
            component: CountryPage,
            exact: true,
        },
        {
            path: getRoute(ROUTES.user),
            component: UsersPage,
            exact: true,
        },
        {
            path: getRoute(ROUTES.user.edit),
            component: UsersDetailsPage,
        },
        {
            path: getRoute(ROUTES.customer),
            component: CustomersPage,
            exact: true,
        },
        {
            path: getRoute(ROUTES.auth.logout),
            component: LogoutPage,
        },
        {
            path: getRoute(ROUTES.restaurant.new),
            component: RestaurantDetailPage,
        },
        {
            path: getRoute(ROUTES.restaurant.edit),
            component: RestaurantDetailPage,
        },
        {
            path: getRoute(ROUTES.restaurant.clone),
            component: RestaurantDetailPage,
        },
        {
            path: getRoute(ROUTES.country.new),
            component: CountryDetailPage,
        },
        {
            path: getRoute(ROUTES.country.edit),
            component: CountryDetailPage,
        },
        {
            path: getRoute(ROUTES.country.clone),
            component: CountryDetailPage,
        },
        {
            path: getRoute(ROUTES.operator),
            component: OperatorPage,
            exact: true,
        },
        {
            path: getRoute(ROUTES.operator.new),
            component: OperatorDetailPage,
        },
        {
            path: getRoute(ROUTES.operator.edit),
            component: OperatorDetailPage,
        },
        {
            path: getRoute(ROUTES.operator.clone),
            component: OperatorDetailPage,
        },
        {
            path: getRoute(ROUTES.order),
            component: OrderPage,
            exact: true,
        },
    ];
}
