import { ApiResult } from './../interfaces/common';
import { Restaurant } from './../interfaces/restaurant';
import { httpGet, httpPost, httpPut, httpDelete } from '../services/api';
import { AuthContextInterface } from '../context/AuthContext';
import { showError, showSuccess } from '../services/alert';
import { findAvailableLanguage } from '../config/translation';

export async function getRestaurants(
    query: Record<string, any>,
    authContext?: Partial<AuthContextInterface>,
): Promise<Restaurant[]> {
    try {
        const response = await httpGet(`restaurant/find/`, query, authContext);
        if (response.success && response.result) {
            const restaurants: Restaurant[] = response.result;
            return restaurants;
        } else {
            showError("Something's wrong: " + response.message);
            return [];
        }
    } catch (err) {
        showError('Error retriveing restaurants: ' + err);
        return [];
    }
}

export async function getCountryRestaurants(
    countryId: number,
    query: Record<string, any>,
    authContext?: Partial<AuthContextInterface>,
): Promise<Restaurant[]> {
    try {
        const response = await httpGet(`restaurant/country/${countryId}`, query, authContext);
        if (response.success && response.result) {
            const restaurants: Restaurant[] = response.result;
            return restaurants;
        } else {
            showError("Something's wrong: " + response.message);
            return [];
        }
    } catch (err) {
        showError('Error retriveing restaurants: ' + err);
        return [];
    }
}
export async function getOperatorRestaurants(
    operatorId: number,
    query: Record<string, any>,
    authContext?: Partial<AuthContextInterface>,
): Promise<Restaurant[]> {
    try {
        const response = await httpGet(`restaurant/operator/${operatorId}`, query, authContext);
        if (response.success && response.result) {
            const restaurants: Restaurant[] = response.result;
            return restaurants;
        } else {
            showError("Something's wrong: " + response.message);
            return [];
        }
    } catch (err) {
        showError('Error retriveing restaurants: ' + err);
        return [];
    }
}

export async function getRestaurantById(
    id: number,
    authContext?: Partial<AuthContextInterface>,
): Promise<Restaurant | null> {
    try {
        const response = await httpGet(`restaurant/get/${id}`, {}, authContext);
        if (response.success && response.result && response.result.id) {
            const restaurant: Restaurant = response.result;
            return restaurant;
        } else {
            showError("Something's wrong: " + response.message);
            return null;
        }
    } catch (err) {
        showError('Error retriveing restaurants: ' + err);
        return null;
    }
}

export async function createRestaurant(
    data: Record<string, any>,
    authContext?: Partial<AuthContextInterface>,
): Promise<ApiResult> {
    try {
        const response = await httpPost('restaurant/new', data, authContext);
        if (response.success && response.result) {
            showSuccess(`Restaurant ${data.name} added successfully`);
        } else {
            showError("Something's wrong: " + response.message);
        }
        return response;
    } catch (err) {
        showError('Error while creating restaurant: ' + err);
        return { success: false, message: 'Technical error' };
    }
}

export async function updateRestaurant(
    id: number,
    data: Record<string, any>,
    authContext?: Partial<AuthContextInterface>,
): Promise<ApiResult> {
    try {
        const response = await httpPut(`restaurant/update/${id}`, data, authContext);

        const restaurant = await getRestaurantById(id, authContext);
        if (response.success && response.result && restaurant?.translations) {
            showSuccess(
                `Restaurant Munchies ${
                    restaurant?.translations[findAvailableLanguage(data?.translations || {}) || 'en']?.name
                } updated successfully`,
            );
        } else {
            showError("Something's wrong: " + response.message);
        }
        return response;
    } catch (err) {
        showError('Error while updating restaurant: ' + err);
        return { success: false, message: 'Technical error' };
    }
}

export async function removeRestaurant(id: number, authContext?: Partial<AuthContextInterface>): Promise<any> {
    try {
        const response = await httpDelete(`restaurant/remove/${id}`, {}, authContext);
        if (response.success && response.result) {
            showSuccess(`Restaurant ${id} deleted successfully`);
            return response.result;
        } else {
            showError("Something's wrong: " + response.message);
            return null;
        }
    } catch (err) {
        showError('Error while deleting restaurant: ' + err);
        return null;
    }
}
