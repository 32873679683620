import React, { useState, useEffect } from 'react';
import CoreFlex from '../../../../core/flex/CoreFlex';
import { Theme, Typography, Icon } from '@material-ui/core';
import CoreFormInput from '../../../../core/form/input/CoreFormInput';
import { CoreIconButton } from '../../../../core/icon-button/CoreIconButton';
import { InputType } from '../../../../../interfaces';
import { makeStyles, createStyles } from '@material-ui/styles';
import { grey } from '@material-ui/core/colors';
import CoreSwitch from '../../../../core/switch/CoreSwitch';
import { CoreImageUpload } from '../../../../core/image-upload/CoreImageUpload';
import { orderBy } from 'lodash';
import { Product, ProductChoice, ProductChoiceType, ProductChoiceTranslation } from '../../../../../interfaces/product';
import { Language, getTranslation } from '../../../../../config/translation';
import { useStore } from '../../../../../services/store';
import { stores } from '../../../../../stores';
import { showError } from '../../../../../services/alert';
import CoreFormSelect from '../../../../core/form/select/CoreFormSelect';
import { toTitleCase } from '../../../../../services/tools';
import CoreTranslatedInput from '../../../../core/form/input/translated/CoreTranslatedInput';
import clsx from 'clsx';
import CoreChip from '../../../../core/chip/CoreChip';

interface Props {
    choice: ProductChoice;
    index: number;
    products: Product[];
    onChangeChoice: (
        name: keyof ProductChoice | keyof ProductChoiceTranslation,
        value: string | number | boolean | string[],
        index: number,
        language?: Language,
    ) => void;
    onDeleteChoice: (index: number) => void;
}

export default function ProductChoiceEditorRow({ choice, products, index, onDeleteChoice, onChangeChoice }: Props) {
    const classes = useStyles();
    const [isEditMode, setIsEditMode] = useState(!choice.id);
    const [optionValue, setOptionValue] = useState('');
    const [isInitDone, setIsInitDone] = useState(false);
    const [language] = useStore<Language>(stores.language);
    products.forEach((item) => {
        item.name = getTranslation(item, language);
    });
    products = orderBy(products, 'name', 'asc');
    const translation = choice.translations?.[language];

    useEffect(() => {
        if (!isEditMode) {
            if (!translation?.name || translation?.options?.length < 2) {
                showError('ERROR: Please fill in the name and add atleast 2 options.');
                setIsEditMode(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditMode]);

    useEffect(() => {
        if (choice.type && isInitDone) {
            onChangeChoice('options', [], index, language);
        }
        setIsInitDone(true);
    }, [choice.type]);

    useEffect(() => {
        if (optionValue && [ProductChoiceType.PRODUCT, ProductChoiceType.IMAGE].includes(choice.type)) {
            setOptionValue('');
            handleAddOption();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [optionValue]);

    function handleAddOption() {
        if (optionValue) {
            onChangeChoice('options', (translation?.options || []).concat(optionValue), index, language);
            setOptionValue('');
        }
    }

    function handleDeleteChoice() {
        setIsInitDone(false);
        onDeleteChoice(index);
    }

    function handleDeleteOption(optionIndex: number) {
        const newOptions = translation?.options.filter((item, i) => i !== optionIndex) || [];
        onChangeChoice('options', newOptions, index, language);
    }

    return (
        <>
            <CoreFlex
                direction="column"
                alignItems="flex-start"
                className={!choice.isActive ? classes.rootInactive : !isEditMode ? classes.root : ''}
                onClick={() => {
                    setIsEditMode(!isEditMode);
                }}
            >
                <CoreFlex alignItems="center" justify="flex-start">
                    <CoreFlex justify="flex-start" className={classes.switch}>
                        <CoreSwitch
                            onValueChange={(value) => onChangeChoice('isActive', value, index)}
                            value={choice.isActive}
                        />
                    </CoreFlex>
                    {isEditMode && (
                        <>
                            <CoreFlex className={classes.smallField}>
                                <CoreFormSelect
                                    value={choice.type}
                                    label="Choice type"
                                    onValueChange={(value) => {
                                        onChangeChoice('type', value as ProductChoiceType, index);
                                    }}
                                    options={Object.keys(ProductChoiceType).map((item, index) => {
                                        return {
                                            value: ProductChoiceType[item as ProductChoiceType],
                                            label: toTitleCase(item),
                                        };
                                    })}
                                />
                            </CoreFlex>
                            <CoreFlex className={classes.formField}>
                                <CoreTranslatedInput
                                    label="Choice title"
                                    language={language}
                                    translationObject={choice.translations || {}}
                                    keyName="name"
                                    required
                                    placeholder="Eg. Choose drink"
                                    value={translation?.name || ''}
                                    type={InputType.text}
                                    onValueChange={(value) => onChangeChoice('name', value, index, language)}
                                />
                            </CoreFlex>
                            <CoreFlex className={classes.smallField}>
                                {[ProductChoiceType.TEXT, ProductChoiceType.ICON].includes(choice.type) && (
                                    <>
                                        <CoreFormInput
                                            onSubmit={handleAddOption}
                                            label={
                                                choice.type === ProductChoiceType.ICON ? 'Add icon name' : 'Add option'
                                            }
                                            required
                                            placeholder={
                                                choice.type === ProductChoiceType.ICON
                                                    ? 'Eg. edit'
                                                    : 'Eg. Sparkling water'
                                            }
                                            value={optionValue}
                                            type={InputType.text}
                                            onValueChange={setOptionValue}
                                        />
                                        <CoreIconButton iconName="add" onClick={handleAddOption} />
                                    </>
                                )}
                                {choice.type === ProductChoiceType.PRODUCT && (
                                    <CoreFormSelect
                                        fullWidth
                                        label="Add product option"
                                        value={optionValue}
                                        options={products
                                            .filter(
                                                (item: Product) =>
                                                    item.id && !translation?.options.includes(item.id + ''),
                                            )
                                            .map((item: Product) => {
                                                return {
                                                    value: item.id || 0,
                                                    label: getTranslation(item, language) || '',
                                                };
                                            })}
                                        onValueChange={(value) => setOptionValue(value as string)}
                                    />
                                )}
                                {choice.type === ProductChoiceType.IMAGE && (
                                    <CoreImageUpload
                                        onUploadComplete={(value: string) => setOptionValue(value)}
                                        className={classes.imageUpload}
                                        width={150}
                                        basePath="product-choice"
                                        imageUrl={optionValue}
                                        height={150}
                                        requirements={{
                                            destinationHeight: 200,
                                            destinationWidth: 200,
                                        }}
                                    />
                                )}
                            </CoreFlex>
                        </>
                    )}

                    {!isEditMode && (
                        <CoreFlex justify="center">
                            <Typography variant="subtitle1">{translation?.name?.toUpperCase() || 'n/a'}</Typography>
                        </CoreFlex>
                    )}

                    <CoreFlex className={classes.buttons} justify="flex-end">
                        {isEditMode && (
                            <CoreIconButton
                                iconName="check"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setIsEditMode(false);
                                }}
                            />
                        )}
                        {!isEditMode && (
                            <CoreIconButton
                                iconName="edit"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setIsEditMode(true);
                                }}
                            />
                        )}
                        <CoreIconButton
                            iconName="delete"
                            onClick={(event) => {
                                event.stopPropagation();
                                handleDeleteChoice();
                            }}
                        />
                    </CoreFlex>
                </CoreFlex>

                {Boolean(translation?.options.length) && (
                    <CoreFlex
                        alignItems="center"
                        wrap="wrap"
                        justify="center"
                        className={clsx(
                            !choice.isActive ? classes.rootInactive : !isEditMode ? classes.root : '',
                            classes.row,
                        )}
                    >
                        {choice.type === ProductChoiceType.TEXT &&
                            translation?.options.map((option: string, index: number) => {
                                return (
                                    <CoreChip
                                        key={index}
                                        label={option}
                                        onDelete={(value) => handleDeleteOption(index)}
                                    />
                                );
                            })}
                        {choice.type === ProductChoiceType.PRODUCT &&
                            translation?.options.map((option: string, index: number) => {
                                const optionProduct = products.find((item: Product) => item.id === parseInt(option));
                                return (
                                    <CoreChip
                                        size="medium"
                                        avatarSize={70}
                                        className={classes.productChip}
                                        avatar={optionProduct?.photo}
                                        key={index}
                                        label={getTranslation(optionProduct, language) || ''}
                                        onDelete={() => handleDeleteOption(index)}
                                    />
                                );
                            })}
                        {choice.type === ProductChoiceType.IMAGE &&
                            translation?.options.map((option: string, index: number) => {
                                return (
                                    <div key={index} className={classes.imageWrapper}>
                                        <img src={option} alt={choice.name} className={classes.choiceImage} />
                                        <CoreIconButton
                                            toolTip="Delete option"
                                            className={classes.deleteButton}
                                            iconName="cancel"
                                            onClick={() => handleDeleteOption(index)}
                                        />
                                    </div>
                                );
                            })}
                        {choice.type === ProductChoiceType.ICON &&
                            translation?.options.map((option: string, index: number) => {
                                return (
                                    <div key={index} className={classes.imageWrapper}>
                                        <Icon className={classes.choiceIcon}>{option}</Icon>
                                        <CoreIconButton
                                            toolTip="Delete option"
                                            className={classes.deleteButtonDark}
                                            iconName="cancel"
                                            onClick={() => handleDeleteOption(index)}
                                        />
                                    </div>
                                );
                            })}
                    </CoreFlex>
                )}
            </CoreFlex>
        </>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:hover': {
                backgroundColor: grey[100],
            },
        },
        rootInactive: {
            opacity: 0.25,
        },
        formField: {
            display: 'flex',
            flexBasis: 0,
            flexGrow: 1,
            flexShrink: 1,
            padding: '0.5rem 1rem',
        },
        smallField: {
            padding: '0.5rem 0.5rem',
            width: '250px',
        },
        miniField: {
            padding: '0.5rem 0.5rem',
            minWidth: '150px',
            width: '100%',
        },
        priceTag: {
            fontSize: '1rem',
        },
        row: {
            marginBottom: '1rem',
        },
        optionField: {
            width: '250px',
            margin: '1rem',
        },
        productChip: {
            height: '80px',
            backgroundColor: grey[500],
            borderRadius: '40px',
            '&:focus': {
                backgroundColor: grey[600],
            },
            '&:hover': {
                backgroundColor: grey[600],
            },
        },
        nameLabel: {
            width: '100%',
        },
        choiceTitle: {
            width: '250px',
            marginLeft: '1rem',
        },
        imageUpload: {
            margin: '2rem 1rem',
        },
        imageWrapper: {
            margin: '0rem 0.5rem',
            position: 'relative',
        },
        deleteButton: {
            position: 'absolute',
            right: '0px',
            top: '0px',
            color: theme.palette.common.white,
            opacity: 0.7,
            '&:hover': {
                opacity: 1,
            },
        },
        deleteButtonDark: {
            position: 'absolute',
            right: '0px',
            top: '0px',
            color: grey[500],
            opacity: 0.7,
            '&:hover': {
                color: grey[700],
            },
        },
        choiceImage: {
            width: '200px',
            height: '200px',
        },
        choiceIcon: {
            fontSize: '200px',
        },
        buttons: {
            opacity: 0.7,
            width: '150px',
            marginLeft: '1rem',
        },
        switch: {
            width: '100px',
        },
    }),
);
