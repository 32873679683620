import { makeStyles, Theme, Typography } from '@material-ui/core';
import React, { useState, useEffect, useContext } from 'react';
import Dropzone from 'react-dropzone';
import { grey } from '@material-ui/core/colors';
import CoreFlex from '../flex/CoreFlex';
import imagePlaceholder from '../../../assets/icons/add_photo.png';
import { verifyImageFile, startUpload } from '../../../services/utils';
import './custom-image-crop.css';
import { ImageRequirements } from '../../../interfaces';
import { AuthContext } from '../../../context/AuthContext';
import { CoreImageUploadCropDialog } from './crop-dialog/CoreImageUploadCropDialog';
import clsx from 'clsx';

const acceptedFileTypes = ['image/x-png', 'image/png', 'image/jpg', 'image/jpeg', 'image/gif', 'image/svg+xml'];

interface Props {
    onUploadComplete: (imagePath: string) => void;
    disabled?: boolean;
    imageUrl?: string;
    label?: string;
    aspect?: number;
    width?: number;
    height?: number;
    doCrop?: boolean;
    basePath?: string;
    className?: string;
    requirements?: ImageRequirements;
}

interface StyleProps {
    width?: number;
    height?: number;
    label?: string;
}

export function CoreImageUpload({
    label = 'Select file',
    disabled = false,
    imageUrl,
    basePath,
    onUploadComplete,
    doCrop = true,
    aspect = 1 / 1,
    width = 200,
    height = 200,
    requirements,
    className,
}: Props) {
    const authContext = useContext(AuthContext);
    const classes = useStyles({ width, height, label });
    const [cropDialogOpen, setCropDialogOpen] = useState(false);
    const [croppedImageSrc, setCroppedImageSrc] = useState<string | undefined>();
    const [imageFile, setImageFile] = useState<File | undefined>();

    if (!requirements || requirements.acceptedTypes) {
        requirements = {
            acceptedTypes: acceptedFileTypes,
            ...requirements,
        };
    }

    useEffect(() => {
        if (imageFile) {
            setCropDialogOpen(true);
        }
    }, [imageFile]);

    async function handleDrop(files: File[]) {
        if (!requirements) {
            return;
        }
        const isVerfified = files[0].type.includes('svg') || (await verifyImageFile(files[0], requirements));
        if (files && files.length > 0 && isVerfified) {
            const file = files[0];
            if (doCrop) {
                setImageFile(file);
            } else {
                setCroppedImageSrc(URL.createObjectURL(files[0]));
                await startUpload(onUploadComplete, authContext, file, undefined, undefined, label);
            }
        }
    }

    return (
        <div
            className={className}
            onClick={(event) => {
                event.stopPropagation();
            }}
        >
            <Dropzone
                onDrop={handleDrop}
                disabled={disabled}
                accept={acceptedFileTypes}
                multiple={false}
                maxSize={requirements.maxSize}
            >
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                        <CoreFlex
                            alignItems="center"
                            justify="center"
                            className={clsx(classes.photoBox, disabled ? classes.disabled : classes.enabled)}
                            direction="column"
                        >
                            <input {...getInputProps()} />
                            <img
                                src={imageUrl || croppedImageSrc || imagePlaceholder}
                                alt="Uploadable"
                                className={
                                    croppedImageSrc || imageUrl ? classes.photoThumbnail : classes.uploadPhotoIcon
                                }
                            />
                            {label && !croppedImageSrc && !imageUrl && (
                                <Typography variant="caption" className={classes.label}>
                                    {label.toLocaleUpperCase()}
                                </Typography>
                            )}
                        </CoreFlex>
                    </div>
                )}
            </Dropzone>
            <CoreImageUploadCropDialog
                requirements={requirements}
                aspect={aspect}
                label={label || 'Image'}
                file={imageFile}
                open={cropDialogOpen}
                onClose={() => setCropDialogOpen(false)}
                onUploadComplete={onUploadComplete}
                croppedImageSrc={croppedImageSrc}
                setCroppedImageSrc={setCroppedImageSrc}
            />
        </div>
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    photoBox: (props: StyleProps) => ({
        width: props.width + 'px' || '200px',
        height: props.height + 'px' || '200px',
        borderWidth: '2px',
        borderColor: grey[400],
        backgroundColor: grey[100],
    }),
    uploadPhotoIcon: (props: StyleProps) => ({
        width: props.width ? props.width / 4 + 'px' : '50px',
        opacity: 0.5,
    }),
    photoThumbnail: (props: StyleProps) => ({
        width: props.width + 'px' || '200px',
        height: props.height + 'px' || '200px',
    }),
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    disabled: {
        opacity: 0.5,
    },
    enabled: {
        opacity: 1,
    },
    label: {
        padding: '0.5rem',
        textAlign: 'center',
    },
}));
