import React, { FormEvent, ChangeEvent } from 'react';
import { Grid, Button, DialogContent, DialogActions, Typography, Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { InputType, ValidationType } from '../../../interfaces';
import CoreFormInput from '../../core/form/input/CoreFormInput';
import { Link } from 'react-router-dom';
import { validate } from '../../../services/validation';
import { LoginData } from '../../../interfaces/auth';

interface Props {
    handleLogin: (event: FormEvent<Element>) => void;
    loginData: LoginData;
    onValueChange: (name: keyof LoginData, value: string | boolean) => void;
}

export function LoginForm({ handleLogin, loginData, onValueChange }: Props) {
    const classes = useStyles();

    const validators = {
        email: () => {
            return validate({
                type: ValidationType.Email,
                value: loginData.email,
            });
        },
        password: () => {
            return validate({
                type: ValidationType.Password,
                value: loginData.password,
            });
        },
    };

    return (
        <form onSubmit={handleLogin}>
            <Grid item>
                <DialogContent dividers>
                    <Grid
                        container
                        alignItems="stretch"
                        alignContent="stretch"
                        justify="space-around"
                        direction="column"
                        spacing={3}
                    >
                        <Grid item className={classes.gridItem}>
                            <CoreFormInput
                                type={InputType.email}
                                value={loginData.email}
                                label="Email"
                                placeholder="Please enter your login email"
                                onValueChange={(value) => onValueChange('email', value)}
                            />
                        </Grid>
                        <Grid item className={classes.gridItem}>
                            <CoreFormInput
                                label="Password"
                                placeholder="Enter your password"
                                type={InputType.password}
                                value={loginData.password}
                                onValueChange={(value) => onValueChange('password', value)}
                            />
                        </Grid>
                        <Grid container justify="space-evenly" alignItems="center" direction="row-reverse">
                            <Grid item>
                                <Switch
                                    id="rememberMe"
                                    onChange={(event, checked) => onValueChange('rememberMe', checked)}
                                    color="primary"
                                    checked={loginData.rememberMe}
                                />
                            </Grid>
                            <Grid>
                                <Typography variant="caption">Remember me on this device</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Grid>
            <Grid item>
                <DialogActions>
                    <Grid container justify="space-evenly" alignItems="center" className={classes.actions}>
                        <Grid item>
                            <Button variant="contained" color="primary" fullWidth type="submit">
                                Login
                            </Button>
                        </Grid>
                        <Grid item>
                            <Link to="/auth/register" className={classes.button}>
                                <Button variant="contained">Register</Button>
                            </Link>
                        </Grid>
                        <Grid container justify="space-evenly" alignItems="center" className={classes.actions}>
                            <Grid item>
                                <Link to="/auth/forgot-password" className={classes.button}>
                                    <Button>Forgot password</Button>
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Grid>
        </form>
    );
}

const useStyles = makeStyles({
    actions: {
        margin: '1rem',
    },
    gridItem: {
        width: '100%',
    },
    button: {
        textDecoration: 'none',
    },
});
