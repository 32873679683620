import { stores } from './../stores';
import { LoginData } from './../interfaces/auth';
import { ApiResult } from './../interfaces/common';
import { User, UserType } from './../interfaces/user';
import { httpPost } from '../services/api';
import { decodeToken, isTokenExpired } from '../services/tokens';
import { AuthContextInterface } from '../context/AuthContext';
import { getUserLocationFromIp } from './ipLocation';
import { RegistrationData } from '../interfaces/auth';
import { showError } from '../services/alert';

export async function loginUser(loginData: LoginData, authContext?: Partial<AuthContextInterface>): Promise<ApiResult> {
    const ipLocation = await getUserLocationFromIp();
    const response = await httpPost('auth/login', { ...loginData, ipLocation }, authContext);

    if (!response) {
        return { success: false, message: 'Unable to connect to the server' };
    }

    if (response.success && response.result && authContext && authContext.authToken) {
        // Get user data
        const user: User = response.result;

        // Check if user data matches with the token information
        const payload = decodeToken(authContext.authToken);

        if (payload.type !== user.type || payload.id !== user.id) {
            return { success: false, message: 'Login unsuccessful' };
        }

        if (isTokenExpired(authContext.authToken)) {
            return { success: false, message: 'Token has expired, please log in again.' };
        }
    }
    return response;
}

export async function registerUser(
    registrationData: RegistrationData,
    authContext?: Partial<AuthContextInterface>,
): Promise<ApiResult> {
    const ipLocation = await getUserLocationFromIp();
    delete registrationData.passwordRepeat;
    const response = await httpPost(
        'auth/register',

        {
            ...registrationData,
            type: UserType.STAFF,
            ipLocation,
        },
        authContext,
    );
    if (!response) {
        return { success: false, message: 'Unable to connect to the server' };
    }
    return response;
}

export async function refreshTokens(authContext?: Partial<AuthContextInterface>): Promise<ApiResult> {
    const response = await httpPost('auth/refresh', undefined, authContext);
    if (!response) {
        return { success: false, message: 'Unable to connect to the server' };
    }
    return response;
}

export async function logoutUser(authContext?: Partial<AuthContextInterface>): Promise<ApiResult> {
    const response = await httpPost('auth/logout', undefined, authContext);
    if (!response) {
        return { success: false, message: 'Unable to connect to the server' };
    }
    authContext && authContext.setAuthToken && authContext.setAuthToken(undefined);
    authContext && authContext.setUser && authContext.setUser(undefined);
    return response;
}

export function setStateLoggedIn(response: ApiResult, authContext: Partial<AuthContextInterface>) {
    if (response && response.success && response.result) {
        const user: User = response.result;
        if (user && authContext.setUser && authContext.setLoggedIn && user.type !== UserType.CUSTOMER) {
            authContext.setUser(user);
            authContext.setLoggedIn(true);
            return;
        }
    }
    showError('Error while logging user in: ' + response.message);
}

export async function forgotPassword(data: Pick<LoginData, 'email'>): Promise<ApiResult> {
    const ipLocation = await getUserLocationFromIp();
    const response = await httpPost('auth/forgot-password', { ...data, ipLocation });

    if (!response) {
        return { success: false, message: 'Unable to connect to the server' };
    }

    if (response.success) {
        return {
            success: true,
            message: `If account with ${data.email} exists email with password recovery has been sent there`,
        };
    }
    return response;
}

export async function resetPassword(data: Pick<LoginData, 'password'> & { token: string }): Promise<ApiResult> {
    const ipLocation = await getUserLocationFromIp();
    const response = await httpPost('auth/reset-password', { ...data, ipLocation });

    if (!response) {
        return { success: false, message: 'Unable to connect to the server' };
    }

    if (response.success) {
        return { success: true, message: 'Password has been changed' };
    }
    return response;
}
