import React, { MouseEvent, useEffect, useState, useContext } from 'react';
import { createStyles, makeStyles, Theme, TableRow, TableCell, Typography, Tooltip, Icon } from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment';
import { useStore } from '../../../../services/store';
import { Language, getTranslation } from '../../../../config/translation';
import { stores } from '../../../../stores';
import { CoreIconButton } from '../../../core/icon-button/CoreIconButton';
import { Order, OrderEvent, OrderStatus, OrderType, OrderTypeIcons } from '../../../../interfaces/order';
import CoreFlex from '../../../core/flex/CoreFlex';
import { useCoreAnimations } from '../../../../config/styles/animations';
import { Product } from '../../../../interfaces/product';
import { Country } from '../../../../interfaces/country';
import { formatPrice } from '../../../../services/helpers';
import CoreButton from '../../../core/button/CoreButton';
import { COLOR } from '../../../../services/createTheme';
import { OrderTimerThresholds } from '../../../../services/order';
import { grey } from '@material-ui/core/colors';
import { addOrderEvent } from '../../../../microservices/order';
import { AuthContext } from '../../../../context/AuthContext';
import OrderTableRowDetails from './details/OrderTableRowDetails';
import { formatTimeDifference } from '../../../../services/utils';

interface Props {
    order: Order;
    status: OrderStatus;
    onUpdateOrder: (order: Order) => void;
    detailsHeaderColor: string;
}

interface StyleProps {
    rowHeight?: number;
    timerColor: string;
}

export default function OrderTableRow({ order, status, detailsHeaderColor, onUpdateOrder }: Props) {
    const [language] = useStore<Language>(stores.language);
    const [products] = useStore<Product[]>(stores.products);
    const [country] = useStore<Country>(stores.selectedCountry);
    const [waitingTime, setWaitingTime] = useState('');
    const [isExpanded, setIsExpanded] = useState(false);
    const authContext = useContext(AuthContext);
    const deliveredEvent = order.events.find((item) => item.status === OrderStatus.DELIVERED);
    const receivedEvent = order?.events?.find((item) => item.status === OrderStatus.RECEIVED);
    const timeDifference =
        order.status === OrderStatus.DELIVERED && deliveredEvent?.createdAt
            ? moment(deliveredEvent.createdAt).diff(moment(receivedEvent?.createdAt))
            : moment().diff(moment(receivedEvent?.createdAt));
    const thresholds = OrderTimerThresholds[order.status];
    let timerColor: string = grey[300];

    if (!thresholds) {
        timerColor = grey[300];
    } else if (timeDifference < thresholds.ok) {
        timerColor = COLOR.MunchiesGreen;
    } else if (timeDifference < thresholds.late) {
        timerColor = COLOR.MunchiesOrange;
    } else if (timeDifference > thresholds.late) {
        timerColor = '#F11414';
    }

    const classes = useStyles({ rowHeight: 75, timerColor });

    let buttonColor;
    let buttonText;
    let secondaryButtonText;
    let secondaryButtonAction;
    let buttonAction;

    useEffect(() => {
        let timer: NodeJS.Timeout;

        if (order.status === OrderStatus.DELIVERED && deliveredEvent?.createdAt) {
            setWaitingTime(getWaitingTime(new Date(deliveredEvent.createdAt)));
        } else {
            setWaitingTime(getWaitingTime());
            timer = setTimeout(() => {
                setWaitingTime(getWaitingTime());
            }, 1000);
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [waitingTime]);

    function getWaitingTime(until?: Date): string {
        if (!order.createdAt || !receivedEvent?.createdAt) {
            return '';
        }
        return formatTimeDifference(receivedEvent.createdAt, until);
    }

    function getTotalQuantity(): number {
        let totalQuantity = 0;
        order.items.forEach((item) => {
            totalQuantity += item.quantity;
        });
        return totalQuantity;
    }

    switch (status) {
        case OrderStatus.RECEIVED:
            buttonColor = COLOR.MunchiesOrange;
            buttonText = '> COOKING';
            buttonAction = () => {
                changeOrderStatus(OrderStatus.COOKING);
            };
            secondaryButtonText = 'REFUND';
            secondaryButtonAction = handleRequestRefund;
            break;
        case OrderStatus.COOKING:
            buttonColor = COLOR.MunchiesGreen;
            buttonText = '> READY';
            buttonAction = () => {
                changeOrderStatus(OrderStatus.READY);
            };
            secondaryButtonText = 'REFUND';
            secondaryButtonAction = handleRequestRefund;
            break;
        case OrderStatus.READY:
            buttonColor = '#7140B7';
            buttonText = '> DELIVERY';
            buttonAction = () => {
                changeOrderStatus(OrderStatus.PICKED_UP);
            };
            secondaryButtonText = 'NO SHOW';
            secondaryButtonAction = () => {
                changeOrderStatus(
                    order.type === OrderType.ONLINE ? OrderStatus.COURIER_NO_SHOW : OrderStatus.CUSTOMER_NO_SHOW,
                );
            };
            break;
        case OrderStatus.PICKED_UP:
            buttonColor = '#108500';
            buttonText = '> DELIVERED';
            buttonAction = () => {
                changeOrderStatus(OrderStatus.DELIVERED);
            };
            secondaryButtonText = 'NO SHOW';
            secondaryButtonAction = () => {
                changeOrderStatus(OrderStatus.CUSTOMER_NO_SHOW);
            };
            break;
        case OrderStatus.DELIVERED:
        default:
            buttonColor = grey[200];
            buttonText = 'REFUND';
            buttonAction = () => {};
            break;
    }

    async function changeOrderStatus(newStatus: OrderStatus) {
        if (newStatus === order.status || !order.id) {
            return;
        }

        console.log('New Status', newStatus, 'OldStatus', order.status, ' = ', newStatus === order.status);
        const orderEvent: OrderEvent = {
            status: newStatus,
            orderId: order.id,
            previousStatus: order.status,
            handledBy: authContext?.user?.id,
            comment: '',
            meta: '',
        };

        await addOrderEvent(order.id, orderEvent, authContext);
    }

    function handleRequestRefund() {}

    function handleClick(event: MouseEvent<HTMLTableRowElement>) {}

    function handleToggleExpand() {
        setIsExpanded(!isExpanded);
    }

    return (
        <>
            <TableRow id={order.id + ''} onClick={handleClick} className={clsx(classes.row, classes.hover)}>
                <TableCell className={clsx(classes.cell, classes.paddingLeft, isExpanded && classes.expandedRow)}>
                    <Typography variant="h6">{order.id}</Typography>
                </TableCell>
                <TableCell className={clsx(classes.cell, isExpanded && classes.expandedRow)}>
                    <CoreFlex className={clsx(classes.counter, classes.animationScale1_5x)}>
                        <Typography variant="subtitle1">
                            <strong>{waitingTime}</strong>
                        </Typography>
                    </CoreFlex>
                </TableCell>
                <TableCell className={clsx(classes.cell, isExpanded && classes.expandedRow)}>
                    <CoreFlex justify="flex-start">
                        <Tooltip title={order.type.toUpperCase().replaceAll('_', ' ')}>
                            <Icon fontSize="large" className={clsx(classes.orderType, classes.animationScale2x)}>
                                {OrderTypeIcons[order.type]}
                            </Icon>
                        </Tooltip>
                    </CoreFlex>
                </TableCell>
                <TableCell className={clsx(classes.cell, isExpanded && classes.expandedRow)}>
                    <CoreFlex direction="column" alignItems="flex-start">
                        <Typography>{`${order.user?.firstName} ${order.user?.lastName}`}</Typography>
                        <Typography>DIAMOND</Typography>
                    </CoreFlex>
                </TableCell>
                <TableCell className={clsx(classes.itemsCell, isExpanded && classes.expandedRow)}>
                    <CoreFlex className={classes.itemsBox}>
                        <Typography variant="h6"> {getTotalQuantity()} </Typography>
                        <CoreFlex wrap="wrap" justify="flex-start" className={classes.productImageWrapper}>
                            {order.items.slice(0, 9).map((orderItem) => {
                                const product = products.find((item) => item.id === orderItem.productId);
                                if (product) {
                                    return (
                                        <Tooltip key={orderItem.id} title={getTranslation(product, language)}>
                                            <img
                                                src={product?.photo}
                                                alt={getTranslation(product, language)}
                                                className={classes.productImage}
                                            />
                                        </Tooltip>
                                    );
                                } else {
                                    return (
                                        <CoreFlex key={orderItem.id}>
                                            <></>
                                        </CoreFlex>
                                    );
                                }
                            })}
                            {order.items.length > 9 && (
                                <Typography variant="h6" className={classes.productImage}>
                                    ...
                                </Typography>
                            )}
                        </CoreFlex>
                    </CoreFlex>
                </TableCell>
                <TableCell className={clsx(classes.cell, isExpanded && classes.expandedRow)}>
                    <CoreFlex justify="flex-start">
                        <Typography> {formatPrice(order.finalPrice, country)}</Typography>
                    </CoreFlex>
                </TableCell>
                <TableCell className={clsx(classes.cell, isExpanded && classes.expandedRow)}>
                    <CoreFlex justify="flex-start">
                        {secondaryButtonText && secondaryButtonAction && (
                            <CoreButton
                                label={secondaryButtonText}
                                customColor={grey[300]}
                                onClick={secondaryButtonAction}
                            />
                        )}
                        <CoreButton
                            label={buttonText}
                            customColor={buttonColor}
                            onClick={buttonAction}
                            className={classes.button}
                        />
                    </CoreFlex>
                </TableCell>
                <TableCell className={clsx(classes.cell, isExpanded && classes.expandedRow)}>
                    <CoreFlex justify="flex-end">
                        <CoreIconButton iconName="unfold_more" onClick={handleToggleExpand} />
                    </CoreFlex>
                </TableCell>
            </TableRow>
            {isExpanded && (
                <TableRow>
                    <TableCell colSpan={8} className={clsx(classes.clearPaddingMargin, classes.expandedRow)}>
                        <OrderTableRowDetails
                            order={order}
                            headerColor={detailsHeaderColor}
                            onUpdateOrder={onUpdateOrder}
                        />
                    </TableCell>
                </TableRow>
            )}
            {isExpanded && (
                <TableRow className={classes.clearPaddingMargin}>
                    <TableCell colSpan={8} className={classes.clearPaddingMargin}>
                        <div className={classes.backDrop} onClick={() => setIsExpanded(false)}></div>{' '}
                    </TableCell>
                </TableRow>
            )}
        </>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        row: ({ rowHeight }: StyleProps) => ({
            margin: 0,
            padding: 0,
            height: '77px',
        }),
        backDrop: {
            zIndex: 1999,
            position: 'fixed',
            left: 0,
            top: 0,
            bottom: 0,
            right: 0,
            backgroundColor: 'rgba(0,0,0,0.8)',
        },
        expandedRow: {
            zIndex: 2000,
            position: 'relative',
            backgroundColor: theme.palette.common.white,
        },
        clearPaddingMargin: {
            margin: 0,
            padding: 0,
        },
        cell: {
            // margin: "0.5rem 0rem",
            padding: '0.5rem',
        },
        paddingLeft: {
            paddingLeft: '1.5rem',
        },
        button: {
            marginLeft: '1rem',
        },
        iconButton: {
            padding: '0.5rem',
            margin: '0.5rem',
        },
        clearMargin: {
            margin: 0,
            padding: 0,
            height: '100%',
        },
        hover: {
            '&:hover': {
                backgroundColor: `rgba(0,0,0,0.02)`,
            },
        },
        counter: (props: StyleProps) => ({
            backgroundColor: props.timerColor,
            borderRadius: '1rem',
            height: '30px',
            width: '120px',
            fontWeight: 900,
            color: theme.palette.common.white,
        }),
        productImage: {
            width: '30px',
            height: '30px',
            margin: '0rem 0.3rem',
        },
        itemsCell: {
            height: '100%',
            padding: '0rem',
            margin: '0rem',
        },
        itemsBox: {
            // backgroundColor: theme.palette.grey[600],
            // color: theme.palette.common.white,
            padding: '0rem 1rem',
            width: '270px',
            margin: 0,
            height: '77px',
        },
        productImageWrapper: {
            marginLeft: '1rem',
            padding: '0.5rem 0rem',
            height: '100%',
        },
        orderType: {
            opacity: 0.8,
        },
        ...useCoreAnimations(theme),
    }),
);
