import React, { FormEvent } from 'react';
import { InputType } from '../../interfaces';
import { Grid, Typography, Button } from '@material-ui/core';
import CoreFormInput from '../core/form/input/CoreFormInput';
import logo from '../../assets/logo/munchies_logo.png';
import { useDeviceType } from '../../services/utils';
import { grey, green, red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';
import { RegistrationData } from '../../interfaces/auth';
import { stores, useStore } from '../../stores';
import { Restaurant } from '../../interfaces/restaurant';
import CoreFormSelect from '../core/form/select/CoreFormSelect';

interface Props {
    values: RegistrationData;
    validation: any;
    classes: Record<string, string>;
    onRegisterAccount: () => void;
    onValueChange: (name: keyof RegistrationData, value: string | number | undefined) => void;
}

export function RegistrationForm({ values, validation, classes, onRegisterAccount, onValueChange }: Props) {
    const { isMobile } = useDeviceType();
    const internalClasses = useStyles();
    const [restaurants] = useStore<Restaurant[]>(stores.restaurants);
    classes = { ...classes, ...internalClasses };

    const selectClass = values.restaurantId ? classes.selectSuccess : classes.select;

    return (
        <form onSubmit={onRegisterAccount}>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                alignContent="center"
                spacing={2}
                className={isMobile ? classes.formContainerMobile : classes.formContainer}
            >
                <Grid item>{isMobile && <img src={logo} className={classes.logoMobile} alt="Munchies Logo" />}</Grid>
                <Grid item className={classes.gridItem}>
                    <Typography variant="h5" align="center">
                        Staff Registration
                    </Typography>
                </Grid>
                <Grid item className={classes.gridItem}>
                    <CoreFormInput
                        autoCapitalize="words"
                        value={values.firstName}
                        onValueChange={(value) => onValueChange('firstName', value)}
                        label="First name"
                        placeholder="Enter your first name"
                        name="firstName"
                        id="firstName"
                        autoFocus
                        validation={validation.name}
                        required
                        type={InputType.text}
                    />
                    <CoreFormInput
                        autoCapitalize="words"
                        value={values.lastName}
                        onValueChange={(value) => onValueChange('lastName', value)}
                        label="Last name"
                        placeholder="Enter your last name"
                        name="lastName"
                        id="lastName"
                        autoFocus
                        validation={validation.name}
                        required
                        type={InputType.text}
                    />
                </Grid>
                <Grid item className={classes.gridItem}>
                    <CoreFormInput
                        value={values.email}
                        onValueChange={(value) => onValueChange('email', value)}
                        label="Email"
                        placeholder="Enter your login e-mail"
                        name="email"
                        id="email"
                        validation={validation.email}
                        required
                        type={InputType.email}
                    />
                </Grid>
                <Grid item className={classes.gridItem}>
                    <CoreFormInput
                        value={values.phone}
                        onValueChange={(value) => onValueChange('phone', value)}
                        label="Phone"
                        placeholder="Enter your phone number"
                        name="phone"
                        validation={validation.phone}
                        id="phone"
                        required
                        type={InputType.phone}
                    />
                </Grid>
                {Boolean(restaurants.length) && (
                    <Grid item className={classes.gridItem}>
                        <CoreFormSelect
                            className={selectClass}
                            fullWidth
                            value={values.restaurantId || undefined}
                            onValueChange={(value) => onValueChange('restaurantId', value as number)}
                            options={restaurants.map((restaurant) => {
                                return {
                                    label: restaurant.translations?.en?.name || restaurant.name || '',
                                    value: restaurant.id,
                                };
                            })}
                        />
                    </Grid>
                )}
                <Grid item className={classes.gridItem}>
                    <CoreFormInput
                        value={values.password}
                        type={InputType.password}
                        onValueChange={(value) => onValueChange('password', value)}
                        label="Password"
                        placeholder="Enter your password"
                        name="password"
                        id="password"
                        validation={validation.password}
                        helperText={
                            'Password must include uppercase and lowercase letters numbers and symbols and be at least 8 characters long.'
                        }
                    />
                </Grid>
                <Grid item className={classes.gridItem}>
                    <CoreFormInput
                        value={values.passwordRepeat || ''}
                        type={InputType.password}
                        onValueChange={(value) => onValueChange('passwordRepeat', value)}
                        placeholder="Please repeat the password"
                        validation={validation.passwordRepeat}
                        label="Repeat password"
                        name="repeatPassword"
                        id="repeatPassword"
                    />
                </Grid>
                <Grid item className={classes.gridItem}>
                    <Button variant="contained" size="large" color="primary" fullWidth type="submit">
                        Register Account
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}

const useStyles = makeStyles({
    select: {
        '&:before': {
            borderColor: grey[500],
        },
        '&:after': {
            borderColor: grey[500],
        },
    },
    selectSuccess: {
        '&:before': {
            borderColor: green[500],
        },
        '&:after': {
            borderColor: green[500],
        },
    },
    main: {
        borderColor: grey[500],
    },
    error: {
        borderWidth: '2px',
        borderColor: red[500],
    },
    validated: {
        borderWidth: '2px',
        borderColor: green[500],
    },
    helperText: {
        textAlign: 'center',
    },
    capitalize: {
        textTransform: 'capitalize',
    },
});
