import React, { useState, useContext, useEffect } from 'react';
import { Grid, Paper, Theme, Typography } from '@material-ui/core';
import CoreFormInput from '../../../components/core/form/input/CoreFormInput';
import { InputType, ValidationItem, ValidationType } from '../../../interfaces';
import { makeStyles, createStyles } from '@material-ui/styles';
import { useStore } from '../../../services/store';
import { stores } from '../../../stores';
import { AuthContext } from '../../../context/AuthContext';
import CoreFlex from '../../../components/core/flex/CoreFlex';
import { validate } from '../../../services/validation';
import { updateOperator, createOperator, removeOperator, getOperatorById } from '../../../microservices/operator';
import CoreCrudToolbar from '../../../components/core/crud-toolbar/CoreCrudToolbar';
import CoreFormSelect from '../../../components/core/form/select/CoreFormSelect';
import { useRouter, getRoute } from '../../../services/router';
import { ROUTES } from '../../../routes';
import { getDefaultOperator, Operator } from '../../../interfaces/operator';
import { Language } from '../../../config/translation';
import { CoreFlagElement } from '../../../components/core/flag-element/CoreFlagElement';
import { showError, showSuccess } from '../../../services/alert';
import { Country } from '../../../interfaces/country';
import CoreSwitch from '../../../components/core/switch/CoreSwitch';
import CoreFormInputAddress from '../../../components/core/form/input/address/CoreFormInputAddress';
import { RestaurantType } from '../../../interfaces/restaurant';
import CoreFormDatePicker from '../../../components/core/form/date-picker/CoreFormDatePicker';
import { getUserTypeName, User } from '../../../interfaces/user';
import { getUsers } from '../../../microservices/user';
import { isArray } from 'lodash';
import RestaurantTypeBox from '../../../components/restaurant/type-box/RestaurantTypeBox';
import { useCoreAnimations } from '../../../config/styles/animations';
import { prepareObjectForCloning } from '../../../services/utils';

export default function OperatorDetailPage() {
    const { navigateTo, params } = useRouter();
    const authContext = useContext(AuthContext);
    const classes = useStyles();
    const [operator, setOperator] = useState<Operator>(getDefaultOperator());
    const [language, setLanguage] = useStore<Language>(stores.language);
    const [countries] = useStore<Country[]>(stores.countries);
    const [users, setUsers] = useState<User[]>([]);

    const validators = {
        name: () => {
            return validate({
                type: ValidationType.Text,
                value: operator.name,
                message: 'Please enter operator name.',
            });
        },
        legalName: () => {
            return validate({
                value: operator.legalName,
                type: ValidationType.Text,
                minLenght: 5,
                message: 'Please enter legal name of the operator in english, identical to establishment certificate.',
            });
        },
        address: () => {
            return validate({
                validated: operator.address.length > 5,
                message: 'Please enter an official address of the operator.',
            });
        },
        email: () => {
            return validate({
                type: ValidationType.Email,
                value: operator.email,
                // message: 'Please enter an official contact email address for the operator.',
            });
        },
        phoneNumber: () => {
            return validate({
                type: ValidationType.Phone,
                value: operator.phone,
                message: 'Please enter an official phone number of the operator.',
            });
        },
        ceoName: () => {
            return validate({
                type: ValidationType.FullName,
                value: operator.ceoName,
                message: 'Please enter a full name of operator CEO.',
            });
        },
        ceoEmail: () => {
            return validate({
                type: ValidationType.Email,
                value: operator.ceoEmail,
                message: 'Please enter an email address of the operator CEO.',
            });
        },
        ceoPhoneNumber: () => {
            return validate({
                type: ValidationType.Phone,
                value: operator.ceoPhone,
                message: 'Please enter an official phone number of the operator CEO.',
            });
        },
        ownerId: () => {
            return validate({
                validated: Boolean(operator.ownerId),
                message: 'Please select operator owner user.',
            });
        },
        taxNumber: () => {
            return validate({
                validated: operator.taxNumber?.length > 3,
                message: 'Please enter operator tax number.',
            });
        },
        registrationNumber: () => {
            return validate({
                validated: operator.registrationNumber?.length > 3,
                message: 'Please enter operator company official registration number.',
            });
        },
        priceClass: () => {
            return validate({
                validated: operator.priceClass > 0,
                message: 'Please choose operator price class.',
            });
        },
        franchiseFee: () => {
            return validate({
                type: ValidationType.Number,
                value: operator.franchiseFee,
                message: 'Please choose operator franchise fee.',
            });
        },
        restaurantTypes: () => {
            return validate({
                validated: operator.allowedRestaurantTypes.length > 0,
                message: 'Please choose at least one allowed restaurant type.',
            });
        },
        countries: () => {
            return validate({
                validated: operator.countries.length > 0,
                message: 'Please choose at least one operating country for operator.',
            });
        },
    };

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function loadData() {
        const operatorId = parseInt(params.operatorId);
        const cloneId = parseInt(params.cloneId);
        if (operatorId) {
            const selectedOperator = await getOperatorById(operatorId, authContext);

            if (selectedOperator) {
                setOperator(selectedOperator || getDefaultOperator());
            }
        }

        if (cloneId) {
            const clonedOperator = await getOperatorById(cloneId, authContext);
            if (clonedOperator) {
                const cleanedUpRestaurant = prepareObjectForCloning<Operator>(clonedOperator);
                cleanedUpRestaurant.signedAt = new Date();
                setOperator(cleanedUpRestaurant || getDefaultOperator());
            }
        }
        const response = await getUsers({}, authContext);
        setUsers(isArray(response) ? response : [response]);
    }

    function handleAddCountry(countryId: number) {
        if (operator.countries.includes(countryId)) {
            return;
        }
        handleValueChange('countries', operator.countries.concat([countryId]));
    }

    function handleRemoveCountry(countryId: number) {
        if (!operator.countries.includes(countryId)) {
            return;
        }
        handleValueChange(
            'countries',
            operator.countries.filter((item) => item !== countryId),
        );
    }

    function handleValueChange(name: keyof Operator, value: string | number | boolean | any[] | Date) {
        setOperator({
            ...operator,
            [name]: value,
        });
    }

    function validateAllValidators() {
        Object.keys(validators).some((validatorId: string) => {
            const validator = validators[validatorId as keyof typeof validators];
            const validationResult = validator() as ValidationItem;
            const isValidated = validationResult.validated;
            if (!validator || !validationResult || !isValidated) {
                return { validated: false, cause: validatorId, message: validationResult.message };
            }
        });
        return { validated: true, cause: '', message: '' };
    }

    async function handleSaveOperator() {
        const validationResult = validateAllValidators();
        if (!validationResult.validated) {
            showError(`Problem with operator validation at ${validationResult.cause}: ${validationResult.message}`);
            return;
        }

        const response = operator.id
            ? await updateOperator(operator.id, operator, authContext)
            : await createOperator(operator, authContext);

        console.log('WTF', response, response.success, response.result, response.result?.id);
        if (response && response.success && response.result && response.result.id) {
            setOperator(response.result);
            showSuccess('Operator updated successfully');
            navigateTo(getRoute(ROUTES.operator));
        } else {
            showError('Something went wrong: ' + response.message);
        }
    }

    async function handleDeleteOperator() {
        if (operator.id) {
            await removeOperator(operator.id, authContext);
        }
        navigateTo(getRoute(ROUTES.operator));
    }

    function handleCancel() {
        navigateTo(getRoute(ROUTES.operator));
    }

    function handleToggleType(type: RestaurantType) {
        if (operator.allowedRestaurantTypes.includes(type)) {
            handleValueChange(
                'allowedRestaurantTypes',
                operator.allowedRestaurantTypes.filter((item) => item !== type),
            );
        } else {
            handleValueChange('allowedRestaurantTypes', operator.allowedRestaurantTypes.concat([type]));
        }
    }

    return (
        <form autoComplete="off">
            <CoreCrudToolbar
                allowDelete={authContext.isAdmin}
                object={operator}
                objectName="Operator"
                onCancel={handleCancel}
                onSave={handleSaveOperator}
                onDelete={handleDeleteOperator}
                onToggleVisibility={() => {
                    handleValueChange('isActive', !operator.isActive);
                }}
                onChangeLanguage={setLanguage}
            />

            <Paper className={classes.paper}>
                <Grid container direction="column" justify="space-between">
                    <Grid item className={classes.formField}>
                        <Typography variant="h6">Operator details</Typography>
                    </Grid>
                    <CoreFlex alignItems="flex-start">
                        <CoreFlex direction="column">
                            <CoreFlex>
                                <CoreFormInput
                                    className={classes.formField}
                                    autoFocus
                                    required
                                    validation={validators.name}
                                    label="Operator name"
                                    value={operator.name || ''}
                                    type={InputType.text}
                                    onValueChange={(value) => handleValueChange('name', value)}
                                    placeholder="Please enter an operator name"
                                />
                                <CoreFormInput
                                    className={classes.smallField}
                                    validation={validators.legalName}
                                    label="Legal name"
                                    placeholder="Enter official operator company name"
                                    required
                                    value={operator.legalName || ''}
                                    type={InputType.text}
                                    onValueChange={(value) => handleValueChange('legalName', value)}
                                />
                                <CoreFormInput
                                    className={classes.smallField}
                                    validation={validators.registrationNumber}
                                    label="Registration number"
                                    value={operator.registrationNumber + ''}
                                    onValueChange={(value) => handleValueChange('registrationNumber', value)}
                                />
                                <CoreFormInput
                                    className={classes.smallField}
                                    validation={validators.taxNumber}
                                    label="Tax number"
                                    value={operator.taxNumber + ''}
                                    onValueChange={(value) => handleValueChange('taxNumber', value)}
                                />
                            </CoreFlex>

                            <CoreFlex alignItems="flex-start">
                                <CoreFormInputAddress
                                    value={operator.address}
                                    validation={validators.address}
                                    className={classes.addressField}
                                    onChangeCoordinates={() => {}}
                                    onValueChange={(value: string) => handleValueChange('address', value as string)}
                                />
                                <CoreFormInput
                                    className={classes.smallField}
                                    label="Phone number"
                                    validation={validators.phoneNumber}
                                    required
                                    value={operator.phone}
                                    type={InputType.phone}
                                    onValueChange={(value) => handleValueChange('phone', value)}
                                    placeholder="Enter phone number"
                                />
                                <CoreFormInput
                                    className={classes.smallField}
                                    validation={validators.email}
                                    label="Email address"
                                    required
                                    value={operator.email}
                                    type={InputType.email}
                                    onValueChange={(value) => handleValueChange('email', value)}
                                    placeholder="Enter email address"
                                />
                                <CoreFormSelect
                                    label="Owner reprasentative"
                                    value={operator.ownerId || 0}
                                    className={classes.ownerSelect}
                                    onValueChange={(value) => handleValueChange('ownerId', value as number)}
                                    options={users.map((item) => {
                                        return {
                                            label: `${item.firstName} ${item.lastName} (${getUserTypeName(
                                                item.type,
                                            ).replaceAll('_', ' ')})`,
                                            image: item.photo,
                                            value: item.id,
                                        };
                                    })}
                                />
                            </CoreFlex>
                        </CoreFlex>
                    </CoreFlex>
                    <CoreFlex>
                        <CoreFlex direction="column" className={classes.specialBlock}>
                            <Grid item className={classes.formField}>
                                <Typography variant="h6">Operator countries</Typography>
                            </Grid>
                            <CoreFlex direction="column" className={classes.languageBlock}>
                                <CoreFlex justify="flex-start">
                                    {operator.countries.map((countryId) => (
                                        <CoreFlagElement
                                            key={countryId}
                                            countryId={countryId}
                                            onClick={(value) => handleRemoveCountry(value as number)}
                                            className={classes.flag}
                                        />
                                    ))}
                                </CoreFlex>
                                <CoreFormSelect
                                    label="Add country"
                                    value={''}
                                    onValueChange={(value) => handleAddCountry(value as number)}
                                    options={countries
                                        .filter((item) => item.id && !operator.countries.includes(item.id))
                                        .map((item) => {
                                            return {
                                                label: item.translations?.[language]?.name || item.name || 'n/a',
                                                image: item.flag,
                                                value: item.id,
                                            };
                                        })}
                                />
                            </CoreFlex>
                        </CoreFlex>
                        <CoreFlex direction="column">
                            <Grid item className={classes.formField}>
                                <Typography variant="h6">Allowed restaurant types</Typography>
                            </Grid>
                            <CoreFlex>
                                {Object.keys(RestaurantType).map((type) => {
                                    return (
                                        <RestaurantTypeBox
                                            key={type}
                                            type={type as RestaurantType}
                                            selected={operator.allowedRestaurantTypes.includes(type as RestaurantType)}
                                            onClick={handleToggleType}
                                        />
                                    );
                                })}
                            </CoreFlex>
                        </CoreFlex>
                    </CoreFlex>
                    <Grid item className={classes.formField}>
                        <Typography variant="h6">CEO details</Typography>
                    </Grid>
                    <CoreFlex>
                        <CoreFormInput
                            className={classes.smallField}
                            validation={validators.ceoName}
                            label="CEO name"
                            value={operator.ceoName || ''}
                            onValueChange={(value) => handleValueChange('ceoName', value)}
                        />
                        <CoreFormInput
                            className={classes.smallField}
                            validation={validators.ceoPhoneNumber}
                            label="CEO phone number"
                            value={operator.ceoPhone || ''}
                            onValueChange={(value) => handleValueChange('ceoPhone', value)}
                        />
                        <CoreFormInput
                            className={classes.smallField}
                            validation={validators.ceoEmail}
                            label="CEO email"
                            value={operator.ceoEmail || ''}
                            onValueChange={(value) => handleValueChange('ceoEmail', value)}
                        />
                    </CoreFlex>
                    <Grid item className={classes.formField}>
                        <Typography variant="h6">Franchise details</Typography>
                    </Grid>
                    <CoreFlex alignItems="flex-start">
                        <CoreFlex direction="column">
                            <Typography variant="caption">Is franchise?</Typography>
                            <CoreSwitch
                                value={operator.isFranchise}
                                onValueChange={(value) => handleValueChange('isFranchise', !operator.isFranchise)}
                                color="secondary"
                            />
                        </CoreFlex>
                        <CoreFormInput
                            disabled={!operator.isFranchise}
                            className={classes.smallField}
                            validation={validators.franchiseFee}
                            label="Franchise fee"
                            value={operator.franchiseFee + ''}
                            onValueChange={(value) => handleValueChange('franchiseFee', value)}
                        />
                        <CoreFormInput
                            disabled={!operator.isFranchise}
                            className={classes.smallField}
                            validation={validators.priceClass}
                            label="Price class"
                            value={operator.priceClass + ''}
                            onValueChange={(value) => handleValueChange('priceClass', value)}
                        />
                        <CoreFormDatePicker
                            label="Signed at"
                            disabled={!operator.isFranchise}
                            className={classes.formField}
                            value={operator.signedAt}
                            onValueChange={(value) => value && handleValueChange('signedAt', value)}
                        />
                    </CoreFlex>
                </Grid>
            </Paper>
        </form>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formField: {
            padding: '0.5rem 0.5rem',
            minWidth: '300px',
            width: '100%',
        },
        addressField: {
            padding: '0.5rem 0.5rem',
            minWidth: '500px',
            width: '100%',
        },
        select: {
            width: '100%',
        },

        smallField: {
            padding: '0.5rem 0.5rem',
            minWidth: '200px',
            width: '100%',
        },
        priceSample: {
            margin: '1rem 1rem',
            padding: '1rem 1rem',
            maxWidth: '300px',
            minWidth: '300px',
            borderRadius: '8px',
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
        },
        switch: {
            minWidth: '100px',
            padding: '0.5rem 1rem',
            textAlign: 'center',
        },
        imageUpload: {
            margin: '1rem 1rem',
        },
        paper: {
            margin: '2rem',
            padding: '2rem',
        },
        flag: {
            height: '25px',
            margin: '0.5rem',
            marginBottom: '1rem',
        },
        languageBlock: {
            backgroundColor: 'rgba(0,0,0,0.05)',
            padding: '1rem',
            borderRadius: '1rem',
            margin: '0.5rem',
        },
        specialBlock: {
            margin: '0.5rem',
        },
        restaurantType: {
            margin: '0.5rem',
            padding: '0.5rem',
            backgroundColor: 'rgba(0,0,0,0.05)',
            borderRadius: '1rem',
            textAlign: 'center',
            width: '100px',
            height: '120px',
            cursor: 'pointer',
        },
        restaurantTypeSelected: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
        },
        ownerSelect: {
            marginTop: '0.5rem',
        },
        ...useCoreAnimations(theme),
    }),
);
