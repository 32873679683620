export const STORE_NAME_USER_DEVICE = 'userDevice';
export const STORE_NAME_USER_DEVICE_ORIENTATION = 'userDeviceOrientation';

export enum DeviceType {
    Mobile = 'Mobile',
    Tablet = 'Tablet',
    Desktop = 'Desktop',
}
export enum DeviceOrientation {
    Portrait = 'Portrait',
    Landscape = 'Landscape',
}
