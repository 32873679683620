import { ApiResult } from './../interfaces/common';
import { Category } from './../interfaces/category';
import { httpGet, httpPost, httpPut, httpDelete } from '../services/api';
import { AuthContextInterface } from '../context/AuthContext';
import { showError, showSuccess } from '../services/alert';

export async function getCategory(id: number, authContext?: Partial<AuthContextInterface>): Promise<Category | null> {
    try {
        const response = await httpGet(`category/get/${id}`, {}, authContext);
        if (response.success && response.result) {
            const category: Category = response.result;
            return category;
        } else {
            showError("Something's wrong: " + response.message);
            return null;
        }
    } catch (err) {
        showError('Error retriveing category ' + err);
        return null;
    }
}

export async function getCategories(
    restaurantId: number,
    authContext: Partial<AuthContextInterface>,
): Promise<Category[]> {
    try {
        const response: ApiResult = await httpGet(`category/find/${restaurantId}`, {}, authContext);
        if (response.success && response.result) {
            const categories: Category[] = response.result;
            return categories;
        } else {
            showError("Something's wrong when retreaving categories " + response.message);
            return [];
        }
    } catch (err) {
        showError('Error retriveing categories ' + err);
        return [];
    }
}

export async function createCategory(
    categoryData: Category,
    authContext?: Partial<AuthContextInterface>,
): Promise<ApiResult> {
    try {
        const response = await httpPost('category/new', categoryData, authContext);
        if (response.success && response.result) {
            showSuccess(`Category ${response.result?.id} added successfully`);
            return response;
        } else {
            showError("Something's wrong with creating a category " + response.message);
            return response;
        }
    } catch (err) {
        showError('Error when creating a category ' + err);
        return err;
    }
}

export async function updateCategory(
    id: number,
    updatedData: Record<string, any>,
    authContext?: Partial<AuthContextInterface>,
) {
    try {
        const response = await httpPut(`category/update/${id}`, updatedData, authContext);
        if (response.success && response.result) {
            showSuccess(`Category ${id} updated successfully`);
            return response;
        } else {
            showError("Something's wrong with updating a category " + response.message);
            return response;
        }
    } catch (err) {
        showError('Error when updating a category ' + err);
        return err;
    }
}

export async function removeCategory(id: number, authContext?: Partial<AuthContextInterface>) {
    try {
        const response = await httpDelete(`category/remove/${id}`, {}, authContext);
        if (response.success && response.result) {
            showSuccess(`Category ${id} deleted successfully`);
            return response;
        } else {
            showError("Something's wrong with deleting a category " + response.message);
            return response;
        }
    } catch (err) {
        showError('Error when deleting a category' + err);
        return err;
    }
}
