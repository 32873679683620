import React, { useState } from 'react';
import CoreFlex from '../../../core/flex/CoreFlex';
import { AlertMessage } from '../../../../interfaces';
import CoreChip from '../../../core/chip/CoreChip';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Theme, Typography } from '@material-ui/core';
import CoreFormInput from '../../../core/form/input/CoreFormInput';
import CoreButton from '../../../core/button/CoreButton';
import { CoreSnackbar } from '../../../core/snackbar/CoreSnackbar';
import { grey } from '@material-ui/core/colors';

interface Props {
    tags: string[];
    onAddTag: (value: string) => void;
    onDeleteTag: (value: string) => void;
}

export default function ProductsFormTags({ tags, onDeleteTag, onAddTag }: Props) {
    const [showMessage, setShowMessage] = useState<AlertMessage | undefined>();
    const [tagValue, setTagValue] = useState('');
    const classes = useStyles();

    function handleAddTag(): void {
        const cleanTagValue = tagValue.replace(/[#!@#%^&&*()_+|"|||<>?|\s;:.,']/g, '');
        if (!cleanTagValue.length) {
            setShowMessage({
                type: 'error',
                message: 'Error: Please enter tag value',
            });
            return;
        }
        if (tags.includes(cleanTagValue.toLowerCase())) {
            setShowMessage({
                type: 'error',
                message: `Error: You already have a tag called ${cleanTagValue}.`,
            });
            return;
        }
        setTagValue('');
        onAddTag(cleanTagValue);
    }

    return (
        <>
            {showMessage && (
                <CoreSnackbar
                    variant={showMessage.type}
                    message={showMessage.message}
                    onClose={() => setShowMessage(undefined)}
                />
            )}
            <CoreFlex>
                <CoreFlex direction="column">
                    <CoreFlex justify="space-between" className={classes.cardHeader}>
                        <Typography variant="h6">Tags</Typography>
                        <CoreButton label="Add tag" onClick={handleAddTag} />
                    </CoreFlex>
                    {tags && (
                        <CoreFlex justify="flex-start" className={classes.tagBox} wrap="wrap">
                            {tags.map((tag) => {
                                return (
                                    <CoreChip color={grey[500]} key={tag} label={'#' + tag} onDelete={onDeleteTag} />
                                );
                            })}
                        </CoreFlex>
                    )}
                    <CoreFlex>
                        <CoreFormInput
                            className={classes.inputField}
                            onSubmit={() => {
                                handleAddTag();
                            }}
                            value={tagValue}
                            label="Enter tag name here"
                            onValueChange={setTagValue}
                        />
                    </CoreFlex>
                </CoreFlex>
            </CoreFlex>
        </>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tagBox: {
            paddingBottom: '1rem',
        },
        inputField: {
            width: '100%',
            paddingRight: '1rem',
        },
        cardHeader: {
            padding: '1rem 0.5rem',
        },
    }),
);
