import React, { useState, ChangeEvent, useContext, useEffect } from 'react';
import { Grid, Paper, Theme, Typography } from '@material-ui/core';
import { InputType, ValidationType, AlertMessage } from '../../../interfaces';
import { makeStyles, createStyles } from '@material-ui/styles';
import { AuthContext } from '../../../context/AuthContext';
import CoreFlex from '../../../components/core/flex/CoreFlex';
import { validate } from '../../../services/validation';
import CoreCrudToolbar from '../../../components/core/crud-toolbar/CoreCrudToolbar';
import { CoreImageUpload } from '../../../components/core/image-upload/CoreImageUpload';
import { useRouter, getRoute } from '../../../services/router';
import { ROUTES } from '../../../routes';
import { removeCategory, updateCategory, createCategory, getCategory } from '../../../microservices/category';
import { CoreSnackbar } from '../../../components/core/snackbar/CoreSnackbar';
import { Category, CategoryTranslation, getDefaultCategory } from '../../../interfaces/category';
import { Language } from '../../../config/translation';
import { stores, useStore } from '../../../stores';
import CoreTranslatedInput from '../../../components/core/form/input/translated/CoreTranslatedInput';
import { Country } from '../../../interfaces/country';
import { prepareObjectForCloning } from '../../../services/utils';
import { cloneDeep } from 'lodash';

export default function CategoryDetailPage() {
    const [showMessage, setShowMessage] = useState<AlertMessage | undefined>();
    const { navigateTo, params } = useRouter();
    const authContext = useContext(AuthContext);
    const classes = useStyles();
    const [category, setCategory] = useState<Category>(getDefaultCategory());
    const [categories, setCategories] = useStore<Category[]>(stores.categories);
    const [language, setLanguage] = useStore<Language>(stores.language);
    const [selectedCountry] = useStore<Country>(stores.selectedCountry);

    const validators = {
        name: () => {
            return validate({
                type: ValidationType.Text,
                value: (category && category.name) || '',
            });
        },
        description: () => {
            return validate({
                type: ValidationType.Text,
                value: (category && category.translations?.en?.description) || '' || '',
            });
        },
    };

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function loadData() {
        const categoryId = parseInt(params.categoryId);
        const cloneId = parseInt(params.cloneId);
        if (categoryId) {
            const category = await getCategory(categoryId, authContext);
            setCategory(category || getDefaultCategory());
        }

        if (cloneId) {
            const clonedCategory = await getCategory(cloneId, authContext);
            if (clonedCategory) {
                const cleanedUpCategory = prepareObjectForCloning<Category>(clonedCategory);
                console.log('cleanedUpCategory', cleanedUpCategory);
                setCategory(cleanedUpCategory || getDefaultCategory());
            }
        }

        if (params.restaurantId) {
            setCategory({
                ...category,
                restaurantId: params.restaurantId || 0,
            });
        }
    }

    function handleValueChange(
        name: keyof Category | keyof CategoryTranslation,
        value: string | number | boolean,
        language?: Language,
    ) {
        if (language) {
            setCategory({
                ...category,
                translations: {
                    ...category.translations,
                    [language]: {
                        ...category.translations?.[language],
                        [name]: value,
                    },
                },
            });
            return;
        }
        setCategory({
            ...category,
            [name]: value,
        });
    }

    function handleImageUploaded(imageUrl: string) {
        handleValueChange('photo', imageUrl);
    }

    async function handleSaveCategory() {
        if (!validators.name().validated || !validators.description().validated) {
            setShowMessage({
                type: 'error',
                message:
                    'Error: Problem with category validation. Please check that all the fields are filled in correctly.',
            });
            return;
        }

        const response = category.id
            ? await updateCategory(category.id, category, authContext)
            : await createCategory(category, authContext);

        if (response && response.success && response.result) {
            navigateTo(getRoute(ROUTES.product, { restaurantId: category.restaurantId }));
            const newCategories = cloneDeep(categories);
            if (category.id) {
                newCategories.forEach((item, index) => {
                    newCategories[index] = item.id === category.id ? response.result : item;
                });
            } else {
                newCategories.push(response.result);
            }
            console.log('newCategories', newCategories);
            setCategories(newCategories);
        } else {
            setShowMessage({
                type: 'error',
                message: `Error: Saving category. ${response.message}`,
            });
        }
    }

    async function handleDeleteCategory() {
        if (category.id) {
            await removeCategory(category.id, authContext);
        }
        navigateTo(getRoute(ROUTES.product, { restaurantId: category.restaurantId }));
    }

    function handleCancel() {
        navigateTo(getRoute(ROUTES.product, { restaurantId: category.restaurantId }));
    }

    return (
        <form autoComplete="off">
            {showMessage && (
                <CoreSnackbar
                    variant={showMessage.type}
                    message={showMessage.message}
                    onClose={() => setShowMessage(undefined)}
                />
            )}
            <CoreCrudToolbar
                object={category}
                switchDisabled={!authContext.isAdmin && !authContext.isManager}
                objectName="Category"
                allowDelete={authContext.isManager}
                onCancel={handleCancel}
                onSave={handleSaveCategory}
                onDelete={handleDeleteCategory}
                languages={selectedCountry?.languages || []}
                onChangeLanguage={setLanguage}
                translationObject={category.translations}
                selectedLanguage={language}
                translationKeys={['name', 'description'] as (keyof CategoryTranslation)[]}
                onToggleVisibility={() => {
                    handleValueChange('isActive', !category.isActive);
                }}
            />

            <Paper className={classes.paper}>
                <Grid container direction="column" justify="space-between">
                    <Grid item className={classes.formField}>
                        <Typography variant="h6">Category details</Typography>
                    </Grid>
                    <CoreFlex>
                        <CoreFlex direction="column">
                            <CoreTranslatedInput
                                translationObject={category.translations || {}}
                                language={language}
                                keyName="name"
                                className={classes.formField}
                                validation={validators.name}
                                autoFocus
                                required
                                label="Category name"
                                value={category.name || ''}
                                type={InputType.text}
                                onValueChange={(value) => handleValueChange('name', value, language)}
                                placeholder="Please enter a category name"
                            />
                            <CoreTranslatedInput
                                translationObject={category.translations || {}}
                                language={language}
                                keyName="description"
                                className={classes.formField}
                                validation={validators.description}
                                label="Description"
                                placeholder="Write a description for the menu category"
                                rows={4}
                                multiline
                                required
                                value={''}
                                type={InputType.text}
                                onValueChange={(value) => handleValueChange('description', value, language)}
                            />
                        </CoreFlex>
                        <CoreImageUpload
                            className={classes.imageUpload}
                            label="Category Image"
                            aspect={1 / 1}
                            basePath="category"
                            width={200}
                            height={200}
                            imageUrl={category.photo}
                            onUploadComplete={handleImageUploaded}
                            requirements={{
                                destinationWidth: 500,
                                destinationHeight: 500,
                            }}
                        />
                    </CoreFlex>
                </Grid>
            </Paper>
        </form>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formField: {
            padding: '0.5rem 0.5rem',
            minWidth: '300px',
            width: '100%',
        },
        select: {
            width: '100%',
        },

        smallField: {
            padding: '0.5rem 0.5rem',
            minWidth: '200px',
            width: '100%',
        },
        priceSample: {
            margin: '1rem 1rem',
            padding: '1rem 1rem',
            maxWidth: '300px',
            minWidth: '300px',
            borderRadius: '8px',
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
        },
        switch: {
            minWidth: '100px',
            padding: '0.5rem 1rem',
            textAlign: 'center',
        },
        imageUpload: {
            margin: '1rem 1rem',
        },
        paper: {
            margin: '2rem',
            padding: '2rem',
        },
    }),
);
