import React, { ChangeEvent, useState, useEffect } from 'react';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Geocode from 'react-geocode';
import CoreFormInput from '../CoreFormInput';
import { stores } from '../../../../../stores';
import { ValidationItem } from '../../../../../interfaces';

export interface AddressResult {
    lat: number;
    long: number;
    title: string;
    country?: string;
    city?: string;
    street?: string;
    house?: string;
    postCode?: string;
}

export interface AddressComponent {
    long_name: string;
    short_name: string;
    types: string[];
}

interface RenderSuggestionProps {
    highlightedIndex: number | null;
    index: number;
    selectedItem: AddressResult['title'];
    suggestion: AddressResult;
}

interface Props {
    value: string;
    onValueChange: (value: string) => void;
    className?: string;
    onChangeCoordinates: (location: AddressResult) => void;
    placeholder?: string;
    require?: boolean;
    validation?: (value: string) => ValidationItem;
}

export default function CoreFormInputAddress({
    value,
    onValueChange,
    className,
    onChangeCoordinates,
    placeholder,
    require = false,
    validation,
}: Props) {
    const classes = useStyles();
    const [inputRef, setInputRef] = useState<any>();
    const [suggestions, setSuggestions] = useState<AddressResult[]>([]);
    const [wasSelected, setWasSelected] = useState<boolean>(true);

    const token = process.env.REACT_APP_GOOGLE_MAP_TOKEN;
    Geocode.setApiKey(token || '');
    Geocode.setLanguage(stores.language);
    Geocode.enableDebug();

    useEffect(() => {
        if (value && !wasSelected) {
            getSuggestions(value);
        }
    }, [value]);

    function handleChange(value: string, event: ChangeEvent<any>) {
        setWasSelected(false);
        onValueChange(value);
        setInputRef(event.target);
    }

    function handleSelection(suggestion: AddressResult) {
        onValueChange(suggestion.title);
        onChangeCoordinates(suggestion);
        setWasSelected(true);
        setSuggestions([]);
    }

    function renderSuggestion(suggestionProps: RenderSuggestionProps) {
        const { suggestion, selectedItem } = suggestionProps;
        const isSelected = (selectedItem || '').indexOf(suggestion.title) > -1;

        return (
            <MenuItem
                key={suggestionProps.index}
                onClick={() => handleSelection(suggestion)}
                component="div"
                style={{
                    fontWeight: isSelected ? 500 : 400,
                }}
            >
                {suggestion.title}
            </MenuItem>
        );
    }

    function getAddressComponentValue(location: any, types: string[]): string {
        if (!location || !location.address_components?.length) {
            return 'n/a';
        }
        const addressComponent: AddressComponent = location.address_components.find((component: any) => {
            let isFound = false;
            types.some((type) => {
                if (component.types.includes(type)) {
                    isFound = true;
                    return;
                }
            });
            if (isFound) {
                return component;
            }
        });

        return addressComponent?.long_name || 'n/a';
    }

    async function getSuggestions(value: string) {
        const addresses: AddressResult[] = [];

        if (value) {
            try {
                const response = await Geocode.fromAddress(value);

                if (response && response.status === 'OK' && response.results && response.results.length) {
                    const locations = response.results;
                    locations.forEach((location: any) => {
                        addresses.push({
                            title: location.formatted_address,
                            lat: location.geometry.location.lat,
                            long: location.geometry.location.lng,
                            country: getAddressComponentValue(location, ['country']),
                            city: getAddressComponentValue(location, [
                                'locality',
                                'administrative_area_level_1',
                                'administrative_area_level_2',
                            ]),
                            street: getAddressComponentValue(location, ['route']),
                            house: getAddressComponentValue(location, ['street_number']),
                            postCode: getAddressComponentValue(location, ['postal_code']),
                        });
                    });
                }
            } catch (error) {
                console.log('Error with address input: ' + error);
            }
        }
        setSuggestions(addresses);
    }

    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <CoreFormInput
                    value={value}
                    validation={validation}
                    onValueChange={handleChange}
                    label="Address"
                    required={require}
                    className={className}
                    placeholder={placeholder}
                    autoComplete="chrome-off"
                />
                <Popper open={suggestions.length > 0} anchorEl={inputRef} className={classes.popper}>
                    <Paper
                        square
                        style={{
                            marginTop: 8,
                            width: inputRef ? inputRef.clientWidth : undefined,
                        }}
                    >
                        {suggestions.map((suggestion: AddressResult, index: number) =>
                            renderSuggestion({
                                suggestion,
                                index,
                                highlightedIndex: 0,
                                selectedItem: 'selected item',
                            }),
                        )}
                    </Paper>
                </Popper>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            maxHeight: 50,
        },
        container: {
            flexGrow: 1,
            position: 'relative',
        },
        paper: {
            position: 'absolute',
            zIndex: 1,
            marginTop: theme.spacing(1),
            left: 0,
            right: 0,
        },
        inputRoot: {
            flexWrap: 'wrap',
        },
        inputInput: {
            width: 'auto',
            flexGrow: 1,
        },
        divider: {
            height: theme.spacing(2),
        },
        popper: {
            zIndex: 1000,
        },
    }),
);
