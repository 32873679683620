import { Language } from './../config/translation';
import { Country } from './../interfaces/country';

export function populateCountry(country: Country): Country {
    const languages = Object.keys(country.translations || {});
    const firstLanguage = languages.length ? languages[0] : null;
    return {
        ...country,
        name:
            country.translations?.en?.name || firstLanguage
                ? country.translations?.[firstLanguage as Language]?.name
                : '',
    };
}

export function populateCountries(countries: Country[]) {
    return countries.map((country) => populateCountry(country));
}
