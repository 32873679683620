import { useRef } from 'react';
import { debounce } from 'lodash';

interface Props {
    callback: () => void;
    delay: number;
    options?: Record<string, any>;
}

export function useDebounce(callback: (...args: any) => any, delay: number, options?: Record<string, any>) {
    return useRef(debounce(callback, delay, options)).current;
}
