import React from 'react';
import { Route, RouteProps, Switch, Redirect } from 'react-router-dom';
import { useStore } from '../../../../services/store';
import { stores } from '../../../../stores';

export default function CoreNavigationHandler() {
    const [routes] = useStore<RouteProps[]>(stores.routes);
    return (
        <Switch>
            {routes.map((route: RouteProps) => (
                <Route key={String(route.path)} {...route} />
            ))}
            <Redirect to="/dashboard" />
        </Switch>
    );
}
