import React from 'react';
import CoreFormInput, { CoreFormInputProps } from '../CoreFormInput';
import { InputType } from '../../../../../interfaces';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { getLanguageName, Language, TranslationFlag } from '../../../../../config/translation';
import CoreFlex from '../../../flex/CoreFlex';

interface Props extends CoreFormInputProps {
    translationObject: Partial<Record<Language, any>>;
    language: Language;
    keyName: string;
}

export default function CoreTranslatedInput({ translationObject, language, keyName, ...rest }: Props) {
    const classes = useStyles();

    if (!translationObject || !language || !keyName) {
        return null;
    }

    const value = translationObject[language] ? translationObject[language][keyName] || '' : '';
    const flagUrl = TranslationFlag[language];
    const languageName = getLanguageName(language);

    return (
        <CoreFormInput
            {...rest}
            value={value}
            type={InputType.text}
            endAdornment={
                <CoreFlex>
                    <Typography variant="subtitle1" className={classes.languageName}>
                        {getLanguageName(language).toUpperCase()}
                    </Typography>
                    <img src={flagUrl} alt={languageName.toString().toLowerCase()} className={classes.flag} />
                </CoreFlex>
            }
        />
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        flag: {
            height: '25px',
        },
        languageName: {
            marginRight: '0.5rem',
        },
    }),
);
