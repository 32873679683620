import React, { useState, useEffect, useContext } from 'react';
import { useTheme, createStyles } from '@material-ui/styles';
import { TableColumnType } from '../../interfaces';
import { Icon, makeStyles, Theme, Tooltip, Typography } from '@material-ui/core';
import { getOperators, removeOperator, updateOperator } from '../../microservices/operator';
import CoreTableSimple from '../../components/core/table/simple/CoreTableSimple';
import { AuthContext } from '../../context/AuthContext';
import { useRouter, getRoute } from '../../services/router';
import { ROUTES } from '../../routes';
import { CoreLoader } from '../../components/core/loader/CoreLoader';
import { objectsEqual } from '../../services/utils';
import { Operator } from '../../interfaces/operator';
import { cloneDeep, isArray } from 'lodash';
import { type } from 'os';
import { RestaurantType, RestaurantTypeIcons } from '../../interfaces/restaurant';
import CoreFlex from '../../components/core/flex/CoreFlex';
import { toTitleCase } from '../../services/tools';
import { Country } from '../../interfaces/country';
import { CoreFlagElement } from '../../components/core/flag-element/CoreFlagElement';
import RestaurantTypeBox from '../../components/restaurant/type-box/RestaurantTypeBox';
import { useCoreAnimations } from '../../config/styles/animations';
import clsx from 'clsx';
import UserCard from '../../components/user/card/UserCard';

export default function OperatorPage() {
    const { navigateTo } = useRouter();
    const authContext = useContext(AuthContext);
    const theme = useTheme();
    const [operators, setOperators] = useState<Operator[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const classes = useStyles(theme);

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function loadData() {
        setIsLoading(true);
        const result = await getOperators(authContext);
        if (isArray(result) && !objectsEqual(operators, result)) {
            setOperators(result);
        }
        setIsLoading(false);
    }

    function handleAddNew() {
        navigateTo(getRoute(ROUTES.operator.new));
    }

    function handleEditOperator(id: number) {
        navigateTo(getRoute(ROUTES.operator.edit, { operatorId: id }));
    }

    async function handleToggle(key: keyof Operator, operator: Operator) {
        operators.forEach((item: any) => {
            if (item.id === operator.id && typeof item[key] === 'boolean') {
                item[key] = !operator[key];
            }
        });
        if (operator.id) {
            await updateOperator(operator.id, { [key]: operator[key] }, authContext);
        }
        setOperators(operators);
    }

    function formatRestaurantTypes(types: RestaurantType[], object: Operator): JSX.Element {
        return (
            <CoreFlex>
                {types.map((type: RestaurantType) => {
                    return <RestaurantTypeBox key={type} type={type} compact className={classes.animationScale1_5x} />;
                })}
            </CoreFlex>
        );
    }

    function formatCountries(countriesIds: number[]): JSX.Element {
        return (
            <CoreFlex justify="flex-start">
                {countriesIds.map((countryId, index) => {
                    return (
                        <CoreFlagElement
                            className={clsx(classes.animationScale2x, classes.languageFlag)}
                            key={index}
                            countryId={countryId}
                        />
                    );
                })}
            </CoreFlex>
        );
    }

    function formatOwner(ownerId: number) {
        return <UserCard userId={ownerId} />;
    }

    async function handleRemoveOperator(id: number) {
        await removeOperator(id, authContext);
        setOperators(operators.filter((item) => item.id !== id));
    }

    function handleCloneOperator(id: number) {
        navigateTo(getRoute(ROUTES.operator.clone, { cloneId: id }));
    }

    return (
        <>
            <Typography variant="h5" className={classes.title}>
                Operator management
            </Typography>
            {isLoading && <CoreLoader type="circular" text="Loading operators..." />}
            {!isLoading && (
                <CoreTableSimple
                    onAddNew={handleAddNew}
                    hover
                    onClickRow={() => {}}
                    data={operators}
                    rowHeight={135}
                    onEdit={handleEditOperator}
                    onClone={handleCloneOperator}
                    onRemove={handleRemoveOperator}
                    columns={[
                        {
                            label: 'Active',
                            column: 'isActive' as keyof Operator,
                            type: TableColumnType.Switch,
                            className: classes.switch,
                            action: (value, operator) => handleToggle('isActive', operator),
                        },
                        {
                            label: 'Franchise',
                            column: 'isFranchise' as keyof Operator,
                            type: TableColumnType.Switch,
                            className: classes.switch,
                            action: (value, operator) => handleToggle('isFranchise', operator),
                        },
                        { label: 'Name', column: 'name' },
                        {
                            label: 'Allowed restaurant types',
                            column: 'allowedRestaurantTypes' as keyof Operator,
                            formatFunction: formatRestaurantTypes,
                        },
                        { label: 'Countries', column: 'countries' as keyof Operator, formatFunction: formatCountries },
                        {
                            label: 'Owner representative',
                            column: 'ownerId' as keyof Operator,
                            formatFunction: formatOwner,
                            className: classes.animationScale1_2x,
                        },
                        { label: 'Address', column: 'address' as keyof Operator },
                    ]}
                />
            )}
        </>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            flexGrow: 1,
            margin: theme.spacing(1),
        },
        tableWrapper: {
            maxHeight: '85vh',
            overflow: 'auto',
        },
        flag: {
            maxWidth: '3rem',
        },
        switch: {},
        tableHeader: {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.common.white,
        },
        title: {
            marginBottom: '1rem',
        },
        languageFlag: {
            maxWidth: '1.5rem',
            marginRight: '0.5rem',
        },
        restaurantType: {
            margin: '0.5rem',
            padding: '0.5rem',
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
        },
        ...useCoreAnimations(theme),
    }),
);
