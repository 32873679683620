import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';
import { grey, green, red } from '@material-ui/core/colors';
import { Theme, Select, InputLabel, FormControl, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import clsx from 'clsx';
import CoreFlex from '../../flex/CoreFlex';
import { ValidationItem } from '../../../../interfaces';
import CoreFormInput from '../input/CoreFormInput';

interface Props {
    value: string | number | undefined | Date;
    onValueChange: (value: string | number | undefined | Date) => void;
    options: DropDownOption[];
    className?: string;
    label?: string;
    validation?: (value: string) => ValidationItem;
    fullWidth?: boolean;
    width?: number;
    disabled?: boolean;
}

export interface DropDownOption {
    value: string | number | null | undefined | Date;
    label: string;
    image?: string;
}

interface StyleProps {
    width?: number;
    fullWidth?: boolean;
}

export default function CoreFormSelect({
    value,
    onValueChange,
    options,
    className,
    label,
    validation,
    disabled,
    fullWidth = true,
    width = fullWidth ? undefined : 223,
}: Props) {
    const classes = useStyles({ width, fullWidth });
    const inputProps: Record<string, any> = {};
    const [validationResult, setValidationResult] = useState<ValidationItem | null>(null);
    const inputLabel = useRef<HTMLLabelElement>(null);
    const [labelWidth, setLabelWidth] = React.useState(0);

    useEffect(() => {
        if (!inputLabel.current?.offsetWidth) {
            return;
        }
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    useEffect(() => {
        doValidation();
    }, [value, validation]);

    function handleChange(event: React.ChangeEvent<any>) {
        onValueChange(event.target.value);
        doValidation();
    }

    function doValidation() {
        if (validation) {
            const result = validation(value + '');
            setValidationResult(result);
        } else {
            setValidationResult(null);
        }
    }

    let selectClass;

    if (validationResult && validationResult.validated) {
        selectClass = classes.validated;
    } else if (validationResult && !validationResult.validated) {
        selectClass = classes.error;
    } else {
        selectClass = classes.main;
    }

    return (
        <div className={clsx(classes.root, className)}>
            <FormControl className={classes.formControl} variant="outlined">
                <InputLabel ref={inputLabel}>{label}</InputLabel>
                <Select
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                    className={clsx(classes.select, selectClass)}
                    fullWidth={fullWidth}
                    labelWidth={labelWidth}
                    disabled={disabled}
                    error={Boolean(validation && validationResult && !validationResult?.validated)}
                    value={value}
                    onChange={handleChange}
                    inputProps={inputProps}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: 250,
                                width: 200,
                            },
                        },
                    }}
                >
                    <MenuItem value="">-Select-</MenuItem>
                    {options.map((option, index) => (
                        <MenuItem value={option.value as any} key={index}>
                            <CoreFlex wrap="nowrap">
                                {option.image && (
                                    <ListItemIcon className={classes.removeMarginPadding}>
                                        <img src={option.image} alt={option.label} className={classes.image} />
                                    </ListItemIcon>
                                )}
                                <ListItemText className={classes.removeMarginPadding}>{option.label}</ListItemText>
                            </CoreFlex>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: (props: StyleProps) => ({
            width: props.fullWidth ? '100%' : props.width ? props.width + 'px' : '',
        }),
        formControl: (props: StyleProps) => ({
            minWidth: props.width + 'px',
            width: '100%',
        }),
        select: {
            minWidth: '100%',
            height: '56px',
        },
        main: {
            borderWidth: '2px',
            borderColor: grey[500],
        },
        error: {
            '& .MuiOutlinedInput-notchedOutline': {
                borderWidth: '2px',
                borderColor: theme.palette.error.main,
            },
        },
        validated: {
            '& .MuiOutlinedInput-notchedOutline': {
                borderWidth: '2px',
                borderColor: green[500],
            },
        },
        image: {
            height: '25px',
        },
        removeMarginPadding: {
            padding: 0,
            margin: 0,
        },
    }),
);
