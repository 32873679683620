import React, { useContext } from 'react';
import { TableRow, TableCell, Theme, Typography, Checkbox } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { OrderItem, Order, OrderStatus } from '../../../../../../../interfaces/order';
import clsx from 'clsx';
import { useStore } from '../../../../../../../services/store';
import { stores } from '../../../../../../../stores';
import { Product } from '../../../../../../../interfaces/product';
import { getTranslation, Language } from '../../../../../../../config/translation';
import CoreFlex from '../../../../../../core/flex/CoreFlex';
import { formatPrice } from '../../../../../../../services/helpers';
import { Country } from '../../../../../../../interfaces/country';
import { COLOR } from '../../../../../../../services/createTheme';
import { grey } from '@material-ui/core/colors';
import CoreButton from '../../../../../../core/button/CoreButton';
import { useCoreAnimations } from '../../../../../../../config/styles/animations';
import { markOrderItemAsSoldOut } from '../../../../../../../microservices/order';
import { AuthContext } from '../../../../../../../context/AuthContext';

interface Props {
    order: Order;
    orderItem: OrderItem;
    onToggleReady: () => void;
}

export default function OrderTableRowDetailsItemsRow({ orderItem, order, onToggleReady }: Props) {
    const classes = useStyles();
    const authContext = useContext(AuthContext);
    const [products] = useStore<Product[]>(stores.products);
    const [language] = useStore<Language>(stores.language);
    const [country] = useStore<Country>(stores.selectedCountry);
    const product = products.find((item) => orderItem.productId === item.id);
    const variation = product?.variations?.find((item) => item.id === orderItem.variation?.variationId);

    async function handleMarkAsSoldOut() {
        if (!orderItem.id) {
            return;
        }
        await markOrderItemAsSoldOut(orderItem.id, authContext);
    }

    return (
        <TableRow className={classes.root}>
            <TableCell id="ready" className={clsx(classes.cell, classes.miniCell)}>
                <Checkbox
                    className={classes.ready}
                    size="medium"
                    classes={{ root: classes.readyRootOverride }}
                    value={Boolean(orderItem.readyAt)}
                    checked={Boolean(orderItem.readyAt)}
                    onClick={onToggleReady}
                    readOnly={order.status !== OrderStatus.COOKING}
                />
            </TableCell>
            <TableCell id="quantity" className={clsx(classes.cell, classes.miniCell)}>
                <Typography variant="h6">{`${orderItem.quantity} x`}</Typography>
            </TableCell>
            <TableCell id="item" className={clsx(classes.cell, classes.nameCell)}>
                <CoreFlex justify="flex-start">
                    <img src={product?.photo} alt={getTranslation(product, language)} className={classes.photo} />
                    <Typography variant="subtitle1">{getTranslation(product, language)}</Typography>
                </CoreFlex>
            </TableCell>
            <TableCell id="unitPrice" className={clsx(classes.cell, classes.smallCell)}>
                <Typography variant="subtitle1">
                    {formatPrice((orderItem.totalPrice || 0) / orderItem.quantity, country, true)}
                </Typography>
            </TableCell>
            <TableCell id="totalPrice" className={clsx(classes.cell, classes.smallCell)}>
                <Typography variant="subtitle1"> {formatPrice(orderItem.totalPrice || 0, country, true)}</Typography>
            </TableCell>
            <TableCell id="customisation" className={classes.cell}>
                <CoreFlex wrap="wrap" direction="row" justify="flex-start">
                    {variation && product?.variations && product?.variations?.length > 1 && (
                        <div className={clsx(classes.customisationBox, classes.variation, classes.animationScale1_2x)}>
                            <Typography variant="subtitle1">
                                {getTranslation(variation, language).toUpperCase()}
                            </Typography>
                        </div>
                    )}
                    {product?.choices?.length &&
                        orderItem?.choices &&
                        orderItem?.choices?.length > 0 &&
                        orderItem?.choices.map((item) => {
                            const choice = product.choices?.find((productChoice) => productChoice.id === item.choiceId);
                            return (
                                <div
                                    className={clsx(
                                        classes.customisationBox,
                                        classes.choice,
                                        classes.animationScale1_2x,
                                    )}
                                    key={item.id}
                                >
                                    <Typography variant="subtitle1">{item.selectionName.toUpperCase()}</Typography>
                                </div>
                            );
                        })}
                    {product?.extras?.length &&
                        orderItem?.extras &&
                        orderItem?.extras?.length > 0 &&
                        orderItem?.extras.map((item) => {
                            const extra = product.extras?.find((productExtra) => productExtra.id === item.extraId);
                            return (
                                <div
                                    className={clsx(
                                        classes.customisationBox,
                                        classes.extra,
                                        classes.animationScale1_2x,
                                    )}
                                    key={item.id}
                                >
                                    <Typography variant="subtitle1">
                                        {getTranslation(extra, language).toUpperCase()}
                                    </Typography>
                                </div>
                            );
                        })}
                    {product?.exclusions?.length &&
                        orderItem?.exclusions &&
                        orderItem?.exclusions?.length > 0 &&
                        orderItem?.exclusions.map((item) => {
                            const exclusion = product.exclusions?.find(
                                (productExclusion) => productExclusion.id === item.exclusionId,
                            );
                            return (
                                <div
                                    className={clsx(
                                        classes.customisationBox,
                                        classes.exclusion,
                                        classes.animationScale1_2x,
                                    )}
                                    key={item.id}
                                >
                                    <Typography variant="subtitle1">
                                        {`NO ${getTranslation(exclusion, language).toUpperCase()}`}
                                    </Typography>
                                </div>
                            );
                        })}
                    {Boolean(orderItem.specialRequest?.length) && (
                        <div className={clsx(classes.customisationBox, classes.request)}>
                            <Typography variant="subtitle1">
                                {(orderItem.specialRequest || '').toUpperCase()}
                            </Typography>
                        </div>
                    )}
                </CoreFlex>
            </TableCell>
            <TableCell id="actions" className={clsx(classes.cell, classes.button)}>
                <CoreFlex justify="flex-end">
                    {(order.status === OrderStatus.RECEIVED || order.status === OrderStatus.COOKING) &&
                        !orderItem.isSoldOut && (
                            <CoreButton label="SOLD OUT" onClick={handleMarkAsSoldOut} customColor={grey[300]} />
                        )}
                    {orderItem.isSoldOut && (
                        <div className={classes.soldOut}>
                            <Typography>SOLD OUT</Typography>
                        </div>
                    )}
                    {(order.status === OrderStatus.DELIVERED || order.status === OrderStatus.PICKED_UP) && (
                        <CoreButton label="REFUND" onClick={() => {}} customColor={grey[300]} />
                    )}
                </CoreFlex>
            </TableCell>
        </TableRow>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderRadius: '1rem 1rem 0rem 0rem',
            borderBottom: '1px solid rgba(0,0,0,0.1)',
            '&:hover': {
                backgroundColor: 'rgba(0,0,0, 0.02)',
            },
        },
        smallCell: { width: '120px' },
        miniCell: { width: '40px' },
        nameCell: { width: '300px' },
        cell: {
            paddingBottom: '0.5rem',
            paddingTop: '0.5rem',
            borderWidth: '0px',
        },
        ready: {
            width: '50px',
            height: '50px',
            transform: 'scale(1.5)',
        },
        readyRootOverride: {
            borderWidth: '0px',
        },
        photo: {
            width: '80px',
            height: '80px',
            marginRight: '1rem',
        },
        customisationBox: {
            borderRadius: '1rem',
            padding: '0rem 1rem',
            margin: '0.5rem 0.75rem',
            flexWrap: 'nowrap',
            color: theme.palette.common.white,
        },
        variation: {
            backgroundColor: COLOR.MunchiesGreen,
        },
        choice: {
            backgroundColor: COLOR.MunchiesBlue,
        },
        extra: {
            backgroundColor: COLOR.MunchiesOrange,
        },
        exclusion: {
            backgroundColor: theme.palette.error.main,
        },
        request: {
            backgroundColor: 'unset',
            borderColor: grey[700],
            borderWidth: '1px',
            borderStyle: 'solid',
            color: grey[700],
        },
        button: {
            width: '150px',
        },
        soldOut: {
            backgroundColor: theme.palette.error.dark,
            borderRadius: '1rem',
            color: theme.palette.common.white,
            padding: '0.1rem 1rem',
        },
        ...useCoreAnimations(theme),
    }),
);
