import React, { useEffect, useState } from 'react';
import CoreFormInput, { CoreFormInputProps } from '../CoreFormInput';
import { useStore } from '../../../../../services/store';
import { stores } from '../../../../../stores';
import { InputType } from '../../../../../interfaces';
import { Typography } from '@material-ui/core';
import { Country } from '../../../../../interfaces/country';

interface Props extends CoreFormInputProps {
    countryId?: number;
    prefix?: string;
    value: string;
    onValueChange: (value: string, event?: any) => void;
}

export default function CoreFormInputMoney({ countryId, prefix, value, onValueChange, ...rest }: Props) {
    const [country, setCountry] = useState<Country>();
    const [countries] = useStore(stores.countries);

    useEffect(() => {
        if (countryId) {
            setCountry(countries.find((item: Country) => item.id === countryId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryId]);

    function handleValueChange(value: string) {
        onValueChange(parseFloat(value) * 100 + '');
    }

    return (
        <CoreFormInput
            {...rest}
            value={parseFloat(value) / 100 + ''}
            type={InputType.number}
            onValueChange={handleValueChange}
            startAdornment={
                <Typography variant="subtitle1">
                    {Boolean(prefix) && prefix}
                    {!prefix && country && country.isSymbolAtFront && country.currencySymbol}
                </Typography>
            }
            endAdornment={
                <Typography variant="subtitle1">
                    {country && !country.isSymbolAtFront
                        ? country.currencySymbol + ' ' + country.currencyName
                        : country && country.currencyName}
                </Typography>
            }
        />
    );
}
