import React from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Typography, Theme } from '@material-ui/core';
import CoreFlex from '../flex/CoreFlex';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        background: (props: Props) => ({
            width: props.size + 'px',
            height: props.size + 'px',
            borderRadius: '50%',
            backgroundColor: props.color || theme.palette.primary.main,
        }),
        text: {
            color: theme.palette.common.white,
        },
    }),
);

interface Props {
    value: number | string;
    className?: string;
    color?: string;
    size?: number;
}

export function CoreRowNumber({ value, color, size = 30, className }: Props) {
    const classes = useStyles({ value, color, size, className });

    return (
        <div className={className}>
            <CoreFlex className={classes.background} justify="center" alignItems="center">
                <Typography variant="h6" className={classes.text}>
                    {value}
                </Typography>
            </CoreFlex>
        </div>
    );
}
