import React, { useState, Fragment, useContext } from 'react';
import { Grid, Paper, makeStyles, CircularProgress } from '@material-ui/core';
import { ValidationType } from '../../../interfaces';
import { yellow } from '@material-ui/core/colors';
import logo from '../../../assets/logo/munchies_logo.png';
import { validate } from '../../../services/validation';
import { registerUser, setStateLoggedIn } from '../../../microservices/auth';
import { RegistrationForm } from '../../../components/registration/RegistrationForm';
import { useDeviceType } from '../../../services/utils';
import { AuthContext } from '../../../context/AuthContext';
import { RegistrationData } from '../../../interfaces/auth';
import { showSuccess } from '../../../services/alert';

export function RegisterPage() {
    const authContext = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [registrationData, setRegistrationData] = useState<RegistrationData>({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: '',
        restaurantId: 0,
        passwordRepeat: '',
    });
    const validators = {
        firstName: () => {
            return validate({
                type: ValidationType.Name,
                value: registrationData.firstName,
            });
        },
        lastName: () => {
            return validate({
                type: ValidationType.Name,
                value: registrationData.lastName,
            });
        },
        email: () => {
            return validate({
                type: ValidationType.Email,
                value: registrationData.email,
            });
        },
        phone: () => {
            return validate({
                type: ValidationType.Phone,
                value: registrationData.phone,
            });
        },
        password: () => {
            return validate({
                type: ValidationType.Password,
                value: registrationData.password,
            });
        },
        passwordRepeat: () => {
            return {
                validated: registrationData.password === registrationData.passwordRepeat,
                message: 'Passwords do not match, please check and try again.',
            };
        },
        restaurant: () => {
            return {
                validated: registrationData.restaurantId || registrationData.restaurantId === 0,
                message: 'Please choose the restaurant your work in.',
            };
        },
    };

    const { isMobile } = useDeviceType();
    const classes = useStyles();

    function handleValueChange(name: keyof RegistrationData, value: string | number | boolean | any[] | undefined) {
        setRegistrationData({ ...registrationData, [name]: value });
    }

    async function handleRegisterAccount() {
        if (
            validators.firstName().validated &&
            validators.email().validated &&
            validators.phone().validated &&
            validators.password().validated &&
            validators.passwordRepeat().validated &&
            validators.restaurant().validated
        ) {
            setIsLoading(true);
            const response = await registerUser(
                {
                    firstName: validators.firstName().value + '',
                    lastName: validators.lastName().value + '',
                    email: (validators.email().value + '').toLowerCase(),
                    phone: validators.phone().value + '',
                    password: validators.password().value + '',
                    passwordRepeat: validators.password().value + '',
                    restaurantId: registrationData.restaurantId,
                },
                authContext,
            );
            setIsLoading(false);
            if (!response) {
                window.alert('Network problem!');
                return;
            }
            if (response && !response.success) {
                window.alert(response.message + '\n' + response.result);
            }

            if (response && response.success) {
                setStateLoggedIn(response, authContext);
                showSuccess('Registration successful!');
            }
        }
    }

    return (
        <Fragment>
            <Grid
                container
                alignItems="center"
                justify="center"
                alignContent="center"
                direction="column"
                className={classes.gridContainer}
            >
                <Grid item className={classes.padding}>
                    {!isMobile && <img src={logo} className={classes.logo} alt="Munchies logo" />}
                </Grid>
                <Grid item>
                    <Paper className={isMobile ? classes.paperMobile : classes.paper} elevation={3}>
                        {isLoading ? (
                            <Grid item>
                                <CircularProgress />
                            </Grid>
                        ) : (
                            <RegistrationForm
                                classes={classes}
                                onValueChange={handleValueChange}
                                onRegisterAccount={handleRegisterAccount}
                                values={registrationData}
                                validation={validators}
                            />
                        )}
                    </Paper>
                </Grid>
            </Grid>
        </Fragment>
    );
}

const useStyles = makeStyles({
    gridContainer: {
        backgroundColor: yellow[600],
        top: '0',
        left: '0',
        bottom: '0',
        right: '0',
        width: '100%',
        height: '100vh',
    },

    paperMobile: {
        position: 'absolute',
        top: '0',
        left: '0',
        bottom: '0',
        right: '0',
        width: '100vw',
        height: '100vh',
        overflowY: 'scroll',
    },

    paper: {
        minWidth: '400px',
        maxWidth: '400px',
        textAlign: 'center',
        transitionDuration: '2s',
        WebkitTransition: '2s',
        transition: 'height 4s',
    },

    logo: {
        margin: '30px',
        width: '200px',
    },

    logoMobile: {
        margin: '10px',
        width: '120px',
    },
    formContainerMobile: {
        padding: '1rem',
        height: '100%',
        display: 'flex',
        width: '100%',
    },

    formContainer: {
        padding: '2.5rem',
        height: '100%',
        display: 'flex',
        width: '100%',
    },
    gridItem: {
        width: '100%',
    },
    padding: {
        padding: '1rem',
    },
});
